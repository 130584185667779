/* eslint-disable react/jsx-indent */
import clsx from "clsx";
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingBar from "react-top-loading-bar";
import Popup from "reactjs-popup";

import {
  Controller, SubmitHandler, useFieldArray, useForm,
} from "react-hook-form";
import { useDebounce } from "../hooks";

import { ReactComponent as ChevronDownIcon } from "../assets/icons/ic_chevron_down.svg";
import { ReactComponent as CloseIcon } from "../assets/icons/ic_close.svg";
import { ReactComponent as DetailIcon } from "../assets/icons/ic_detail.svg";
import { ReactComponent as QuestionIcon } from "../assets/icons/ic_help_outline.svg";
import { ReactComponent as SearchIcon } from "../assets/icons/ic_search.svg";
import { ReactComponent as ThumbnailIcon } from "../assets/icons/ic_thumbnail.svg";
import { ReactComponent as ThumbnailVideoIcon } from "../assets/icons/ic_thumbnail_video.svg";
import { ReactComponent as TrashIcon } from "../assets/icons/ic_trash.svg";

import { useAppSelector } from "../app/hooks";
import {
  DropUploader, LoadingAnimation, ModalConfirmation,
} from "../components";
import { Select, Tooltip } from "../components/atoms";
import {
  AddItemRequest,
  useGetAllModifierQuery, useGetIngredientsListQuery, useGetItemQuery,
  useImageMutation,
  useNutritionListQuery,
  usePostItemMutation,
  useUpdateItemMutation, useUpdateItemPhotoMutation,
  useUpdateItemVideoMutation,
  useUserInfoQuery, useVideoMutation,
} from "../services";

import { getBreakpointValue } from "../utils";

interface Confirmation {
  isOpen: boolean;
  data: AddItemRequest | "close" | null;
}

type NutritionLabel = {
  id: string;
  label: string;
  defaultUnit: string;
}

const nutritionLabels: NutritionLabel[] = [
  {
    defaultUnit: "g",
    id: "nutri_totfat",
    label: "Total Fat",
  },
  {
    defaultUnit: "g",
    id: "nutri_saturfat",
    label: "Saturated Fat",
  },
  {
    defaultUnit: "g",
    id: "nutri_transfat",
    label: "Trans Fat",
  },
  {
    defaultUnit: "mg",
    id: "nutri_choles",
    label: "Cholesterol",
  },
  {
    defaultUnit: "mg",
    id: "nutri_sodium",
    label: "Sodium",
  },
  {
    defaultUnit: "g",
    id: "nutri_totcarbo",
    label: "Total Carbohydrate",
  },
  {
    defaultUnit: "g",
    id: "nutri_dietfiber",
    label: "Dietary Fiber",
  },
  {
    defaultUnit: "g",
    id: "nutri_totsugar",
    label: "Total Sugars",
  },
  {
    defaultUnit: "mcg",
    id: "nutri_vitd",
    label: "Vitamin D",
  },
  {
    defaultUnit: "mcg",
    id: "nutri_vitc",
    label: "Vitamin C",
  },
  {
    defaultUnit: "g",
    id: "nutri_protein",
    label: "Protein",
  },
  {
    defaultUnit: "mg",
    id: "nutri_calcium",
    label: "Calcium",
  },
  {
    defaultUnit: "mg",
    id: "nutri_iron",
    label: "Iron",
  },
  {
    defaultUnit: "mg",
    id: "nutri_potass",
    label: "Potassium",
  },
];

function ItemsAddPage() {
  const [isIngredientsWarningOpen, setIsIngredientsWarningOpen] = useState(false);
  const [isShowNutritionInfo, setShowNutritionInfo] = useState<boolean>(true);
  const [nutritionFormHeight, setNutritionFormHeight] = useState<number>(0);
  const [checkedIngredients, setCheckedIngredients] = useState<string[]>([]);
  const [prevCheckedIngredients, setPrevCheckedIngredients] = useState<string[]>([]);
  const [keyword, setKeyword] = useState<string>("");
  const [modalConfirmation, setModalConfirmation] = useState<Confirmation>({
    isOpen: false,
    data: null,
  });

  const uid = useAppSelector(({ auth }: any) => auth.uid);
  const { state } = useLocation<StateID>();
  const { goBack } = useHistory();
  const isMobile = getBreakpointValue("md") > window.innerWidth;
  const debouncedKeyword = useDebounce<string>(keyword, 500);
  const ref = useRef(null);
  const nutritionFormRef = useRef<HTMLDivElement | null>(null);

  const {
    register, control, handleSubmit, setValue, reset, formState,
  } = useForm<AddItemRequest>({
    defaultValues: {
      category_id: state?.category_id,
      section_id: state?.section_id,
      name: "",
      description: "",
      image: null,
      video: null,
      prices: [
        {
          price: 0,
          description: "",
        },
      ],
      prep_time: 0,
      modifiers: [],
      isNew: false,
      isSignature: false,
      isPublished: true,
      calories: 0,
      ingredients_warning: [],
      is_nutrition_published: false,
      nutri_serving_size: null,
      nutri_serving_unit_id: "g",
      nutri_calories: null,
      nutri_calories_fat: null,
      nutritions: [],
    },
  });

  const {
    fields: priceFields,
    append: appendPrice,
    update: updatePrice,
    remove: removePrice,
  } = useFieldArray({
    name: "prices",
    control,
    keyName: "key",
  });

  const {
    fields: modifierFields,
    append: appendModifier,
    remove: removeModifier,
    update: updateModifier,
  } = useFieldArray({
    name: "modifiers",
    control,
  });

  const {
    fields: nutritionFields,
  } = useFieldArray({
    name: "nutritions",
    control,
  });

  // const [modifiers, setModifiers] = useState<ItemModifier[]>([]);
  const [postImage, { isLoading: isLoadingImage, isError: isErrorImage }] = useImageMutation();
  const [postVideo, { isLoading: isLoadingVideo, isError: isErrorVideo }] = useVideoMutation();
  const [createItem, { isLoading: isLoadingCreateItem }] = usePostItemMutation();
  const [updateItem, { isLoading: isLoadingUpdateItem }] = useUpdateItemMutation();
  const { data: dataModifiers, isLoading: isLoadingModifiers } = useGetAllModifierQuery();
  const { data: dataUserInfo, isLoading: isLoadingUserInfo } = useUserInfoQuery();
  const { data: dataItem, isLoading: isLoadingItems } = useGetItemQuery(state?.item_id ?? "", {
    skip: !state?.item_id ?? true,
  });
  const [updateItemPhoto, { isLoading: isLoadingUpdateItemPhoto }] = useUpdateItemPhotoMutation();
  const [updateItemVideo, { isLoading: isLoadingUpdateItemVideo }] = useUpdateItemVideoMutation();
  const { data: ingredientWarningsData } = useGetIngredientsListQuery();
  const { data: nutritionListData } = useNutritionListQuery();

  /**
   * Function to handle set keyword in search ingredients
   */
  const handleSearchIngredients = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value);
  }, []);

  /**
   * Function to handle open modal confirmation and send data to callbackConfirmation function
   * @param {AddItemRequest} data
   */
  const onSubmit: SubmitHandler<AddItemRequest> = (data) => {
    const priceDescriptionLength = data.prices.filter((price) => !!price.description).length;

    if (priceFields.length > 1 && priceDescriptionLength !== data.prices.length) {
      toast.error("Price definition is required");
      return;
    }

    if (data.prices.length === 1 && data.prices[0].description) {
      data.prices[0].description = "";
    }

    if (data.video && !data.image) {
      toast.error("Image is required if you upload video");
      return;
    }

    setModalConfirmation({ isOpen: true, data });
  };

  /**
   * Function to handle callback confirmation from onSubmit function
   */
  const callbackConfirmation = () => {
    const { data } = modalConfirmation;

    if (data === "close") {
      goBack();
      return;
    }
    if (!data) return;

    const mergedData: AddItemRequest = {
      ...data,
      category_id: state?.category_id,
      section_id: state?.section_id ?? "",
      prep_time: data.prep_time ?? 0,
      id: state?.item_id,
      nutri_calories: data.nutri_calories === 0 ? null : data.nutri_calories,
      nutri_calories_fat: data.nutri_calories_fat === 0 ? null : data.nutri_calories_fat,
      nutri_serving_size: data.nutri_serving_size === 0 ? null : data.nutri_serving_size,
      nutritions: data.nutritions.map((item) => {
        delete item.unit;
        return item;
      }).filter((item) => {
        if (!item.value && !item.daily_value) {
          return null;
        }

        return item;
      }).filter((item) => item),
    };

    if ((state?.category_id && state?.section_id && !state?.item_id) || (state?.category_id && !state?.item_id)) {
      createItem(mergedData)
        .unwrap()
        .then(() => goBack())
        .catch((error: any) => {
          toast.error(error.data?.message ?? "Failed to create item");
        });
    }

    if (
      (state?.category_id && state?.section_id && state?.item_id)
      || (state?.category_id && state?.item_id)
      || state?.item_id
    ) {
      const id = state.item_id ?? "";
      const { image, video } = data;

      let firstUpdate = dataItem?.data?.image === image
        ? Promise.resolve({}) : updateItemPhoto({ id, photo: image }).unwrap();

      if (dataItem?.data?.image && image === "") {
        firstUpdate = updateItemPhoto({ id, photo: null }).unwrap();
      }

      let secondUpdate = dataItem?.data?.video === video
        ? Promise.resolve({}) : updateItemVideo({ id, video }).unwrap();

      if (dataItem?.data?.video && video === "") {
        secondUpdate = updateItemVideo({ id, video: null }).unwrap();
      }

      const thirdUpdate = updateItem(mergedData).unwrap();

      Promise.all([firstUpdate, secondUpdate, thirdUpdate])
        .then(() => {
          goBack();
        })
        .catch((error) => {
          toast.error(error.data?.message ?? "Failed to update item");
        });
    }

    // if (state?.item_id) {
    //   const id = state.item_id;
    //   const { image, video } = data;

    //   let firstUpdate =
    //     dataItem?.data?.image === image ? Promise.resolve({}) : updateItemPhoto({ id, photo: image }).unwrap();

    //   if (dataItem?.data?.image && image === "") {
    //     firstUpdate = updateItemPhoto({ id, photo: null }).unwrap();
    //   }

    //   let secondUpdate =
    //     dataItem?.data?.video === video ? Promise.resolve({}) : updateItemVideo({ id, video }).unwrap();

    //   if (dataItem?.data?.video && video === "") {
    //     secondUpdate = updateItemVideo({ id, video: null }).unwrap();
    //   }

    //   const thirdUpdate = updateItem(mergedData).unwrap();

    //   Promise.all([firstUpdate, secondUpdate, thirdUpdate])
    //     .then(() => {
    //       goBack();
    //     })
    //     .catch((error) => {
    //       toast.error(error.data?.message ?? "Failed to update item");
    //     });
    // }

    // setModalConfirmation((prevState) => ({ ...prevState, isOpen: false}));
    setModalConfirmation({
      isOpen: false,
      data: null,
    });
  };

  /**
   * Function to handle upload image
   * @param {string} path
   */
  const uploadImage = (path: string) => {
    if (path === "") {
      setValue("image", "", {
        shouldDirty: true,
      });
      return;
    }
    if (!uid) return;

    const formData = new FormData();
    formData.append("uid", uid);
    formData.append("img", path);

    postImage(formData)
      .unwrap()
      .then((res) => {
        if (res.data?.image_id) {
          setValue("image", res.data.image_id, {
            shouldDirty: true,
          });
        }
      })
      .catch((error) => {
        toast.error(error.data?.message ?? "Failed to upload");
        setValue("image", "");
      });
  };

  /**
   * Function to handle upload video
   * @param {string} path
   */
  const uploadVideo = (path: string) => {
    if (path === "") {
      setValue("video", "", {
        shouldDirty: true,
      });
      return;
    }
    if (!uid) return;

    const formData = new FormData();
    formData.append("uid", uid);
    formData.append("video", path);

    postVideo(formData)
      .unwrap()
      .then((res) => {
        if (res.data?.video_id) {
          setValue("video", res.data.video_id, {
            shouldDirty: true,
          });
        }
      })
      .catch((error) => {
        toast.error(error.data?.message ?? "Failed to upload");
        setValue("video", "", {
          shouldDirty: true,
        });
      });
  };

  /**
   * Function to handle check ingredients warning
   * @param {string} id
   */
  const handleChecked = (id: string) => {
    setCheckedIngredients((prevState) => {
      const newState = [...prevState];
      const index = newState.indexOf(id);

      if (index > -1) {
        newState.splice(index, 1);
      } else {
        newState.push(id);
      }

      return newState;
    });
  };

  /**
   * Function to handle save ingredients warning
   */
  const handleSaveIngredients = () => {
    setPrevCheckedIngredients(checkedIngredients);
    setValue("ingredients_warning", checkedIngredients, {
      shouldDirty: true,
    });
    setIsIngredientsWarningOpen(false);
  };

  /**
   * Function to handle close modal ingredients warning
   */
  const handleCloseIngredientsModal = () => {
    if (!dataItem?.data) {
      setIsIngredientsWarningOpen(false);
      return;
    }

    if (
      JSON.stringify(prevCheckedIngredients).toLocaleLowerCase()
      !== JSON.stringify(checkedIngredients).toLocaleLowerCase()
    ) {
      setCheckedIngredients(prevCheckedIngredients);
      setIsIngredientsWarningOpen(false);
      return;
    }

    setIsIngredientsWarningOpen(false);
  };

  /**
   * Function to get list of ingredients warning
   */
  const ingredientsWarning = useMemo(() => {
    if (!ingredientWarningsData?.data) return [];

    // Create copy of ingredients list
    const copiedIngredients = [...ingredientWarningsData.data];

    if (debouncedKeyword !== "") {
      const filteredIngredients = copiedIngredients
        .filter((ingredient) => ingredient.name.toLowerCase().includes(debouncedKeyword.toLowerCase()))
        .sort((a, b) => (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1));

      if (filteredIngredients.length > 0) {
        return filteredIngredients;
      }

      return copiedIngredients.sort((a, b) => (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1));
    }

    return copiedIngredients.sort((a, b) => (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1));
  }, [debouncedKeyword, ingredientWarningsData]);

  useEffect(() => {
    if (dataItem?.data?.ingredients_warning) {
      setCheckedIngredients(dataItem.data.ingredients_warning);
      setPrevCheckedIngredients(dataItem.data.ingredients_warning);
    }
  }, [dataItem]);

  useEffect(() => {
    if (state?.item_id && dataItem?.data && nutritionListData?.data) {
      const {
        id,
        name,
        description,
        image,
        video,
        prices,
        prep_time,
        modifiers,
        is_new,
        is_signature,
        calories,
        ingredients_warning,
        publish_status,
        is_nutrition_published,
        nutri_calories,
        nutri_calories_fat,
        nutri_serving_size,
        nutri_serving_unit_id,
        nutritions,
      } = dataItem.data;

      const item: AddItemRequest = {
        id,
        name,
        description,
        image: image ?? "",
        video: video ?? "",
        prices,
        prep_time: prep_time ?? "",
        modifiers,
        isNew: is_new,
        isSignature: is_signature,
        calories,
        ingredients_warning,
        isPublished: publish_status,
        is_nutrition_published,
        nutri_calories,
        nutri_calories_fat,
        nutri_serving_size,
        nutri_serving_unit_id: nutri_serving_unit_id || "g",
        nutritions: nutritionListData.data.map(({ unit, id: nutriId }) => {
          const nutrition = nutritions.find((nutri) => nutri.nutri_id === nutriId);
          const defaultNutrition = nutritionLabels.find((nutri) => nutri.id === nutriId);

          if (nutrition) {
            return {
              ...nutrition,
              nutri_id: nutriId,
              value: nutrition.value,
              daily_value: nutrition.daily_value,
              unit,
            };
          }

          return {
            nutri_id: nutriId,
            value: null,
            daily_value: null,
            unit: defaultNutrition?.defaultUnit ?? "",
          };
        }),
      };

      reset(item);
    }
  }, [state?.item_id, dataItem, reset]);

  useEffect(() => {
    const progress = ref.current as any;

    if (isLoadingModifiers || isLoadingItems || isLoadingUserInfo || isLoadingCreateItem || isLoadingUpdateItem) {
      progress?.continuousStart();
    } else {
      progress?.complete();
    }
  }, [isLoadingModifiers, isLoadingItems, isLoadingUserInfo, isLoadingCreateItem, isLoadingUpdateItem]);

  useEffect(() => {
    if (Object.keys(formState.errors).length > 0) {
      Object.keys(formState.errors).forEach((err) => {
        if (err === "prep_time" && formState.errors[err]?.type === "required") {
          toast.error("'Preparation Time' is required", {
            toastId: "prep_time",
          });
        }
      });
    }
  }, [formState]);

  useEffect(() => {
    const idNull = modifierFields.find((field) => field.modifier_id === "");

    if (idNull) {
      updateModifier(modifierFields.indexOf(idNull), {
        modifier_id: dataModifiers?.data?.[0].id ?? "",
        min: idNull.min,
        max: idNull.max,
      });
    }
  }, [modifierFields]);

  useEffect(() => {
    if (nutritionFormRef?.current) {
      setNutritionFormHeight(nutritionFormRef.current.scrollHeight);
    }
  }, [nutritionFormRef?.current, nutritionFields]);

  return (
    <>
      <LoadingBar height={4} color="#0078D3" ref={ref} />
      <div className="flex flex-col flex-1">
        <div className="flex justify-between p-4 border-b border-line-gray">
          <div className="flex items-center">
            <button type="button" title="Back" className="bg-transparent" onClick={goBack}>
              <ChevronDownIcon className="h-6 w-6 transform rotate-90 mr-2" />
            </button>
            <h2 className="font-bold text-2xl">{state?.item_id ? "Update Item" : "Add New Item"}</h2>
          </div>

          <div className="hidden md:flex">
            <button
              type="button"
              title="Cancel"
              className="button-gray py-2 px-4 mr-4"
              onClick={() => {
                if (formState.isDirty) {
                  setModalConfirmation({ isOpen: true, data: "close" });
                } else {
                  goBack();
                }
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="button-primary py-2 px-4"
              onClick={handleSubmit(onSubmit)}
              disabled={!formState.isDirty
                || isLoadingImage
                || isLoadingVideo
                || isLoadingCreateItem
                || isLoadingUpdateItem
                || isLoadingUpdateItemPhoto
                || isLoadingUpdateItemVideo
                || isLoadingImage
                || isLoadingVideo}
            >
              {isLoadingCreateItem
              || isLoadingUpdateItem
              || isLoadingUpdateItemPhoto
              || isLoadingUpdateItemVideo
              || isLoadingImage
              || isLoadingVideo ? (
                  <div className="flex">
                    <LoadingAnimation size={5} />
                    {isLoadingImage && <span className="text-sm ml-2 font-normal">uploading image</span>}
                    {isLoadingVideo && <span className="text-sm ml-2 font-normal">uploading video</span>}
                    {(isLoadingCreateItem || isLoadingUpdateItem) && (
                      <span className="text-sm ml-2 font-normal">Saving</span>
                    )}
                  </div>
                ) : (
                  <span>Save</span>
                )}
            </button>
          </div>
        </div>

        <div className="p-4 md:p-6">
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="md:px-4">
              <div className="flex items-center mb-1">
                <DetailIcon />
                <p className="font-semibold text-lg ml-1">Item Details</p>
              </div>
              <p className="text-sm text-secondary opacity-70 mb-7">Fill the item details below</p>

              <div className="flex flex-col md:flex-row mb-7 md:items-center">
                <p className="md:w-4/12 mb-2 md:mb-0 font-medium">Name</p>
                <input
                  type="text"
                  className="input-base flex-1 max-w-xl"
                  placeholder="Add an item name"
                  disabled={isLoadingCreateItem || isLoadingUpdateItem}
                  {...register("name")}
                />
              </div>

              <div className="flex flex-col md:flex-row mb-7">
                <p className="md:w-4/12 mb-2 md:mb-0 font-medium">Description</p>
                <textarea
                  rows={3}
                  className="input-base flex-1 max-w-xl"
                  placeholder="Type a item description"
                  disabled={isLoadingCreateItem || isLoadingUpdateItem}
                  {...register("description")}
                />
              </div>

              <div className="flex mb-7 items-start">
                <p className="hidden md:block w-4/12 font-medium">Photo & video</p>

                <div className="flex flex-1 mr-4 md:mr-8">
                  <div className="flex flex-col w-full">
                    <p className="font-semibold text-lg ml-1 mb-4 flex items-center">
                      <ThumbnailIcon />
                    Photo
                    </p>

                    <DropUploader
                      type="primary"
                      withText
                      size="big"
                      setFilePath={uploadImage}
                      preview={dataItem?.data?.image}
                      uploadFailed={isErrorImage}
                    />
                    <input type="text" {...register("image")} className="hidden" />
                  </div>
                </div>

                <div className="flex flex-1">
                  <div className="flex flex-col w-full">
                    <p className="font-semibold text-lg ml-1 mb-4 flex items-center">
                      <ThumbnailVideoIcon />
                    Video
                    </p>

                    <DropUploader
                      type="primary"
                      withText
                      size="big"
                      accept="video"
                      setFilePath={uploadVideo}
                      preview={dataItem?.data?.video}
                      uploadFailed={isErrorVideo}
                    />
                    <input type="text" {...register("video")} className="hidden" />
                  </div>
                </div>
              </div>

              <div className="flex flex-col md:flex-row mb-7">
                <p className="md:w-4/12 mb-2 md:mb-0 font-medium">Price(s)</p>

                <div className="flex flex-1 flex-col gap-2 md:gap-4 items-start max-w-xl">
                  {priceFields.map((field, index) => {
                    const hideDeleteButtonAndInputDescription = priceFields.length === 1 && index === 0;

                    return (
                      <div
                        className="flex flex-1 gap-2 md:gap-4 w-full shadow-md md:shadow-none rounded p-3 md:p-0"
                        key={field.key}
                      >
                        <div className="flex items-center">
                          {field.id && (
                            <input type="text" className="hidden" {...register(`prices.${index}.id` as const)} />
                          )}
                          <input
                            type="number"
                            min="0"
                            disabled={isLoadingCreateItem || isLoadingUpdateItem}
                            className="input-base text-sm rounded-r-none border-r-0"
                            placeholder="0.00"
                            defaultValue={field.price}
                            {...register(`prices.${index}.price` as const, {
                              required: true,
                              valueAsNumber: true,
                            })}
                          />
                          <div className="p-2 border bg-line-gray h-full rounded-r-md text-sm">
                            {dataUserInfo?.data?.main_currency ?? "USD"}
                          </div>
                        </div>

                        <input
                          type="text"
                          className={clsx(
                            "input-base text-sm rounded",
                            hideDeleteButtonAndInputDescription && "!opacity-0",
                          )}
                          placeholder="Description (e.g. Large, Medium, Small)"
                          defaultValue={field.description}
                          disabled={isLoadingCreateItem || isLoadingUpdateItem || hideDeleteButtonAndInputDescription}
                          {...register(`prices.${index}.description` as const)}
                        />

                        <button
                          type="button"
                          title="Delete"
                          disabled={isLoadingCreateItem || isLoadingUpdateItem}
                          className={
                            hideDeleteButtonAndInputDescription
                              ? "opacity-0 p-2 cursor-not-allowed"
                              : "bg-red-100 border border-red-500 rounded p-2 text-center"
                          }
                          onClick={() => !hideDeleteButtonAndInputDescription && removePrice(index)}
                        >
                          <TrashIcon className="text-red-500" />
                        </button>
                      </div>
                    );
                  })}
                  <button
                    type="button"
                    title="Add Price"
                    disabled={isLoadingCreateItem || isLoadingUpdateItem}
                    className="button-link w-full md:w-auto mt-2 md:mt-0"
                    onClick={() => appendPrice({ price: 0, description: "" })}
                  >
                  Add Price
                  </button>
                </div>
              </div>

              <div className="flex flex-col md:flex-row mb-7">
                <p className="md:w-4/12 mb-2 md:mb-0 font-medium">Preparation Time</p>
                <div className="flex items-center">
                  <input
                    type="number"
                    min="0"
                    className="input-base text-sm flex-grow rounded-r-none border-r-0 md:w-24"
                    placeholder="0"
                    disabled={isLoadingCreateItem || isLoadingUpdateItem}
                    {...register("prep_time", {
                      valueAsNumber: true,
                    })}
                  />
                  <div className="p-2 border bg-line-gray h-full rounded-r-md text-sm">Min</div>
                </div>
              </div>

              <div className="flex flex-col md:flex-row mb-7">
                <p className="md:w-4/12 mb-2 md:mb-0 font-medium">Calories</p>
                <div className="flex items-center">
                  <input
                    type="number"
                    min="0"
                    max={9999}
                    maxLength={4}
                    disabled={isLoadingCreateItem || isLoadingUpdateItem}
                    className="input-base text-sm flex-grow rounded-r-none border-r-0 md:w-24"
                    placeholder="0"
                    {...register("calories", {
                      valueAsNumber: true,
                      required: true,
                    })}
                  />
                  <div className="p-2 border bg-line-gray h-full rounded-r-md text-sm">Cal</div>
                </div>
              </div>

              {(dataModifiers?.data ?? []).length > 0 && (
                <div className="flex flex-col md:flex-row mb-7">
                  <p className="md:w-4/12 mb-2 md:mb-0 font-medium">Modifiers</p>

                  <div className="flex flex-1 flex-col gap-2 md:gap-4 items-start max-w-xl">
                    {modifierFields.map((field, index) => (
                      <div
                        className="flex flex-wrap md:flex-nowrap flex-1 justify-between gap-2 md:gap-4 w-full shadow-md md:shadow-none rounded p-3 md:p-0"
                        key={field.id}
                      >
                        <div className="flex flex-col flex-grow w-full">
                          <p className="hidden md:block text-secondary text-sm mb-2">Modifiers</p>
                          <Controller
                            control={control}
                            name={`modifiers.${index}.modifier_id`}
                            render={({
                              field: {
                                onChange, value, name, ref: controllerRef,
                              },
                            }) => (
                              <Select
                                name={name}
                                options={
                                  dataModifiers?.data?.map((mod) => ({
                                    value: mod.id,
                                    label: mod.group_name,
                                  })) ?? []
                                }
                                disabled={isLoadingCreateItem || isLoadingUpdateItem}
                                selectRef={controllerRef}
                                customClass="input-base text-sm"
                                onChange={onChange}
                                defaultValue={value}
                              />
                            )}
                          />
                        </div>

                        <div className="flex flex-col w-3/12 md:w-2/12">
                          <p className="text-secondary text-sm">Min</p>
                          <input
                            type="number"
                            min="0"
                            disabled={isLoadingCreateItem || isLoadingUpdateItem}
                            className="input-base text-sm rounded mt-2"
                            defaultValue={modifierFields[index].min}
                            {...register(`modifiers.${index}.min` as const, {
                              required: true,
                              valueAsNumber: true,
                            })}
                          />
                        </div>

                        <div className="flex flex-col w-3/12 md:w-2/12">
                          <p className="text-secondary text-sm">Max</p>
                          <input
                            type="number"
                            disabled={isLoadingCreateItem || isLoadingUpdateItem}
                            className="input-base text-sm rounded mt-2"
                            defaultValue={modifierFields[index].max}
                            {...register(`modifiers.${index}.max` as const, {
                              required: true,
                              valueAsNumber: true,
                            })}
                          />
                        </div>

                        <button
                          type="button"
                          title="Delete"
                          disabled={isLoadingCreateItem || isLoadingUpdateItem}
                          className="bg-red-100 border border-red-500 rounded p-2 text-center self-end"
                          onClick={() => removeModifier(index)}
                        >
                          <TrashIcon className="text-red-500" />
                        </button>
                      </div>
                    ))}

                    <button
                      type="button"
                      title="Add Modifier"
                      disabled={isLoadingCreateItem || isLoadingUpdateItem}
                      className="button-link w-full md:w-auto mt-2 md:mt-0"
                      onClick={() => appendModifier({
                        modifier_id: "",
                        min: 0,
                        max: 0,
                      })}
                    >
                    Add Modifier
                    </button>
                  </div>
                </div>
              )}

              <div className="flex flex-col md:flex-row mb-7">
                <p className="md:w-4/12 mb-2 md:mb-0 font-medium flex flex-row items-center">
                Ingredients Warning

                  <Tooltip content="Max 5 selected ingredients" placement="top" theme="light" className="shadow-md">
                    <QuestionIcon className="mx-1 w-4 h-4 cursor-pointer" />
                  </Tooltip>
                </p>

                <button
                  type="button"
                  disabled={isLoadingCreateItem || isLoadingUpdateItem}
                  title={checkedIngredients.length > 0 ? "Edit Ingredients warning" : "Add Ingredients warning"}
                  className="button-link w-full md:w-auto mt-2 md:mt-0"
                  onClick={() => setIsIngredientsWarningOpen(!isIngredientsWarningOpen)}
                >
                  {checkedIngredients.length > 0 ? "Edit Ingredients warning" : "Add Ingredients warning"}
                </button>
              </div>

              <div className="flex flex-col md:flex-row mb-7">
                <p className="md:w-4/12 mb-2 md:mb-0 font-medium">Label</p>
                <div className="flex flex-col gap-1">
                  <div className="flex items-center">
                    <p className="md:w-48 text-secondary flex flex-1 items-center">
                    Mark as &quot;New&quot;
                      <QuestionIcon className="ml-1 w-4 h-4" />
                    </p>
                    <div className="relative inline-block w-8 mr-2 align-middle select-none">
                      <input
                        {...register("isNew")}
                        type="checkbox"
                        id="isNew"
                        disabled={isLoadingCreateItem || isLoadingUpdateItem}
                        className="toggle-checkbox absolute block w-4 h-4 rounded-full bg-white border-2 appearance-none transition-all duration-300 ease-in cursor-pointer"
                      />
                      <label
                        htmlFor="isNew"
                        className="toggle-label block overflow-hidden h-4 rounded-full bg-gray-300 transition-all duration-300 ease-in cursor-pointer"
                      />
                    </div>
                    <p className="font-normal">Yes</p>
                  </div>
                  <div className="flex items-center">
                    <p className="md:w-48 text-secondary flex flex-1 items-center">
                    Mark as &quot;Signature&quot;
                      <QuestionIcon className="ml-1 w-4 h-4" />
                    </p>
                    <div className="relative inline-block w-8 mr-2 align-middle select-none">
                      <input
                        {...register("isSignature")}
                        type="checkbox"
                        id="isSignature"
                        disabled={isLoadingCreateItem || isLoadingUpdateItem}
                        className="toggle-checkbox absolute block w-4 h-4 rounded-full bg-white border-2 appearance-none transition-all duration-300 ease-in cursor-pointer"
                      />
                      <label
                        htmlFor="isSignature"
                        className="toggle-label block overflow-hidden h-4 rounded-full bg-gray-300 transition-all duration-300 ease-in cursor-pointer"
                      />
                    </div>
                    <p className="font-normal">Yes</p>
                  </div>
                </div>
              </div>

              <div className="flex justify-between md:justify-start mb-4 items-center">
                <p className="md:w-4/12 mb-2 md:mb-0 font-medium">Publish Status</p>
                <div className="flex items-center">
                  <div className="relative inline-block w-8 mr-2 align-middle select-none">
                    <input
                      {...register("isPublished")}
                      type="checkbox"
                      disabled={isLoadingCreateItem || isLoadingUpdateItem}
                      id="isPublished"
                      className="toggle-checkbox absolute block w-4 h-4 rounded-full bg-white border-2 appearance-none transition-all duration-300 ease-in cursor-pointer"
                      defaultChecked={false}
                    />
                    <label
                      htmlFor="isPublished"
                      className="toggle-label block overflow-hidden h-4 rounded-full bg-gray-300 transition-all duration-300 ease-in cursor-pointer"
                    />
                  </div>
                  <p>Published</p>
                </div>
              </div>

              <div className="flex justify-between md:justify-start mb-7 items-center">
                <p className="md:w-4/12 mb-2 md:mb-0 font-medium">Publish Nutrition Facts</p>
                <div className="flex items-center">
                  <div className="relative inline-block w-8 mr-2 align-middle select-none">
                    <input
                      {...register("is_nutrition_published")}
                      type="checkbox"
                      disabled={isLoadingCreateItem || isLoadingUpdateItem}
                      id="is_nutrition_published"
                      className="toggle-checkbox absolute block w-4 h-4 rounded-full bg-white border-2 appearance-none transition-all duration-300 ease-in cursor-pointer"
                      defaultChecked={false}
                    />
                    <label
                      htmlFor="is_nutrition_published"
                      className="toggle-label block overflow-hidden h-4 rounded-full bg-gray-300 transition-all duration-300 ease-in cursor-pointer"
                    />
                  </div>
                  <p>Published</p>
                </div>
              </div>
            </div>

            <div
              id="nutrition-info"
              className="border rounded-md max-w-7xl overflow-hidden transition-[height] duration-300 ease-in-out"
              style={{
                height: isShowNutritionInfo ? `${nutritionFormHeight + 50}px` : "50px",
              }}
            >
              <button
                type="button"
                onClick={() => setShowNutritionInfo((prevState) => !prevState)}
                className="w-full flex items-center justify-between bg-white-secondary px-4 py-3 border-b rounded-t-md"
              >
                <span className="text-[17px] text-black">Nutrition Info</span>
                <ChevronDownIcon className={clsx(
                  "w-4 h-4 transition-all duration-300 ease-in-out",
                  isShowNutritionInfo ? "rotate-180" : "",
                )}
                />
              </button>

              <div
                ref={nutritionFormRef}
              >
                <div className="flex flex-col p-4 border-b md:py-8">
                  <div className="flex flex-col md:flex-row mb-4 md:items-center">
                    <p className="md:w-4/12 mb-2 md:mb-0 font-medium">Serving Size</p>
                    <div className="w-full md:max-w-sm flex items-center">
                      <input
                        type="number"
                        min="0"
                        max={9999}
                        maxLength={4}
                        disabled={isLoadingCreateItem || isLoadingUpdateItem}
                        className="input-base text-sm flex-grow rounded-r-none border-r-0 w-full"
                        placeholder="e.g. 4 Cookies ( 300g )"
                        {...register("nutri_serving_size", {
                          valueAsNumber: true,
                        })}
                      />

                      <Controller
                        control={control}
                        name="nutri_serving_unit_id"
                        render={({
                          field: {
                            onChange, value, name, ref: controllerRef,
                          },
                        }) => (
                          <select
                            name={name}
                            disabled={isLoadingCreateItem || isLoadingUpdateItem}
                            ref={controllerRef}
                            onChange={onChange}
                            value={value?.toString()}
                            className="p-2 border border-line-gray bg-line-gray h-full rounded-r-md rounded-l-0 text-sm"
                          >
                            {["g", "mg", "pcs"].map((unit, index) => (
                              <option value={unit} key={index.toString()}>{unit}</option>
                            ))}
                          </select>
                        )}
                      />
                    </div>
                  </div>

                  <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                    <div className="flex flex-1 flex-col md:flex-row md:items-center md:justify-between">
                      <p className="md:w-4/12 mb-2 md:mb-0 font-medium">Calories</p>

                      <div className="flex flex-col md:flex-row md:items-center md:justify-between flex-1">
                        <div className="flex items-center mb-4 md:mb-0">
                          <input
                            type="number"
                            min="0"
                            max={9999}
                            maxLength={4}
                            disabled={isLoadingCreateItem || isLoadingUpdateItem}
                            className="input-base text-sm flex-grow rounded-r-none border-r-0 md:w-24"
                            placeholder="0"
                            {...register("nutri_calories", {
                              valueAsNumber: true,
                            })}
                          />
                          <span className="p-2 border bg-line-gray h-full rounded-r-md text-sm text-center w-12">Cal</span>
                        </div>

                        <div className="flex flex-col md:gap-4 md:w-2/3 lg:w-2/4 md:flex-row md:items-center md:ml-4 md:justify-between">
                          <p className="mb-2 md:mb-0 font-medium">Calories from fat</p>
                          <div className="flex items-center">
                            <input
                              type="number"
                              min="0"
                              max={9999}
                              maxLength={4}
                              disabled={isLoadingCreateItem || isLoadingUpdateItem}
                              className="input-base text-sm flex-grow rounded-r-none border-r-0 md:w-24"
                              placeholder="0"
                              {...register("nutri_calories_fat", {
                                valueAsNumber: true,
                              })}
                            />
                            <div className="p-2 border bg-line-gray h-full rounded-r-md text-sm text-center w-12">Cal</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col p-4 pb-0 md:pt-8 md:pb-4">
                  {nutritionFields.map(({
                    nutri_id, daily_value, value, unit,
                  }, index) => (
                    <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-7" key={nutri_id}>
                      <div className="flex flex-1 flex-col md:flex-row md:items-center md:justify-between">
                        <label htmlFor={nutri_id} className="md:w-4/12 mb-2 md:mb-0 font-medium">
                          {nutritionLabels.find(({ id }) => id === nutri_id)?.label ?? ""}
                        </label>

                        <div className="flex flex-col md:flex-row md:items-center md:justify-between flex-1">
                          <div className="flex items-center mb-2 md:mb-0">
                            <input
                              type="number"
                              min="0"
                              id={nutri_id}
                              max={9999}
                              maxLength={4}
                              disabled={isLoadingCreateItem || isLoadingUpdateItem}
                              className="input-base text-sm flex-grow rounded-r-none border-r-0 md:w-24"
                              placeholder="0"
                              defaultValue={value || undefined}
                              {...register(`nutritions.${index}.value`, {
                                valueAsNumber: true,
                              })}
                            />
                            <span className="p-2 border bg-line-gray h-full rounded-r-md text-sm text-center w-12">{unit}</span>
                          </div>

                          <div className="flex flex-col md:gap-4 md:w-2/3 lg:w-2/4 md:flex-row md:items-center md:ml-4 md:justify-between">
                            <label htmlFor={`${nutri_id}-daily_value`} className="mb-2 md:mb-0 font-medium">Daily value</label>
                            <div className="flex items-center">
                              <input
                                type="number"
                                id={`${nutri_id}-daily_value`}
                                min="0"
                                max={9999}
                                maxLength={4}
                                disabled={isLoadingCreateItem || isLoadingUpdateItem}
                                className="input-base text-sm flex-grow rounded-r-none border-r-0 md:w-24"
                                placeholder="0"
                                {...register(`nutritions.${index}.daily_value`, {
                                  valueAsNumber: true,
                                })}
                                defaultValue={daily_value || undefined}
                              />
                              <div className="p-2 border bg-line-gray h-full rounded-r-md text-sm text-center w-12">%</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <p className="p-4 pt-0 text-custom-black max-w-4xl">
                *The % Daily Value tells you how much a nutrient in a serving food contributes to a daily diet.
                  {" "}
                 2000 calories a day is used for general nutrition advice.
                </p>
              </div>
            </div>
          </form>
        </div>

        <button
          type="button"
          className="button-primary py-2 px-4 m-4 mt-0 md:hidden"
          onClick={handleSubmit(onSubmit)}
          disabled={!formState.isDirty || isLoadingImage || isLoadingVideo}
        >
          {isLoadingCreateItem
          || isLoadingUpdateItem
          || isLoadingUpdateItemPhoto
          || isLoadingUpdateItemVideo
          || isLoadingImage
          || isLoadingVideo ? (
              <div className="flex">
                <LoadingAnimation size={5} />
                {isLoadingImage && <span className="text-sm ml-2 font-normal">uploading image</span>}
                {isLoadingVideo && <span className="text-sm ml-2 font-normal">uploading video</span>}
                {(isLoadingCreateItem || isLoadingUpdateItem) && <span className="text-sm ml-2 font-normal">Saving</span>}
              </div>
            ) : (
              <span>Save</span>
            )}
        </button>
      </div>

      {/* Ingedients warning list */}
      <Popup
        modal
        open={isIngredientsWarningOpen}
        onClose={() => setIsIngredientsWarningOpen(false)}
        closeOnEscape
        lockScroll
        contentStyle={{
          // maxHeight: isMobile ? "90%" : "85%",
          maxHeight: "100%",
          maxWidth: isMobile ? "90%" : 558,
          width: "100%",
          overflow: "hidden",
          backgroundColor: "white",
          borderRadius: 8,
        }}
      >
        <div className="w-full md:w-auto inset-0 md:relative pt-4 bg-white">
          <div className="flex items-center justify-center relative pb-4 px-2 md:px-4 border-b">
            <div className="flex flex-col items-center">
              <h2 className="font-bold text-lg">Ingredient Warnings</h2>
              <small>(max 5 selected ingredients)</small>
            </div>

            <button
              type="button"
              className="bg-transparent focus:outline-none absolute right-4"
              onClick={handleCloseIngredientsModal}
              title="Close"
              aria-label="Close"
            >
              <CloseIcon className="h-6 w-6" />
            </button>
          </div>
        </div>

        {/* Search Ingredients */}
        <div id="search-ingredients" className="py-4">
          <div className="flex flex-row items-center relative px-4">
            <input
              type="search"
              className="input-base border-0 shadow-input-ingredient rounded-md w-full"
              placeholder="Search here..."
              onChange={handleSearchIngredients}
            />
            <button
              type="button"
              className="button-primary px-2 py-2 absolute right-6"
              title="Search ingredient"
              aria-label="Search ingredient"
            >
              <SearchIcon className="h-5 w-5" />
            </button>
          </div>
        </div>

        {ingredientWarningsData?.data && ingredientWarningsData.data.length > 0 ? (
          <div className="h-[370px] overflow-y-auto md:h-[350px] lg:h-[370px]">
            <div className="h-full flex flex-row flex-wrap items-start px-4">
              {ingredientsWarning.map((ingredient) => {
                const isChecked = !!checkedIngredients.find((checkedId) => checkedId === ingredient.id);

                return (
                  <div
                    key={ingredient.id}
                    className="flex flex-row items-center py-3 md:py-4 px-1 w-2/4 border-b even:justify-end even:pl-2 even:md:pl-0"
                  >
                    <div
                      key={ingredient.id}
                      className="flex flex-row items-center justify-between group-even:justify-end py-4 w-full max-w-[225px] relative"
                    >

                      <div
                        id="icon-and-name"
                        className={`flex flex-row items-center mr-1 ${
                          !isChecked && checkedIngredients.length === 5 ? "opacity-50" : ""
                        }`}
                      >
                        <Tooltip content={ingredient.name} placement="bottom">
                          <img
                            src={`${process.env.REACT_APP_CDN_URL}/images/ingredients/${ingredient.icon}`}
                            alt={ingredient.name}
                            className="h-[30px] w-[30px] mr-2 md:mr-3"
                            title={ingredient.name}
                          />
                        </Tooltip>

                        <label
                          className="hover:cursor-pointer text-sm md:text-base w-auto text-ellipsis overflow-x-hidden whitespace-nowrap"
                          htmlFor={ingredient.id}
                          title={ingredient.name}
                        >
                          {ingredient.name}
                        </label>
                      </div>

                      <input
                        key={isChecked ? "1" : "0"}
                        type="checkbox"
                        id={ingredient.id}
                        name="checked-modifiers"
                        className="form-tick appearance-none bg-white bg-check h-4 w-4 border border-gray-300 rounded checked:bg-primary min-w-[16px] checked:border-transparent focus:outline-none cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed mr-2 md:mr-4"
                        onChange={() => handleChecked(ingredient.id)}
                        disabled={isChecked ? false : checkedIngredients.length >= 5}
                        defaultChecked={isChecked}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div>No ingredient warnings</div>
        )}

        <div className="flex items-center justify-center gap-2 px-4 py-2">
          <button
            type="button"
            title="Save"
            className="button-primary w-full md:w-auto disabled:cursor-not-allowed"
            onClick={handleSaveIngredients}
            disabled={JSON.stringify(prevCheckedIngredients) === JSON.stringify(checkedIngredients)}
          >
            Save
          </button>

          <button
            type="button"
            title="Cancel"
            className="button-gray w-full md:w-auto"
            onClick={handleCloseIngredientsModal}
          >
            Cancel
          </button>
        </div>
      </Popup>

      <ModalConfirmation
        isOpen={modalConfirmation.isOpen}
        content="Are you sure?"
        onCloseModal={() => setModalConfirmation({ isOpen: false, data: null })}
        onConfirmModal={callbackConfirmation}
        confirmationText={modalConfirmation.data === "close" ? "Close" : "Save"}
      />
    </>
  );
}

export default ItemsAddPage;
