import clsx from "clsx";
import {
  ChangeEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";
import LoadingBar from "react-top-loading-bar";

import { ReactComponent as CurrencyIcon } from "../../assets/icons/ic_currency.svg";
import { ReactComponent as FeedbackIcon } from "../../assets/icons/ic_feedback_setting.svg";
import { ReactComponent as GlobeIcon } from "../../assets/icons/ic_globe.svg";
// import { ReactComponent as CartIcon } from "../../assets/icons/ic_cart.svg";
import { ReactComponent as CardIcon } from "../../assets/icons/ic_cc.svg";
import { ReactComponent as GuestIcon } from "../../assets/icons/ic_guest.svg";
import { ReactComponent as QuestionIcon } from "../../assets/icons/ic_help_outline.svg";
import { ReactComponent as OrdersIcon } from "../../assets/icons/ic_orders.svg";
import { ReactComponent as TableIcon } from "../../assets/icons/ic_tables.svg";

import { useAppSelector } from "../../app/hooks";
import {
  SettingTabletModel,
  useSettingTabletQuery,
  useUpdateSettingTabletMutation,
} from "../../services";
import { Switch, Tooltip } from "../atoms";

interface Props {
  saveSetting: boolean;
  setSaveSetting: (arg0: boolean) => void;
}

function SettingTablet({ saveSetting, setSaveSetting }: Props) {
  const savedUser = useAppSelector(({ auth }) => auth.user);
  const shouldEnabled = savedUser?.features.includes("tablet");
  const ref = useRef(null);

  const { data: dataTablet, isLoading: isLoadingDataTablet, refetch } = useSettingTabletQuery();
  const [updateSettingTablet, { isLoading: isLoadingUpdateSettingTablet }] = useUpdateSettingTabletMutation();

  const [settings, setSettings] = useState<SettingTabletModel>({
    language_is_active: false,
    currency_is_active: false,
    feedback_is_active: false,
    guest_is_active: false,
    // order_is_active: false,
    payment_is_active: false,
    payment_type_array: [
      {
        id: "pay_cash",
        is_display: false,
      },
      {
        id: "pay_credit",
        is_display: false,
      },
    ],
    tables_is_active: false,
    cart_is_active: false,
  });

  const toggleActive = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target;

    // if (name === "order_is_active" && settings.order_is_active) {
    //   setSettings((state) => ({
    //     ...state,
    //     tables_is_active: false,
    //     payment_is_active: false,
    //     guest_is_active: false,
    //     [name]: checked,
    //   }));
    //   return;
    // }
    if (name === "cart_is_active" && settings.cart_is_active) {
      setSettings((state) => ({
        ...state,
        tables_is_active: false,
        payment_is_active: false,
        guest_is_active: false,
        [name]: checked,
      }));
      return;
    }

    setSettings((state) => ({
      ...state,
      [name]: checked,
    }));
  };

  useEffect(() => {
    if (dataTablet?.data) {
      setSettings((state) => ({
        ...state,
        ...dataTablet?.data,
      }));
    }
  }, [dataTablet?.data]);

  useEffect(() => {
    if (saveSetting) {
      updateSettingTablet(settings)
        .unwrap()
        .then((res) => {
          if (res.status === "Success") toast.success("Successfully update tablet settings");
        })
        .catch((err) => toast.error(err.message))
        .finally(() => {
          setSaveSetting(false);
          refetch();
        });
    }
  }, [saveSetting]);

  useEffect(() => {
    const progress = ref.current as any;
    if (isLoadingDataTablet || isLoadingUpdateSettingTablet) {
      progress?.continuousStart();
    } else {
      progress?.complete();
    }
  }, [isLoadingDataTablet, isLoadingUpdateSettingTablet]);

  return (
    <>
      <LoadingBar height={4} color="#0078D3" ref={ref} />
      <div className={clsx(
        "flex flex-col lg:flex-row gap-4 md:gap-16",
        "px-4 md:px-6",
        shouldEnabled ? "" : "opacity-50",
      )}
      >
        <div className="flex flex-col flex-1 gap-8">
          {/* Language */}
          <div className="flex gap-1 items-center justify-between">
            <div className="flex flex-col">
              <div className="flex items-center">
                <GlobeIcon className="h-5 w-5 mr-2" />
                <p className="font-semibold text-lg mt-1">Language</p>
              </div>
              <p className="font-medium">Display Language Button</p>
              <p className="text-sm text-secondary">
                Additional info for this setting can be placed here
              </p>
            </div>
            <div className="flex items-center gap-2">
              <Switch
                name="language_is_active"
                id="toggle-language"
                title="Toggle Language"
                checked={settings?.language_is_active}
                onChange={toggleActive}
                disabled={!shouldEnabled}
              />
              <p className="">Yes</p>
            </div>
          </div>

          {/* Currency */}
          <div className="flex gap-1 items-center justify-between">
            <div className="flex flex-col">
              <div className="flex items-center">
                <CurrencyIcon className="h-5 w-5 mr-2" />
                <p className="font-semibold text-lg mt-1">Currency</p>
              </div>
              <p className="font-medium">Display Prices</p>
              <p className="text-sm text-secondary">
                Additional info for this setting can be placed here
              </p>
            </div>
            <div className="flex items-center gap-2">
              <Switch
                name="currency_is_active"
                id="toggle-currency"
                title="Toggle Currency"
                checked={settings.currency_is_active}
                onChange={toggleActive}
                disabled={!shouldEnabled}
              />
              <p className="">Yes</p>
            </div>
          </div>

          {/* Feedback */}
          <div className="flex gap-1 items-center justify-between">
            <div className="flex flex-col">
              <div className="flex items-center">
                <FeedbackIcon className="h-5 w-5 mr-2" />
                <p className="font-semibold text-lg mt-1">Feedback</p>
              </div>
              <p className="font-medium">Display Feedback Button</p>
              <p className="text-sm text-secondary">
                Additional info for this setting can be placed here
              </p>
            </div>
            <div className="flex items-center gap-2">
              <Switch
                name="feedback_is_active"
                id="toggle-feedback"
                title="Toggle Feedback"
                checked={settings.feedback_is_active}
                onChange={toggleActive}
                disabled={!shouldEnabled}
              />
              <p className="">Yes</p>
            </div>
          </div>

          {/* Cart */}
          {/* <div className="flex gap-1 items-center justify-between">
            <div className="flex flex-col">
              <div className="flex items-center">
                <CartIcon className="h-5 w-5 mr-2" />
                <p className="font-semibold text-lg mt-1">Cart</p>
              </div>
              <p className="font-medium">Display Cart Button</p>
              <p className="text-sm text-secondary">
                Additional info for this setting can be placed here
              </p>
            </div>
            <div className="flex items-center gap-2">
              <Switch
                id="toggle-cart"
                name="cart_is_active"
                title="Toggle Cart"
                checked={settings.cart_is_active}
                onChange={(e) => toggleActive(e)}
                disabled={!shouldEnabled}
              />

              <p className="">Yes</p>
            </div>
          </div> */}
        </div>

        <div className="flex flex-col flex-1 gap-8">
          {/* Ordering Settings */}
          <div className="flex gap-1 items-center justify-between border-b pb-7">
            <div className="flex flex-col">
              <div className="flex items-center">
                <OrdersIcon className="h-5 w-5 mr-2" />
                <p className="font-semibold text-lg mt-1 flex flex-row items-center">
                  Ordering Settings

                  <Tooltip content="If you disable Ordering Settings, your customers will not allow to orders. Tablet Menu will display an information text." placement="top" className="p-1">
                    <QuestionIcon className="mx-1 w-4 h-4 cursor-help" />
                  </Tooltip>
                </p>
              </div>
              <p className="font-medium">Display ordering option</p>
              <p className="text-sm text-secondary">
                Additional info for this setting can be placed here
              </p>
            </div>
            <div className="flex items-center gap-2">
              <Switch
                id="toggle-order"
                title="Toggle Orders"
                name="cart_is_active"
                checked={settings.cart_is_active}
                onChange={toggleActive}
                disabled={!shouldEnabled}
              />
              <p className="">Yes</p>
            </div>
          </div>

          {/* Tables */}
          <div className="flex gap-1 items-center justify-between">
            <div className="flex flex-col">
              <div className="flex items-center">
                <TableIcon className="h-5 w-5 mr-2" />
                <p className="font-semibold text-lg mt-1">Tables</p>
              </div>
              <p className="font-medium">Display Tables</p>
              <p className="text-sm text-secondary">
                Additional info for this setting can be placed here
              </p>
            </div>
            <div className="flex items-center gap-2">
              <Switch
                id="toggle-tables"
                title="Toggle Tables"
                name="tables_is_active"
                checked={settings.tables_is_active}
                onChange={toggleActive}
                disabled={!shouldEnabled}
              />
              <p className="">Yes</p>
            </div>
          </div>

          {/* Guests */}
          <div className="flex gap-1 items-center justify-between">
            <div className="flex flex-col">
              <div className="flex items-center">
                <GuestIcon className="h-5 w-5 mr-2" />
                <p className="font-semibold text-lg mt-1">Guests</p>
              </div>
              <p className="font-medium">Display Guests Button</p>
              <p className="text-sm text-secondary">
                Additional info for this setting can be placed here
              </p>
            </div>
            <div className="flex items-center gap-2">
              <Switch
                id="toggle-guetst"
                title="Toggle Guests Button"
                name="guest_is_active"
                checked={settings.guest_is_active}
                onChange={toggleActive}
                disabled={!shouldEnabled}
              />
              <p className="">Yes</p>
            </div>
          </div>

          {/* Payment Type */}
          <div className="flex flex-col">
            <div className="flex gap-1 items-center justify-between">
              <div className="flex flex-col">
                <div className="flex items-center">
                  <CardIcon className="h-5 w-5 mr-2" />
                  <p className="font-semibold text-lg mt-1">Payment Type</p>
                </div>
                <p className="font-medium">Display all payment type</p>
                <p className="text-sm text-secondary">
                  Additional info for this setting can be placed here
                </p>
              </div>

              <div className="flex items-center gap-2">
                <Switch
                  id="toggle-payment-type"
                  title="Toggle Payment Type"
                  name="payment_is_active"
                  checked={settings.payment_is_active}
                  onChange={toggleActive}
                  disabled={!shouldEnabled}
                />
                <p className="">Yes</p>
              </div>
            </div>
            <div className="mt-4 w-full flex flex-col gap-4 font-medium pr-12">
              {settings.payment_type_array.map(({ id, is_display }) => (
                <div key={id} className="w-full flex justify-between items-center">
                  <label htmlFor="enable-cash">{id === "pay_cash" ? "Cash" : "Credit Card"}</label>
                  <input
                    id={id}
                    name={id}
                    title={id === "pay_cash" ? "Cash" : "Credit Card"}
                    type="checkbox"
                    checked={is_display}
                    disabled={!settings.payment_is_active}
                    onChange={() => {
                      setSettings((state) => ({
                        ...state,
                        payment_type_array: state.payment_type_array.map((payment) => {
                          if (payment.id === id) {
                            return {
                              ...payment,
                              is_display: !is_display,
                            };
                          }

                          return payment;
                        }),
                      }));
                    }}
                    className={clsx(
                      "form-tick",
                      "appearance-none cursor-pointer rounded",
                      "border border-gray-300 checked:border-transparent",
                      "focus:outline-none",
                      "bg-white checked:bg-check checked:bg-primary",
                      "h-4 w-4",
                    )}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SettingTablet;
