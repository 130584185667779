import { ReactComponent as SpinnerIcon } from "../assets/icons/ic_spinner.svg";

interface Props {
  size?: number;
}

function LoadingAnimation({ size = 10 }: Props) {
  return <SpinnerIcon className={`animate-spin h-${size} w-${size}`} />;
}

export default LoadingAnimation;
