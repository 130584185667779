import { useEffect, useState, useRef } from "react";
import {
  useForm, useFieldArray, SubmitHandler, Controller,
} from "react-hook-form";
import { toast } from "react-toastify";
import LoadingBar from "react-top-loading-bar";

import { ItemForm } from "../model";
import { LocalStorage } from "../../../utils";
import { CardFormItem, LoadingAnimation } from "../../../components";
import { Select } from "../../../components/atoms";
import {
  CodeNameResponse,
  useCategoryListQuery,
  useImageMutation,
  useCurrencyListQuery,
  // useAutofillQuery,
  useStep4Mutation,
} from "../../../services";

interface Props {
  nextStep: () => void;
  prevStep: () => void;
  resetStep: () => void;
  uid: string;
}

interface ImageField {
  image_id: string;
  path: string;
}
const defaultImageField: ImageField = {
  image_id: "",
  path: "",
};

function AddItems({
  nextStep, prevStep, resetStep, uid,
}: Props) {
  const {
    register, control, handleSubmit, setValue, reset, formState,
  } = useForm<{ items: ItemForm[] }>({
    mode: "onBlur",
  });
  const {
    fields,
    append,
    remove,
    //  update,
  } = useFieldArray({
    control,
    name: "items",
  });
  const [currency, setCurrency] = useState<CodeNameResponse>();
  const [imageFields, setImageFields] = useState<ImageField[]>([defaultImageField]);
  const [postImage, { isLoading: isLoadingPostImage }] = useImageMutation();
  const [postStep4, { isLoading: isLoadingPostStep4 }] = useStep4Mutation();
  const { data: categories, isLoading: isLoadingCategoryList } = useCategoryListQuery(uid);
  const { data: currencies, isLoading: isLoadingCurrencyList } = useCurrencyListQuery();
  // const { data: dataAutofill } = useAutofillQuery(
  //   {
  //     type: "item",
  //     count: fields.length,
  //     names: categories?.data?.map((category) => category.name)?.join(","),
  //   },
  //   { skip: fields.length === 0 }
  // );

  const ref = useRef(null);

  const handleNewItem = () => {
    if (fields.length >= 3 || !categories?.data) return;
    append({
      name: "",
      description: "",
      category_id: categories.data[0].id,
      price: null,
    });

    setImageFields((prevState) => [...prevState, defaultImageField]);
  };

  const onNext: SubmitHandler<{ items: ItemForm[] }> = (data) => {
    if (!currency?.code) return;

    const items = data.items.map((item, index) => ({
      ...item,
      image: imageFields[index].image_id,
      price: item.price ?? 0,
    }));

    postStep4({ uid, currency: currency.code, items })
      .unwrap()
      .then((res) => {
        LocalStorage.setValue("register-step4", { currency, items });
        LocalStorage.setValue("register-step4-images", imageFields);

        if (res.data?.uid === uid) {
          nextStep();
        } else {
          resetStep();
        }
      })
      .catch((error) => {
        // resetStep(); // if uid not found or expired
        toast.error(error.data?.message ?? "Failed to upload", {
          position: "top-center",
        });
      });
  };

  const uploadImage = (path: string | Blob, index: number) => {
    if (path === "") {
      const tempImages = imageFields;
      tempImages[index] = defaultImageField;
      setImageFields(tempImages);
      return;
    }
    const formData = new FormData();
    formData.append("uid", uid);

    if (path instanceof Blob) {
      formData.append("img", path, "item.jpg");
    } else {
      formData.append("img", path);
    }

    postImage(formData)
      .unwrap()
      .then((res) => {
        setImageFields((prevState) => {
          const newState = prevState.map((state, stateIndex) => {
            if (index === stateIndex && res.data) return res.data;
            return state;
          });
          return newState;
        });
      })
      .catch((error) => {
        toast.error(error.data?.message ?? "Failed to upload", {
          position: "top-center",
        });
      });
  };

  const handleCurrencyChange = (e: SelectChangeType) => {
    const { value } = e.target;
    setCurrency(currencies?.data?.find((cur) => cur.code === value));
  };

  // const autofill = () => {
  //   if (!dataAutofill?.data) return;

  //   dataAutofill.data.forEach((data, index) => {
  //     const category_id = categories?.data?.find(({ name }) => name === data.category)?.id;

  //     if (!category_id) {
  //       toast.error("there's no default item in this category", { toastId: "autofill" });
  //       return;
  //     }
  //     update(index, { name: data.name, description: data.description, category_id });
  //     fetch(process.env.REACT_APP_CDN_URL + "/images/" + data.image).then(async (res) => {
  //       const blob = await res.blob();
  //       uploadImage(blob, index);
  //     });
  //   });
  // };

  useEffect(() => {
    const savedValue = LocalStorage.getValue<{ currency: CodeNameResponse; items: ItemForm[] }>("register-step4");

    if (savedValue) {
      setCurrency(savedValue.currency);
      reset({ items: savedValue.items });
    } else {
      if (fields.length === 0) {
        handleNewItem();
      }

      if (currencies?.data?.length && currency === undefined) {
        setCurrency(currencies.data[0]);
      }

      if (categories?.data?.length) {
        setValue("items.0.category_id", categories?.data[0].id);
      }
    }
  }, [currencies, categories]);

  useEffect(() => {
    const savedImages = LocalStorage.getValue<ImageField[]>("register-step4-images");

    if (savedImages) {
      setImageFields(savedImages);
    }
  }, []);

  useEffect(() => {
    const progress = ref.current as any;
    if (isLoadingCategoryList || isLoadingCurrencyList) {
      progress?.continuousStart();
    } else {
      progress?.complete();
    }
  }, [isLoadingCategoryList, isLoadingCurrencyList]);

  return (
    <>
      <LoadingBar height={4} color="#0078D3" ref={ref} />
      <h2 className="font-bold text-lg md:text-2xl mb-2">Add Items</h2>

      <div className="flex w-full justify-between items-center mb-4">
        <p className="text-xs md:text-sm text-secondary">Try with “Starters” or “Desserts”</p>

        {/* <button className="button-link" onClick={autofill}>
          Auto-Fill
        </button> */}
      </div>

      {/* CARDS */}
      {fields.map((field, index) => (
        <CardFormItem
          key={`item-${field.id}`}
          onRemove={() => remove(index)}
          setImagePath={(path: string) => uploadImage(path, index)}
          type="item"
          image={imageFields && imageFields[index]?.path}
        >
          <div>
            <div className="flex flex-col md:flex-row w-full mb-4">
              <p className="text-xs w-5/12 mt-3 mb-1 md:mb-0 opacity-70">Item Name</p>
              <input
                type="text"
                className="input-base text-sm flex-auto"
                onKeyDown={(e) => e.key === "," && e.preventDefault()}
                placeholder="Item Name"
                {...register(`items.${index}.name` as const, {
                  required: true,
                })}
              />
            </div>

            <div className="flex flex-col md:flex-row w-full mb-4">
              <p className="text-xs w-5/12 mt-3 mb-1 md:mb-0 opacity-70">Description</p>
              <textarea
                className="input-base text-sm flex-auto"
                placeholder="Type Item Description"
                rows={3}
                {...register(`items.${index}.description` as const)}
              />
            </div>

            {categories?.data && (
              <div className="flex flex-col md:flex-row w-full mb-4">
                <p className="text-xs w-5/12 mt-3 mb-1 md:mb-0 opacity-70">Category</p>
                <div className="w-full">
                  <Controller
                    control={control}
                    name={`items.${index}.category_id`}
                    defaultValue={categories.data[0].id}
                    render={({
                      field: {
                        onChange, value, name, ref: controllerRef,
                      },
                    }) => (
                      <Select
                        options={
                          categories?.data?.map((category) => ({
                            value: category.id,
                            label: category.name,
                          })) ?? []
                        }
                        onChange={onChange}
                        selectRef={controllerRef}
                        name={name}
                        value={value}
                        placeholder="Choose Category"
                      />
                    )}
                  />
                </div>
              </div>
            )}

            {currencies?.data && (
              <div className="flex flex-col md:flex-row w-full mb-4">
                <p className="text-xs w-5/12 mt-3 mb-1 md:mb-0 opacity-70">Price</p>
                <div className="w-full flex">
                  <input
                    type="number"
                    min="0"
                    className="input-base text-sm flex-grow rounded-r-none border-r-0"
                    placeholder="Item Price"
                    {...register(`items.${index}.price` as const, { valueAsNumber: true })}
                  />
                  <div className="flex items-center">
                    <label htmlFor="currency" className="sr-only">
                      Currency
                    </label>
                    <Select
                      name="currency"
                      options={
                        currencies.data.map((currency2) => ({
                          value: currency2.code,
                          label: currency2.name,
                        })) ?? []
                      }
                      onChange={handleCurrencyChange}
                      value={currency?.code}
                      customClass="appearance-none p-2 pr-7 border bg-line-gray h-full rounded-r-md text-sm focus:ring-primary focus:border-primary"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </CardFormItem>
      ))}

      {fields.length < 3 && (
        <button
          type="button"
          title="New Item"
          className="button-secondary w-full py-2 text-sm"
          onClick={handleNewItem}
        >
          New Item
        </button>
      )}

      <div className="flex mt-6 w-full md:w-auto md:self-end">
        <button
          type="button"
          title="Back"
          className="button-secondary flex-grow md:flex-grow-0 mr-3"
          onClick={prevStep}
        >
          Back
        </button>

        <button
          type="button"
          className="button-primary flex-grow md:flex-grow-0"
          onClick={handleSubmit(onNext)}
          disabled={!formState.isValid || isLoadingPostStep4 || isLoadingPostImage}
        >
          {isLoadingPostStep4 || isLoadingPostImage ? (
            <div className="flex gap-1">
              <LoadingAnimation size={5} />
              {isLoadingPostStep4 && <span className="text-sm ml-2 font-normal">Loading</span>}
              {isLoadingPostImage && <span className="text-sm ml-2 font-normal">Uploading</span>}
            </div>
          ) : (
            <span>Next</span>
          )}
        </button>
      </div>
    </>
  );
}

export default AddItems;
