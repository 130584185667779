import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./base-service";
import type { ItemListResponse } from "./item-services";

export interface SectionRequestType {
  category_id?: string;
  id?: string;
  name: string;
  description: string;
  isPublished: boolean;
}

// export interface SectionItemsResponseType {
//   id: string;
//   name: string;
//   image: string;
//   currency: string;
//   prices: string[] | number[];
//   is_published: boolean;
// }

export interface GetAllSectionResponseType {
  name: string;
  description: string;
  items: ItemListResponse[];
}

interface GetSectionListType {
  sections?: {
    id: string;
    name: string;
  }[];
}

type GetSectionListResponseType = GetSectionListType;

export interface GetSingleSectionResponseType {
  // id: string;
  // name: string;
  // description: string;
  // image: string;
  // display_as: string;
  // num_of_columns: string;
  publish_status: boolean;
  id?: string;
  name: string;
  description: string;
  // isPublished: boolean;
}

export interface SectionHeaderRequestType {
  section_id: string;
  name: string;
  description: string;
}

export interface DragAndDropSectionRequestType {
  section_id: string;
  item_id_array: string[];
}

export const sectionServices = createApi({
  reducerPath: "section",
  baseQuery,
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  endpoints: (builder) => ({
    // Get Section List
    getSectionList: builder.query<HttpResponse<GetSectionListResponseType>, string | undefined>({
      query: (id) => ({
        url: `/section/list?categoryid=${id}`,
        method: "GET",
      }),
    }),
    // Get All Sections Data
    getAllSection: builder.query<HttpResponse<GetAllSectionResponseType>, { id: string | undefined }>({
      query: ({ id }) => ({
        url: `/section/${id}`,
        method: "GET",
      }),
    }),
    // Get Single Section Data
    getSingleSection: builder.query<HttpResponse<GetSingleSectionResponseType>, { id: string }>({
      query: ({ id }) => ({
        url: `/section/single/${id}`,
        method: "GET",
      }),
    }),
    // Create Section
    createSection: builder.mutation<HttpResponse<{}>, SectionRequestType>({
      query: (body) => ({
        url: "/section",
        method: "POST",
        body,
      }),
    }),
    // Update Section
    updateSection: builder.mutation<HttpResponse<{}>, SectionRequestType>({
      query: (body) => ({
        url: "/section",
        method: "PUT",
        body,
      }),
    }),
    // Update Header Section
    updateSectionHeader: builder.mutation<HttpResponse<{}>, SectionHeaderRequestType>({
      query: (body) => ({
        url: "/section/header",
        method: "PUT",
        body,
      }),
    }),
    // Update Drag and Drop Section
    updateDragAndDropSection: builder.mutation<HttpResponse<{} | null>, DragAndDropSectionRequestType>({
      query: (body) => ({
        url: "/section/drag",
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  useGetSectionListQuery,
  useGetAllSectionQuery,
  useGetSingleSectionQuery,
  useCreateSectionMutation,
  useUpdateSectionHeaderMutation,
  useUpdateSectionMutation,
  useUpdateDragAndDropSectionMutation,
} = sectionServices;
