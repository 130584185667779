import {
  useEffect,
} from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import { useAppSelector } from "../app/hooks";
import routes from "../app/route-config";
import { DashboardLayout, PrivateRoute, PublicRoute } from "../components";
import {
  setupMessaging,
  // registerFCMServiceWorker,
} from "../features/notification";
import NotFoundPage from "../pages/not-found";

function Router() {
  const { token: accessToken, refresh_token: refreshToken } = useAppSelector(({ auth }) => auth);
  const token = accessToken || refreshToken;

  useEffect(() => {
    (async () => {
      await setupMessaging();
    })();
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={routes.publicRoutes.map(({ path }) => path)}>
          {routes.publicRoutes.map((route) => (
            <PublicRoute
              key={route.path}
              path={route.path}
              component={route.component}
              isAuthenticated={!!token}
            />
          ))}
        </Route>

        {token ? (
          <Route
            exact
            path={routes.privateRoutes.flatMap(({ path, routes: subRoutes }) => {
              if (subRoutes) return subRoutes.map((route) => route.path);

              return path;
            })}
          >
            <DashboardLayout>
              {routes.privateRoutes.flatMap((route) => {
                if (route.component) {
                  return (
                    <PrivateRoute
                      key={route.path}
                      path={route.path}
                      component={route.component}
                      isAuthenticated={!!token}
                    />
                  );
                }

                if (route.routes) {
                  return route.routes.map((nestedRoute) => (
                    <PrivateRoute
                      key={nestedRoute.path}
                      path={nestedRoute.path}
                      component={nestedRoute.component}
                      isAuthenticated={!!token}
                    />
                  ));
                }

                return null;
              })}
            </DashboardLayout>
          </Route>
        ) : (
          <Redirect to={{ pathname: "/login" }} />
        )}

        <Route component={NotFoundPage} />
      </Switch>
    </BrowserRouter>
  );
}

export default Router;
