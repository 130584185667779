import { createApi } from "@reduxjs/toolkit/query/react";

import baseQuery from "./base-service";

export interface OrderFilterListResponse {
  id: string;
  total: number;
}

export interface GetAllOrderResponse {
  id: string;
  order_id: number;
  order_status_id: string;
  order_type_id: string;
  output_type_id: string;
  payment_type_id: string;
  table_name: string;
  price_discount: number;
  price_subtotal: number;
  price_delivery: number;
  price_total: number;
  cancel_notes: string;
  notes: string;
  promo_code: string;
  guest_count: number;
  cust_name: string;
  cust_email: string;
  cust_building: string;
  cust_street: string;
  cust_phone: string;
  cust_floor?: string;
  cust_address: string;
  cust_zone: string;
  cust_apart: string;
  cust_plate_num: string;
  orders: {
    id: string;
    name: string;
    qty: number;
    price_unit: number;
    price_total: number;
    sent_time: string;
    notes: string;
    modifiers: {
      name: string;
      price_unit: number;
      price_total: number;
    }[];
  }[];
  sent_time: string;
  update_time: string;
}

interface UpdateOrderTableRequestBody {
  id: string;
  table_id: string;
}

interface UpdateOrderStatusRequestBody {
  id: string;
  order_status_id: string;
  cancel_note: string;
}

interface DeleteOrderItemRequestBody {
  orderId: string;
  itemId: string;
}

interface AllOrdersQueryResponse {
  /**
   * Order filter type
   * @example filter_all | filter_new | filter_prepare
   */
  filter_type: string;
  order_types?: string[];
  status_types?: string[];
  offset: number;
  limit: number;
  from: string;
  to: string;
}

export const orderServices = createApi({
  reducerPath: "orders",
  baseQuery,
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  endpoints: (builder) => ({
    getAllOrders: builder.query<HttpResponse<GetAllOrderResponse[]>, AllOrdersQueryResponse>({
      query: ({
        filter_type, order_types, status_types, from, to, offset, limit,
      }) => ({
        url: (() => {
          const url = "/order/list";
          const orderTypes = order_types ? order_types.map((type) => `&order_types[]=${type}`).join("") : "";
          const statusTypes = status_types ? status_types.map((type) => `&status_types[]=${type}`).join("") : "";

          return `${url}?filter_type=${filter_type}&from=${from}&to=${to}&offset=${offset}&limit=${limit}${orderTypes}${statusTypes}`;
        })(),
        method: "GET",
      }),
    }),
    getFilterList: builder.query<HttpResponse<OrderFilterListResponse[]>, void>({
      query: () => ({
        url: "/order/filterList",
        method: "GET",
      }),
    }),
    updateOrderTable: builder.mutation<HttpResponse<{}>, UpdateOrderTableRequestBody>({
      query: (body) => ({
        url: "/order/table",
        method: "PUT",
        body,
      }),
    }),
    updateOrderStatus: builder.mutation<HttpResponse<{}>, UpdateOrderStatusRequestBody>({
      query: (body) => ({
        url: "/order/status",
        method: "PUT",
        body,
      }),
    }),
    deleteOrderItem: builder.mutation<HttpResponse<{}>, DeleteOrderItemRequestBody>({
      query: (body) => ({
        url: "/order/item",
        method: "DELETE",
        body,
      }),
    }),
    deleteOrder: builder.mutation<HttpResponse<{}>, { id: string }>({
      query: (body) => ({
        url: "/order",
        method: "DELETE",
        body,
      }),
    }),
    deleteMultipleOrder: builder.mutation<HttpResponse<{}>, { id_array: string[] }>({
      query: (body) => ({
        url: "/order/multiple",
        method: "DELETE",
        body,
      }),
    }),
  }),
});

export const {
  useGetAllOrdersQuery,
  useGetFilterListQuery,
  useUpdateOrderTableMutation,
  useUpdateOrderStatusMutation,
  useDeleteOrderItemMutation,
  useDeleteOrderMutation,
  useDeleteMultipleOrderMutation,
} = orderServices;
