// pages
import CategoriesPage from "../pages/categories";
import CategoryPage from "../pages/category";
import ItemsPage from "../pages/items";
import ItemPage from "../pages/item";
import LocalizePage from "../pages/localize";
import ThemesPage from "../pages/themes";
import ThemePage from "../pages/theme";
import FeedbacksPage from "../pages/feedbacks";
import FeedbackAddPage from "../pages/feedback";
import FeedbackLocalizePage from "../pages/feedback-localize";
import ModifiersPage from "../pages/modifiers";
import ModifierLocalizePage from "../pages/modifier-localize";
import BannerPage from "../pages/banner";
import BannersPage from "../pages/banners";
import DevicesPage from "../pages/devices";
import SettingPage from "../pages/setting";
import SectionsPage from "../pages/sections";
import DashboardPage from "../pages/dashboard";
import ReportsPage from "../pages/reports";
import OrdersPage from "../pages/orders";

import FrontPage from "../pages/front-page";
import EmailVerificationPage from "../pages/email-verification";
import LoginPage from "../pages/login";
import ForgotPassword from "../pages/forgot-password";
import ResetPasswordPage from "../pages/reset-password";

import TablesPage from "../pages/tables";

// icon
import { ReactComponent as MenuIcon } from "../assets/icons/ic_menu.svg";
import { ReactComponent as ThemeIcon } from "../assets/icons/ic_theme.svg";
import { ReactComponent as FeedbackIcon } from "../assets/icons/ic_feedback.svg";
import { ReactComponent as ModifierIcon } from "../assets/icons/ic_modifier.svg";
import { ReactComponent as BannerIcon } from "../assets/icons/ic_banner.svg";
import { ReactComponent as DeviceIcon } from "../assets/icons/ic_device.svg";
import { ReactComponent as SettingIcon } from "../assets/icons/ic_setting.svg";
import { ReactComponent as TablesIcon } from "../assets/icons/ic_tables.svg";
import { ReactComponent as DashboardIcon } from "../assets/icons/ic_dashboard.svg";
import { ReactComponent as ReportsIcon } from "../assets/icons/ic_chart_up.svg";
import { ReactComponent as OrdersIcon } from "../assets/icons/ic_orders.svg";

const privateRoutes: PrivateRouteType[] = [
  {
    path: "/dashboard",
    name: "Dashboard",
    Icon: DashboardIcon,
    component: DashboardPage,
    // disabled: true,
  },
  {
    path: "/reports",
    name: "Reports",
    Icon: ReportsIcon,
    component: ReportsPage,
  },
  {
    path: "/",
    name: "Menu",
    Icon: MenuIcon,
    routes: [
      {
        path: "/categories",
        name: "Categories",
        component: CategoriesPage,
      },
      {
        path: "/localize",
        name: "Localize",
        component: LocalizePage,
      },
    ],
  },
  {
    path: "/orders",
    name: "Orders",
    Icon: OrdersIcon,
    component: OrdersPage,
    // disabled: true,
  },
  {
    path: "/themes",
    name: "Theme",
    Icon: ThemeIcon,
    component: ThemesPage,
  },
  {
    path: "/feedbacks",
    name: "Feedback",
    Icon: FeedbackIcon,
    component: FeedbacksPage,
  },
  {
    path: "/modifiers",
    name: "Modifier",
    Icon: ModifierIcon,
    component: ModifiersPage,
  },
  {
    path: "/banners",
    name: "Banners",
    Icon: BannerIcon,
    component: BannersPage,
  },
  {
    path: "/devices",
    name: "Devices",
    Icon: DeviceIcon,
    component: DevicesPage,
  },
  {
    path: "/tables",
    name: "Tables",
    Icon: TablesIcon,
    component: TablesPage,
  },
  {
    path: "/setting",
    name: "Setting",
    Icon: SettingIcon,
    component: SettingPage,
  },
  // non sidebar path
  {
    path: "/category",
    component: CategoryPage,
  },
  {
    path: "/item",
    component: ItemPage,
  },
  {
    path: "/items",
    component: ItemsPage,
  },
  {
    path: "/theme",
    component: ThemePage,
  },
  {
    path: "/feedback",
    component: FeedbackAddPage,
  },
  {
    path: "/banner",
    component: BannerPage,
  },
  {
    path: "/feedback-localize",
    component: FeedbackLocalizePage,
  },
  {
    path: "/modifier-localize",
    component: ModifierLocalizePage,
  },
  {
    path: "/section",
    component: SectionsPage,
  },
];

const publicRoutes: PublicRouteType[] = [
  {
    path: "/",
    component: FrontPage,
  },
  {
    path: "/email-verification",
    component: EmailVerificationPage,
  },
  {
    path: "/login",
    component: LoginPage,
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
  },
  {
    path: "/reset-password",
    component: ResetPasswordPage,
  },
];

const routes = {
  privateRoutes,
  publicRoutes,
};

export default routes;
