/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/alt-text */
import {
  MouseEvent, useCallback, useEffect, useState,
} from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";

import { ReactComponent as TrashIcon } from "../assets/icons/ic_trash.svg";
import { ReactComponent as UploadIcon } from "../assets/icons/ic_upload_cloud.svg";

type UploaderType = "primary" | "secondary";
type SizeType = "default" | "big" | "custom";

interface Props {
  type: UploaderType;
  setFilePath: (url: string) => void;
  size?: SizeType;
  customClassName?: string; // fill if sizeType is custom
  withText?: boolean;
  accept?: "image" | "video";
  preview?: string;
  uploadFailed?: boolean;
}

function DropUploader({
  type,
  setFilePath,
  size = "default",
  customClassName,
  withText = false,
  accept = "image",
  preview,
  uploadFailed = false,
}: Props) {
  const [display, setDisplay] = useState<string>();
  const [isShowModifier, setShowModifier] = useState<boolean>(false);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const image = acceptedFiles[0];
      if (image) {
        setFilePath(image);

        const itemURL = URL.createObjectURL(image);
        setDisplay(itemURL);
      }
    },
    [setFilePath],
  );

  const { getRootProps, getInputProps, inputRef } = useDropzone({
    onDrop,
    accept: accept === "image" ? "image/jpg,image/jpeg,image/png" : "video/mp4,video/mpeg,video/webm",
    multiple: false,
    maxSize: 2000000,
    onDropRejected: (error) => {
      const anyError = error as any;

      if (anyError?.length > 0 && anyError[0].errors?.length > 0) {
        let errorMessage = anyError[0].errors[0].message;
        if (errorMessage === "File is larger than 2000000 bytes") {
          errorMessage = "File is larger than 2 MB";
        }
        toast.error(errorMessage);
      } else {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    },
  });

  const handleItemChange = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    inputRef.current?.click();
  };

  const handleDelete = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setFilePath("");
    setDisplay(undefined);
    setShowModifier(false);
  };

  useEffect(() => {
    if (uploadFailed) {
      setDisplay("");
    }
  }, [uploadFailed]);

  useEffect(() => {
    const baseUrl = process.env.REACT_APP_CDN_URL;
    if (preview && accept === "image") {
      setDisplay(`${baseUrl}/images/${preview}`);
    }
    if (preview && accept === "video") {
      setDisplay(`${baseUrl}/videos/${preview}`);
    }
  }, [preview, accept]);

  if (type === "secondary") {
    return (
      <div
        className={
          `relative flex justify-center items-center overflow-hidden cursor-pointer bg-primary bg-opacity-5${
            size === "default" ? " w-24 h-24 rounded" : ""
          }${size === "big" ? " w-48 h-48 rounded-xl" : ""
          }${size === "custom" ? ` ${customClassName}` : ""}`
        }
        onMouseEnter={() => display && setShowModifier(true)}
        onMouseLeave={() => display && setShowModifier(false)}
        {...getRootProps()}
      >
        {display && isShowModifier && (
          <div className="w-full h-full bg-black bg-opacity-3 absolute inset-0">
            <button
              type="button"
              title="Delete"
              className="bg-gray-100 rounded-full p-2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              onClick={handleDelete}
            >
              <TrashIcon className="w-5 h-5" />
            </button>
          </div>
        )}
        {!isShowModifier && <input {...getInputProps()} />}
        {display ? <img src={display} className="object-contain" /> : <UploadIcon className="text-active" />}
      </div>
    );
  }

  return (
    <>
      <div
        className={
          `relative flex justify-center items-center overflow-hidden cursor-pointer object-contain${
            display ? "" : " border-2 border-line-gray border-dashed"
          }${size === "default" ? " w-24 h-24 rounded" : ""
          }${size === "big" ? " w-full h-full md:w-48 md:h-48 rounded-xl" : ""
          }${size === "custom" ? ` ${customClassName}` : ""}`
        }
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {display ? (
          accept === "image" ? (
            <img src={display} className="object-contain h-full" />
          ) : (
            <video key={display} width="100%" height="100%" controls className="object-contain h-full">
              <track kind="captions" />
              <source src={display} type="video/mp4" />
              <source src={display} type="video/mpeg" />
              Your browser does not support the video tag.
            </video>
          )
        ) : (
          <div className="flex flex-col justify-center items-center p-4">
            <UploadIcon className={type === "primary" ? "text-secondary" : "text-active"} />
            {withText && (
              <p className="text-xs text-secondary text-center w-24 mt-2">
                Drop
                {" "}
                <b>{accept === "image" ? "jpg" : "mp4"}</b>
                {" "}
                or
                {" "}
                <b>{accept === "image" ? "png" : "mpeg"}</b>
                {" "}
                files
                here
              </p>
            )}
          </div>
        )}
      </div>

      {display && (
        <div className="flex mt-4 rounded-full border border-line-gray overflow-hidden md:w-48 h-14 md:h-8">
          <button
            type="button"
            title="Change"
            className="text-xs h-full flex items-center justify-center px-4 flex-1 hover:bg-line-gray text-active border-r border-line-gray"
            onClick={handleItemChange}
          >
            <UploadIcon className="w-3 h-3 mr-1" />
            Change
          </button>

          <button
            type="button"
            title="Remove"
            className="text-xs h-full flex items-center justify-center px-4 md:flex-1 hover:bg-line-gray"
            onClick={handleDelete}
          >
            <TrashIcon className="w-3 h-3 mr-1" />
            <p className="hidden md:block">Remove</p>
          </button>
        </div>
      )}
    </>
  );
}

export default DropUploader;
