import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { toast } from "react-toastify";

import { RootState } from "../app/store";
import { AuthResponse, logout } from "../features/auth";
import { updateToken } from "../features/auth/slice";
import { deleteFCMToken, getFCMToken } from "../features/notification";
import { LocalStorage, SessionStorage } from "../utils";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type ResultError = {
  status: number;
  data?: {
    message: string;
  }
}

const baseUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/web/`;

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: async (headers, { getState }) => {
    headers.set("accept", "application/json");

    const { token } = (getState() as RootState).auth;

    if (token) headers.set("authorization", token);

    return headers;
  },
});

const baseQueryWithLogout: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error) {
    // eslint-disable-next-line no-console
    console.log("result.error", result.error);
  }

  if (result.error?.status === 498) {
    await deleteFCMToken().then(async () => {
      const fcmToken = await getFCMToken();
      const refreshToken = LocalStorage.getValue("refresh_token") || SessionStorage.getValue("refresh_token");
      const refreshResult = await baseQuery({
        url: "/user/refresh",
        method: "POST",
        body: {
          refresh_token: refreshToken,
          fcm_token: fcmToken,
        },
      }, api, extraOptions);

      if (refreshResult.error?.status !== 401 && refreshResult.data) {
        api.dispatch(updateToken(refreshResult.data as AuthResponse));
        // refetch original query
        result = await baseQuery(args, api, extraOptions);
      } else {
        api.dispatch(logout());
      }
    });
  }

  if (result.error?.status === 401) {
    // const refreshResult = await baseQuery({
    //   url: 'token/refresh/',
    //   method: 'POST'
    // }, api, extraOptions);
    // if (refreshResult.data) {
    //   api.dispatch(tokenUpdated({ accessToken: refreshResult.data as string }));
    //   result = await baseQuery(args, api, extraOptions);
    // } else {
    //   api.dispatch(logout());
    // }
    api.dispatch(logout());
    return result;
  }

  // const errorData = result.error as ResultError;

  // if (result.error?.status === 400) {
  //   toast.error(errorData ? errorData.data?.message : "Bad request");
  // }

  if (result.error?.status === "FETCH_ERROR") {
    toast.error("Fetch Error: Please Check your internet connection", {
      toastId: "base_error",
    });
  }

  return result;
};

export default baseQueryWithLogout;
