/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from "react";
import {
  Control,
  Controller,
  UseFormRegister,
} from "react-hook-form";

import { FeedbackFormModel, QuestionsFeedback } from "../../services";
import { getBreakpointValue } from "../../utils";
import Select from "../atoms/select";

import { ReactComponent as CopyIcon } from "../../assets/icons/ic_copy.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/ic_trash_bold.svg";

interface Props {
  index?: number;
  id?: string;
  register: UseFormRegister<FeedbackFormModel>;
  control: Control<FeedbackFormModel, object>;
  handleDuplicate: (arg0: number) => void;
  handleDelete: (arg0: number) => void;
  activeField?: QuestionsFeedback;
  disabled?: boolean
}

const answerTypes = [
  {
    value: "rating",
    label: "Rating",
  },
  {
    value: "yesno",
    label: "Yes / No",
  },
  {
    value: "text",
    label: "Text",
  },
  {
    value: "smiley",
    label: "Smiley",
  },
];

const getTabletBreakpoint = () => (getBreakpointValue("sm") < window.innerWidth) && (getBreakpointValue("lg") > window.innerWidth);

function FeedbackForm({
  index = 0, id = "overlay", register, disabled, control, handleDuplicate, handleDelete, activeField,
}: Props) {
  const [breakpoint, setBreakpoint] = useState(getTabletBreakpoint());

  useEffect(() => {
    function handleResize() {
      setBreakpoint(getTabletBreakpoint());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="shadow border border-gray-100 rounded-lg w-full pt-6 bg-white">
      <div className="flex flex-col md:flex-row gap-4 md:gap-8 p-4 md:border-b md:border-line-gray">
        {activeField ? (
          <input
            type="text"
            className="input-base md:w-8/12"
            value={activeField.question}
            readOnly
            disabled={disabled}
          />
        ) : (
          <input
            type="text"
            disabled={disabled}
            className="input-base md:w-8/12"
            {...register(`questions.${index}.question` as const)}
          />
        )}

        <Controller
          control={control}
          name={`questions.${index}.answer_type`}
          defaultValue="rating"
          render={({
            field: {
              onChange, value, name, ref,
            },
          }) => {
            const selectedValue = activeField?.answer_type ?? value;

            return (
              <div className="flex flex-1 relative">
                <Select
                  options={answerTypes.map(({ value: answerValue, label }) => ({
                    value: answerValue,
                    label,
                  }))}
                  onChange={onChange}
                  selectRef={ref}
                  name={name}
                  value={selectedValue}
                  disabled={disabled}
                />

                {(selectedValue === "rating" && !breakpoint) && (
                  <span className="pointer-events-none absolute right-7 top-2 text-placeholder">
                    ★★★★★
                  </span>
                )}

                {(selectedValue === "smiley" && !breakpoint) && (
                  <span className="pointer-events-none absolute right-7 top-2">
                    🥰 😐 🙁
                  </span>
                )}
              </div>
            );
          }}
        />
      </div>

      <div className="flex justify-between p-4 pt-0 md:pt-4">
        <div className="flex items-center gap-2">
          <div className="relative inline-block w-7 align-middle select-none">
            <Controller
              control={control}
              name={`questions.${index}.is_required`}
              render={({
                field: {
                  onChange, value, name,
                },
              }) => (
                <input
                  type="checkbox"
                  id={`toggle-${id}`}
                  className="toggle-checkbox absolute block w-4 h-4 rounded-full bg-white border-2 appearance-none transition-all duration-300 ease-in cursor-pointer"
                  onChange={onChange}
                  name={name}
                  disabled={disabled}
                  checked={activeField?.is_required ?? value}
                />
              )}
            />
            <label
              htmlFor={`toggle-${id}`}
              className="toggle-label block overflow-hidden h-4 rounded-full bg-gray-300 transition-all duration-300 ease-in cursor-pointer"
            />
          </div>

          <p>Required</p>
        </div>

        <div className="flex gap-4">
          <button
            onClick={() => handleDuplicate(index)}
            type="button"
            title="Duplicate"
          >
            <CopyIcon className="h-5 w-5" color="#666666" />
          </button>

          <button onClick={() => handleDelete(index)} type="button" title="Delete">
            <TrashIcon className="h-5 w-5" color="#666666" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default FeedbackForm;
