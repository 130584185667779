/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { subDays } from "date-fns";
import {
  // utcToZonedTime,
  format,
} from "date-fns-tz";
import {
  useEffect, useMemo, useRef, useState,
} from "react";
import Calendar from "react-calendar";
import Pagination from "react-paginate";
import { toast } from "react-toastify";
import LoadingBar from "react-top-loading-bar";
import Popup from "reactjs-popup";
import { PopupActions } from "reactjs-popup/dist/types";

// import { getBreakpointValue } from "../utils";
import { LoadingAnimation } from "..";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { addMultipleSelected, addSelected, removeSelected } from "../../features/selectable";
import { useDebounce } from "../../hooks";
import {
  FeedbackResultModel,
  // useGetFeedbackResultQuery,
  // useToggleFeedbackOpenMutation,
  useDeleteMultipleFeedbacksMutation,
  useGetAllFeedbackResultQuery,
} from "../../services";
import { Select } from "../atoms";

import { ReactComponent as ChevronDownIcon } from "../../assets/icons/ic_chevron_down.svg";
import { ReactComponent as FilterIcon } from "../../assets/icons/ic_filter.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/ic_thin_calendar.svg";
import { ReactComponent as ExportIcon } from "../../assets/icons/ic_thin_download.svg";
import { ReactComponent as ReloadIcon } from "../../assets/icons/ic_thin_repeat.svg";
import { ReactComponent as IllustrationNoItems } from "../../assets/illustration_no_items.svg";
import FeedbackResultList from "./feedback-result-list";

interface Props {
  // keyword: string;
  okDateRange: Date[];
  setOkDateRange: React.Dispatch<React.SetStateAction<Date[]>>;
}

// interface DetailResult {
//   isOpen: boolean;
//   id: string | undefined;
// }

const formatDate = (date: Date, type = "EEE, dd MMM yyyy") => format(date, type);

// const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

// const parseDateUTC = (date: string) => utcToZonedTime(date, timeZone);

// let newDate = new Date(date);
// newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset());
// return newDate;

function FeedbackResults({ okDateRange, setOkDateRange }: Props) {
  const keyword = ""; // move to props if used
  const dateForm = format(okDateRange[0], "yyyyMMdd");
  const dateTo = format(okDateRange[1], "yyyyMMdd");

  const dispatch = useAppDispatch();
  const checklist = useAppSelector((state) => state.selectable);
  const token = useAppSelector((state) => state.auth.token);
  const [allChecked, setAllChecked] = useState<boolean>(false);
  // const [openDetail, setOpenDetail] = useState<DetailResult>({
  //   isOpen: false,
  //   id: undefined,
  // });
  const today = new Date();
  const [dateRange, setDateRange] = useState([subDays(today, 7), today]);
  const [openMobileFilter, setOpenMobileFilter] = useState<boolean>(false);
  const [openMobileCalendar, setOpenMobileCalendar] = useState<boolean>(false);
  const [mobileStatus, setMobileStatus] = useState<string>("all");
  const [isLoadingDownload, setLoadingDownload] = useState<boolean>(false);
  const ref = useRef(null);

  const [status, setStatus] = useState<string>("all");
  const debouncedKeyword = useDebounce<string>(keyword, 500);
  const calendarRef = useRef<PopupActions | null>(null);

  // pagination
  const [pageCount, setPageCount] = useState<number>(0);
  const [itemOffset, setItemOffset] = useState<number>(0);
  const [itemLimit, setItemLimit] = useState<number>(5);
  const {
    data: dataAllResult,
    refetch,
    isLoading,
    isFetching,
  } = useGetAllFeedbackResultQuery({
    status,
    offset: itemOffset,
    limit: itemLimit,
    from: dateForm,
    to: dateTo,
  });
  // const [toggleOpen, { isLoading: isLoadingToggleStatus }] = useToggleFeedbackOpenMutation();
  // const { data: dataResult, isLoading: isLoadingResult } = useGetFeedbackResultQuery(openDetail.id, {
  //   skip: openDetail.id === undefined,
  // });
  const [deleteMultipleFeedbacks] = useDeleteMultipleFeedbacksMutation();
  const dataFeedbackResult: FeedbackResultModel[] = useMemo(() => dataAllResult?.data ?? [], [dataAllResult?.data]);
  // const isMobile = getBreakpointValue("md") > window.innerWidth;

  const handleChecklist = (id: string) => {
    const checked = checklist.find((checkedID) => checkedID === id);

    if (checked) {
      dispatch(removeSelected(id));
    } else {
      dispatch(addSelected(id));
    }
  };

  // const handleToggleOpen = (id?: string) => {
  //   if (id) {
  //     toggleOpen({ id })
  //       .unwrap()
  //       .then(() => {
  //         toast.success("Toggle status success");
  //         refetch();
  //       })
  //       .catch((err) => {
  //         toast.error(err.message ?? "Toggle status failed");
  //       });
  //   }
  // };

  const filteredComponents = dataFeedbackResult.filter(
    ({ form_name }) => form_name.toLowerCase().includes(debouncedKeyword.toLowerCase()),
  );

  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemLimit) % (dataAllResult?.total_item ?? 0);
    setItemOffset(newOffset);
  };

  // const transformAnswerEmoticon = (id: string): string => {
  //   if (id === "0") return "🙁";
  //   if (id === "1") return "😐";
  //   if (id === "2") return "🥰";
  //   return "";
  // };

  const downloadBlob = (blob: Blob) => {
    const a = document.createElement("a");
    a.download = `feedback_results_${dateForm}_${dateTo}.csv`;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const handleExport = () => {
    setLoadingDownload(true);
    const baseUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/web/feedback/download`;
    const headers = new Headers();
    if (token) headers.set("authorization", token);

    fetch(`${baseUrl}?status=${status}&offset=${itemOffset}&limit=${itemLimit}&from=${dateForm}&to=${dateTo}`, {
      headers,
    })
      .then((res) => res.blob())
      .then(downloadBlob)
      .catch((err) => toast.error(err.message ?? "Toggle status failed"))
      .finally(() => setLoadingDownload(false));
  };

  const filterMobileStatus = (e: InputChangeType) => {
    setMobileStatus(e.currentTarget.value);
  };

  const handleDeleteMultipleFeedback = () => {
    deleteMultipleFeedbacks(checklist)
      .unwrap()
      .then(() => {
        refetch();
        toast.success("Successfully delete selected feedbacks");
      })
      .catch(() => {
        toast.error("Failed to delete selected feedbacks");
      });
  };

  const handleAllChecked = () => {
    if (allChecked) {
      const checks = filteredComponents.filter((data) => checklist.includes(data.id ?? "")).map(({ id }) => id);

      checks.forEach((id) => {
        if (id) dispatch(removeSelected(id));
      });
    } else {
      const selected = dataFeedbackResult.flatMap(({ id }) => (id ? [id] : []));
      dispatch(addMultipleSelected(selected));
    }

    setAllChecked((o) => !o);
  };

  const allCheckedCurrentPage = () => filteredComponents.every((data) => {
    if (data.id) return checklist.includes(data.id);
    return false;
  });

  useEffect(() => {
    setAllChecked(allCheckedCurrentPage());
  }, [checklist]);

  useEffect(() => {
    if (!dataAllResult) return;

    const pageCounts = Math.ceil((dataAllResult.total_item ?? 0) / itemLimit);

    setPageCount(pageCounts);
    setAllChecked(allCheckedCurrentPage());
  }, [dataAllResult, itemOffset, itemLimit, dataFeedbackResult]);

  useEffect(() => {
    const progress = ref.current as any;
    if (isLoading || isFetching) {
      // if (isLoading || isFetching || isLoadingToggleStatus || isLoadingResult) {
      progress?.continuousStart();
    } else {
      progress?.complete();
    }
  // }, [isLoading, isFetching, isLoadingToggleStatus, isLoadingResult]);
  }, [isLoading, isFetching]);

  if (!dataAllResult) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <LoadingAnimation />
      </div>
    );
  }

  return (
    <div className="flex flex-col p-2 pt-0 mb-14 md:mb-0">
      <LoadingBar height={4} color="#0078D3" ref={ref} />

      {/* header / list modifier */}
      <div className="flex gap-4 justify-between mb-6">
        <div className="hidden md:flex md:flex-1 gap-4">
          <div className="w-auto">
            <Select
              name="status"
              value={status}
              options={[
                { value: "all", label: "All Status" },
                { value: "open", label: "Open" },
                { value: "close", label: "Close" },
              ]}
              onChange={(e) => setStatus(e.target.value)}
              customClass="flex pr-8"
            />
          </div>

          <div className="flex flex-1 rounded border border-line-gray overflow-hidden">
            <Popup
              ref={calendarRef}
              position="bottom center"
              contentStyle={{
                width: "auto",
                maxWidth: 560,
                background: "white",
              }}
              closeOnDocumentClick
              closeOnEscape
              onOpen={() => setDateRange(okDateRange)}
              trigger={(
                <input
                  type="text"
                  className="w-full px-2 text-sm"
                  value={okDateRange?.map((date) => formatDate(date))?.join(" - ")}
                  readOnly
                />
              )}
            >
              <Calendar selectRange showDoubleView onChange={setDateRange} returnValue="range" defaultValue={dateRange} />
              <div className="flex justify-end w-full">
                <button
                  title="Ok"
                  type="button"
                  className="border rounded py-1 px-3 my-1 mr-1 text-sm self-end"
                  onClick={() => {
                    setOkDateRange(dateRange);
                    calendarRef.current?.close();
                  }}
                >
                  Ok
                </button>
              </div>
            </Popup>

            <div className="bg-gray-100 flex px-3 items-center">
              <CalendarIcon className="w-4 h-4" color="#666666" />
            </div>
          </div>
        </div>

        <div className="flex gap-4 flex-1 md:flex-none">
          <button
            type="button"
            title="Export"
            className="button-link text-sm border border-line-gray rounded px-4 py-2 md:py-1 flex flex-1 md:flex-none justify-center items-center gap-2"
            onClick={handleExport}
          >
            {isLoadingDownload ? (
              <LoadingAnimation size={5} />
            ) : (
              <>
                <ExportIcon className="w-3 h-3" color="#0078D3" />
                Export
              </>
            )}
          </button>

          <button
            type="button"
            title="Reload"
            className="button-link text-sm border border-line-gray rounded px-4 py-2 md:py-1 flex flex-1 md:flex-none justify-center items-center gap-2"
            onClick={refetch}
            disabled={isFetching}
          >
            <ReloadIcon className="w-3 h-3" color="#0078D3" />
            Reload
          </button>

          <button
            type="button"
            title="Delete Selected"
            className="hidden md:block button-error text-sm py-2 px-4 font-normal rounded"
            disabled={!(dataFeedbackResult.length > 0 && checklist.length > 0)}
            onClick={handleDeleteMultipleFeedback}
          >
            Delete Selected
          </button>

          <button
            type="button"
            title="Filter"
            className="md:hidden button-link text-sm border border-line-gray rounded px-4 py-2 md:py-1 flex flex-1 md:flex-none justify-center items-center gap-2"
            onClick={() => setOpenMobileFilter(true)}
          >
            <FilterIcon className="w-3 h-3" color="#0078D3" />
            Filter
          </button>
        </div>
      </div>

      {filteredComponents.length > 0 ? (
        <>
          <FeedbackResultList
            data={filteredComponents}
            refetchFeedbackResult={refetch}
            loadingBarRef={ref?.current}
            checkbox={{
              checklist,
              showCheckbox: true,
              onClickSelectAll: handleAllChecked,
              onClickCheck: handleChecklist,
              isAllChecked: allChecked,
            }}
          />

          {/* list */}
          {/* <div className="hidden md:flex w-full p-4 bg-gray-100 font-medium items-center">
            <div className="pr-4">
              <label className="flex items-center space-x-3">
                <input
                  type="checkbox"
                  className="form-tick appearance-none bg-white bg-check h-4 w-4 border border-gray-300 rounded checked:bg-primary checked:border-transparent focus:outline-none cursor-pointer"
                  onChange={handleAllChecked}
                  checked={allChecked}
                />
              </label>
            </div>
            <div className="flex flex-1 flex-col-reverse md:flex-row">
              <p className="md:pl-4 md:w-1/2 border-l">Date</p>
              <div className="flex md:w-1/2 flex-row-reverse md:flex-row">
                <p className="md:pl-4 flex-1 border-l">Form</p>
                <p className="md:pl-4 md:flex-1 border-l">Status</p>
              </div>
            </div>
            <p className="md:pl-4 border-l w-32">Action</p>
          </div>

          {filteredComponents.map((data) => {
            const { isOpen } = data;
            let statusStyle = "bg-green-100 text-success";
            let actionStyle = "bg-error";
            if (isOpen === false) {
              statusStyle = "bg-red-100 text-error";
              actionStyle = "bg-success";
            }

            const isChecked = !!checklist.find((checkedID) => checkedID === data.id);

            return (
              <div
                key={data.id}
                className="flex w-full text-sm p-4 md:border-b md:border-gray-100 rounded-md md:rounded-none shadow-lg md:shadow-none mb-3 md:mb-0 items-center"
                onClick={() => {
                  if (isMobile) {
                    setOpenDetail({ isOpen: true, id: data.id });
                  }
                }}
              >
                <div className="pr-4 hidden md:block">
                  <label className="flex items-center space-x-3">
                    <input
                      key={isChecked ? "1" : "0"} // hack to force update
                      type="checkbox"
                      name="checked-feedbacks"
                      className="form-tick appearance-none bg-white bg-check h-4 w-4 border border-gray-300 rounded checked:bg-primary checked:border-transparent focus:outline-none cursor-pointer"
                      onChange={() => data.id && handleChecklist(data.id)}
                      checked={isChecked}
                    />
                  </label>
                </div>
                <div className="flex flex-1 flex-col-reverse md:flex-row">
                  <p className="md:pl-4 md:w-1/2 mt-2 md:mt-0">
                    {data.date ? format(parseDateUTC(data.date), "dd MMM yyyy", { timeZone }) : "-"}
                  </p>
                  <div className="flex md:w-1/2 flex-row-reverse md:flex-row">
                    <p className="pl-4 flex-1 font-medium md:font-normal">{data.form_name}</p>
                    <div className="md:pl-4 md:flex-1">
                      <span className={`px-2 py-1 rounded text-xs ${statusStyle}`}>
                        {data.isOpen ? "Open" : "Closed"}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="md:pl-4 md:w-32 flex justify-between items-center">
                  <button
                    type="button"
                    title="View"
                    className="hidden md:block button-link text-sm font-light"
                    onClick={() => setOpenDetail({ isOpen: true, id: data.id })}
                  >
                    View
                  </button>

                  <button
                    type="button"
                    title={data.isOpen ? "Close" : "Open"}
                    className={`px-3 py-1 rounded-md text-sm text-white disabled:opacity-50 ${actionStyle}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleToggleOpen(data.id);
                    }}
                    disabled={isLoadingToggleStatus}
                  >
                    {data.isOpen ? "Close" : "Open"}
                  </button>
                </div>
              </div>
            );
          })} */}

          {/* footer / pagination */}
          <div className="flex gap-4 p-4 w-full justify-between">
            {(dataAllResult?.total_item ?? 0) >= itemLimit ? (
              <Pagination
                // breakLabel="..."
                nextLabel="›"
                previousLabel="‹"
                marginPagesDisplayed={8}
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                containerClassName="flex gap-2 items-center text-sm"
                activeClassName="text-active border-primary outline-primary"
                pageClassName="rounded border border-gray-300 w-6 h-6 flex items-center justify-center"
                previousClassName="rounded border border-gray-300 w-6 h-6 flex items-center justify-center"
                nextClassName="rounded border border-gray-300 w-6 h-6 flex items-center justify-center"
                pageLinkClassName="block w-full text-center focus:outline-none"
                nextLinkClassName="block w-full text-center focus:outline-none"
                previousLinkClassName="block w-full text-center focus:outline-none"
              />
            ) : (
              <div />
            )}
            <div className="hidden md:flex gap-4 items-center">
              <p>Show:</p>

              <div className="relative flex items-center h-8">
                <select
                  className="absolute border border-line-gray rounded-md inset-0 py-1 pr-24 pl-2 h-8 appearance-none text-sm"
                  value={itemLimit}
                  onChange={(e) => setItemLimit(Number(e.target.value) ?? 10)}
                >
                  {[5, 10, 20].map((number) => (
                    <option value={number} key={number}>
                      {number}
                    </option>
                  ))}
                </select>
                <p className="text-sm relative z-10 ml-8 pointer-events-none">per page</p>
                <div className="transform w-4 h-4 relative z-10 mx-2 pointer-events-none">
                  <ChevronDownIcon />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="h-full w-full mt-16 flex-1 flex flex-col gap-1 justify-center items-center">
          <IllustrationNoItems className="h-64 w-64 mb-4" />

          <p className="text-lg">No result</p>
          <p className="text-secondary">You haven’t add any result</p>
        </div>
      )}

      {/* modal feedback result detail */}
      {/* <Popup
        modal
        open={openDetail.isOpen}
        onClose={() => setOpenDetail({ isOpen: false, id: undefined })}
        closeOnDocumentClick={!isMobile}
        closeOnEscape
        lockScroll
        contentStyle={{
          maxHeight: isMobile ? "100%" : "80%",
          height: isMobile ? "100%" : "auto",
          maxWidth: isMobile ? "100%" : 600,
          width: "100%",
          overflowY: "auto",
          backgroundColor: "white",
          borderRadius: isMobile ? 0 : 8,
        }}
      >
        <div className="pb-4">
          <div className="flex justify-end">
            <button
              type="button"
              title="Close"
              className="h-6 w-6 text-xl md:text-2xl mr-4 md:mr-0"
              onClick={() => setOpenDetail({ isOpen: false, id: undefined })}
            >
              &times;
            </button>
          </div>

          <div className="flex justify-between items-center mx-4 mb-1">
            <p className="flex flex-col md:flex-row gap-2 md:items-center">
              <b className="text-2xl">{dataResult?.data?.form_name ?? "-"}</b>
              <span className="hidden md:block">-</span>
              <span className="text-base mmd:text-2xl">
                Feedback
                {dataResult?.data?.feedback_number ?? "-"}
              </span>
            </p>
            <p
              className={`px-2 py-1 rounded text-xs ${
                dataResult?.data?.isOpen ? "bg-green-100 text-success" : "bg-red-100 text-error"
              } md:mr-8`}
            >
              {dataResult?.data?.isOpen ? "Open" : "Closed"}
            </p>
          </div>

          <p className="mx-4 mb-6 text-xs md:text-base text-secondary">
            {dataResult?.data?.date
              ? format(parseDateUTC(dataResult.data.date), "EEE, dd MMM yyyy - HH:mm", { timeZone })
              : ""}
          </p>

          <div className="flex flex-col border border-line-gray rounded-md mx-4 shadow">
            {(dataResult?.data?.records ?? []).map((rec) => (
              <div className="border-b border-line-gray p-4 flex">
                <p className="w-7/12">{rec.question}</p>
                {rec.answer_type === "smiley" && <p className="w-5/12">{transformAnswerEmoticon(rec.answer)}</p>}
                {rec.answer_type === "rating" && (
                  <div className="flex items-center w-5/12">
                    <p className="text-star">
                      {Array(+rec.answer)
                        .fill("★")
                        .join("")}
                    </p>
                    <p className="text-placeholder">
                      {Array(5 - +rec.answer)
                        .fill("★")
                        .join("")}
                    </p>
                  </div>
                )}
                {rec.answer_type === "yesno" && <p className="w-5/12 font-normal">{rec.answer === "1" ? "Yes" : "No"}</p>}
                {rec.answer_type === "text" && <p className="w-5/12 break-all">{rec.answer}</p>}
              </div>
            ))}
          </div>
        </div>
      </Popup> */}

      {/* modal filter mobile only */}
      <Popup
        modal
        open={openMobileFilter}
        onClose={() => setOpenMobileFilter(false)}
        closeOnDocumentClick={false}
        closeOnEscape
        lockScroll
      >
        <div className="modal rounded-t-xl md:rounded bg-white fixed md:relative bottom-0 left-0 right-0 p-4">
          <div className="flex justify-between mb-4">
            <h3 className="text-2xl font-medium">Filters</h3>
            <button className="text-4xl" onClick={() => setOpenMobileFilter(false)} title="Close" type="button">
              &times;
            </button>
          </div>

          <div className="flex flex-col mb-4">
            <p className="text-xs mb-1">Status</p>

            <div className="flex justify-between items-center">
              <label htmlFor="all" className="text-sm">
                <input
                  type="radio"
                  name="status"
                  id="all"
                  className="mr-2"
                  value="all"
                  onChange={filterMobileStatus}
                  defaultChecked
                />
                All Status
              </label>

              <label htmlFor="open" className="text-sm">
                <input
                  type="radio"
                  name="status"
                  id="open"
                  className="mr-2"
                  value="open"
                  onChange={filterMobileStatus}
                />
                Open Only
              </label>

              <label htmlFor="close" className="text-sm">
                <input
                  type="radio"
                  name="status"
                  id="close"
                  className="mr-2"
                  value="close"
                  onChange={filterMobileStatus}
                />
                Close Only
              </label>
            </div>
          </div>

          <p className="text-xs mb-1">Date</p>
          {openMobileCalendar ? (
            <div className="flex flex-col mb-4 items-center">
              <Calendar selectRange onChange={setDateRange} defaultValue={dateRange} />
            </div>
          ) : (
            <div className="flex rounded-md border border-line-gray overflow-hidden mb-4 h-10">
              <input
                type="text"
                className="w-full px-2 text-sm"
                onClick={() => setOpenMobileCalendar(true)}
                value={dateRange?.map((date) => formatDate(date))?.join(" - ")}
                readOnly
              />

              <div className="bg-gray-100 flex px-3 items-center">
                <CalendarIcon className="w-4 h-4" color="#666666" />
              </div>
            </div>
          )}

          <div className="flex flex-col gap-2">
            <button
              type="button"
              title="Apply Filter"
              className="button-primary py-2 rounded-md"
              onClick={() => {
                setOkDateRange(dateRange);
                setStatus(mobileStatus);
                setOpenMobileCalendar(false);
                setOpenMobileFilter(false);
              }}
            >
              Apply Filter
            </button>

            <button
              className="button-secondary py-2 rounded-md font-normal text-secondary border-line-gray"
              type="button"
              title="Reset Filter"
            >
              Reset Filter
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );
}

export default FeedbackResults;
