/* eslint-disable @typescript-eslint/no-empty-function */
import {
  useEffect, useMemo, useRef, useState,
} from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingBar from "react-top-loading-bar";

import imagePlaceholder from "../assets/image_placeholder.png";
import { ReactComponent as IllustrationNoItems } from "../assets/illustration_no_items.svg";

import {
  ContentHeader,
  CardWithOptions,
  ModalConfirmation,
  SortableItem,
  SortableItemOverlay,
  SortableGridContainer,
  LoadingAnimation,
} from "../components";
import { useDebounce } from "../hooks";
import {
  CategoryListResponse,
  useDeleteCategoryMutation,
  useGetAllCategoryQuery,
  usePublishCategoryMutation,
  useUpdateCategoryHeaderMutation,
  useDuplicateCategoryMutation,
  useDragAndDropCategoryMutation,
} from "../services";

interface FormCategory {
  name: string;
  description: string;
}

interface Confirmation {
  isOpen: boolean;
  data: string | null;
}

function CategoriesPage() {
  const { register, handleSubmit, setValue } = useForm<FormCategory>();
  const [toggler, setToggler] = useState<boolean[]>([]);
  const [keyword, setKeyword] = useState<string>("");
  const [modalConfirmation, setModalConfirmation] = useState<Confirmation>({
    isOpen: false,
    data: null,
  });
  const [filteredComponents, setFilteredComponents] = useState<CategoryListResponse[]>([]);
  const [activeId, setActiveId] = useState<string | null>(null);

  const debouncedKeyword = useDebounce<string>(keyword, 500);
  const { push } = useHistory();

  const { data, isLoading, refetch } = useGetAllCategoryQuery();
  const [updateCategoryHeader] = useUpdateCategoryHeaderMutation();
  const [publishCategory, { isLoading: isLoadingPublish }] = usePublishCategoryMutation();
  const [deleteCategory, { isLoading: isLoadingDeleteItem }] = useDeleteCategoryMutation();
  const [duplicateCategory, { isLoading: isLoadingDuplicateCategory }] = useDuplicateCategoryMutation();
  const [dragAndDropCategory, { isLoading: isLoadingDragAndDropCategory }] = useDragAndDropCategoryMutation();
  const ref = useRef(null);

  /**
   * @description Handle publish category
   * @param index<number>
   */
  const handleIsPublish = (index: number) => {
    const category = data?.data?.categories[index];
    if (category) {
      let textIsPublish = "publish";
      let tempToggler = toggler.map((isPublish, current) => {
        const isCurrentIndex = current === index;
        if (isCurrentIndex) {
          textIsPublish = isPublish ? "unpublish" : "publish";
        }
        return isCurrentIndex ? !isPublish : isPublish;
      });

      setToggler(tempToggler);

      publishCategory(category.id)
        .unwrap()
        .then(() => {
          toast.success(`${category.name} ${textIsPublish}ed`);
        })
        .catch(() => {
          tempToggler = toggler.map((isPublish, current) => (current === index ? !isPublish : isPublish));
          setToggler(tempToggler);
          toast.error(`${category.name} ${textIsPublish} failed`);
        });
    }
  };

  /**
   * @description Handle enter category (redirect to /items page with state category_id)
   * @param id<string>
   */
  const handleEnter = (id: string) => {
    push({
      pathname: "/items",
      state: {
        category_id: id,
      },
    });
  };

  /**
   * @description Handle edit category (redirect to /category page with state category_id)
   * @param id
   */
  const handleEdit = (id: string) => {
    push({
      pathname: "/category",
      state: {
        category_id: id,
      },
    });
  };

  /**
   * @description Function to handle click on delete category
   * @param id<string>
   */
  const handleDelete = (id: string) => {
    setModalConfirmation({ isOpen: true, data: id });
  };

  /**
   * @description Handle Duplicate category
   * @param id<string>
   */
  const handleDuplicate = (id: string) => {
    duplicateCategory({ id })
      .unwrap()
      .then(() => {
        refetch();
      })
      .catch((error) => {
        toast.error(error.data?.message ?? "Failed to duplicate category");
      });
  };

  /**
   * @description Handle callback from modal confirmation to delete category
   */
  const callbackConfirmation = () => {
    const { data: modalConfirmationData } = modalConfirmation;

    if (!modalConfirmationData) return;
    deleteCategory(modalConfirmationData)
      .unwrap()
      .then(() => {
        toast.success("Successfullly delete category");
        refetch();
      })
      .catch(() => {
        toast.error("Failed to delete category");
      })
      .finally(() => {
        setModalConfirmation((prevState) => ({ ...prevState, isOpen: false }));
      });
  };

  /**
   * @description Handle click on go to localize page (redirect to /localize page with state category_id)
   * @param id
   */
  const goToLocalize = (id: string) => {
    push({
      pathname: "/localize",
      state: {
        category_id: id,
      },
    });
  };

  /**
   * @description Handle submit form to update category header
   * @param { name: string; description: string }
   * @returns void
   */
  const onSubmit: SubmitHandler<FormCategory> = ({ name, description }) => {
    if (data?.data?.name === name && data?.data?.description === description) return;

    updateCategoryHeader({ name, description })
      .unwrap()
      .then(() => {
        toast.success("Successfully update form");
      })
      .catch(() => {
        toast.error("Failed to update form");
      });
  };

  /**
   * @description Function to check active category
   */
  const activeField = useMemo(
    () => filteredComponents.find(({ id }) => id === activeId),
    [filteredComponents, activeId],
  );

  /**
   * @description Handle Drag end
   * @param ids<string[]>
   */
  const handleDragEnd = (ids: string[]) => {
    dragAndDropCategory({ category_id_array: ids });
  };

  useEffect(() => {
    const responseData = data?.data;

    if (responseData) {
      setToggler(responseData.categories?.map(({ is_published }) => is_published) ?? []);
      setValue("name", responseData.name);
      setValue("description", responseData.description);
    }
  }, [data?.data]);

  useEffect(() => {
    const progress = ref.current as any;
    if (isLoading || isLoadingDragAndDropCategory) {
      progress?.continuousStart();
    } else {
      progress?.complete();
    }
  }, [isLoading, isLoadingDragAndDropCategory]);

  useEffect(() => {
    if (!data?.data?.categories || Object.keys(data?.data ?? {}).length === 0) {
      setFilteredComponents([]);
      return;
    }

    const filtered = data?.data?.categories.filter(
      ({ name }) => name.toLowerCase().includes(debouncedKeyword.toLowerCase()),
    );
    setFilteredComponents(filtered);
  }, [data?.data, debouncedKeyword]);

  useEffect(() => {
    setToggler(filteredComponents.map(({ is_published }) => is_published) ?? []);
  }, [filteredComponents]);

  if (!data) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <LoadingAnimation />
      </div>
    );
  }

  return (
    <>
      <LoadingBar height={4} color="#0078D3" ref={ref} />
      <div className="flex-1">
        <ContentHeader
          title="All Categories"
          subtitle="Here you can see all categories that you have created. You can try to create a new one also!"
          onSearch={setKeyword}
          value={keyword}
          buttonTitle="New Category"
          onButtonClick={() => push("/category")}
        />

        <form className="m-6 w-10/12 md:w-1/3">
          <input
            className="input-base border-0 py-0 pl-0 text-lg font-bold mb-1 border-b border-transparent rounded-none focus:border-black focus:ring-0 focus:border-b-1"
            type="text"
            {...register("name")}
            placeholder="Type a menu name"
            onBlur={handleSubmit(onSubmit)}
          />
          <input
            className="input-base border-0 py-0 pl-0 text-sm border-b border-transparent rounded-none focus:border-black focus:ring-0"
            type="text"
            {...register("description")}
            placeholder="Type a menu description"
            onBlur={handleSubmit(onSubmit)}
          />
        </form>

        {!isLoading && (
          <div
            className={
              `my-3 mx-6 ${
                filteredComponents.length > 0 ? "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6" : ""}`
            }
          >
            {filteredComponents.length > 0 ? (
              <SortableGridContainer
                items={filteredComponents}
                setItems={setFilteredComponents}
                setActiveId={setActiveId}
                onDragEnd={handleDragEnd}
                overlayChildren={
                  activeId ? (
                    <SortableItemOverlay id={activeId} useHandler positionHandler="bottom">
                      <CardWithOptions
                        item={{
                          id: activeField?.id ?? "",
                          title: activeField?.name ?? "",
                          subtitle: `${activeField?.total_items ?? "0"} Items`,
                          image: activeField?.image
                            ? `${process.env.REACT_APP_CDN_URL}/images/${activeField.image}`
                            : imagePlaceholder,
                          isActive: !!activeField?.is_published,
                        }}
                        onEnter={() => {}}
                        onEdit={() => {}}
                        onIsPublish={() => {}}
                        onDelete={() => {}}
                        isLoadingPublish={false}
                        dragable
                      />
                    </SortableItemOverlay>
                  ) : null
                }
              >
                {filteredComponents.map(({
                  id, name, total_items, image, is_published,
                }, index) => (
                  <SortableItem
                    key={id}
                    id={id}
                    style={{ opacity: id === activeId ? 0 : 1 }}
                    useHandler
                    positionHandler="bottom"
                    disabled={!!debouncedKeyword}
                  >
                    <CardWithOptions
                      item={{
                        id,
                        title: name,
                        subtitle: `${total_items} Items`,
                        image: image ? `${process.env.REACT_APP_CDN_URL}/images/${image}` : imagePlaceholder,
                        isActive: toggler[index] ?? is_published,
                      }}
                      goToLocalize={goToLocalize}
                      onIsPublish={() => handleIsPublish(index)}
                      isLoadingPublish={isLoadingPublish}
                      onDelete={handleDelete}
                      onEnter={handleEnter}
                      onEdit={handleEdit}
                      onDuplicate={handleDuplicate}
                      isLoadingDuplicate={isLoadingDuplicateCategory}
                      dragable
                    />
                  </SortableItem>
                ))}
              </SortableGridContainer>
            ) : (
              <div className="h-full w-full flex-1 flex flex-col gap-1 justify-center items-center">
                <IllustrationNoItems className="h-64 w-64 mb-4" />

                <p className="text-lg">
                  There&#39;s no
                  {debouncedKeyword.length > 0 ? debouncedKeyword : "category"}
                </p>
                <p className="text-secondary">
                  You haven’t add any
                  {" "}
                  {debouncedKeyword.length > 0 ? `${debouncedKeyword} in category` : "category"}
                </p>
              </div>
            )}
          </div>
        )}
      </div>

      <ModalConfirmation
        isOpen={modalConfirmation.isOpen}
        content="Are you sure?"
        onCloseModal={() => setModalConfirmation({ isOpen: false, data: null })}
        onConfirmModal={callbackConfirmation}
        confirmationText="Delete"
        confirmationLoading={isLoadingDeleteItem}
      />
    </>
  );
}

export default CategoriesPage;
