import { createApi } from "@reduxjs/toolkit/query/react";

import baseQuery from "./base-service";

export interface ItemListResponse {
  id: string;
  name: string;
  image?: string | null;
  currency: string;
  prices: number[] | null;
  is_published: boolean;
  object?: "item" | "section";
  item_count?: number | null;
}

export interface AllItemResponse {
  name: string;
  description: string;
  items: ItemListResponse[];
}

export interface ItemPrice {
  id?: string;
  price: number;
  description: string;
}

export interface ItemModifier {
  modifier_id: string;
  min: number;
  max: number;
}

interface ItemResponse {
  id: string;
  name: string;
  description: string;
  image?: string;
  video?: string;
  prices: ItemPrice[];
  prep_time: number;
  modifiers: ItemModifier[];
  is_new: boolean;
  is_signature: boolean;
  calories: number;
  ingredients_warning: string[]; // ingredient id
  publish_status: boolean;
  is_nutrition_published: boolean;
  nutri_serving_size: number | null;
  nutri_serving_unit_id: string | null;
  nutri_calories: number | null;
  nutri_calories_fat: number | null;
  nutritions: {
    nutri_id: string;
    value: number | null;
    daily_value: number | null;
    unit?: string;
  }[];
}

export interface AddItemRequest {
  id?: string;
  category_id?: string;
  section_id?: string;
  name: string;
  description: string;
  image: string | null;
  video: string | null;
  prices: ItemPrice[];
  prep_time: number;
  modifiers: ItemModifier[];
  isNew: boolean;
  isSignature: boolean;
  calories: number;
  ingredients_warning: string[]; // ingredient id
  isPublished: boolean;
  is_nutrition_published: boolean;
  nutri_serving_size: number | null;
  nutri_serving_unit_id: string | null;
  nutri_calories: number | null;
  nutri_calories_fat: number | null;
  nutritions: ItemResponse["nutritions"];
}
export interface UpdateItemHeaderRequest {
  category_id: string;
  name?: string;
  description?: string;
}

export const itemServices = createApi({
  reducerPath: "item",
  baseQuery,
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  endpoints: (builder) => ({
    getAllItem: builder.query<HttpResponse<AllItemResponse>, string>({
      query: (categoryID) => ({
        url: `/item/${categoryID}`,
        method: "GET",
      }),
    }),
    getItem: builder.query<HttpResponse<ItemResponse>, string>({
      query: (id) => ({
        url: `/item/single/${id}`,
        method: "GET",
      }),
    }),
    postItem: builder.mutation<HttpResponse<{}>, AddItemRequest>({
      query: (body) => ({
        url: "/item",
        method: "POST",
        body,
      }),
    }),
    updateItem: builder.mutation<HttpResponse<{}>, AddItemRequest>({
      query: (body) => ({
        url: "/item",
        method: "PUT",
        body,
      }),
    }),
    updateItemHeader: builder.mutation<HttpResponse<{}>, UpdateItemHeaderRequest>({
      query: (body) => ({
        url: "/item/header",
        method: "PUT",
        body,
      }),
    }),
    publishItem: builder.mutation<HttpResponse<{}>, string>({
      query: (id) => ({
        url: "/item/publish",
        method: "PUT",
        body: { id },
      }),
    }),
    deleteItem: builder.mutation<HttpResponse<{}>, string>({
      query: (id) => ({
        url: "/item",
        method: "DELETE",
        body: { id },
      }),
    }),
    moveItem: builder.mutation<HttpResponse<{}>, { id: string; category_id: string; section_id: string }>({
      query: (body) => ({
        url: "/item/move",
        method: "PUT",
        body,
      }),
    }),
    updateItemPhoto: builder.mutation<HttpResponse<{}>, { id: string; photo: string | null }>({
      query: (body) => ({
        url: "/item/photo",
        method: "PUT",
        body,
      }),
    }),
    updateItemVideo: builder.mutation<HttpResponse<{}>, { id: string; video: string | null }>({
      query: (body) => ({
        url: "/item/video",
        method: "PUT",
        body,
      }),
    }),
    duplicateItem: builder.mutation<HttpResponse<{}>, { id: string }>({
      query: (body) => ({
        url: "/item/duplicate",
        method: "POST",
        body,
      }),
    }),
    dragAndDropItem: builder.mutation<HttpResponse<{}>, { category_id: string; item_id_array: string[] }>({
      query: (body) => ({
        url: "/item/drag",
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  useGetAllItemQuery,
  useGetItemQuery,
  usePostItemMutation,
  useUpdateItemMutation,
  useUpdateItemHeaderMutation,
  usePublishItemMutation,
  useDeleteItemMutation,
  useMoveItemMutation,
  useUpdateItemPhotoMutation,
  useUpdateItemVideoMutation,
  useDuplicateItemMutation,
  useDragAndDropItemMutation,
} = itemServices;
