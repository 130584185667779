import { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Popup from "reactjs-popup";

import { useAppSelector } from "../../app/hooks";
import { useDebounce, useQrLink } from "../../hooks";
import { Tooltip } from "../atoms";
import Published from "../publish";

import { ReactComponent as EyeIcon } from "../../assets/icons/ic_eye.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/ic_plus.svg";
import { ReactComponent as PublishIcon } from "../../assets/icons/ic_publish.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/ic_search.svg";
import { ReactComponent as UserIcon } from "../../assets/icons/ic_user_outline.svg";
import logo from "../../assets/logo.png";

interface Props {
  routes: PrivateRouteType[];
  marginTop: string;
}

function HeaderUserDesktop({ routes, marginTop }: Props) {
  const savedUser = useAppSelector(({ auth }) => auth.user);
  const isQR = savedUser?.features.includes("qrmobile");

  const [keyword, setKeyword] = useState<string>("");
  const debouncedKeyword = useDebounce<string>(keyword, 500);
  const { push } = useHistory();
  const { qrLink, qrLinkLoading } = useQrLink();

  const handleChange = (e: InputChangeType) => {
    setKeyword(e.target.value);
  };

  const handleClick = (path: string) => {
    push(path);
  };

  const searchRoutes = useMemo((): PrivateRouteType[] => routes
    .flatMap((route) => {
      // mapped nested routes
      if (route.routes) {
        return route.routes.map((nestedRoute) => nestedRoute);
      }
      return route;
    })
    .filter(({ name }) => {
      if (isQR && (name === "Devices" || name === "Theme")) {
        return false;
      }
      return true;
    })
    .filter((route) => {
      const name = route.path?.toLowerCase();
      const key = debouncedKeyword.toLowerCase();
      return name?.toLowerCase().includes(key) && !!route.name;
    }), [debouncedKeyword, routes]);

  const viewMenu = async () => {
    if (qrLink) {
      window.open(qrLink, "_blank");
    } else {
      toast.error("Failed to fetch QR web link");
    }
  };

  return (
    <div className="hidden md:flex items-center mx-6 my-3 p-2 shadow-md rounded-lg">
      <div className="flex items-center flex-1">
        <SearchIcon className="w-4 h-4 text-placeholder ml-2 mr-5" />
        <Popup
          modal
          contentStyle={{
            background: "transparent",
            border: 0,
            // marginTop: "1rem",
            marginTop: `calc(1rem + ${marginTop})`,
            marginRight: "1rem",
            marginLeft: "18rem",
            width: "calc(100% - 19rem)",
          }}
          trigger={(
            <button
              className="text-sm text-left text-placeholder w-10/12 outline-none"
              type="button"
            >
              Search here ...
            </button>
          )}
        >
          {(close: () => void) => (
            <>
              <div className="w-full flex bg-white rounded items-center px-2 py-1">
                <SearchIcon className="h-4 w-4 mx-2" />
                <input
                  type="text"
                  placeholder="Search here ..."
                  className="input-base focus:ring-0"
                  value={keyword}
                  onChange={handleChange}
                />
                <PlusIcon className="transform transition rotate-45 h-6 w-6 ml-2" onClick={close} />
              </div>

              {searchRoutes.length > 0 && debouncedKeyword.length > 0 && (
                <div className="w-full flex flex-col bg-white rounded mt-4 p-2">
                  {searchRoutes.map(({ name, Icon, path }) => (
                    <button
                      type="button"
                      className="flex items-center p-2 cursor-pointer hover:bg-gray-100 rounded"
                      onClick={() => {
                        if (!path) return;
                        handleClick(path);
                        close();
                      }}
                    >
                      {Icon ? (
                        <Icon className="h-4 w-4 mr-4 text-secondary" />
                      ) : (
                        <p className="text-xs h-4 w-4 mr-4 text-secondary">○</p>
                      )}
                      <p className="text-sm text-secondary">{name ?? path}</p>
                    </button>
                  ))}
                </div>
              )}
            </>
          )}
        </Popup>
      </div>

      <img src={logo} alt="Logo" className="h-9 w-9" />

      <div className="flex flex-1 justify-end items-center">
        {/* <button className="bg-orange bg-opacity-10 text-orange rounded text-xs py-1 px-2">
          Free trial
        </button> */}

        {isQR && (
          <button
            className="px-2"
            onClick={viewMenu}
            disabled={qrLinkLoading}
            type="button"
          >
            <Tooltip
              content="View Menu"
              placement="bottom"
            >
              <EyeIcon title="view menu" className="w-4 h-4 text-secondary hover:text-primary" />
            </Tooltip>
          </button>
        )}

        <Popup
          modal
          contentStyle={{
            background: "white",
            borderRadius: "8px",
            maxWidth: "591px",
          }}
          trigger={(
            <button
              className="px-2"
              type="button"
            >
              <Tooltip
                content="Publish"
                placement="bottom"
              >
                <PublishIcon title="publish" className="w-4 h-4 text-secondary hover:text-primary" />

              </Tooltip>
            </button>
          )}
        >
          <Published />
        </Popup>

        <button
          type="button"
          className="px-2"
          onClick={() => {
            push({
              pathname: "/setting",
              state: {
                tab: "account",
              },
            });
          }}
        >
          <Tooltip
            content="Account"
            placement="bottom"
          >
            <UserIcon title="account" className="w-4 h-4 text-secondary hover:text-primary" />

          </Tooltip>
        </button>
      </div>
    </div>
  );
}

export default HeaderUserDesktop;
