/* eslint-disable no-console */
import { useEffect } from "react";

import publishedImage from "../assets/published.png";
import LoadingAnimation from "./loading-animation";

import { usePublishMutation } from "../services";

function Published() {
  const [publish, { isLoading, isError }] = usePublishMutation();

  useEffect(() => {
    publish()
      .unwrap()
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="flex flex-col gap-8 w-full items-center p-8 text-center">
      {!isError
        && (isLoading ? (
          <div className="rounded-full h-40 w-40 bg-gray-100 flex items-center justify-center">
            <LoadingAnimation />
          </div>
        ) : (
          <img src={publishedImage} className="h-40 w-40" alt="Published  Successfully" />
        ))}
      <p className="font-bold text-lg md:text-2xl">
        {isLoading ? "Publishing" : `Published  ${isError ? "Failed" : "Successfully"}`}
      </p>
    </div>
  );
}

export default Published;
