/* eslint-disable @typescript-eslint/no-empty-function */
import {
  useEffect, useMemo, useRef, useState,
} from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Popup from "reactjs-popup";
import LoadingBar from "react-top-loading-bar";

import { ReactComponent as IllustrationNoItems } from "../assets/illustration_no_items.svg";
import { ReactComponent as SpinnerIcon } from "../assets/icons/ic_spinner.svg";
import { ReactComponent as ChevronIcon } from "../assets/icons/ic_chevron_down.svg";
import { ReactComponent as DetailIcon } from "../assets/icons/ic_detail.svg";
import { ReactComponent as CloseIcon } from "../assets/icons/ic_close.svg";
import { ReactComponent as PlusIcon } from "../assets/icons/ic_plus.svg";
import imagePlaceholder from "../assets/image_placeholder.png";

import { getBreakpointValue } from "../utils";

import {
  ContentHeader,
  CardWithOptions,
  LoadingAnimation,
  ModalConfirmation,
  SortableItem,
  SortableItemOverlay,
  SortableGridContainer,
} from "../components";
import { useDebounce } from "../hooks";

import {
  useGetAllItemQuery,
  useGetCategoryListQuery,
  usePublishItemMutation,
  useDeleteItemMutation,
  useUpdateItemHeaderMutation,
  useMoveItemMutation,
  ItemListResponse,
  useDuplicateItemMutation,
  useDragAndDropItemMutation,
  useCreateSectionMutation,
  useGetSingleSectionQuery,
  useUpdateSectionMutation,
  useGetSectionListQuery,
} from "../services";

interface FormItem {
  name: string;
  description: string;
}

interface Confirmation {
  isOpen: boolean;
  data: string | null;
}

interface FormSection {
  id?: string;
  name: string;
  description: string;
  isPublished: boolean;
}

function ItemsPage() {
  const [moveItem, setMoveItem] = useState<string | null>(null);
  const [toggler, setToggler] = useState<boolean[]>([]);
  const [keyword, setKeyword] = useState<string>("");
  const [selectedDataToMove, setDataToMove] = useState<{
    id: string;
    type: string | "section" | "category";
  }>({
    id: "",
    type: "",
  });
  const [modalConfirmation, setModalConfirmation] = useState<Confirmation>({
    isOpen: false,
    data: null,
  });
  const [filteredComponents, setfilteredComponents] = useState<ItemListResponse[]>([]);
  const [activeId, setActiveId] = useState<string | null>(null);
  const [modalSection, setModalSection] = useState<Confirmation>({
    isOpen: false,
    data: null,
  });

  const { push, replace, goBack } = useHistory();
  const { state } = useLocation<StateID>();
  const ref = useRef(null);
  const isMobile = getBreakpointValue("md") > window.innerWidth;
  const { register, setValue, handleSubmit } = useForm<FormItem>();
  const {
    register: registerSection,
    reset: resetSection,
    handleSubmit: handleSubmitNewSection,
    setValue: setValueSection,
  } = useForm<FormSection>();
  const debouncedKeyword = useDebounce<string>(keyword, 500);

  const { data, isLoading, refetch } = useGetAllItemQuery(state?.category_id ?? "");
  const { data: categoryList } = useGetCategoryListQuery();
  const { data: sectionList, refetch: refetchSectionList } = useGetSectionListQuery(state?.category_id ?? "");
  const [publishItem, { isLoading: isLoadingPublish }] = usePublishItemMutation();
  const [deleteItem, { isLoading: isLoadingDeleteItem }] = useDeleteItemMutation();
  const [updateMoveItem, { isLoading: isLoadingMoveItem }] = useMoveItemMutation();
  const [duplicateItem, { isLoading: isLoadingDuplicateItem }] = useDuplicateItemMutation();
  const [updateItemHeader] = useUpdateItemHeaderMutation();
  const [dragAndDropItems, { isLoading: isLoadingdragAndDropItems }] = useDragAndDropItemMutation();
  const [createSection, { isLoading: isLoadingCreateSection }] = useCreateSectionMutation();
  const [updateSection, { isLoading: isLoadingUpdateSection }] = useUpdateSectionMutation();
  const { data: SectionData, isLoading: isLoadingLoadSectionData } = useGetSingleSectionQuery(
    {
      id: modalSection.data ?? "",
    },
    {
      skip: !modalSection.data ?? true,
    },
  );

  const onSearch = (text: string) => {
    setKeyword(text);
  };

  /**
   * @description Function to handle publish item and section
   * @param index<number>
   */
  const handleIsPublish = (index: number) => {
    const item = data?.data?.items[index];
    if (item) {
      let textIsPublish = "publish";
      let tempToggler = toggler.map((isPublish, current) => {
        const isCurrentIndex = current === index;
        if (isCurrentIndex) {
          textIsPublish = isPublish ? "unpublish" : "publish";
        }
        return isCurrentIndex ? !isPublish : isPublish;
      });

      setToggler(tempToggler);

      publishItem(item.id)
        .unwrap()
        .then(() => {
          toast.success(`${item.name} ${textIsPublish}ed`);
        })
        .catch(() => {
          tempToggler = toggler.map((isPublish, current) => (current === index ? !isPublish : isPublish));

          setToggler(tempToggler);
          toast.error(`${item.name} ${textIsPublish} failed`);
        });
    }
  };

  /**
   * @description Function to handle click new item (redirect to /item page with state category_id)
   */
  const handleNewItem = () => {
    push({
      pathname: "item",
      state: {
        category_id: state.category_id,
      },
    });
  };

  /**
   * @description Function to handle click edit item (redirect to /item page with state item_id)
   * @param id<string>
   */
  const handleEdit = (id: string) => {
    push({
      pathname: "/item",
      state: {
        item_id: id,
      },
    });
  };

  /**
   * @description Function to handle click enter section (redirect to /section page with state)
   * @param id<string>
   */
  const handleEnterSection = (id: string) => {
    push({
      pathname: "/section",
      state: {
        section_id: id,
        category_id: state?.category_id,
      },
    });
  };

  /**
   * @description Function to handle edit section
   * @param id<string>
   */
  const handleEditSection = async (id: string) => {
    setModalSection({
      isOpen: true,
      data: id,
    });
  };

  /**
   * @description Function to handle open modal confirmation and set the data to modalConfirmation.data
   * @param id
   */
  const handleDelete = (id: string) => {
    setModalConfirmation({ isOpen: true, data: id });
  };

  /**
   * @description Function to handle callback confirmation to delete item or section
   * @returns
   */
  const callbackConfirmation = () => {
    const { data: modalConfirmationData } = modalConfirmation;

    const isSection = sectionList?.data?.sections && sectionList?.data?.sections
      .find((section) => section.id === modalConfirmationData);

    if (!modalConfirmationData) return;
    deleteItem(modalConfirmationData)
      .unwrap()
      .then(() => {
        if (isSection) {
          toast.success("Successfully delete section");
        } else {
          toast.success("Successfully delete item");
        }

        refetch();
      })
      .catch(() => {
        toast.error("Failed to delete item");
      })
      .finally(() => {
        setModalConfirmation((prevState: Confirmation) => ({ ...prevState, isOpen: false }));
      });
  };

  /**
   * @description Function to handle update item header
   * @param param<{ name: string; description: string }>
   */
  const onSubmit: SubmitHandler<FormItem> = ({ name, description }) => {
    if ((data?.data?.name === name && data?.data?.description === description) || state?.category_id === undefined) {
      return;
    }

    updateItemHeader({ category_id: state.category_id, name, description })
      .unwrap()
      .then(() => {
        toast.success("Successfully update item");
      })
      .catch(() => {
        toast.error("Failed update item");
      });
  };

  /**
   * @description Function to handle move item and section to another category or section
   * @returns void
   */
  const onMoveItem = () => {
    if (!moveItem || (!selectedDataToMove.id && !selectedDataToMove.type)) return;

    const isSection = sectionList?.data?.sections?.find((section) => section.id === moveItem);

    updateMoveItem({
      id: moveItem,
      category_id: selectedDataToMove.type === "category" ? selectedDataToMove.id : state.category_id ?? "",
      section_id: selectedDataToMove.type === "section" ? selectedDataToMove.id : "",
    })
      .unwrap()
      .then(() => {
        toast.success(`Successfully move ${isSection ? "section" : "item"}`);
        refetch();
      })
      .catch(() => {
        toast.error(`Failed to move ${isSection ? "section" : "item"}`);
      })
      .finally(() => {
        setMoveItem(null);
      });
  };

  /**
   * @description Function to handle duplicate item
   * @param id<string>
   */
  const handleDuplicate = (id: string) => {
    duplicateItem({ id })
      .unwrap()
      .then(() => {
        refetch();
      })
      .catch((error: any) => {
        toast.error(error.data?.message ?? "Failed to duplicate item");
      });
  };

  const activeField = useMemo(
    () => filteredComponents.find(({ id }) => id === activeId),
    [filteredComponents, activeId],
  );

  /**
   * @description Function to handle drag end
   * @param ids<string[]>
   */
  const handleDragEnd = (ids: string[]) => {
    if (state.category_id) {
      dragAndDropItems({
        category_id: state.category_id,
        item_id_array: ids,
      });
    }
  };

  /**
   * @description Function to handle create/update section
   * @param { name: string, description: string, isPublished: boolean }
   */
  const onSubmitNewSection: SubmitHandler<FormSection> = ({ name, description, isPublished }) => {
    const { category_id } = state;

    if (!category_id) return;

    if (modalSection.data === null) {
      createSection({
        category_id,
        name,
        description,
        isPublished,
      })
        .unwrap()
        .then(() => {
          toast.success("Successfully create section");
          refetch();
        })
        .catch(() => {
          toast.error("Failed to create section");
        })
        .finally(() => {
          setModalSection((prevState) => ({
            ...prevState,
            isOpen: false,
            data: null,
          }));
          resetSection();
        });
    } else {
      updateSection({
        id: modalSection.data,
        name,
        description,
        isPublished,
      })
        .unwrap()
        .then(() => {
          toast.success("Successfully update section");
          refetch();
        })
        .catch(() => {
          toast.error("Failed to update section");
        })
        .finally(() => {
          setModalSection((prevState) => ({
            ...prevState,
            isOpen: false,
            data: null,
          }));
          resetSection();
        });
    }
  };

  /**
   * @description Function to handle close modal section
   */
  const handleOnCloseModal = () => {
    setModalSection((prevState: Confirmation) => ({
      ...prevState,
      isOpen: false,
      data: null,
    }));
  };

  // Check if the moveItem id is section or an item
  // If it is an item, then we need to check if the section is in the same category
  const renderSectionList = () => {
    const isSection = sectionList?.data?.sections?.find(({ id }: { id: string; name: string }) => id === moveItem);

    if (isSection) return "";

    return sectionList && sectionList?.data?.sections ? (
      <optgroup label="Sections">
        {sectionList?.data?.sections.map((section: { id: string; name: string }) => (
          <option key={section.id} value={`${section.id}-section`}>
            {section.name}
          </option>
        ))}
      </optgroup>
    ) : (
      ""
    );
  };

  // when edit section clicked
  // we need to check if the section modal have data id or not
  useEffect(() => {
    resetSection();

    if (modalSection.data) {
      setValueSection("name", SectionData?.data?.name ?? "");
      setValueSection("description", SectionData?.data?.description ?? "");
      setValueSection("isPublished", SectionData?.data?.publish_status ?? false);

      setModalSection((prevState) => ({
        ...prevState,
        isOpen: true,
      }));
    } else {
      setModalSection({
        isOpen: false,
        data: null,
      });
    }
  }, [modalSection.data, SectionData]);

  useEffect(() => {
    if (!state?.category_id) {
      replace("/categories");
    }
  }, [state?.category_id]);

  useEffect(() => {
    const responseData = data?.data;

    if (responseData) {
      setToggler(responseData.items?.map(({ is_published }) => is_published) ?? []);
      setValue("name", responseData.name);
      setValue("description", responseData.description);
    }
  }, [data?.data]);

  useEffect(() => {
    if (!state?.category_id) {
      replace("/categories");
    }
  }, [state]);

  useEffect(() => {
    const progress = ref.current as any;

    if (isLoading || isLoadingdragAndDropItems || isLoadingPublish || isLoadingDuplicateItem) {
      progress?.continuousStart();
    } else {
      progress?.complete();
    }
  }, [isLoading, isLoadingdragAndDropItems, isLoadingPublish, isLoadingDuplicateItem]);

  useEffect(() => {
    if (!data?.data?.items || Object.keys(data?.data ?? {}).length === 0) {
      setfilteredComponents([]);
      return;
    }

    const filtered = data?.data?.items
      .filter(({ name }) => name.toLowerCase().includes(debouncedKeyword.toLowerCase()));

    setfilteredComponents(filtered);
  }, [data?.data, debouncedKeyword]);

  useEffect(() => {
    setToggler(filteredComponents.map(({ is_published }) => is_published) ?? []);
  }, [filteredComponents]);

  useEffect(() => {
    refetchSectionList();
  }, [moveItem]);

  if (!data) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <LoadingAnimation />
      </div>
    );
  }

  return (
    <>
      <div className="flex-1">
        {moveItem && (
          <Popup
            open={moveItem !== null}
            contentStyle={{
              borderRadius: 8,
              padding: "1rem",
              background: "transparent",
              border: 0,
            }}
            onClose={() => setMoveItem(null)}
            closeOnDocumentClick
          >
            <div className="flex flex-col justify-between gap-12 fixed md:relative w-full md:h-full inset-0 top-auto md:inset-auto p-4 md:p-6 bg-white rounded-t-xl md:rounded-b-xl">
              <div className="flex flex-col md:flex-row md:items-center md:mt-8 gap-4 relative">
                <p className="text-secondary">
                  Move
                  {data?.data?.items.find(({ id }) => id === moveItem)?.name}
                  {" "}
                  to :
                </p>
                <select
                  name="categories"
                  className="input-base flex-1"
                  onChange={(e) => {
                    // Get the id and type data of the selected item and set to selectedDataToMove
                    const getDataType = e.target.value.split("-")[1];
                    const getId = e.target.value.split("-")[0];

                    setDataToMove({
                      id: getId,
                      type: getDataType,
                    });
                  }}
                  value={`${
                    !selectedDataToMove.id && !selectedDataToMove.type
                      ? ""
                      : `${selectedDataToMove.id}-${selectedDataToMove.type}`
                  }`}
                >
                  <option value="" disabled>
                    Select Category
                    {" "}
                    {sectionList?.data?.sections && sectionList?.data?.sections.length > 0 ? "/ Section" : ""}
                  </option>
                  <optgroup label="Categories">
                    {categoryList
                      && categoryList?.data?.categories
                        .filter((category) => category.id !== state?.category_id)
                        .map((category) => (
                          <option key={category.id} value={`${category.id}-category`}>
                            {category.name}
                          </option>
                        ))}
                  </optgroup>

                  {/* Function to handle render section list */}
                  {renderSectionList()}
                </select>
                <ChevronIcon className="h-5 w-5 absolute top-12 md:top-2 right-2" />
              </div>

              <div className="flex w-full justify-end mt-4">
                <button type="button" title="Close" className="button-secondary py-2 w-1/2 md:w-1/4 mr-4" onClick={() => setMoveItem(null)}>
                  Close
                </button>

                <button
                  type="button"
                  title={isLoadingMoveItem ? "Loading..." : "Move"}
                  className="button-primary py-2 w-1/2 md:w-1/4 flex items-center justify-center"
                  onClick={onMoveItem}
                  disabled={isLoadingMoveItem}
                >
                  {isLoadingMoveItem ? (
                    <SpinnerIcon className="animate-spin h-4 w-4 m-2" />
                  ) : (
                    <>
                      <span>Move</span>
                      <ChevronIcon className="h-5 w-5 transform -rotate-90 ml-3" color="white" />
                    </>
                  )}
                </button>
              </div>
            </div>
          </Popup>
        )}

        <LoadingBar height={4} color="#0078D3" ref={ref} />

        <ContentHeader
          title={data?.data?.name ?? ""}
          subtitle="Here you can see all items that you have created. You can try to create a new one also!"
          onSearch={onSearch}
          value={keyword}
          buttonTitle="New Item"
          onButtonClick={handleNewItem}
          backButton={goBack}
        />

        <form className="m-6 w-10/12 md:w-1/3 flex items-start">
          <div>
            <input
              className="input-base border-0 py-0 pl-0 text-lg font-bold mb-1 border-b border-transparent rounded-none focus:border-black focus:ring-0 focus:border-b-1"
              type="text"
              {...register("name")}
              placeholder="Type an item name"
              onBlur={handleSubmit(onSubmit)}
            />
            <input
              className="input-base border-0 py-0 pl-0 text-sm border-b border-transparent rounded-none focus:border-black focus:ring-0 focus:border-b-1"
              type="text"
              {...register("description")}
              placeholder="Type an item description"
              onBlur={handleSubmit(onSubmit)}
            />
          </div>
          <button
            type="button"
            className="button-primary min-w-[35px] p-0 flex items-center justify-center h-[35px] rounded-full"
            onClick={() => setModalSection({ isOpen: true, data: null })}
            title="Add new section"
            aria-label="Add new section"
          >
            <PlusIcon className="w-5 h-5" />
          </button>
        </form>

        {!isLoading && (
          <div
            className={
              `my-3 mx-6 ${
                filteredComponents.length > 0 ? "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6" : ""}`
            }
          >
            {filteredComponents.length > 0 ? (
              <SortableGridContainer
                items={filteredComponents}
                setItems={setfilteredComponents}
                setActiveId={setActiveId}
                onDragEnd={handleDragEnd}
                overlayChildren={
                  activeId ? (
                    <SortableItemOverlay id={activeId} useHandler positionHandler="bottom">
                      {activeField?.object === "section" ? (
                        <CardWithOptions
                          item={{
                            id: activeField?.id ?? "",
                            title: activeField?.name ?? "",
                            subtitle: `${activeField?.item_count} items / section`,
                            isActive: !!activeField?.is_published,
                          }}
                          cardType="section"
                          onEnter={handleEnterSection}
                          onEdit={handleEditSection}
                          onIsPublish={() => {}}
                          onDelete={() => {}}
                          isLoadingPublish={false}
                          dragable
                        />
                      ) : (
                        <CardWithOptions
                          item={{
                            id: activeField?.id ?? "",
                            title: activeField?.name ?? "",
                            subtitle: `${activeField?.currency} ${activeField?.prices?.[0]?.toString()}`,
                            image: activeField?.image
                              ? `${process.env.REACT_APP_CDN_URL}/images/${activeField.image}`
                              : imagePlaceholder,
                            isActive: !!activeField?.is_published,
                          }}
                          onEdit={() => {}}
                          onIsPublish={() => {}}
                          onDelete={() => {}}
                          isLoadingPublish={false}
                          dragable
                        />
                      )}
                    </SortableItemOverlay>
                  ) : null
                }
              >
                {filteredComponents.map(
                  ({
                    id, name, prices, image, currency, is_published, object, item_count,
                  }, index) => (
                    <SortableItem
                      key={id}
                      id={id}
                      style={{ opacity: id === activeId ? 0 : 1 }}
                      useHandler
                      positionHandler="bottom"
                      disabled={!!debouncedKeyword}
                    >
                      {object === "section" ? (
                        <CardWithOptions
                          item={{
                            id,
                            title: name,
                            subtitle: `${item_count} items / section`,
                            isActive: toggler[index] ?? is_published,
                          }}
                          onIsPublish={() => handleIsPublish(index)}
                          isLoadingPublish={isLoadingPublish}
                          onDelete={handleDelete}
                          onMove={setMoveItem}
                          onEnter={handleEnterSection}
                          onEdit={handleEditSection}
                          cardType="section"
                          onDuplicate={handleDuplicate}
                          isLoadingDuplicate={isLoadingDuplicateItem}
                          dragable
                        />
                      ) : (
                        <CardWithOptions
                          key={id}
                          item={{
                            id,
                            title: name,
                            subtitle: `${currency} ${prices?.[0]?.toString()}`,
                            image: image ? `${process.env.REACT_APP_CDN_URL}/images/${image}` : imagePlaceholder,
                            isActive: toggler[index] ?? is_published,
                          }}
                          onIsPublish={() => handleIsPublish(index)}
                          isLoadingPublish={isLoadingPublish}
                          onDelete={handleDelete}
                          onMove={setMoveItem}
                          onEdit={handleEdit}
                          onDuplicate={handleDuplicate}
                          isLoadingDuplicate={isLoadingDuplicateItem}
                          dragable
                        />
                      )}
                    </SortableItem>
                  ),
                )}
              </SortableGridContainer>
            ) : (
              <div className="h-full w-full flex-1 flex flex-col gap-1 justify-center items-center">
                <IllustrationNoItems className="h-64 w-64 mb-4" />

                <p className="text-lg">
                  There&#39;s no
                  {debouncedKeyword.length > 0 ? debouncedKeyword : "item"}
                </p>
                <p className="text-secondary">
                  You haven&#39;t add any
                  {" "}
                  {debouncedKeyword.length > 0 ? `${debouncedKeyword} in item` : "item"}
                </p>
              </div>
            )}
          </div>
        )}
      </div>

      <Popup
        modal
        open={modalSection.isOpen}
        onClose={handleOnCloseModal}
        // closeOnDocumentClick={!isMobile}
        closeOnEscape
        lockScroll
        contentStyle={{
          maxHeight: isMobile ? "100%" : "80%",
          maxWidth: isMobile ? "90%" : 600,
          width: "100%",
          overflowY: "auto",
          backgroundColor: "white",
          borderRadius: 8,
        }}
      >
        <div className="w-full md:w-auto inset-0 md:relative pt-4 bg-white">
          <div className="flex items-center justify-between pb-4 px-4 border-b">
            <div className="flex items-center">
              <button type="button" className="bg-transparent focus:outline-none">
                <DetailIcon className="h-6 w-6 mr-2" />
              </button>
              <h2 className="font-bold text-2xl pr-4">
                {modalSection.data ? "Update" : "Add New"}
                {" "}
                Section
              </h2>
            </div>

            <button
              type="button"
              className="bg-transparent focus:outline-none"
              onClick={handleOnCloseModal}
              title="Close"
              aria-label="Close"
            >
              <CloseIcon className="h-6 w-6" />
            </button>
          </div>

          <form id="form-add-new-section" className="p-4" onSubmit={handleSubmitNewSection(onSubmitNewSection)}>
            <div className="flex flex-col md:flex-row md:items-center mb-4">
              <p className="md:w-5/12 mb-2 md:mb-0">Section Name</p>
              <input
                type="text"
                disabled={isLoadingCreateSection || isLoadingUpdateSection || isLoadingLoadSectionData}
                className="input-base flex-1"
                placeholder="Add section name"
                {...registerSection("name", {
                  required: true,
                })}
              />
            </div>

            <div className="flex flex-col md:flex-row md:items-start mb-4">
              <p className="md:w-5/12 mb-2 md:mb-0">Description</p>
              <textarea
                rows={4}
                className="input-base flex-1"
                disabled={isLoadingCreateSection || isLoadingUpdateSection || isLoadingLoadSectionData}
                placeholder="Type a section description"
                {...registerSection("description")}
              />
            </div>

            <div className="flex flex-row md:flex-row md:items-start mb-4">
              <p className="md:w-5/12 mb-2 md:mb-0 flex-1 md:flex-none">Publish Status</p>

              <div className="flex items-center gap-2 flex-1">
                <div className="relative inline-block w-8 align-middle text-center select-none">
                  <input
                    type="checkbox"
                    id="toggle-is-published"
                    {...registerSection("isPublished")}
                    className="toggle-checkbox absolute block w-4 h-4 rounded-full bg-white border-2 appearance-none transition-all duration-300 ease-in cursor-pointer"
                    disabled={isLoadingCreateSection || isLoadingUpdateSection || isLoadingLoadSectionData}
                    // checked={watchSection("isPublished")}
                  />
                  <label
                    htmlFor="toggle-is-published"
                    className="toggle-label block overflow-hidden h-4 rounded-full bg-gray-300 transition-all duration-300 ease-in cursor-pointer"
                  />
                </div>

                <label htmlFor="toggle-is-published" className="cursor-pointer">
                  Published
                </label>
              </div>
            </div>

            <div className="w-full flex items-center mt-6 justify-center gap-2">
              <button
                className="button-primary py-2 flex items-center"
                type="submit"
                disabled={isLoadingCreateSection || isLoadingUpdateSection || isLoadingLoadSectionData}
              >
                {isLoadingCreateSection || isLoadingUpdateSection || isLoadingLoadSectionData ? (
                  <SpinnerIcon className="animate-spin h-6 w-6 mx-auto" />
                ) : modalSection.data ? (
                  "Update"
                ) : (
                  "Save"
                )}
              </button>
              <button
                className="button-gray py-2"
                type="button"
                onClick={handleOnCloseModal}
                disabled={isLoadingCreateSection || isLoadingUpdateSection || isLoadingLoadSectionData}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </Popup>

      <ModalConfirmation
        isOpen={modalConfirmation.isOpen}
        content="Are you sure?"
        onCloseModal={() => setModalConfirmation({ isOpen: false, data: null })}
        onConfirmModal={callbackConfirmation}
        confirmationText="Delete"
        confirmationLoading={isLoadingDeleteItem}
      />
    </>
  );
}

export default ItemsPage;
