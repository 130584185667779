import { useEffect, useState, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingBar from "react-top-loading-bar";
import { useForm, SubmitHandler, useFieldArray } from "react-hook-form";

import { ReactComponent as ChevronDownIcon } from "../assets/icons/ic_chevron_down.svg";
import { ReactComponent as ThumbnailIcon } from "../assets/icons/ic_thumbnail.svg";
import { ReactComponent as ButtonIcon } from "../assets/icons/ic_button.svg";

import {
  useGetThemeQuery,
  useImageMutation,
  usePostThemeMutation,
  useUpdateThemeMutation,
  useUpdateThemeBackgroundMutation,
  AddThemeRequest,
  useUserLanguageListQuery,
} from "../services";
import { DropUploader, ModalConfirmation, LoadingAnimation } from "../components";
import { useAppSelector } from "../app/hooks";

interface Confirmation {
  isOpen: boolean;
  data: AddThemeRequest | null;
}

function ThemesAddPage() {
  const uid = useAppSelector(({ auth }) => auth.uid);
  const { state } = useLocation<{ theme_id?: string }>();
  const { goBack } = useHistory();

  const {
    register, control, handleSubmit, reset, formState, setValue,
  } = useForm<AddThemeRequest>();

  const { fields, append } = useFieldArray({
    control,
    name: "button_texts",
  });

  const [postImage, { isLoading: isLoadingImage }] = useImageMutation();
  const [createTheme, { isLoading: isLoadingCreateTheme }] = usePostThemeMutation();
  const [updateTheme, { isLoading: isLoadingUpdateTheme }] = useUpdateThemeMutation();
  const [updateThemeBackground,
    { isLoading: isLoadingUpdateThemeBackground, isError: isErrorBackground },
  ] = useUpdateThemeBackgroundMutation();
  const { data: dataTheme, isLoading: isLoadingTheme } = useGetThemeQuery(state?.theme_id ?? "", {
    skip: !state?.theme_id ?? true,
  });
  const { data: dataLanguage, isLoading: isLoadingLanguage } = useUserLanguageListQuery(undefined, {
    skip: !!state?.theme_id,
  });
  const [modalConfirmation, setModalConfirmation] = useState<Confirmation>({
    isOpen: false,
    data: null,
  });
  const ref = useRef(null);

  /**
   * Function to handle open modal confirmation when user click submit
   * @param {AddThemeRequest} data
   * @returns {void}
   */
  const onSubmit: SubmitHandler<AddThemeRequest> = (data: AddThemeRequest) => {
    if (!data.name) {
      toast.error("Name is required");
      return;
    }
    if (!data.background) {
      toast.error("Background is required");
      return;
    }
    if (!data.button_shape) {
      toast.error("Button shape is required");
      return;
    }

    const filledName = data.button_texts.filter((text) => text.name !== "");
    if (filledName.length !== data.button_texts.length) {
      toast.error("Button text is required", { toastId: "error-button-texts" });
      return;
    }

    setModalConfirmation({ isOpen: true, data });
  };

  /**
   * Function to handle callback confirm modal confirmation when user click save
   * @returns {void}
   */
  const callbackConfirmation = () => {
    const { data } = modalConfirmation;
    if (!data) return;

    if (state?.theme_id) {
      const id = state.theme_id;
      const { background } = data;

      const firstUpdate = updateTheme({ ...data, id }).unwrap();
      let secondUpdate = dataTheme?.data?.background_img === background
        ? Promise.resolve({})
        : updateThemeBackground({ id, background }).unwrap();

      if (dataTheme?.data?.background_img && background === "") {
        secondUpdate = updateThemeBackground({ id, background: null }).unwrap();
      }

      Promise.all([firstUpdate, secondUpdate])
        .then(goBack)
        .catch((error) => {
          toast.error(error.data?.message ?? "Failed to update theme");
        });
    } else {
      createTheme(data)
        .unwrap()
        .then(goBack)
        .catch((error) => {
          toast.error(error.data?.message ?? "Failed to create theme");
        });
    }

    setModalConfirmation((prevState) => ({ ...prevState, isOpen: false }));
  };

  /**
   * Function to handle upload image
   * @param {String} path
   */
  const uploadImage = (path: string) => {
    if (path === "") {
      setValue("background", "", { shouldDirty: true });
      return;
    }
    if (!uid) return;

    const formData = new FormData();
    formData.append("uid", uid);
    formData.append("img", path);

    postImage(formData)
      .unwrap()
      .then((res) => {
        if (res.data?.image_id) {
          setValue("background", res.data.image_id, { shouldDirty: true });
        }
      })
      .catch((error) => {
        toast.error(error.data?.message ?? "Failed to upload");
        setValue("background", "", { shouldDirty: true });
      });
  };

  useEffect(() => {
    if (state?.theme_id) {
      if (!dataTheme?.data) return;

      const {
        name, button_shape, button_texts, background_img,
      } = dataTheme.data;

      reset({
        name,
        button_shape,
        button_texts,
        background: background_img,
      });
    } else {
      const button_texts = dataLanguage?.data?.map(({ lang_id }) => ({ lang_id, name: "" }));

      reset({
        name: "",
        button_texts,
        background: "",
        button_shape: "round",
      });
    }
  }, [state?.theme_id, dataTheme, reset, append, dataLanguage?.data]);

  useEffect(() => {
    const progress = ref.current as any;
    if (isLoadingTheme || isLoadingLanguage) {
      progress?.continuousStart();
    } else {
      progress?.complete();
    }
  }, [isLoadingTheme, isLoadingLanguage]);

  return (
    <>
      <LoadingBar height={4} color="#0078D3" ref={ref} />
      <div className="flex flex-col flex-1 md:p-0">
        <div className="flex justify-between p-4 border-b border-line-gray">
          <div className="flex items-center">
            <button type="button" title="Back" className="bg-transparent" onClick={goBack}>
              <ChevronDownIcon className="h-6 w-6 transform rotate-90 mr-2" />
            </button>
            <h2 className="font-bold text-2xl">{state?.theme_id ? "Update Theme" : "Add New Theme"}</h2>
          </div>

          <div className="hidden md:flex">
            <button type="button" title="Cancel" className="button-gray py-2 px-4 mr-4" onClick={goBack}>
              Cancel
            </button>

            <button
              type="button"
              title={isLoadingUpdateTheme || isLoadingCreateTheme ? "Saving..." : "Save"}
              className="button-primary py-2 px-4"
              onClick={handleSubmit(onSubmit)}
              disabled={!formState.isDirty
                || isLoadingImage
                || isLoadingUpdateTheme
                || isLoadingCreateTheme
                || isLoadingUpdateThemeBackground}
            >
              {isLoadingCreateTheme || isLoadingUpdateTheme || isLoadingImage || isLoadingUpdateThemeBackground ? (
                <div className="flex">
                  <LoadingAnimation size={5} />
                  {isLoadingImage && <span className="text-sm ml-2 font-normal">uploading image</span>}
                  {(isLoadingCreateTheme || isLoadingUpdateTheme || isLoadingUpdateThemeBackground) && (
                    <span className="text-sm ml-2 font-normal">Saving</span>
                  )}
                </div>
              ) : (
                <span>Save</span>
              )}
            </button>
          </div>
        </div>

        <div className="px-4 md:px-6 h-full">
          <form onSubmit={(e) => e.preventDefault()} className="flex flex-col md:flex-row h-full">
            {/* LEFT PANE */}
            <div className="flex flex-1 flex-col py-6 gap-10 md:border-r md:border-line-gray">
              <div className="flex flex-col w-full">
                <p className="font-semibold text-lg mb-1 flex">
                  <ThumbnailIcon className="mr-2" />
                  Your Background
                </p>
                <p className="text-sm text-secondary opacity-70 mb-4">Upload your business background</p>

                <DropUploader
                  type="primary"
                  withText
                  size="custom"
                  customClassName="w-auto h-48 rounded-xl md:mr-6"
                  setFilePath={uploadImage}
                  preview={dataTheme?.data?.background_img}
                  uploadFailed={isErrorBackground}
                />
                <input type="text" {...register("background")} className="hidden" />
              </div>
            </div>

            {/* RIGHT PANE */}
            <div className="flex flex-1 flex-col py-6 md:pl-6">
              <div className="flex flex-col md:flex-row md:items-center">
                <p className="md:w-4/12 mb-2 md:mb-0">Theme Name:</p>
                <input
                  type="text"
                  disabled={isLoadingUpdateTheme || isLoadingCreateTheme}
                  className="input-base w-full"
                  {...register("name")}
                />
              </div>

              <p className="font-semibold text-lg mt-14 flex">
                <ButtonIcon className="mr-2" fill="#181818" />
                Button Text
              </p>

              {fields.map((field, index) => (
                <div className="flex flex-col md:flex-row mt-4 md:items-center" key={field.lang_id}>
                  <input
                    type="text"
                    className="hidden"
                    {...register(`button_texts.${index}.lang_id` as const)}
                    defaultValue={field.lang_id}
                    disabled={isLoadingUpdateTheme || isLoadingCreateTheme}
                    readOnly
                  />
                  <p className="md:w-4/12 mb-2 md:mb-0">
                    Button Text (
                    {field.lang_id}
                    )
                  </p>
                  <input
                    type="text"
                    className="input-base w-full"
                    disabled={isLoadingUpdateTheme || isLoadingCreateTheme}
                    {...register(`button_texts.${index}.name` as const)}
                    defaultValue={field.name}
                  />
                </div>
              ))}

              <p className="font-semibold text-lg mt-14 flex">
                <ButtonIcon className="mr-2" fill="#181818" />
                Button Shape
              </p>

              <div className="flex gap-10 mt-4 ml-4">
                <div className="flex flex-col">
                  <div className="flex items-center mb-2">
                    <input type="radio" {...register("button_shape")} id="button_shape_round" value="round" />
                    <label htmlFor="button_shape_round" className="ml-2">
                      Round
                    </label>
                  </div>
                  <div className="h-24 w-24 rounded-full bg-gray-300 text-white flex items-center justify-center">
                    Menu
                  </div>
                </div>

                <div className="flex flex-col">
                  <div className="flex items-center mb-2">
                    <input type="radio" {...register("button_shape")} id="button_shape_pill" value="pill" />
                    <label htmlFor="button_shape_pill" className="ml-2">
                      Pill
                    </label>
                  </div>
                  <div className="h-12 w-24 rounded-full bg-gray-300 text-white flex items-center justify-center mt-6">
                    Menu
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <button
          type="button"
          title={(isLoadingCreateTheme || isLoadingUpdateTheme || isLoadingImage || isLoadingUpdateThemeBackground) ? "Saving..." : "Save"}
          className="button-primary py-2 px-4 m-4 mt-0 md:hidden"
          onClick={handleSubmit(onSubmit)}
          disabled={!formState.isDirty || isLoadingImage}
        >
          {isLoadingCreateTheme || isLoadingUpdateTheme || isLoadingImage || isLoadingUpdateThemeBackground ? (
            <div className="flex">
              <LoadingAnimation size={5} />
              {isLoadingImage && <span className="text-sm ml-2 font-normal">uploading image</span>}
              {(isLoadingCreateTheme || isLoadingUpdateTheme || isLoadingUpdateThemeBackground) && (
                <span className="text-sm ml-2 font-normal">Saving</span>
              )}
            </div>
          ) : (
            <span>Save</span>
          )}
        </button>
      </div>

      <ModalConfirmation
        isOpen={modalConfirmation.isOpen}
        content="Are you sure?"
        onCloseModal={() => setModalConfirmation({ isOpen: false, data: null })}
        onConfirmModal={callbackConfirmation}
        confirmationText="Save"
      />
    </>
  );
}

export default ThemesAddPage;
