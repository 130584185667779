import clsx from "clsx";
import type { ReactNode } from "react";

type TablePropTypes = {
  className?: string;
  id?: string;
  children: ReactNode;
}

export function Table({ className, id, children }: TablePropTypes) {
  return (
    <div
      id={id}
      className={className || clsx(
        "mx-4",
      )}
    >
      {children}
    </div>
  );
}

export function TableHead({ className, id, children }: TablePropTypes) {
  return (
    <div
      id={id}
      className={className || clsx(
        "hidden md:flex items-center",
        "w-full bg-gray",
        "font-medium",
        "mt-4 p-4",
      )}
    >
      {children}
    </div>
  );
}

export function TableBody({ className, id, children }: TablePropTypes) {
  return (
    <div
      id={id}
      className={className || clsx(
        "w-full",
        "flex flex-col",
      )}
    >
      {children}
    </div>
  );
}

export function TableData({ className, id, children }: TablePropTypes) {
  return (
    <div
      id={id}
      className={className || clsx(
        "flex-1",
      )}
    >
      {children}
    </div>
  );
}

export function TableRow({ className, id, children }: TablePropTypes) {
  return (
    <div
      id={id}
      className={className || clsx(
        "flex w-full",
      )}
    >
      {children}
    </div>
  );
}

export function TableHeader({ className, id, children }: TablePropTypes) {
  return (
    <div
      id={id}
      className={className || clsx(
        "flex-1",
      )}
    >
      {children}
    </div>
  );
}
