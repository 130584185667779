const isLocalhost = Boolean(
  window.location.hostname === "localhost"
    // [::1] is the IPv6 localhost address.
    || window.location.hostname === "[::1]"
    // 127.0.0.0/8 are considered localhost for IPv4.
    || window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
);

const registerValidSW = async (swUrl: string) => {
  navigator.serviceWorker.getRegistration(swUrl)
    .then((swRegistration) => {
      if (!swRegistration) {
        navigator.serviceWorker.register(swUrl)
          .then((registration) => {
            console.log({ registration });

            registration.onupdatefound = () => {
              const installingWorker = registration.installing;

              if (installingWorker == null) {
                return;
              }

              installingWorker.onstatechange = () => {
                if (installingWorker.state === "installed") {
                  if (navigator.serviceWorker.controller) {
                    // registration.update();
                    console.log("New content is available; please refresh.");
                  }
                }
              };
            };
          }).catch((error) => {
            console.error("Error during service worker registration:", error);
          });
      }
    });

  // navigator.serviceWorker.getRegistrations()
  //   .then((registrations) => {
  //     console.log({ registrations });

  //     if (registrations.length === 0) {
  //       navigator.serviceWorker.register(swUrl)
  //         .then((registration) => {
  //           console.log({ registration });

  //           registration.onupdatefound = () => {
  //             const installingWorker = registration.installing;

  //             if (installingWorker == null) {
  //               return;
  //             }

  //             installingWorker.onstatechange = () => {
  //               if (installingWorker.state === "installed") {
  //                 if (navigator.serviceWorker.controller) {
  //                   registration.update();
  //                   console.log("New content is available; please refresh.");
  //                 }
  //               }
  //             };
  //           };
  //         }).catch((error) => {
  //           console.error("Error during service worker registration:", error);
  //         });
  //     }
  //   });

  // navigator.serviceWorker.getRegistrations()
  //   .then((registrations) => {
  //     console.log(registrations.length);

  //     if (registrations.length === 0) {
  //       navigator
  //         .serviceWorker
  //         .register(swUrl)
  //         .then((registration) => {
  //           console.log({ registration });

  //           registration.onupdatefound = () => {
  //             const installingWorker = registration.installing;

  //             console.log({ installingWorker });

  //             if (installingWorker == null) {
  //               return;
  //             }

  //             installingWorker.onstatechange = () => {
  //               if (installingWorker.state === "installed") {
  //                 if (navigator.serviceWorker.controller) {
  //                   console.log("New content is available; please refresh.");
  //                 }
  //               }
  //             };
  //           };
  //         })
  //         .catch((error) => {
  //           console.error("Error during service worker registration:", error);
  //         });
  //     } else {
  //       console.log("Service Worker already registered.");
  //     }
  //   }).catch((error) => {
  //     console.log("Error getting Service Worker registrations:", error);
  //   });
};

const checkValidServiceWorker = (swUrl: string) => {
  fetch(swUrl, {
    headers: { "Service-Worker": "script" },
  })
    .then((response) => {
      const contentType = response.headers.get("content-type");

      if (
        response.status === 404
        || (contentType != null && contentType.indexOf("javascript") === -1)
      ) {
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
            console.log("Unregister Service Worker");
          });
        });
      } else {
        registerValidSW(swUrl);
      }
    })
    .catch(() => {
      console.log(
        "No internet connection found. App is running in offline mode.",
      );
    });
};

export const register = () => {
  const swUrl = "/firebase-messaging-sw.js";

  if ("serviceWorker" in navigator) {
    // if (isLocalhost) {
    //   // This is running on localhost. Let's check if a service worker still exists or not.
    //   checkValidServiceWorker(swUrl);

    //   navigator.serviceWorker.ready.then(() => {
    //     console.log("Firebase messaging successfully initialized");
    //   });
    // } else {
    //   // Is not localhost. Just register service worker
    // registerValidSW(swUrl);
    // }

    checkValidServiceWorker(swUrl);
  }
};

export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}
