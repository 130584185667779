import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./base-service";

export interface IngredientsListResponse {
  id: string;
  icon: string;
  name: string;
}

export const ingredientsServices = createApi({
  reducerPath: "ingredients",
  baseQuery,
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  endpoints: (builder) => ({
    getIngredientsList: builder.query<HttpResponse<IngredientsListResponse[]>, void>({
      query: () => ({
        url: "/ingredients/list",
        method: "GET",
      }),
    }),
  }),
});

export const { useGetIngredientsListQuery } = ingredientsServices;
