import clsx from "clsx";
import {
  ChangeEvent, memo,
  useEffect,
  useMemo, useState,
} from "react";
import { toast } from "react-toastify";
import Popup from "reactjs-popup";

import { ReactComponent as CheckIcon } from "../../assets/icons/ic_check.svg";
import { ReactComponent as ChevronUpIcon } from "../../assets/icons/ic_chevron_up.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/ic_close.svg";
import { ReactComponent as SpinnerIcon } from "../../assets/icons/ic_spinner.svg";

import { useDropdown } from "../../hooks";
import {
  useOrderTypeListQuery,
  usePromoTypeListQuery,
  type PromoCodeModel,
} from "../../services";
import { getBreakpointValue } from "../../utils";
import { Select } from "../atoms";
import { ORDER_TYPE_LIST_TEXT } from "../../constants";

interface ModalPromoCodeProps {
  open: boolean;
  defaultValue: PromoCodeModel | null;
  onClose: () => void;
  onSubmit: (value: PromoCodeModel, reset: () => void) => void;
  onDelete: (id: string, reset: () => void) => void;
  isLoadingDeletePromoCode: boolean;
  isLoadingCreatePromoCode: boolean;
}

const promoCodeOrderType = [
  {
    id: "all",
    value: ORDER_TYPE_LIST_TEXT.all,
  },
  {
    id: "order_dinein",
    value: ORDER_TYPE_LIST_TEXT.order_dinein,
  },
  {
    id: "order_pickup",
    value: ORDER_TYPE_LIST_TEXT.order_pickup,
  },
  {
    id: "order_delivery",
    value: ORDER_TYPE_LIST_TEXT.order_delivery,
  },
  {
    id: "order_drivetru",
    value: ORDER_TYPE_LIST_TEXT.order_drivetru,
  },
];

const defaultFormState: PromoCodeModel = {
  code: "",
  type: "PERCENT",
  amount: 0,
  order_modes: [
    "all",
  ],
  min_order: 0,
};

const ModalPromoCode = memo(({
  open,
  defaultValue,
  onClose,
  onSubmit,
  onDelete,
  isLoadingDeletePromoCode,
  isLoadingCreatePromoCode,
}: ModalPromoCodeProps) => {
  const { data: promoTypeList } = usePromoTypeListQuery();
  const { data: orderModeTypeList } = useOrderTypeListQuery();

  const isMobile = getBreakpointValue("md") > window.innerWidth;
  const isLoading = isLoadingDeletePromoCode || isLoadingCreatePromoCode;

  const [formState, setFormState] = useState<PromoCodeModel>(defaultFormState);

  const {
    isOpen: isOrderModeListOpened,
    DropdownToggler,
    DropdownContent,
  } = useDropdown({
    id: "order-mode",
  });

  // const orderModes = useMemo(() => promoCodeOrderType, []);
  const orderModes = useMemo(
    () => {
      const modes = new Set<typeof promoCodeOrderType[0]>();

      modes.add({
        id: "all",
        value: "All",
      });

      (orderModeTypeList?.data || []).forEach(({ id }) => {
        if (id === "order_dinein_tablet" || id === "order_dinein_mobile") {
          modes.add(promoCodeOrderType[1]);
        } else {
          const type = promoCodeOrderType.find((item) => item.id === id) || null;

          if (type) modes.add(type);
        }
      });

      return Array.from(modes);
    },
    [orderModeTypeList?.data],
  );

  const resetForm = () => {
    setFormState(defaultFormState);
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;

    setFormState((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const orderMode = {
    removeOrderMode: (id: string) => {
      setFormState((state) => ({
        ...state,
        order_modes: state.order_modes.filter((itemId) => itemId !== id),
      }));
    },
    appendOrderMode: (id: string) => {
      if (id === "all") {
        setFormState((state) => ({
          ...state,
          order_modes: [id],
        }));

        return;
      }

      orderMode.removeOrderMode("all");

      setFormState((state) => ({
        ...state,
        order_modes: [...state.order_modes, id],
      }));
    },
  };

  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const copiedFormState = { ...formState };

    if (copiedFormState.order_modes.find((id) => id === "all")) {
      onSubmit({
        ...copiedFormState,
        order_modes: [...orderModes.map(({ id }) => id).filter((id) => id !== "all")]
        ,
      }, resetForm);
      return;
    }

    onSubmit(copiedFormState, resetForm);
  };

  useEffect(() => {
    if (defaultValue) {
      setFormState((state) => ({
        ...state,
        ...defaultValue,
      }));
    } else {
      resetForm();
    }
  }, [defaultValue]);

  return (
    <Popup
      modal
      open={open}
      onClose={onClose}
      closeOnEscape
      lockScroll
      contentStyle={{
        maxHeight: isMobile ? "100%" : "80%",
        maxWidth: isMobile ? "90%" : 652,
        width: "100%",
        // overflowY: "auto",
        backgroundColor: "white",
        borderRadius: 8,
        padding: 0,
      }}
    >
      <form
        className={clsx(
          "w-full md:w-auto bg-white",
          "inset-0 md:relative py-6 rounded-lg",
        )}
        onSubmit={handleOnSubmit}
      >
        <div className={clsx(
          "flex items-center justify-between",
          "pb-5 px-4 md:px-6 border-b-[1px]",
        )}
        >
          <span className="font-semibold pr-4">{"id" in formState ? "Update Promo Codes" : "New Promo Codes"}</span>

          <button
            type="button"
            title="Close Modal"
            onClick={onClose}
          >
            <CloseIcon className="w-5 h-5" />
          </button>
        </div>

        <div className={clsx("p-4 md:p-6", "border-b", "flex flex-col md:flex-row md:items-center")}>
          <label
            htmlFor="input-promo-code"
            className={clsx(
              "mr-4 md:min-w-[175px] mb-2 md:mb-0",
              "md:whitespace-nowrap inline-block  md:leading-none",
            )}
          >
            Discount Code
          </label>

          <input
            type="text"
            id="input-promo-code"
            className="input-base w-full inline-block"
            placeholder="Enjoy10"
            name="code"
            onChange={handleOnChange}
            disabled={isLoading || !!("id" in formState)}
            value={formState.code}
            title="Discount code"
          />
        </div>

        <div className={clsx(
          "flex flex-col gap-4",
          "p-4 md:p-6",
        )}
        >
          <div className="flex flex-col md:flex-row md:items-center">
            <label
              htmlFor="discount-type"
              className={clsx(
                "md:whitespace-nowrap inline-block md:leading-none",
                "mr-4 mb-2 md:mb-0 md:min-w-[175px]",
              )}
            >
              Discount Type
            </label>

            <div className="flex flex-row items-center w-full gap-4">
              <Select
                name="type"
                options={[...(promoTypeList?.data ?? [])]
                  .map((item) => {
                    if (item.id === "PERCENT") {
                      return {
                        value: item.id,
                        label: "Percentage",
                      };
                    }

                    return {
                      value: item.id,
                      label: "Amount",
                    };
                  })}
                customClass="input-base text-sm"
                disabled={isLoading}
                onChange={(event) => {
                  const { value } = event.target;

                  setFormState((state) => ({
                    ...state,
                    type: value as PromoCodeModel["type"],
                  }));
                }}
                value={formState.type}
              />

              <div className="flex items-center">
                <input
                  type="number"
                  min={0}
                  max={formState.type === "PERCENT" ? 100 : 9999}
                  className={clsx(
                    "input-base min-w-[80px] md:min-w-[96px] !bg-transparent",
                    "flex-grow rounded-r-none",
                  )}
                  placeholder="0"
                  name="amount"
                  onChange={handleOnChange}
                  disabled={isLoading}
                  value={formState.amount}
                />

                <span className={clsx(
                  "p-2 bg-line-gray h-full text-sm text-center min-w-[48px]",
                  "border rounded-r-md",
                )}
                >
                  {formState.type === "PERCENT" ? "%" : "USD"}
                </span>
              </div>
            </div>
          </div>

          <div className={clsx(
            "flex flex-col md:flex-row md:items-center",
          )}
          >
            <label
              htmlFor="order-mode"
              className={clsx(
                "md:leading-none md:whitespace-nowrap inline-block",
                "mr-4 mb-2 md:mb-0 md:min-w-[175px] ",
              )}
            >
              Order Mode
            </label>

            <div className="flex flex-row items-center w-full gap-4 relative">
              <DropdownToggler
                type="button"
                className={clsx(
                  "input-base text-left relative",
                  "flex flex-row items-center",
                  "pr-8 w-full",
                  isOrderModeListOpened && "border-2 border-primary",
                )}
                disabled={isLoading}
              >
                {formState.order_modes.map((id) => {
                  const value = orderModes.find((item) => item?.id === id)?.value || null;

                  return value;
                }).filter((item) => item).join(", ")}

                <ChevronUpIcon className={clsx(
                  "absolute right-4",
                  "h-3 w-3 text-primary fill-[#181818]",
                  !isOrderModeListOpened && "rotate-180",
                )}
                />
              </DropdownToggler>

              <DropdownContent className={clsx(
                "absolute z-10 top-[120%]",
                "flex flex-col items-center justify-center",
                "w-full max-w-[350px] bg-white rounded shadow-dropdown",
              )}
              >
                {orderModes.map((item) => {
                  if (!item) return <div />;

                  const isChecked = !!(formState.order_modes.find((id) => id === item.id));

                  return (
                    <label
                      key={item.id}
                      htmlFor={`mode-${item.id}`}
                      className={clsx(
                        "w-full border-b last:border-b-0",
                        "py-2 px-3",
                        "flex flex-row items-center",
                        isChecked ? "bg-primary bg-opacity-10 hover:bg-opacity-20" : "hover:bg-gray-100",
                      )}
                    >
                      <input
                        id={`mode-${item.id}`}
                        hidden
                        type="checkbox"
                        checked={isChecked}
                        onChange={() => {
                          if (item.id === "all") {
                            if (isChecked) {
                              if (formState.order_modes.length === 1) {
                                toast.error("At least 1 Order mode is selected");
                                return;
                              }

                              orderMode.removeOrderMode(item.id);
                            } else {
                              orderMode.appendOrderMode(item.id);
                            }
                          } else if (isChecked) {
                            if (formState.order_modes.length === 1) {
                              toast.error("At least 1 Order mode is selected");
                              return;
                            }

                            orderMode.removeOrderMode(item.id);
                          } else {
                            orderMode.appendOrderMode(item.id);
                          }
                        }}
                      />

                      {isChecked && (
                        <CheckIcon className="h-4 w-4 text-primary fill-[#181818]" />
                      )}

                      <span className={`text-sm ${isChecked ? "ml-2" : "ml-6"}`}>{item.value}</span>

                    </label>
                  );
                })}
              </DropdownContent>
            </div>
          </div>
        </div>

        <div className={clsx(
          "flex flex-col md:flex-row md:items-center",
          "px-4 pb-4 md:px-6 md:pb-6",
        )}
        >
          <label
            htmlFor="min-order-form"
            className={clsx(
              "inline-block md:leading-none md:whitespace-nowrap",
              "mr-4 mb-2 md:mb-0 md:min-w-[175px]",
            )}
          >
            Minimum Order Value
          </label>

          <div className="flex items-center mb-4 md:mb-0">
            <input
              id="min-order-form"
              type="number"
              disabled={isLoading}
              min={0}
              max={9999}
              className="input-base text-sm flex-grow rounded-r-none border-r-0 md:w-24"
              placeholder="0"
              name="min_order"
              onChange={handleOnChange}
              value={formState.min_order}
            />
            <span className={clsx(
              "p-2 bg-line-gray h-full text-sm text-center w-12",
              "border rounded-r-md",
            )}
            >
              USD
            </span>
          </div>
        </div>

        <div className="flex items-center justify-center border-t gap-4 px-4 pt-4 md:px-6 w-full md:w-auto">
          <button
            type="button"
            title="Delete"
            className={clsx(
              "button-gray py-2 px-5 flex-1 md:flex-none",
              "h-11 md:min-w-[100px]",
            )}
            disabled={isLoading || !("id" in formState)}
            onClick={() => onDelete(formState.id || "", resetForm)}
          >
            {(isLoadingDeletePromoCode) ? (
              <SpinnerIcon className="animate-spin h-4 w-4 mx-auto" />
            ) : "Delete"}
          </button>

          <button
            type="submit"
            title="Save Promo Code"
            className={clsx(
              "button-primary py-2 px-5 flex-1 md:flex-none",
              "h-11 md:min-w-[100px]",
            )}
            disabled={isLoading || !formState.code}
          >
            {isLoadingCreatePromoCode ? (
              <SpinnerIcon className="animate-spin h-4 w-4 mx-auto" />
            ) : "Save"}
          </button>
        </div>
      </form>
    </Popup>
  );
});

export default ModalPromoCode;
