/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
function debounce<F extends Function>(func: F, wait = 3000): F {
  let timeoutID: number;

  if (!Number.isInteger(wait)) {
    // eslint-disable-next-line no-console
    console.warn("Called debounce without a valid number");
    wait = 300;
  }

  // conversion through any necessary as it wont satisfy criteria otherwise
  return <any> function (this: any, ...args: any[]) {
    clearTimeout(timeoutID);
    const context = this;

    timeoutID = window.setTimeout(() => {
      func.apply(context, args);
    }, wait);
  };
}

export default debounce;
