import {
  useEffect, useMemo, useRef, useState,
} from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingBar from "react-top-loading-bar";
import { format, parseISO } from "date-fns";

import { CardWithOptions, LoadingAnimation, ModalConfirmation } from "..";
import { useDebounce } from "../../hooks";
import {
  FeedbacksFormModel,
  useDeleteFeedbackFormMutation,
  useGetAllFeedbackFormQuery,
  usePublishFeedbackFormMutation,
  useDuplicateFeedbackFormMutation,
} from "../../services";

import { ReactComponent as IllustrationNoItems } from "../../assets/illustration_no_items.svg";

interface Confirmation {
  isOpen: boolean;
  data: string | null;
}

interface Props {
  keyword: string;
}

function FeedbacksForms({ keyword }: Props) {
  const [toggler, setToggler] = useState<boolean[]>([]);
  const [modalConfirmation, setModalConfirmation] = useState<Confirmation>({
    isOpen: false,
    data: null,
  });
  const debouncedKeyword = useDebounce<string>(keyword, 500);
  const { push } = useHistory();

  const { data, isLoading, refetch } = useGetAllFeedbackFormQuery();
  const [publishFeedback, { isLoading: isLoadingPublish }] = usePublishFeedbackFormMutation();
  const [deleteFeedback, { isLoading: isLoadingDeleteItem }] = useDeleteFeedbackFormMutation();
  const [duplicateFeedback, { isLoading: isLoadingDuplicateFeedback }] = useDuplicateFeedbackFormMutation();
  const ref = useRef(null);

  const filteredComponents = useMemo((): FeedbacksFormModel[] => {
    if (!data?.data) return [];
    if (Object.keys(data.data ?? {}).length === 0) return [];

    const filtered = data.data.filter(({ name }) => name.toLowerCase().includes(debouncedKeyword.toLowerCase()));

    setToggler(filtered.map(({ is_published }) => is_published) ?? []);

    return filtered;
  }, [data?.data, debouncedKeyword]);

  const handleIsPublish = (index: number) => {
    if (!data?.data) return;

    const feedback = data.data[index];

    if (feedback) {
      if (feedback.is_published) return;
      // if (feedback.is_published) {
      //   toast.success(`${feedback.name} already published`);
      //   return;
      // }

      let tempToggler = toggler.map((_, current) => current === index);
      setToggler(tempToggler);

      publishFeedback(feedback.id)
        .unwrap()
        .then(() => {
          toast.success(`Successfully publish ${feedback.name}`);
          refetch();
        })
        .catch(() => {
          tempToggler = toggler.map((isPublish, current) => (current === index ? !isPublish : isPublish));
          setToggler(tempToggler);
          toast.error(`Failed to publish ${feedback.name}`);
        });
    }
  };

  const handleEdit = (id: string) => {
    push({
      pathname: "/feedback",
      state: {
        feedback_form_id: id,
      },
    });
  };

  const handleDelete = (id: string) => {
    if (filteredComponents.find((item) => item.id === id)?.is_published) {
      toast.error("Required one active form");
      return;
    }

    setModalConfirmation({ isOpen: true, data: id });
  };

  const handleDuplicate = (id: string) => {
    duplicateFeedback({ id })
      .unwrap()
      .then(() => {
        refetch();
      })
      .catch((error) => {
        toast.error(error.data?.message ?? "Failed to duplicate feedback");
      });
  };

  const goToLocalize = (id: string) => {
    push({
      pathname: "/feedback-localize",
      state: {
        feedback_form_id: id,
      },
    });
  };

  const callbackConfirmation = () => {
    const { data: modalConfirmationData } = modalConfirmation;

    if (!modalConfirmationData) return;
    deleteFeedback(modalConfirmationData)
      .unwrap()
      .then(() => {
        toast.success("Successfully delete");
        refetch();
      })
      .catch(() => {
        toast.error("Failed to delete");
      })
      .finally(() => {
        setModalConfirmation((prevState) => ({ ...prevState, isOpen: false }));
      });
  };

  useEffect(() => {
    const responseData = data?.data;

    if (responseData) {
      setToggler(responseData?.map(({ is_published }) => is_published) ?? []);
    }
  }, [data?.data]);

  useEffect(() => {
    const progress = ref.current as any;
    if (isLoading) {
      progress?.continuousStart();
    } else {
      progress?.complete();
    }
  }, [isLoading]);

  if (!data) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <LoadingAnimation />
      </div>
    );
  }

  return (
    <>
      <LoadingBar height={4} color="#0078D3" ref={ref} />
      <div className="flex-1">
        {!isLoading && (
          <div className={`my-3 mx-6 ${filteredComponents.length > 0 ? "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 my-3 mx-6" : ""}`}>
            {filteredComponents.length > 0 ? (
              filteredComponents.map(
                (
                  {
                    id, name, question_total, created_at, is_published,
                  },
                  index,
                ) => {
                  const parsedDate = parseISO(created_at);
                  const date = format(parsedDate, "dd MMM yyyy");

                  return (
                    <CardWithOptions
                      key={id}
                      item={{
                        id,
                        title: name,
                        icon: "description",
                        subtitle: `${question_total} Questions • ${date}`,
                        isActive: toggler[index] ?? is_published,
                      }}
                      goToLocalize={goToLocalize}
                      onIsPublish={() => handleIsPublish(index)}
                      isLoadingPublish={isLoadingPublish}
                      onDelete={handleDelete}
                      onEdit={handleEdit}
                      onDuplicate={handleDuplicate}
                      isLoadingDuplicate={isLoadingDuplicateFeedback}
                      // disableSwitchButton={filteredComponents.length === 1}
                      disableDeleteButton={filteredComponents.length === 1 || (toggler[index] || is_published)}
                      editOnMenu
                      alwaysShowMenu
                    />
                  );
                },
              )
            ) : (
              <div className="h-full w-full mt-16 flex-1 flex flex-col gap-1 justify-center items-center">
                <IllustrationNoItems className="h-64 w-64 mb-4" />

                <p className="text-lg">
                  There&#39;s no
                  {debouncedKeyword.length > 0 ? debouncedKeyword : "feedback form"}
                </p>

                <p className="text-secondary">
                  You haven&#39;t add any
                  {debouncedKeyword.length > 0 ? (`${debouncedKeyword} in feedback form`) : "feedback form"}
                </p>
              </div>
            )}
          </div>
        )}
      </div>

      <ModalConfirmation
        isOpen={modalConfirmation.isOpen}
        content="Are you sure?"
        onCloseModal={() => setModalConfirmation({ isOpen: false, data: null })}
        onConfirmModal={callbackConfirmation}
        confirmationText="Delete"
        confirmationLoading={isLoadingDeleteItem}
      />
    </>
  );
}

export default FeedbacksForms;
