import {
  useRef, useState, useEffect, useMemo,
} from "react";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import LoadingBar from "react-top-loading-bar";
import { useForm, SubmitHandler, useFieldArray } from "react-hook-form";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  TouchSensor,
  MouseSensor,
  useSensor,
  useSensors,
  DragStartEvent,
  DragEndEvent,
  DragOverlay,
} from "@dnd-kit/core";
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable";

import { ReactComponent as ChevronDownIcon } from "../assets/icons/ic_chevron_down.svg";

import {
  FeedbackForm, ModalConfirmation, SortableItem, SortableItemOverlay, LoadingAnimation,
} from "../components";

import {
  FeedbackFormModel,
  useGetFeedbackFormQuery,
  usePostFeedbackFormMutation,
  useUpdateFeedbackFormMutation,
} from "../services";

interface Confirmation {
  isOpen: boolean;
  data: FeedbackFormModel | "close" | null;
}

const defaultQuestion = {
  question: "",
  answer_type: "rating",
  is_required: false,
};

function FeedbackPage() {
  const { goBack } = useHistory();
  const { state } = useLocation<StateFeedbackFormID>();
  const ref = useRef(null);

  const [activeId, setActiveId] = useState<string | null>(null);
  const [modalConfirmation, setModalConfirmation] = useState<Confirmation>({
    isOpen: false,
    data: null,
  });

  const [createForm, { isLoading: isLoadingCreateForm }] = usePostFeedbackFormMutation();
  const [updateForm, { isLoading: isLoadingUpdateForm }] = useUpdateFeedbackFormMutation();
  const { data: dataFeedback, isLoading: isLoadingFeedback } = useGetFeedbackFormQuery(state?.feedback_form_id ?? "", {
    skip: !state?.feedback_form_id ?? true,
  });

  const {
    register, control, handleSubmit, getValues, reset, formState,
  } = useForm<FeedbackFormModel>({
    defaultValues: {
      name: "",
      header: "",
      questions: [defaultQuestion],
    },
  });

  const {
    fields, append, remove, swap,
  } = useFieldArray({
    name: "questions",
    control,
    keyName: "key",
  });

  /**
   * @description Function to handle active field
   */
  const activeField = useMemo(() => fields.find(({ key }) => key === activeId), [fields, activeId]);

  /**
   * @description Function to handle duplicate question
   * @param index<number>
   */
  const handleDuplicate = (index: number) => {
    const questions = getValues("questions");
    const questionValue = questions[index];
    // append(questions[index]);
    append({
      ...questionValue,
      id: uuidv4(),
    });
  };

  /**
   * @description Function to handle remove question
   * @param index<number>
   */
  const handleDelete = (index: number) => {
    remove(index);
  };

  /**
   * @description Function to handle add question
   */
  const handleAddQuestion = () => {
    append({
      ...defaultQuestion,
      id: uuidv4(),
    });
  };

  /**
   * @description Function to handle submit form then open confirmation modal
   * @param data<FeedbackFormModel>
   */
  const onSubmit: SubmitHandler<FeedbackFormModel> = (data) => {
    setModalConfirmation({ isOpen: true, data });
  };

  /**
   * @description Function to handle callback confirmation from modal confirmation
   * @returns void
   */
  const callbackConfirmation = () => {
    const { data } = modalConfirmation;

    if (data === "close") {
      goBack();
      return;
    }
    if (!data) return;

    if (state?.feedback_form_id) {
      updateForm(data)
        .unwrap()
        .then(goBack)
        .catch((error) => {
          toast.error(error.data?.message ?? "Failed to create form");
        });
    } else {
      createForm(data)
        .unwrap()
        .then(goBack)
        .catch((error) => {
          toast.error(error.data?.message ?? "Failed to create form");
        });
    }
  };

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  /**
   * @description Function to handle drag start
   * @param event<DragStartEvent>
   */
  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    setActiveId(active.id);
  };

  /**
   * @description Function to handle drag end
   * @param event<DragEndEvent>
   * @returns void
   */
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over?.id) return;

    if (active.id !== over.id) {
      const oldIndex = fields.findIndex(({ key }) => key === active.id);
      const newIndex = fields.findIndex(({ key }) => key === over.id);
      swap(oldIndex, newIndex);
    }
    setActiveId(null);
  };

  useEffect(() => {
    if (dataFeedback?.data) {
      reset(dataFeedback.data);
    }
  }, [dataFeedback?.data, reset]);

  useEffect(() => {
    const progress = ref.current as any;
    if (isLoadingFeedback) {
      progress?.continuousStart();
    } else {
      progress?.complete();
    }
  }, [isLoadingFeedback]);

  return (
    <>
      <LoadingBar height={4} color="#0078D3" ref={ref} />
      <div className="flex flex-col flex-1">
        <div className="flex justify-between p-4 border-b border-line-gray">
          <div className="flex items-center">
            <button type="button" title="Back" className="bg-transparent" onClick={goBack}>
              <ChevronDownIcon className="h-6 w-6 transform rotate-90 mr-2" />
            </button>
            <h2 className="font-bold text-2xl">{state?.feedback_form_id ? "Update Form" : "Add New Form"}</h2>
          </div>

          <div className="hidden md:flex">
            <button
              type="button"
              title="Cancel"
              className="button-gray py-2 px-4 mr-4"
              disabled={isLoadingCreateForm || isLoadingFeedback || isLoadingUpdateForm}
              onClick={() => {
                if (formState.isDirty) {
                  setModalConfirmation({ isOpen: true, data: "close" });
                } else {
                  goBack();
                }
              }}
            >
              Cancel
            </button>

            <button
              type="button"
              className="button-primary py-2 px-4"
              onClick={handleSubmit(onSubmit)}
              disabled={!formState.isDirty || isLoadingCreateForm || isLoadingUpdateForm}
            >
              {isLoadingCreateForm || isLoadingUpdateForm ? (
                <div className="flex">
                  <LoadingAnimation size={5} />
                  {(isLoadingCreateForm || isLoadingUpdateForm) && (
                    <span className="text-sm ml-2 font-normal">Saving</span>
                  )}
                </div>
              ) : (
                <span>Save</span>
              )}
            </button>
          </div>
        </div>

        <div className="flex flex-1 flex-col md:flex-row">
          {/* LEFT PANE */}
          <div className="flex flex-col p-6 md:w-4/12 md:h-full md:border-r md:border-line-gray">
            <div className="flex flex-col mb-7">
              <p className="mb-2 font-medium">Form Name</p>
              <input
                type="text"
                className="input-base flex-1 max-w-xl"
                placeholder="Add a Form Name"
                {...register("name")}
              />
            </div>

            <div className="flex flex-col">
              <p className="mb-2 font-medium">Form Header</p>
              <textarea
                rows={3}
                className="input-base flex-1 max-w-xl"
                placeholder="Add a Form Header"
                {...register("header")}
              />
            </div>
          </div>

          {/* RIGHT PANE */}
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
          >
            <SortableContext items={fields.map((field) => field.key)} strategy={verticalListSortingStrategy}>
              <div className="flex flex-col p-6 md:w-8/12 gap-4 md:gap-6">
                {fields.map((field, index) => (
                  <SortableItem
                    key={field.key}
                    id={field.key}
                    style={{ opacity: field.key === activeId ? 0 : 1 }}
                    useHandler
                  >
                    <FeedbackForm
                      index={index}
                      id={field.id}
                      register={register}
                      control={control}
                      handleDuplicate={handleDuplicate}
                      handleDelete={handleDelete}
                    />
                  </SortableItem>
                ))}

                <button type="button" title="Add Question" className="button-link text-center p-4 mt-2" onClick={handleAddQuestion}>
                  Add Question
                </button>
              </div>
            </SortableContext>

            <DragOverlay>
              {activeId ? (
                <SortableItemOverlay id={activeId} useHandler>
                  <FeedbackForm
                    register={register}
                    control={control}
                    handleDuplicate={handleDuplicate}
                    handleDelete={handleDelete}
                    activeField={activeField}
                    disabled={isLoadingCreateForm || isLoadingFeedback || isLoadingUpdateForm}
                  />
                </SortableItemOverlay>
              ) : null}
            </DragOverlay>
          </DndContext>
        </div>

        <button
          type="button"
          className="button-primary py-2 px-4 m-4 mt-0 md:hidden"
          onClick={handleSubmit(onSubmit)}
          disabled={!formState.isDirty || isLoadingCreateForm || isLoadingUpdateForm}
        >
          {isLoadingCreateForm || isLoadingUpdateForm ? (
            <div className="flex">
              <LoadingAnimation size={5} />
              {(isLoadingCreateForm || isLoadingUpdateForm) && <span className="text-sm ml-2 font-normal">Saving</span>}
            </div>
          ) : (
            <span>Save</span>
          )}
        </button>
      </div>

      <ModalConfirmation
        isOpen={modalConfirmation.isOpen}
        content="Are you sure?"
        confirmationLoading={isLoadingCreateForm || isLoadingFeedback || isLoadingUpdateForm}
        onCloseModal={() => setModalConfirmation({ isOpen: false, data: null })}
        onConfirmModal={callbackConfirmation}
        confirmationText={modalConfirmation.data === "close" ? "Close" : "Save"}
      />
    </>
  );
}

export default FeedbackPage;
