import {
  Redirect, Route, RouteProps, useLocation,
} from "react-router-dom";

export type PublicRouteProps = {
  isAuthenticated: boolean;
} & RouteProps;

export default function PublicRoute({
  isAuthenticated,
  ...routeProps
}: PublicRouteProps) {
  const location = useLocation();
  const pathname = "/dashboard";
  // const pathname = "/categories";

  if (isAuthenticated) {
    return <Redirect to={{ pathname, state: { from: location } }} />;
  }

  return <Route {...routeProps} exact />;
}
