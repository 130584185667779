import clsx from "clsx";
import {
  format, subDays,
} from "date-fns";
import {
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Calendar from "react-calendar";
import { Link } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import Popup from "reactjs-popup";
import type { PopupActions } from "reactjs-popup/dist/types";

import { ReactComponent as CalendarIcon } from "../assets/icons/ic_calendar_new.svg";
import { ReactComponent as FeedbackIcon } from "../assets/icons/ic_feedback.svg";
import { ReactComponent as OrdersIcon } from "../assets/icons/ic_orders.svg";

import { useAppSelector } from "../app/hooks";
import { ReportCounter } from "../components";
import FeedbackResultList from "../components/feedbacks/feedback-result-list";
import { OrderList } from "../components/orders";
import { SettingBilling } from "../components/settings";
import {
  FeedbackResultModel,
  useGetDashboardRecentsQuery,
  useGetDashboardSummariesReportQuery,
} from "../services";

const formatDate = (date: Date, type = "dd MMM yyyy") => format(date, type);

function DashboardPage() {
  const today = new Date();

  const skipFetchOnFirstMount = useRef(true);
  const loadingBarRef = useRef(null);
  const { user } = useAppSelector(({ auth }) => auth);
  const calendarRef = useRef<PopupActions | null>(null);

  const [expandId, setExpandId] = useState<string>("");
  const [openDatePicker, setOpenDatePicker] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState([subDays(today, 7), today]);
  const [okDateRange, setOkDateRange] = useState([subDays(today, 7), today]);

  const {
    data: summariesReport,
    isLoading: isLoadingSummariesReport,
  } = useGetDashboardSummariesReportQuery({
    from: format(okDateRange[0], "yyyyMMdd"),
    to: format(okDateRange[1], "yyyyMMdd"),
  });

  const {
    data: dashboardRecentData,
    isLoading: isLoadingRecentData,
    refetch: refetchRecentData,
  } = useGetDashboardRecentsQuery();

  const isLoading = isLoadingRecentData || isLoadingSummariesReport;

  // eslint-disable-next-line max-len
  const feedbacks: FeedbackResultModel[] = useMemo(() => dashboardRecentData?.data?.feedbacks || [], [dashboardRecentData?.data?.feedbacks]);
  const orders = useMemo(() => dashboardRecentData?.data?.orders || [], [dashboardRecentData?.data?.orders]);
  const billings = useMemo(() => dashboardRecentData?.data?.billings || [], [dashboardRecentData?.data?.billings]);

  useEffect(() => {
    if (skipFetchOnFirstMount.current) {
      skipFetchOnFirstMount.current = false;
    }

    const progress = loadingBarRef.current as any;

    if (isLoading) {
      progress?.continuousStart();
    } else {
      progress?.complete();
    }
  }, [isLoading, skipFetchOnFirstMount.current]);

  return (
    <>
      <LoadingBar height={4} color="#0078D3" ref={loadingBarRef} />

      <div className="flex-1">
        <header className={clsx(
          "flex flex-col md:flex-row md:justify-between md:items-center",
          "p-6 pb-0",
          "w-full",
        )}
        >
          <div>
            <h2 className={clsx(
              "font-bold lg:font-semibold text-2xl lg:text-[26px] lg:leading-[33px]",
              "pr-4 md:mb-1",
              "overflow-hidden overflow-ellipsis whitespace-nowrap",
            )}
            >
              Welcome,
              {" "}
              {user?.bus_name ?? "-"}
              !
            </h2>

            <span style={{ color: "#8083A3" }} className={clsx("text-base")}>Check out latest updates</span>
          </div>

          <div className={clsx(
            "max-w-[175px] rounded-lg h-12 bg-[#F8F8F8] overflow-hidden hidden md:flex",
            openDatePicker ? "" : "hover:bg-gray-100",
          )}
          >
            <div className="flex items-center pl-3">
              <CalendarIcon className="w-4 h-4" color="#666666" />
            </div>

            <Popup
              ref={calendarRef}
              position="bottom right"
              contentStyle={{
                width: "auto",
                maxWidth: 560,
                background: "white",
              }}
              closeOnDocumentClick
              closeOnEscape
              open={openDatePicker}
              onOpen={() => setDateRange(dateRange)}
              onClose={() => {
                setOpenDatePicker(false);
                setOkDateRange(dateRange);
                // refetch();
              }}
              trigger={(
                <input
                  type="text"
                  className="w-full pl-2 text-xs font-bold bg-transparent"
                  value={`${dateRange?.map((date, index) => {
                    if (index === 0) {
                      return formatDate(date, "dd MMM");
                    }

                    return formatDate(date);
                  }).join(" - ")}`}
                  readOnly
                />
              )}
            >
              <Calendar
                selectRange
                showDoubleView
                onChange={setDateRange}
                returnValue="range"
                defaultValue={dateRange}
              />
              <div className="flex justify-end w-full">
                <button
                  title="Ok"
                  type="button"
                  className="self-end px-3 py-1 my-1 mr-1 text-sm border rounded"
                  onClick={() => {
                    setOkDateRange(dateRange);
                    calendarRef.current?.close();
                  }}
                >
                  Ok
                </button>
              </div>
            </Popup>
          </div>
        </header>

        {/* Reports */}
        <ReportCounter
          categoryTotal={summariesReport?.data?.category_total || 0}
          itemTotal={summariesReport?.data?.item_total || 0}
          orderTotal={summariesReport?.data?.order_total || 0}
          revenueTotal={summariesReport?.data?.revenue_total || 0}
        />

        {/* Orders */}
        <div className="px-6 mb-6">
          <div className="border rounded-xl">
            <div id="orders-header" className={clsx("p-4", "border-b", "flex flex-row items-center justify-between")}>
              <span className="font-bold text-[15.08px] md:text-base">
                Recent orders
              </span>

              <Link
                to="/orders"
                title="View order"
                className={clsx("text-xs font-bold text-black")}
              >
                <OrdersIcon className={clsx("w-4 h-4", "inline", "mr-2")} />
                <span>
                  View orders
                </span>
              </Link>
            </div>

            <div className="p-4 md:p-0" id="orders-body">
              <div className={clsx("w-full overflow-x-auto")}>
                <OrderList
                  className="md:min-w-[768px] border-b-rounded"
                  data={orders || []}
                  expandId={expandId}
                  isLoading={isLoading}
                  refetch={refetchRecentData}
                  setExpandId={(id: string) => setExpandId(id)}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Feedback */}
        <div className="px-6 mb-6">
          <div className="border rounded-xl">
            <div id="feedback-header" className={clsx("p-4", "border-b", "flex flex-row items-center justify-between")}>
              <span className="font-bold text-[15.08px] md:text-base">
                Recent Feedback
              </span>

              <Link
                to="/feedbacks"
                title="View Feedback"
                className={clsx("text-xs font-bold text-black")}
              >
                <FeedbackIcon className={clsx("w-4 h-4", "inline", "mr-2")} />
                <span>
                  View Feedback
                </span>
              </Link>
            </div>

            <div className="p-4 md:p-0" id="feedback-body">
              <FeedbackResultList
                data={feedbacks}
                loadingBarRef={loadingBarRef?.current}
                refetchFeedbackResult={refetchRecentData}
                showSeparator
              />
            </div>
          </div>
        </div>

        {/* Billing and Plans */}
        <div className="px-6 mb-8">
          <div className="border rounded-xl">
            <div id="billing-header" className={clsx("p-4", "border-b", "flex flex-row items-center justify-between")}>
              <span className="font-bold text-[15.08px] md:text-base">
                Your Plans
              </span>
            </div>

            <div id="billing-body" className="p-4 md:px-0 md:pb-0">
              <SettingBilling
                padding={4}
                showPagination={false}
                invoices={billings}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardPage;
