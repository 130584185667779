// import { useHistory, useLocation } from "react-router";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Stepper, HeaderFrontPage } from "../components";
import {
  TrialInput, SolutionChecklist, AddCategories, AddItems, Register,
  setCredentials,
} from "../features/auth";
import { ReactComponent as Illustration } from "../assets/illustration_register.svg";
import { useAppSelector, useAppDispatch } from "../app/hooks";

export default function FrontPage() {
  const { auth } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const [step, setStep] = useState<number>(auth.step ?? 1);
  const [uid, setUid] = useState<string>(auth.uid ?? "");

  const { push } = useHistory();

  /**
   * @description Function to handle next step in stepper
   * @param number<number>
   * @param value<string>
   */
  const nextStep = (number?: number, value?: string) => {
    if (number === 6) {
      push({
        pathname: "/email-verification",
        state: {
          email: value,
        },
      });
    } else {
      setStep(step + 1);
    }
  };

  /**
   * @description Function to handle previous step and reset step in stepper
   */
  const prevStep = () => setStep(step - 1);
  const resetStep = () => setStep(1);

  useEffect(() => {
    const currentStep = step === 6 ? null : step;
    dispatch(setCredentials({ uid, step: currentStep, remember: true }));
  }, [dispatch, uid, step]);

  return (
    <>
      <HeaderFrontPage />
      <div className="container-frontpage">
        <div className="mb-8 md:mb-14 w-full">
          <Stepper steps={5} activeStep={step} />
        </div>

        {step === 1 && <TrialInput nextStep={nextStep} setUid={setUid} />}
        {step === 2 && <SolutionChecklist nextStep={nextStep} prevStep={prevStep} resetStep={resetStep} uid={uid} />}
        {step === 3 && <AddCategories nextStep={nextStep} prevStep={prevStep} resetStep={resetStep} uid={uid} />}
        {step === 4 && <AddItems nextStep={nextStep} prevStep={prevStep} resetStep={resetStep} uid={uid} />}
      </div>

      {step === 5 && (
        <div className="w-full flex md:pb-8">
          <Illustration className="w-6/12 mt-14 hidden md:block" />

          <div className="w-full md:w-6/12 md:max-w-lg bg-white rounded md:shadow-lg px-3 md:px-8 py-6 md:ml-16">
            <h2 className="font-semi-bold text-2xl text-center">Complete Your Information</h2>

            <p className="text-xs text-secondary text-center mb-5">Complete your information below to get free trial</p>

            <Register prevStep={prevStep} nextStep={nextStep} uid={uid} />
          </div>
        </div>
      )}
    </>
  );
}
