import { ReactNode } from "react";
import { createPortal } from "react-dom";

interface PortalProps {
  containerId?: string;
  children: ReactNode;
}

function Portal({ containerId, children }: PortalProps) {
  const portalRoot = document.getElementById(containerId || "portal-root");

  if (portalRoot) return createPortal(children, portalRoot);

  return null;
}

export default Portal;
