import clsx from "clsx";
import { memo } from "react";
import Popup from "reactjs-popup";

import { ReactComponent as CloseIcon } from "../../assets/icons/ic_close.svg";
import { ReactComponent as SpinnerIcon } from "../../assets/icons/ic_spinner.svg";

import { useGetAllTableQuery } from "../../services";
import { getBreakpointValue } from "../../utils";
import { Select } from "../atoms";

interface ModalChangeTableProps {
  isOpen?: boolean;
  isLoading?: boolean;
  currentTable?: string;
  /**
   * Value
   * @default "choose"
   * @example
   * ```tsx
   * const [value, setValue] = useState<string>("");
   *
   * <ModalChangeTable
   *     // ...
   *     value={value}
   *     // ...
   * />
   * ```
   */
  value?: string;
  onClose?: Parameters<typeof Popup>[0]["onClose"];
  onChange?: (event: SelectChangeType) => void;
  onClickSave?: () => void;
}

function ModalChangeTable({
  isOpen,
  isLoading,
  onClose,
  onChange,
  onClickSave,
  currentTable,
  value = "choose",
}: ModalChangeTableProps) {
  const { data: tables } = useGetAllTableQuery();

  const isMobile = getBreakpointValue("md") > window.innerWidth;

  return (
    <Popup
      modal
      open={isOpen}
      onClose={onClose}
      closeOnEscape
      lockScroll
      contentStyle={{
        maxHeight: isMobile ? "100%" : "80%",
        // maxWidth: isMobile ? "90%" : 652,
        maxWidth: 489.33,
        width: "100%",
        padding: 0,
      }}
      overlayStyle={{
        padding: "16px",
        overflowY: "auto",
      }}
    >
      <form
        onSubmit={(event) => {
          event.preventDefault();
          onClickSave?.();
        }}
        className={clsx("bg-white", "rounded-lg")}
      >
        <div className={clsx("w-full", "p-4", "border-b", "flex flex-row items-center justify-between gap-4")}>
          <span className="font-medium pr-4">
            Change Table
            {currentTable ? ` of ${currentTable}` : ""}
          </span>

          <button
            type="button"
            title="Close Modal"
            onClick={onClose}
          >
            <CloseIcon className="w-5 h-5" />
          </button>
        </div>

        <div className="p-4 md:p-6">
          <Select
            placeholder="Choose Table"
            name="table"
            value={value}
            options={[
              {
                label: "Choose Table",
                value: "choose",
                disabled: true,
              },
              ...(tables?.data?.map((table) => ({
                label: table.name,
                value: table.id,
              })) || []),
            ]}
            // eslint-disable-next-line no-console
            onChange={onChange || ((event) => console.log(event.target.value))}
          />
        </div>

        <div className="flex items-center justify-center gap-4 px-4 pb-4 md:px-6 md:pb-6 w-full md:w-auto">
          <button
            type="submit"
            title="Save"
            className={clsx(
              "button-primary py-2 px-5 flex-1 md:flex-none",
              "h-11 md:min-w-[100px]",
            )}
            disabled={isLoading || value === "choose"}
          >
            {isLoading ? (
              <SpinnerIcon className="animate-spin h-4 w-4 mx-auto" />
            ) : "Save"}
          </button>

          <button
            type="button"
            title="Cancel"
            className={clsx(
              "button-gray py-2 px-5 flex-1 md:flex-none",
              "h-11 md:min-w-[100px]",
            )}
            disabled={isLoading}
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </form>
    </Popup>
  );
}

export default memo(ModalChangeTable);
