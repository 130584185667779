import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { ReactComponent as ChevronDownIcon } from "../assets/icons/ic_chevron_down.svg";
import { ReactComponent as EmailIcon } from "../assets/icons/ic_mail.svg";
import { ReactComponent as Illustration } from "../assets/illustration_email_verification.svg";
import { ReactComponent as LogoIcon } from "../assets/logo.svg";

import { LoadingAnimation } from "../components";
import { InputWithIcon } from "../components/atoms";
import { useForgotPasswordMutation } from "../services";

export interface ForgotPasswordForm {
  email: string;
}

function ForgotPasswordPage() {
  const history = useHistory();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ForgotPasswordForm>({
    mode: "onBlur",
    defaultValues: {
      email: "",
    },
  });
  const [sendToEmail, { isLoading }] = useForgotPasswordMutation();

  const onNext: SubmitHandler<ForgotPasswordForm> = (data) => {
    if (!data.email) return;
    sendToEmail({ email: data.email })
      .unwrap()
      .then((res) => {
        toast.success(res.message);
      })
      .catch((error) => {
        toast.error(error.data?.message ?? "Resend email failed");
      });
  };

  return (
    <div className="relative w-full max-w-3xl pt-32 mx-auto">
      <button type="button" title="Back" className="bg-transparent lg:hidden absolute top-4 left-2" onClick={history.goBack}>
        <ChevronDownIcon className="h-6 w-6 transform rotate-90 mr-2" />
      </button>

      <Illustration className="absolute inset-1 hidden md:block" />

      <div className="font-montserrat relative bg-white rounded text-center w-full md:max-w-xl md:shadow-lg p-6 md:py-5 md:px-8 md:mx-auto">
        <LogoIcon className="w-11 h-11 mb-5 mx-auto" />
        <p className="font-bold text-lg mb-1">Forgot Password? 🔑</p>
        <p className="text-secondary text-xs mb-5">Enter your email address below and we&#39;ll get you back on track.</p>

        <form>
          <Controller
            control={control}
            name="email"
            render={({
              field: {
                onChange, value, name, ref,
              },
            }) => (
              <InputWithIcon
                leftIcon={<EmailIcon className="w-5 h-5 mr-4" />}
                title="Your Email"
                placeholder="example@example.com"
                name={name}
                inputRef={ref({
                  required: {
                    value: true,
                    message: "Email is required",
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid Email",
                  },
                })}
                onChange={onChange}
                value={value}
              />
            )}
          />
          {errors.email?.message && <p className="text-sm text-red-500 text-left p-0 -mt-2">{errors.email.message}</p>}

          <button
            type="button"
            title={isLoading ? "Loading..." : "Send Reset Link"}
            className="button-primary w-11/12 md:w-1/2 flex justify-self-start justify-center rounded font-normal text-sm fixed md:relative bottom-4 md:bottom-0 inset-x-4 md:inset-x-0 md:mt-14"
            onClick={handleSubmit(onNext)}
          >
            {isLoading ? <LoadingAnimation size={5} /> : <span>Send Reset Link</span>}
          </button>
        </form>

        <button
          type="button"
          title="Back to Log In"
          className="button-link hidden md:flex justify-end w-full  text-sm font-bold mt-8"
          onClick={history.goBack}
        >
          Back to Log In
        </button>
      </div>
    </div>
  );
}

export default ForgotPasswordPage;
