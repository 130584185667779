/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useRef, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import LoadingBar from "react-top-loading-bar";
import Popup from "reactjs-popup";

import { ReactComponent as ChevronDownIcon } from "../../assets/icons/ic_chevron_down.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/ic_close.svg";
import { ReactComponent as CurrencyIcon } from "../../assets/icons/ic_currency.svg";
import { ReactComponent as DetailIcon } from "../../assets/icons/ic_detail.svg";
import { ReactComponent as GlobeIcon } from "../../assets/icons/ic_globe.svg";
import { ReactComponent as ThumbnailIcon } from "../../assets/icons/ic_thumbnail.svg";
// import { ReactComponent as MailIcon } from "../../assets/icons/ic_mail.svg";

import { DropUploader } from "..";
import { useAppSelector } from "../../app/hooks";
import {
  CodeNameResponse, SettingGeneralRequest, useCurrencyListQuery, useImageMutation, useMainLanguageListQuery,
  useOtherLanguageListQuery, useSettingGeneralQuery,
  useUpdateSettingGeneralMutation,
} from "../../services";
import { Select } from "../atoms";

interface Props {
  saveSetting: boolean;
  setSaveSetting: (arg0: boolean) => void;
}

function SettingGeneral({ saveSetting, setSaveSetting }: Props) {
  const ref = useRef(null);
  const uid = useAppSelector(({ auth }) => auth.uid);

  const [otherLanguage, setOtherLanguage] = useState<string>();
  const [otherLanguages, setOtherLanguages] = useState<CodeNameResponse[]>([]);

  const { data: dataLangMain, isLoading: isLoadingDataLangMain } = useMainLanguageListQuery();
  const { data: dataLangOther, isLoading: isLoadingDataLangOther } = useOtherLanguageListQuery();
  const { data: dataCurrency, isLoading: isLoadingDataCurrency } = useCurrencyListQuery();
  const { data: dataGeneral, isLoading: isLoadingDataGeneral, refetch: refetchDataGeneral } = useSettingGeneralQuery();
  const [postImage, { isLoading: isLoadingImage }] = useImageMutation();
  const [updateSettingGeneral, { isLoading: isLoadingUpdateSettingGeneral }] = useUpdateSettingGeneralMutation();

  const isLoading = isLoadingDataLangMain
    || isLoadingDataLangOther
    || isLoadingDataCurrency
    || isLoadingDataGeneral
    || isLoadingUpdateSettingGeneral
    || isLoadingImage;

  const {
    register, control, reset, handleSubmit, setValue, watch,
  } = useForm<SettingGeneralRequest>();
  const mainLanguage = watch("default_lang_code");

  const restOtherLanguages = (dataLangOther?.data ?? [])
    .filter(({ code }) => code !== mainLanguage)
    .filter(({ code }) => !otherLanguages.find((lang) => lang.code === code));

  const removeOtherLanguage = (otherCode: string) => {
    const filteredOther = otherLanguages.filter(({ code }) => code !== otherCode);
    setOtherLanguages(filteredOther);
  };

  const uploadImage = (path: string) => {
    if (!uid || !path) {
      setValue("logo", null);
      return;
    }

    const formData = new FormData();
    formData.append("uid", uid || "");
    formData.append("img", path);

    postImage(formData)
      .unwrap()
      .then((res) => {
        if (res.data?.image_id) {
          setValue("logo", res.data.image_id);
        }
      })
      .catch((error) => {
        toast.error(error.data?.message ?? "Failed to upload");
        setValue("logo", undefined);
      });
  };

  const onSubmit: SubmitHandler<SettingGeneralRequest> = (data) => {
    // RegExp for check image
    const regex = /^.*\.(jpg|jpeg|png|gif|bmp)$/;

    updateSettingGeneral({
      ...data,
      logo: data.logo === null ? dataGeneral?.data?.info.logo : regex.test(data.logo as string) ? undefined : data.logo,
    })
      .unwrap()
      .then((res) => {
        if (res.status === "Success") toast.success("Successfully update general settings");
        refetchDataGeneral();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => {
        setSaveSetting(false);
      });
  };

  useEffect(() => {
    if (saveSetting) {
      handleSubmit(onSubmit)();
    }
  }, [saveSetting]);

  useEffect(() => {
    removeOtherLanguage(mainLanguage);
  }, [mainLanguage]);

  useEffect(() => {
    const foundOther = dataLangOther?.data?.find(({ code }) => code === otherLanguage);
    const duplicatedOther = otherLanguages.find(({ code }) => code === otherLanguage);
    if (foundOther && !duplicatedOther) {
      setOtherLanguages((prevState) => [...prevState, foundOther]);
    }
  }, [otherLanguage]);

  useEffect(() => {
    setValue(
      "other_langs_code",
      otherLanguages.map(({ code }) => code),
    );
  }, [otherLanguages]);

  useEffect(() => {
    if (dataGeneral?.data) {
      const {
        info, language, feedback_email, currency,
      } = dataGeneral.data;
      reset({
        business_name: info.business_name,
        address: info.address,
        default_lang_code: language.default.code,
        other_langs_code: [],
        feedback_email,
        currency_code: currency,
        logo: info.logo,
      });
      setOtherLanguages(language.other);
    }
  }, [dataGeneral?.data]);

  useEffect(() => {
    const progress = ref.current as any;
    if (isLoading) {
      progress?.continuousStart();
    } else {
      progress?.complete();
    }
  }, [isLoading]);

  return (
    <>
      <LoadingBar height={4} color="#0078D3" ref={ref} />
      <div className="flex flex-col lg:flex-row gap-4 md:gap-16 px-4 md:px-6">
        <div className="flex flex-col flex-1 gap-4">
          <div className="flex flex-col gap-1">
            <div className="flex items-center">
              <DetailIcon className="h-4 w-4 mr-2" />
              <p className="font-semibold text-lg mt-1">General Information</p>
            </div>
            <p className="text-sm text-secondary">Set your general informations</p>
          </div>

          <div className="flex flex-col gap-1">
            <label htmlFor="business_name" className="text-secondary">
              Business Name
            </label>
            <input
              type="text"
              id="business_name"
              className="input-base"
              placeholder="What is the name of your business?"
              {...register("business_name", { required: true })}
            />
          </div>

          <div className="flex flex-col gap-1">
            <label htmlFor="address" className="text-secondary">
              Address
            </label>
            <textarea
              rows={4}
              id="address"
              className="input-base"
              placeholder="Enter venue address"
              {...register("address")}
            />
          </div>

          <div className="flex flex-col w-full">
            <p className="font-semibold text-lg mb-1 flex">
              <ThumbnailIcon className="mr-2" />
              Your Logo
            </p>
            <p className="text-sm text-secondary opacity-70 mb-4">Upload your business logo</p>

            <DropUploader
              type="primary"
              withText
              size="custom"
              customClassName="w-auto h-48 rounded-xl md:mr-6"
              setFilePath={uploadImage}
              preview={dataGeneral?.data?.info.logo}
              // uploadFailed={isErrorLogo}
            />
            <input type="text" {...register("logo")} className="hidden" />
          </div>
        </div>

        <div className="flex flex-col flex-1 gap-4">
          <div className="flex flex-col gap-1">
            <div className="flex items-center">
              <GlobeIcon className="h-4 w-4 mr-2" />
              <p className="font-semibold text-lg mt-1">Language</p>
            </div>
            <p className="text-sm text-secondary">Set your default language and other languages</p>
          </div>

          <div className="flex flex-col gap-1">
            <label className="text-secondary">Default Language</label>
            <Controller
              control={control}
              name="default_lang_code"
              render={({
                field: {
                  onChange, value, name, ref: controllerRef,
                },
              }) => (
                <Select
                  name={name}
                  options={(dataLangMain?.data ?? []).map(({ name: dataName, code }) => ({
                    value: code,
                    label: dataName,
                  }))}
                  selectRef={controllerRef}
                  customClass="input-base text-sm"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </div>

          <div className="flex flex-col gap-1">
            <label className="text-secondary">Other Language</label>
            <div className="relative flex-grow">
              <div className="relative flex-grow">
                <Popup
                  trigger={<div className="input-base capitalize w-full h-10" />}
                  position="bottom left"
                  contentStyle={{
                    background: "white",
                    padding: "4px 8px",
                    marginTop: 8,
                    borderRadius: 4,
                    visibility: restOtherLanguages.length > 0 ? "visible" : "hidden",
                  }}
                  arrow={false}
                >
                  {restOtherLanguages.map(({ name, code }) => (
                    <div
                      key={code + name + Math.floor(Math.random() * 100000)}
                      onClick={() => setOtherLanguage(code)}
                      className="my-1"
                    >
                      {name}
                    </div>
                  ))}
                </Popup>

                <div className="absolute top-1/2 right-2 transform -translate-y-1/2 w-4 h-4">
                  <ChevronDownIcon />
                </div>
              </div>
              <div className="flex gap-2 absolute inset-0 p-2 pointer-events-none">
                {otherLanguages.map((lang) => (
                  <div
                    className="text-xs rounded py-1 px-2 flex items-center gap-1"
                    style={{ background: "#e5f1fb" }}
                    key={lang.code + lang.name + Math.floor(Math.random() * 100000)}
                  >
                    {lang.name}
                    {" "}
                    <CloseIcon
                      className="h-3 w-3 pointer-events-auto cursor-pointer"
                      onClick={() => removeOtherLanguage(lang.code)}
                    />
                  </div>
                ))}
              </div>
            </div>
            <p className="text-secondary text-sm">
              To translate your menu in the chosen languages, go to Menus &gt; Localize.
            </p>
          </div>

          {/* <div className="flex items-center mt-6">
            <MailIcon className="h-4 w-4 mr-2" />
            <p className="font-semibold text-lg mt-1">Feedback Emails</p>
          </div>

          <div className="flex flex-col gap-1">
            <label htmlFor="email_feedback" className="text-secondary">
              Email that will receive feedback results
            </label>
            <input
              type="text"
              id="email_feedback"
              className="input-base"
              placeholder="Type your email address"
              {...register("feedback_email", { required: true })}
            />
          </div> */}

          <div className="flex flex-col gap-1 mt-6">
            <div className="flex items-center">
              <CurrencyIcon className="h-4 w-4 mr-2" />
              <p className="font-semibold text-lg mt-1">Currency</p>
            </div>
            <p className="text-sm text-secondary">Set your default currency</p>
          </div>

          <div className="flex flex-col gap-1">
            <label htmlFor="currency" className="text-secondary">
              Currency
            </label>
            <Controller
              control={control}
              name="currency_code"
              render={({
                field: {
                  onChange, value, name, ref: controllerRef,
                },
              }) => (
                <Select
                  name={name}
                  options={(dataCurrency?.data ?? []).map(({ name: dataName, code }) => ({
                    value: code,
                    label: dataName,
                  }))}
                  selectRef={controllerRef}
                  customClass="input-base text-sm"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default SettingGeneral;
