/* eslint-disable camelcase */
import { createApi } from "@reduxjs/toolkit/query/react";
import { CodeNameResponse } from ".";

import baseQuery from "./base-service";

export interface LocalizeMenuGetResponse {
  lang_id: string;
  name: string;
  description: string;
  isShow: boolean;
}

export interface ItemPriceLocalize {
  id: string;
  price: number;
  definition: string;
}

export interface LocalizeMenuWithPriceGetResponse extends LocalizeMenuGetResponse {
  prices: ItemPriceLocalize[]
}

export interface LocalizeCategoryGetResponse {
  id: string;
  languages: LocalizeMenuGetResponse[];
  items: {
    id: string;
    object?: string;
    languages: LocalizeMenuWithPriceGetResponse[];
    items_on_section?: {
      id: string;
      object?: string;
      languages: LocalizeMenuWithPriceGetResponse[];
    }[];
  }[]
}

interface LanguageListType extends CodeNameResponse {
  type: string;
}

export interface LocalizeGetResponse {
  main_language: string;
  currency: string;
  menu: LocalizeMenuGetResponse[];
  categories: LocalizeCategoryGetResponse[];
  language_list: LanguageListType[]
}

interface FormLocalizeMenu {
  lang_id: string;
  name: string;
  description: string;
}

export interface UpdateLocalizeMenuRequest {
  data: FormLocalizeMenu[]
}

export interface UpdateLocalizeRequest extends UpdateLocalizeMenuRequest {
  id: string;
}

export const localizeServices = createApi({
  reducerPath: "localize",
  baseQuery,
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  endpoints: (builder) => ({
    getAllLocalize: builder.query<HttpResponse<LocalizeGetResponse>, void>({
      query: () => ({
        url: "/local",
        method: "GET",
      }),
    }),
    updateLocalizeMenu: builder.mutation<HttpResponse<{}>, UpdateLocalizeMenuRequest>({
      query: (body) => ({
        url: "/local/menu",
        method: "PUT",
        body,
      }),
    }),
    updateLocalizeCategory: builder.mutation<HttpResponse<{}>, UpdateLocalizeRequest>({
      query: (body) => ({
        url: "/local/category",
        method: "PUT",
        body,
      }),
    }),
    updateLocalizeItem: builder.mutation<HttpResponse<{}>, UpdateLocalizeRequest>({
      query: (body) => ({
        url: "/local/item",
        method: "PUT",
        body,
      }),
    }),
    toggleActiveMenuLanguage: builder.mutation<HttpResponse<{}>, {language_id: string;}>({
      query: (body) => ({
        url: "/local/menu/toggleActive",
        method: "PUT",
        body,
      }),
    }),
    toggleActiveOtherLanguage: builder.mutation<HttpResponse<{}>, {id: string; language_id: string; type: string;}>({
      query: (body) => ({
        url: "/local/other/toggleActive",
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  useGetAllLocalizeQuery,
  useUpdateLocalizeMenuMutation,
  useUpdateLocalizeCategoryMutation,
  useUpdateLocalizeItemMutation,
  useToggleActiveMenuLanguageMutation,
  useToggleActiveOtherLanguageMutation,
} = localizeServices;
