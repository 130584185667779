import { createApi } from "@reduxjs/toolkit/query/react";

import baseQuery from "./base-service";

export type PromoCodeModel = {
  id?: string;
  code: string;
  type: "PERCENT" | "AMOUNT";
  amount: number;
  order_modes: string[];
  min_order: number;
}

export type UpdatePromoRequestType = Pick<PromoCodeModel, "type" | "amount" | "order_modes" | "min_order"> & {
  id: string
};

export const promoServices = createApi({
  reducerPath: "promo",
  baseQuery,
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  endpoints: (builder) => ({
    // getPromoTypeList: builder.query<HttpResponse<{ id: string }[]>, void>({
    //   query: () => ({
    //     url: "/promoTypeList",
    //     method: "GET",
    //   }),
    // }),
    createPromo: builder.mutation<HttpResponse<{}>, PromoCodeModel>({
      query: (body) => ({
        url: "/promo",
        method: "POST",
        body,
      }),
    }),
    updatePromo: builder.mutation<HttpResponse<{}>, UpdatePromoRequestType>({
      query: (body) => ({
        url: "/promo",
        method: "PUT",
        body,
      }),
    }),
    deletePromo: builder.mutation<HttpResponse<{}>, { id: string }>({
      query: (body) => ({
        url: "/promo",
        method: "DELETE",
        body,
      }),
    }),
    toggleActivePromo: builder.mutation<HttpResponse<{}>, { id: string }>({
      query: (body) => ({
        url: "/promo/active",
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  // useGetPromoTypeListQuery,
  useCreatePromoMutation,
  useDeletePromoMutation,
  useUpdatePromoMutation,
  useToggleActivePromoMutation,
} = promoServices;
