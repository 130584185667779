import clsx from "clsx";
import { forwardRef, memo } from "react";
import QRCode from "react-qr-code";

import { format } from "date-fns";
import { ORDER_TYPE_LIST_TEXT, PAYMENT_TYPE_LIST_TEXT } from "../../constants";
import { useQrLink } from "../../hooks";
import { type GetAllOrderResponse } from "../../services";

interface OrderSummaryProps {
  data: GetAllOrderResponse | null;
  currency: string;
}

const OrderSummary = forwardRef<HTMLDivElement, OrderSummaryProps>(({ data, currency }, ref) => {
  const { qrLink } = useQrLink();

  const details = [
    {
      label: "Date",
      value: data?.sent_time ? format(new Date(data?.sent_time), "dd.MM.yyyy HH:mm:ss") : null,
    },
    {
      label: "Name",
      value: data?.cust_name || "",
    },
    {
      label: "E-mail",
      value: data?.cust_email || "",
    },
    {
      label: "Phone Number",
      value: data?.cust_phone || "",
    },
    {
      label: "Order Type",
      value: ORDER_TYPE_LIST_TEXT[data?.order_type_id as keyof typeof ORDER_TYPE_LIST_TEXT] || "",
    },
    {
      label: "Payment Type",
      value: PAYMENT_TYPE_LIST_TEXT[data?.payment_type_id as keyof typeof PAYMENT_TYPE_LIST_TEXT] || "",
    },
    {
      label: "Address",
      value: data?.cust_address || "",
    },
    {
      label: "Building",
      value: data?.cust_building || "",
    },
    {
      label: "Zone",
      value: data?.cust_zone || "",
    },
    {
      label: "Street",
      value: data?.cust_street || "",
    },
    {
      label: "Apartment",
      value: data?.cust_apart || "",
    },
    {
      label: "Floor",
      value: data && "cust_floor" in data ? (data?.cust_floor || "") : "",
    },
  ];

  return (
    <div
      ref={ref}
      className={clsx(
        "print-page bg-white max-w-[80mm]",
        "py-8",
        // "pb-8 pt-10",
        "font-nunito-sans text-black text-[11px]",
        // "block",
        "hidden print:block",
      )}
    >
      <span className="block text-center">Order Number :</span>

      <h4 className={clsx("font-extrabold text-7xl leading-normal text-center", "my-2")}>{data?.order_id || ""}</h4>

      {/* Order Details */}
      <span className="block text-center text-base mb-6">Details</span>

      <ul className="flex flex-col mb-8">
        {details.map(({ label, value }) => {
          if (!value) return null;

          return (
            <li className={clsx("border-b last:border-transparent border-b-black", "py-2", "flex flex-row items-start justify-between")} key={label}>
              <span className="w-1/2 pr-2">
                {label}
                :
              </span>
              <span className="w-1/2 pl-2 text-right">{value}</span>
            </li>
          );
        })}
      </ul>

      {/* Order Summary */}
      <span className="block text-center text-base mb-6">Order Summary</span>

      <ul className="flex flex-col mb-4">
        {data?.orders.map((item) => (
          <li className={clsx("border-b border-dotted last:border-transparent border-b-black", "py-2")} key={item.id}>
            <div className={clsx(
              "flex flex-row items-start justify-between",
              item.modifiers.length > 0 && "mb-2",
            )}
            >
              <span className="w-1/2 block pr-2">
                {item.name}
                {" "}
                {item.qty > 1 && (
                  <span className="font-bold">
                    X
                    {item.qty}
                  </span>
                )}
              </span>
              <span className="w-1/2 block pl-2 text-right">
                {item.price_total}
                {" "}
                {currency}
              </span>
            </div>

            {item.modifiers.map((mod, index) => (
              <div
                className={clsx(
                  "flex flex-row items-start justify-between",
                  "mb-1",
                  "italic",
                  "text-[9px]",
                )}
                key={`${mod.name}-${index}`}
              >
                <span className="w-1/2 block pr-2">
                  {mod.name}
                </span>
                <span className="w-1/2 block pl-2 text-right">
                  +
                  {" "}
                  {mod.price_total}
                  {" "}
                  {currency}
                </span>
              </div>
            ))}
          </li>
        ))}
      </ul>

      <ul className="flex flex-col mb-8">
        <li className={clsx("py-1.5", "flex flex-row items-start justify-between")}>
          <span className="w-1/2 pr-2">
            Subtotal
          </span>
          <span className="w-1/2 pl-2 text-right">
            {data?.price_subtotal}
            {" "}
            {currency}
          </span>
        </li>

        {data?.order_type_id === "order_delivery" && (
          <li className={clsx("py-1.5", "flex flex-row items-start justify-between")}>
            <span className="w-1/2 pr-2">
              Delivery Charge
            </span>
            <span className="w-1/2 pl-2 text-right">
              {data?.price_delivery}
              {" "}
              {currency}
            </span>
          </li>
        )}

        <li className={clsx("py-1.5", "flex flex-row items-start justify-between")}>
          <span className="w-1/2 pr-2">
            Discount
          </span>
          <span className="w-1/2 pl-2 text-right">
            {data?.price_discount}
            {" "}
            {currency}
          </span>
        </li>

        <li className={clsx("py-1.5", "flex flex-row items-start justify-between")}>
          <span className="w-1/2 pr-2">
            Total
          </span>
          <span className="w-1/2 pl-2 text-right">
            {data?.price_total}
            {" "}
            {currency}
          </span>
        </li>
      </ul>

      <div className={clsx("text-center italic font-semibold", "flex flex-col items-center justify-center gap-4")}>
        <QRCode id="qrcode-menu" value={qrLink || ""} fgColor="#000000" size={96} />

        <div>
          <p className="mb-1">Scan to view menu</p>
          <p>Powered by cloudmenu.cloud</p>
        </div>
      </div>
    </div>
  );
});

export default memo(OrderSummary);
