import ApexChart from "react-apexcharts";
import { memo, useEffect, useState } from "react";

interface PaymentMethodChartProps {
  data: any;
  chartOptions?: ApexCharts.ApexOptions;
}

const donutChartOptions: ApexCharts.ApexOptions = {
  chart: {
    width: 350,
    type: "donut",
  },
  dataLabels: {
    enabled: false,
  },
  tooltip: {
    y: {
      formatter: (val) => `$${val}`,
    },
  },
};

function PaymentMethodChart({ data, chartOptions }: PaymentMethodChartProps) {
  const [options, setOptions] = useState<ApexCharts.ApexOptions>({ ...donutChartOptions });
  const [series, setSeries] = useState<typeof data>([]);

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      ...chartOptions,
      legend: {
        ...chartOptions?.legend,
        customLegendItems: chartOptions?.labels,
      },
      series,
    }));
  }, [chartOptions]);

  useEffect(() => {
    setSeries([...data]);
    setOptions((prevState) => ({
      ...prevState,
      series: [...data],
    }));
  }, [data]);

  return (
    <ApexChart
      type="donut"
      series={series}
      width={options.chart?.width || 350}
      options={options}
    />
  );
}

export default memo(PaymentMethodChart);
