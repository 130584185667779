import {
  memo, useEffect,
  useState,
} from "react";
import ApexChart from "react-apexcharts";

import { useWindow } from "../../hooks";
import { getBreakpointValue } from "../../utils/get-breakpoint";

interface RevenueAreaChartProps {
  series: any;
  chartOptions?: ApexCharts.ApexOptions;
}

// chart options
const areaChartOptions: ApexCharts.ApexOptions = {
  chart: {
    height: 350,
    type: "area",
    toolbar: {
      show: false,
    },
    animations: {
      enabled: true,
      easing: "easeinout",
      speed: 800,
      animateGradually: {
        enabled: true,
        delay: 150,
      },
      dynamicAnimation: {
        enabled: true,
        speed: 350,
      },
    },
  },
  theme: {
    palette: "palette1",
  },
  // colors: ["#004FA3", "#0063CC", "#007AFF", "#3396FF", "#5CABFF"],
  // colors: ["#007AFF", "#06C86B", "#FD3A57", "#FB8832", "#FCC21B"],
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    // width: 1,
    width: 2,
  },
  grid: {
    strokeDashArray: 0,
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
};

function RevenueAreaChart({ series, chartOptions }: RevenueAreaChartProps) {
  const [options, setOptions] = useState<ApexCharts.ApexOptions>(areaChartOptions);
  const [chartWidth, setChartWidth] = useState<number | undefined>(undefined);

  const { paneSize } = useWindow();

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      ...chartOptions,
      xaxis: {
        type: "category",
        categories: chartOptions?.xaxis?.categories || ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        // show: false,
        labels: {
          show: false,
        },
      },
      legend: {
        position: "top",
        floating: true,
        horizontalAlign: "left",
        markers: {
          width: 16,
          height: 16,
          radius: 4,
          offsetX: -4,
        },
        itemMargin: {
          horizontal: 12,
          vertical: 8,
        },
      },
      fill: {
        type: "gradient",
      },
      tooltip: {
        followCursor: true,
        x: {
          show: false,
        },
        y: {
          formatter: (val) => {
            if (val === null) {
              return "$ 0";
            }

            return `$ ${val}`;
          },
        },
      },
    }));
  }, [chartOptions]);

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      series,
    }));
  }, [series]);

  useEffect(() => {
    if (getBreakpointValue("xl") > paneSize.width) {
      if (chartWidth === 680) return;
      setChartWidth(680);
    } else if (paneSize.width < 1560) {
      if (chartWidth === 850) return;
      setChartWidth(850);
    } else {
      if (typeof chartWidth === "undefined") return;
      setChartWidth(undefined);
    }
  }, [paneSize.width, chartOptions]);

  return (
    <ApexChart
      options={options}
      series={series}
      type="area"
      height={350}
      width={chartWidth}
    />
  );
}

export default memo(RevenueAreaChart);
