/* eslint-disable react/jsx-pascal-case */
import clsx from "clsx";
import {
  Fragment,
  memo,
  useEffect,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import Popup from "reactjs-popup";

import { toast } from "react-toastify";
import { ReactComponent as ChevronIcon } from "../assets/icons/ic_chevron_down.svg";
import { ReactComponent as EyeIcon } from "../assets/icons/ic_eye.svg";
import { ReactComponent as PlusIcon } from "../assets/icons/ic_plus.svg";
import { ReactComponent as PublishIcon } from "../assets/icons/ic_publish.svg";
import { ReactComponent as QRCodeIcon } from "../assets/icons/ic_qrcode.svg";

import { useAppDispatch, useAppSelector } from "../app/hooks";
import { QRScan } from "../components";
import { setUser } from "../features/auth";
import { useQrLink } from "../hooks";
import { userDetailModel } from "../services";
import { getBreakpointValue } from "../utils";
import Published from "./publish";

const chevronBaseClass = "w-4 h-4 transform transition-transform ease-out ";
const expandedChevronClass = `${chevronBaseClass}rotate-0`;
const unexpandedChevronClass = `${chevronBaseClass}-rotate-90`;

const buttonBaseClass = "w-full px-4 py-3 flex items-center rounded text-sm transition-colors ease-in ";
const activeButtonClass = `${buttonBaseClass}bg-primary text-white hover:bg-primary`;
const inactiveButtonClass = `${buttonBaseClass}text-secondary hover:bg-gray-300`;

const subRouteBaseClass = "w-full overflow-hidden transform transition-all duration-300 ease-out ";
const expandedSubRouteClass = `${subRouteBaseClass}max-h-xl translate-y-0 opacity-100`;
const unexpandedSubRouteClass = `${subRouteBaseClass}max-h-0 -translate-y-full opacity-0`;

interface Props {
  toggleSidebar: () => void;
  routes: PrivateRouteType[];
  data: userDetailModel;
  marginTop: string;
}

const getAbbreviationName = (name: string) => {
  const splittedName = name.split(" ");
  const firstLetter = splittedName[0];
  let abbreviationName = firstLetter.charAt(0) + firstLetter.slice(-1);
  if (splittedName.length > 1) {
    abbreviationName = firstLetter.charAt(0) + splittedName[1].charAt(0);
  }

  return abbreviationName.toUpperCase();
};

const Sidebar = memo(({
  toggleSidebar, routes, data, marginTop,
}: Props) => {
  const savedUser = useAppSelector(({ auth }) => auth.user);
  const isQROnly = !savedUser?.features.includes("tablet");

  const [isSelected, setSelected] = useState("/categories");
  const [isExpanded, setExpanded] = useState<boolean>(true);
  const [openPopUp, setOpenPopUp] = useState<boolean>(false);

  const { pathname } = useLocation();
  const { push } = useHistory();
  const dispatch = useAppDispatch();
  const { qrLink, qrLinkLoading } = useQrLink();

  const handleClick = (path: string) => {
    setSelected(path);
    push(path);

    const isMobile = getBreakpointValue("md") > window.innerWidth;

    if (isMobile) {
      setTimeout(toggleSidebar, 300);
    }
  };

  const viewMenu = async () => {
    if (qrLink) {
      window.open(qrLink, "_blank");
    } else {
      toast.error("Failed to fetch QR web link");
    }
  };

  useEffect(() => {
    setSelected(pathname);
  }, [pathname]);

  useEffect(() => {
    dispatch(setUser(data));
  }, [data]);

  const businessName = data.bus_name ?? "-";
  const abbreviationName = getAbbreviationName(businessName);

  return (
    <div
      className="flex flex-col-reverse justify-end min-h-screen md:min-h-0 bg-white md:flex-col md:justify-between border-r border-line-gray h-full transition-[height] duration-[250ms] ease-linear"
      style={{
        height: `calc(100vh - ${marginTop})`,
        // height: getBreakpointValue("md") < window.innerWidth ? `calc(100vh - ${marginTop})` : "unset",
      }}
    >
      <div className="p-3 overflow-x-auto">
        {routes
          .filter((route) => route.name)
          .map(({
            path, name, Icon, routes: subRoutes, disabled,
          }) => {
            const isNested = subRoutes !== undefined;
            const isNestedExpanded = isNested && isExpanded;
            const mainButtonClass = isSelected === path ? activeButtonClass : inactiveButtonClass;
            const chevronClass = isExpanded ? expandedChevronClass : unexpandedChevronClass;
            const subButtonContainer = isExpanded ? expandedSubRouteClass : unexpandedSubRouteClass;

            return (
              <Fragment key={path}>
                {name === "Devices" && (
                  <Popup
                    open={openPopUp}
                    onOpen={() => setOpenPopUp(true)}
                    lockScroll
                    closeOnEscape
                    contentStyle={{
                      maxWidth: 500,
                      width: "100%",
                    }}
                    trigger={(
                      <button className={inactiveButtonClass} type="button" title="Connect">
                        <QRCodeIcon className="h-4 w-4" color="#5D5D5D" />
                        <p className="text-left ml-2 flex-1 text-">Connect</p>
                      </button>
                    )}
                    modal
                  >
                    <QRScan closePopUp={() => setOpenPopUp(false)} />
                  </Popup>
                )}

                {isQROnly && (name === "Devices" || name === "Theme") ? null : (
                  <button
                    type="button"
                    title={disabled ? "Coming soon" : name}
                    className={isNestedExpanded ? `${mainButtonClass} bg-gray-100` : mainButtonClass}
                    onClick={() => {
                      if (isNested) {
                        setExpanded(!isExpanded);
                        return;
                      }
                      handleClick(path);
                    }}
                    disabled={disabled}
                  >
                    {Icon && <Icon className="h-4 w-4" color={isSelected === path ? "#FFF" : "#5D5D5D"} />}
                    <p className={clsx(
                      "text-left ml-2 flex-1",
                      isNested ? "font-montserrat font-medium text-[#181818]" : (isSelected === path ? "text-white" : "text-[#5D5D5D]"),
                    )}
                    >
                      {name}
                    </p>
                    {isNested && <ChevronIcon className={chevronClass} />}
                  </button>
                )}

                {subRoutes && (
                  <div className={subButtonContainer}>
                    {subRoutes?.map((nestedRoute) => (
                      <button
                        title={nestedRoute.name}
                        type="button"
                        key={nestedRoute.path}
                        className={isSelected === nestedRoute.path ? activeButtonClass : inactiveButtonClass}
                        onClick={() => handleClick(nestedRoute.path)}
                      >
                        <p className="text-xs">○</p>
                        <p className="text-left ml-2 flex-1">{nestedRoute.name}</p>
                      </button>
                    ))}
                  </div>
                )}
              </Fragment>
            );
          })}

        {/* TODO: fix path on these buttons */}
        <button
          className={`${inactiveButtonClass} md:hidden`}
          onClick={viewMenu}
          disabled={qrLinkLoading}
          type="button"
          title="View Menu"
        >
          <EyeIcon className="h-4 w-4" color={isSelected === "/view-menu" ? "#FFF" : "#5D5D5D"} />
          <p className="text-left ml-2 flex-1 ">View Menu</p>
        </button>

        <Popup
          modal
          contentStyle={{
            background: "white",
            borderRadius: "8px",
            width: "591px",
            marginRight: "1rem",
            marginLeft: "1rem",
          }}
          trigger={(
            <button
              className={`${inactiveButtonClass} md:hidden`}
              onClick={toggleSidebar}
              title="Publish"
              type="button"
            >
              <PublishIcon className="h-4 w-4" color={isSelected === "/publish" ? "#FFF" : "#5D5D5D"} />
              <p className="text-left ml-2 flex-1 ">Publish</p>
            </button>
          )}
        >
          <Published />
        </Popup>
      </div>

      <div className="md:border-t border-line-gray px-3 py-6 flex min-w-0">
        <div className="rounded-full w-8 h-8 bg-primary bg-opacity-10 flex justify-center items-center flex-none">
          <p className="text-sm text-active">{abbreviationName}</p>
        </div>
        <div className="text-xs px-2 min-w-0">
          <p className="text-secondary">{businessName}</p>
          <p className="overflow-hidden overflow-ellipsis">{data.email ?? "-"}</p>
        </div>
      </div>

      <button
        className="md:hidden h-5 w-5 self-end mr-3 mt-5"
        onClick={toggleSidebar}
        title="Toggle Sidebar"
        type="button"
      >
        <PlusIcon className="transform rotate-45" />
      </button>
    </div>
  );
});

export default Sidebar;
