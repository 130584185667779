import { useState, useEffect, KeyboardEvent } from "react";
import QRCode from "react-qr-code";
import { toast } from "react-toastify";

import { useAppSelector } from "../app/hooks";
import { useQrLink } from "../hooks";
import { useVenueIDQuery, useUpdateVenueMutation } from "../services";
import { ReactComponent as CopyIcon } from "../assets/icons/ic_copy.svg";
import LoadingAnimation from "./loading-animation";

interface Props {
  closePopUp: () => void;
}

function QRScan({ closePopUp }: Props) {
  const savedUser = useAppSelector(({ auth }) => auth.user);
  const { data, isLoading } = useVenueIDQuery();
  const { qrLink, qrLinkLoading } = useQrLink();

  const [tab, setTab] = useState<"tablet" | "mobile">("mobile");
  const [venueID, setVenueID] = useState<string>("");
  const [venueIDError, setVenueIDError] = useState<string>("");
  const [pincode, setPincode] = useState<string>("");
  const [updateVenue, { isLoading: isLoadingUpdateVenue }] = useUpdateVenueMutation();

  const handleCopy = (type: string) => {
    if (!data?.data) return;

    // Function to copy a string to the clipboard
    const copyText = (value: string) => {
      if ("navigator" in window && "clipboard" in window.navigator) {
        const { clipboard } = window.navigator;
        clipboard.writeText(value).then(() => {
          toast.success(`${type} Copied to clipboard`);
        });
      } else {
        const textarea = document.createElement("textarea");

        textarea.value = value;
        textarea.setAttribute("readonly", "");
        textarea.style.position = "absolute";
        textarea.style.left = "-9999px";
        document.body.appendChild(textarea);
        textarea.select();
        textarea.setSelectionRange(0, value.length);
        document.execCommand("copy");
        document.body.removeChild(textarea);
        toast.success(`${type} Copied to clipboard`);
      }
    };

    const PINCode = data.data.pincode;
    const VenueID = data.data.venue_id;
    const QRLink = qrLink || "";

    switch (type) {
    case "PIN":
      copyText(PINCode);
      break;

    case "VenueID":
      copyText(VenueID);
      break;

    case "QRLink":
      copyText(QRLink);
      break;

    default:
      toast.error("Something went wrong");
    }
    // window.navigator.clipboard
    //   .writeText(data.data.venue_id)
    //   .then(() => {
    //     toast.success(value + " Copied");
    //   });
  };

  const handleKeyPressPincode = (event: KeyboardEvent<HTMLInputElement>) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  const handleSave = () => {
    if (venueID.length < 9) {
      setVenueIDError("Minimum character is 9");
      return;
    }

    setVenueIDError("");
    updateVenue({ venue_id: venueID, pincode })
      .unwrap()
      .then((res) => {
        if (res.status === "Success") {
          toast.success("Successfully update venue id or pincode");
          closePopUp();
        } else {
          throw new Error(res.message);
        }
      })
      .catch((err) => toast.error(err.data.message ?? err.message));
  };

  useEffect(() => {
    if (data?.data) {
      setVenueID(data.data.venue_id);
      setPincode(data.data.pincode);
    }
  }, [data?.data]);

  useEffect(() => {
    const featureQR = savedUser?.features.includes("qrmobile");
    const featureTablet = savedUser?.features.includes("tablet");

    if (!featureQR && featureTablet) {
      setTab("tablet");
    }
  }, [savedUser]);

  return (
    <div className="flex flex-col items-center gap-4  inset-0 relative bg-white rounded-lg m-2 px-2">
      <div className="flex flex-col md:flex-row justify-between gap-4 w-full p-4 border-b border-line-gray">
        <p className="text-2xl m-0">Connect Your Device</p>

        {(savedUser?.features ?? []).length > 1 && (
          <div className="flex rounded border border-line-gray overflow-hidden">
            <button
              type="button"
              title="Tablet"
              onClick={() => setTab("tablet")}
              className={
                `px-4 py-2 text-xs flex-1 disabled:opacity-25 ${
                  tab === "tablet" ? "bg-primary text-white" : "bg-transparent"}`
              }
            >
              Tablet
            </button>
            <button
              title="Mobile"
              type="button"
              onClick={() => setTab("mobile")}
              className={
                `px-4 py-2 text-xs flex-1 disabled:opacity-25 ${
                  tab === "mobile" ? "bg-primary text-white" : "bg-transparent"}`
              }
            >
              Mobile
            </button>
          </div>
        )}
      </div>

      {tab === "tablet" && (
        <div className="flex-1 p-4 w-full items-center justify-center">
          {isLoading ? (
            <LoadingAnimation />
          ) : (
            <div className="h-full md:h-64 py-4">
              <p className="font-medium opacity-70">Venue ID</p>
              <div className="gap-4 flex justify-between items-center">
                <input
                  type="text"
                  minLength={9}
                  className="input-base"
                  onChange={(e) => setVenueID(e.target.value)}
                  value={venueID}
                />
                <CopyIcon className="h-6 w-6 text-secondary cursor-pointer" onClick={() => handleCopy("VenueID")} />
              </div>
              <div className="mb-3 mt-1 text-error text-xs">{venueIDError}</div>
              <p className="font-medium opacity-70">PIN Code</p>
              <div className="gap-4 flex justify-between items-center">
                <input
                  type="text"
                  maxLength={4}
                  onKeyPress={handleKeyPressPincode}
                  className="input-base"
                  onChange={(e) => setPincode(e.target.value)}
                  value={pincode}
                />
                <CopyIcon className="h-6 w-6 text-secondary cursor-pointer" onClick={() => handleCopy("PIN")} />
              </div>
            </div>
          )}
        </div>
      )}

      {tab === "mobile" && (
        <div className="flex-1 text-center py-4 flex flex-col items-center gap-4 w-full">
          {qrLinkLoading ? (
            <div className="h-full md:h-64 w-full md:w-64 flex items-center justify-center">
              <LoadingAnimation />
            </div>
          ) : (
            <QRCode id="qrcode-web" value={qrLink ?? ""} fgColor="#0078D3" />
          )}
          <div className="flex items-center justify-between w-full gap-2 px-6">
            <a
              href={qrLink ?? ""}
              target="_blank"
              className="text-active font-bold text-truncate"
              rel="noreferrer"
              title={qrLink || process.env.REACT_APP_QR_URL}
            >
              {qrLink || process.env.REACT_APP_QR_URL}
            </a>
            <CopyIcon
              className="h-6 w-6 text-secondary cursor-pointer"
              onClick={() => handleCopy("QRLink")}
              title="Copy QR Link"
            />
          </div>
        </div>
      )}

      <div className="p-4 border-t border-line-gray flex justify-between w-full">
        <button
          className="button-gray py-2 px-4 w-full md:w-auto mr-2"
          onClick={closePopUp}
          title="Cancel"
          type="button"
        >
          Cancel
        </button>

        <button
          type="button"
          title={isLoadingUpdateVenue ? "Loading..." : "Save"}
          className="button-primary py-2 px-6 w-full md:w-auto ml-2"
          onClick={handleSave}
          disabled={isLoadingUpdateVenue}
        >
          {isLoadingUpdateVenue ? <LoadingAnimation size={4} /> : "Save"}
        </button>
      </div>
    </div>
  );
}

export default QRScan;
