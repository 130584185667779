import { createApi } from "@reduxjs/toolkit/dist/query/react";

import baseQuery from "./base-service";

export type TableModel = {
  id: string;
  name: string;
}

export type AddTableRequest = Pick<TableModel, "name">;

export const tableServices = createApi({
  reducerPath: "table",
  baseQuery,
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  endpoints: (builder) => ({
    getAllTable: builder.query<HttpResponse<TableModel[]>, void>({
      query: () => ({
        url: "/table",
        method: "GET",
      }),
    }),
    createTable: builder.mutation<HttpResponse<{}>, AddTableRequest>({
      query: (body) => ({
        url: "/table",
        method: "POST",
        body,
      }),
    }),
    updateTable: builder.mutation<HttpResponse<{}>, TableModel>({
      query: (body) => ({
        url: "/table",
        method: "PUT",
        body,
      }),
    }),
    deleteTable: builder.mutation<HttpResponse<{}>, string>({
      query: (id) => ({
        url: "/table",
        method: "DELETE",
        body: {
          id,
        },
      }),
    }),
    deleteMultipleTable: builder.mutation<HttpResponse<{}>, { id_array: string[] }>({
      query: (body) => ({
        url: "/table/multiple",
        method: "DELETE",
        body,
      }),
    }),
  }),
});

export const {
  useGetAllTableQuery,
  useCreateTableMutation,
  useUpdateTableMutation,
  useDeleteTableMutation,
  useDeleteMultipleTableMutation,
} = tableServices;
