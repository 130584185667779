import { utcToZonedTime, format } from "date-fns-tz";
import { useEffect, useState } from "react";

import Popup from "reactjs-popup";
import { toast } from "react-toastify";
import clsx from "clsx";
import { FeedbackResultModel, useGetFeedbackResultQuery, useToggleFeedbackOpenMutation } from "../../services";
import { getBreakpointValue } from "../../utils";

interface FeedbackResultListProps {
  data: FeedbackResultModel[];
  loadingBarRef?: any;
  refetchFeedbackResult?: () => void;
  showSeparator?: boolean;
  checkbox?: {
    showCheckbox: boolean;
    onClickSelectAll: () => void;
    onClickCheck: (id: string) => void;
    isAllChecked: boolean;
    checklist: string[];
  };
}

interface DetailResult {
  isOpen: boolean;
  id?: string;
}

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
const parseDateUTC = (date: string) => utcToZonedTime(date, timeZone);

function FeedbackResultList({
  data: feedbackResult, loadingBarRef, refetchFeedbackResult, checkbox, showSeparator,
}: FeedbackResultListProps) {
  const [openDetail, setOpenDetail] = useState<DetailResult>({
    isOpen: false,
  });

  const { data: detailFeedback, isLoading: isLoadingDetailFeedback } = useGetFeedbackResultQuery(openDetail?.id, {
    skip: typeof openDetail?.id === "undefined",
  });
  const [toggleOpen, { isLoading: isLoadingUpdateFeedbackStatus }] = useToggleFeedbackOpenMutation();

  const isMobile = getBreakpointValue("md") > window.innerWidth || getBreakpointValue("md") > window.screen.width;

  const transformAnswerEmoticon = (id: string): string => {
    if (id === "0") return "🙁";
    if (id === "1") return "😐";
    if (id === "2") return "🥰";
    return "";
  };

  const handleToggleOpen = (id?: string) => {
    if (id) {
      toggleOpen({ id })
        .unwrap()
        .then(() => {
          toast.success("Toggle status success");
          refetchFeedbackResult?.();
        })
        .catch((err) => {
          toast.error(err.message || "Toggle status failed");
        });
    }
  };

  useEffect(() => {
    if (isLoadingDetailFeedback) {
      loadingBarRef?.continuousStart();
    } else {
      loadingBarRef?.complete();
    }
  }, [isLoadingDetailFeedback]);

  return (
    <>
      <div className={clsx("hidden md:flex w-full p-4 bg-gray-100 font-medium items-center", showSeparator && "border-b")}>
        {checkbox?.showCheckbox && (
          <div className="pr-4">
            <label className="flex items-center space-x-3">
              <input
                type="checkbox"
                className="form-tick appearance-none bg-white bg-check h-4 w-4 border border-gray-300 rounded checked:bg-primary checked:border-transparent focus:outline-none cursor-pointer"
                onChange={checkbox?.onClickSelectAll}
                checked={checkbox?.isAllChecked}
              />
            </label>
          </div>
        )}

        <div className="flex flex-1 flex-col-reverse md:flex-row">
          <p className={clsx("md:w-1/2", checkbox?.showCheckbox && "md:pl-4 border-l")}>Date</p>
          <div className="flex md:w-1/2 flex-row-reverse md:flex-row">
            <p className="md:pl-4 flex-1 border-l">Form</p>
            <p className="md:pl-4 md:flex-1 border-l">Status</p>
          </div>
        </div>
        <p className="md:pl-4 border-l w-32">Action</p>
      </div>

      {/* eslint-disable-next-line react/destructuring-assignment */}
      {feedbackResult.length > 0 ? feedbackResult.map((data) => {
        const { isOpen } = data;
        let statusStyle = "bg-green-100 text-success";
        let actionStyle = "bg-error";
        if (isOpen === false) {
          statusStyle = "bg-red-100 text-error";
          actionStyle = "bg-success";
        }

        const isChecked = !!(checkbox?.checklist || []).find((checkedID) => checkedID === data.id);

        return (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <div
            key={data.id}
            className="flex w-full text-sm p-4 md:border-b md:border-gray-100 rounded-md md:rounded-none shadow-lg md:shadow-none mb-3 md:mb-0 items-center"
            onClick={() => {
              if (isMobile) {
                setOpenDetail({ isOpen: true, id: data.id });
              }
            }}
          >
            {checkbox?.showCheckbox && (
              <div className="pr-4 hidden md:block">
                <label className="flex items-center space-x-3">
                  <input
                    key={isChecked ? "1" : "0"} // hack to force update
                    type="checkbox"
                    name="checked-feedbacks"
                    className="form-tick appearance-none bg-white bg-check h-4 w-4 border border-gray-300 rounded checked:bg-primary checked:border-transparent focus:outline-none cursor-pointer"
                    onChange={() => data.id && checkbox?.onClickCheck(data.id)}
                    checked={isChecked}
                  />
                </label>
              </div>
            )}

            <div className="flex flex-1 flex-col-reverse md:flex-row">
              <p className={clsx("md:w-1/2 mt-2 md:mt-0", checkbox?.showCheckbox && "md:pl-4")}>
                {data.date ? format(parseDateUTC(data.date), "ccc, dd MMM yyyy - kk:mm", { timeZone }) : "-"}
              </p>
              <div className="flex md:w-1/2 flex-row-reverse md:flex-row">
                <p className="pl-4 flex-1 font-medium md:font-normal">{data.form_name}</p>
                <div className="md:pl-4 md:flex-1">
                  <span className={`px-2 py-1 rounded text-xs ${statusStyle}`}>
                    {data.isOpen ? "Open" : "Closed"}
                  </span>
                </div>
              </div>
            </div>

            <div className="md:pl-4 md:w-32 flex justify-between items-center">
              <button
                type="button"
                title="View"
                className="hidden md:block button-link text-sm font-light"
                onClick={() => setOpenDetail({ isOpen: true, id: data.id })}
              >
                View
              </button>

              <button
                type="button"
                title={data.isOpen ? "Close" : "Open"}
                className={`px-3 py-1 rounded-md text-sm text-white disabled:opacity-50 ${actionStyle}`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleToggleOpen(data.id);
                }}
                disabled={isLoadingUpdateFeedbackStatus}
              >
                {data.isOpen ? "Close" : "Open"}
              </button>
            </div>
          </div>
        );
      }) : (
        <div className="w-full p-4">
          <span>No Feedback is Found</span>
        </div>
      )}

      {/* Modal Feedback result detail */}
      <Popup
        modal
        open={openDetail.isOpen}
        onClose={() => setOpenDetail({ isOpen: false, id: undefined })}
        closeOnDocumentClick={!isMobile}
        closeOnEscape
        lockScroll
        contentStyle={{
          maxHeight: isMobile ? "100%" : "80%",
          height: isMobile ? "100%" : "auto",
          maxWidth: isMobile ? "100%" : 600,
          width: "100%",
          overflowY: "auto",
          backgroundColor: "white",
          borderRadius: isMobile ? 0 : 8,
        }}
      >
        <div className="pb-4">
          <div className="flex justify-end">
            <button
              type="button"
              title="Close"
              className="h-6 w-6 text-xl md:text-2xl mr-4 md:mr-0"
              onClick={() => setOpenDetail({ isOpen: false, id: undefined })}
            >
              &times;
            </button>
          </div>

          <div className="flex justify-between items-center mx-4 mb-1">
            <p className="flex flex-col md:flex-row gap-2 md:items-center">
              <b className="text-2xl">{detailFeedback?.data?.form_name ?? "-"}</b>
              <span className="hidden md:block">-</span>
              <span className="text-base mmd:text-2xl">
                Feedback
                {detailFeedback?.data?.feedback_number ?? "-"}
              </span>
            </p>
            <p
              className={`px-2 py-1 rounded text-xs ${
                detailFeedback?.data?.isOpen ? "bg-green-100 text-success" : "bg-red-100 text-error"
              } md:mr-8`}
            >
              {detailFeedback?.data?.isOpen ? "Open" : "Closed"}
            </p>
          </div>

          <p className="mx-4 mb-6 text-xs md:text-base text-secondary">
            {detailFeedback?.data?.date
              ? format(parseDateUTC(detailFeedback.data.date), "EEE, dd MMM yyyy - HH:mm", { timeZone })
              : ""}
          </p>

          <div className="flex flex-col border border-line-gray rounded-md mx-4 shadow">
            {(detailFeedback?.data?.records ?? []).map((rec, index) => {
              const key = `${index}`;

              return (
                <div className="border-b border-line-gray p-4 flex" key={key}>
                  <p className="w-7/12">{rec.question}</p>
                  {rec.answer_type === "smiley" && <p className="w-5/12">{transformAnswerEmoticon(rec.answer)}</p>}
                  {rec.answer_type === "rating" && (
                    <div className="flex items-center w-5/12">
                      <p className="text-star">
                        {Array(+rec.answer)
                          .fill("★")
                          .join("")}
                      </p>
                      <p className="text-placeholder">
                        {Array(5 - +rec.answer)
                          .fill("★")
                          .join("")}
                      </p>
                    </div>
                  )}
                  {rec.answer_type === "yesno" && <p className="w-5/12 font-normal">{rec.answer === "1" ? "Yes" : "No"}</p>}
                  {rec.answer_type === "text" && <p className="w-5/12 break-all">{rec.answer}</p>}
                </div>
              );
            })}
          </div>
        </div>
      </Popup>
    </>
  );
}

export default FeedbackResultList;
