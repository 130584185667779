import Popup from "reactjs-popup";

import { ReactComponent as SpinnerIcon } from "../assets/icons/ic_spinner.svg";

interface Props {
  isOpen: boolean;
  content: string;
  onCloseModal: (
    event?:
      | React.SyntheticEvent<Element, Event>
      | KeyboardEvent
      | TouchEvent
      | MouseEvent
      | undefined
  ) => void;
  onConfirmModal: () => void;
  confirmationText?: string;
  confirmationLoading?: boolean;
}

function ModalConfirmation({
  isOpen,
  content,
  onCloseModal,
  onConfirmModal,
  confirmationText,
  confirmationLoading,
}: Props) {
  return (
    <Popup
      open={isOpen}
      contentStyle={{
        borderRadius: 8,
        padding: "1rem",
        border: 0,
        maxWidth: "400px",
        background: "white",
      }}
      closeOnEscape
      onClose={onCloseModal}
    >
      <div className="modal">
        <div className="flex flex-col">
          <p className="my-4 text-center text-lg">{content}</p>
          <div className="flex flex-col-reverse md:flex-row justify-center gap-4 mt-2 md:mx-8">
            <button
              onClick={onCloseModal}
              className="button-secondary py-2 px-4 flex-1"
              type="button"
              title="Cancel"
            >
              Cancel

            </button>

            <button
              type="button"
              title={confirmationLoading ? "Loading..." : confirmationText || "Ok"}
              onClick={onConfirmModal}
              className="button-primary py-2 px-4 flex-1 text-center"
              disabled={confirmationLoading}
            >
              {confirmationLoading ? (
                <SpinnerIcon className="animate-spin h-4 w-4 mx-auto" />
              ) : confirmationText ?? "Ok"}
            </button>
          </div>
        </div>
      </div>
    </Popup>
  );
}

export default ModalConfirmation;
