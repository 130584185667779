import clsx from "clsx";
import {
  useState, type Dispatch, type SetStateAction,
} from "react";
import Popup from "reactjs-popup";
import type { PopupProps } from "reactjs-popup/dist/types";

import { ORDER_STATUS_LIST_TEXT, ORDER_TYPE_LIST_TEXT } from "../../constants";

export type OrderFilterState = {
  order_types: string[];
  status_types: string[];
};

interface OrderFilterProps {
  order_types: string[];
  status_types: string[];
  triggers?: PopupProps["trigger"];
  setOrderFilter: Dispatch<SetStateAction<OrderFilterState>>;
}

function OrderFilter({
  order_types, status_types, triggers, setOrderFilter,
}: OrderFilterProps) {
  const [filter, setFilter] = useState<OrderFilterState>({
    order_types: [],
    status_types: [],
  });

  const handleSelectStatusTypes = (id: string) => {
    const isAlreadySelected = filter.status_types.indexOf(id) !== -1;

    setFilter((state) => ({
      ...state,
      status_types: isAlreadySelected
        ? state.status_types.filter((statusId) => statusId !== id)
        : [
          ...state.status_types,
          id,
        ],
    }));
  };

  const handleSelectOrderTypes = (id: string) => {
    const isAlreadySelected = filter.order_types.indexOf(id) !== -1;

    setFilter((state) => ({
      ...state,
      order_types: isAlreadySelected
        ? state.order_types.filter((orderId) => orderId !== id)
        : [
          ...state.order_types,
          id,
        ],
    }));
  };

  return (
    <Popup
      position="bottom left"
      arrow
      closeOnDocumentClick
      closeOnEscape
      arrowStyle={{
        transform: "translateX(25px)",
        left: 0,
        filter: "none",
        borderColor: "#EDEBE9",
      }}
      contentStyle={{
        width: "auto",
        maxWidth: 238,
        background: "white",
        border: "1px solid #EDEBE9",
        boxShadow: "none",
      }}
      trigger={triggers}
      onClose={() => {
        setOrderFilter(filter);
      }}
    >
      <div className={clsx("p-2 md:p-4")}>
        <p className="text-xs font-medium uppercase mb-2">Order status :</p>

        <ul className={clsx("mb-4 py-2", "border-b")}>
          {status_types.map((id) => {
            const isSelected = filter.status_types.indexOf(id) !== -1;
            const target = `filter_${id}`;
            const text = ORDER_STATUS_LIST_TEXT[id as keyof typeof ORDER_STATUS_LIST_TEXT];

            return (
              <li key={id} className={clsx("mb-2", "text-sm")}>
                <label htmlFor={target} className={clsx("flex items-center justify-between gap-4", "cursor-pointer")}>
                  {text}

                  <input type="checkbox" name={id} id={target} checked={isSelected} onChange={() => handleSelectStatusTypes(id)} className="input-radio hidden" />

                  <div className={clsx("input-radio-icon")} />
                </label>
              </li>
            );
          })}
        </ul>

        <p className="text-xs font-medium uppercase mb-3">Order type :</p>

        <ul className="pt-2">
          {order_types.map((id) => {
            const isSelected = filter.order_types.indexOf(id) !== -1;
            const target = `filter_${id}`;
            const text = ORDER_TYPE_LIST_TEXT[id as keyof typeof ORDER_TYPE_LIST_TEXT];

            return (
              <li key={id} className={clsx("mb-2 last:mb-0", "text-sm")}>
                <label htmlFor={target} className={clsx("flex items-center justify-between gap-4", "cursor-pointer")}>
                  {text}

                  <input type="checkbox" name={id} id={target} checked={isSelected} onChange={() => handleSelectOrderTypes(id)} className="input-radio hidden" />

                  <div className={clsx("input-radio-icon")} />
                </label>
              </li>
            );
          })}
        </ul>
      </div>
    </Popup>
  );
}

export default OrderFilter;
