import clsx from "clsx";
import { ChangeEvent } from "react";

type SwitchPropTypes = {
  id: string;
  checked?: boolean;
  disabled?: boolean;
  name?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  title?: string;
  className?: string;
}

function Switch({
  id,
  checked,
  disabled,
  name,
  onChange,
  title,
  className,
}: SwitchPropTypes) {
  return (
    <div className={`relative w-8 align-middle text-center select-none inline-block ${className}`}>
      <input
        type="checkbox"
        id={id}
        name={name}
        title={title}
        className={clsx(
          "toggle-checkbox",
          "absolute block appearance-none cursor-pointer",
          "transition-all duration-300 ease-in",
          "w-4 h-4",
          "rounded-full border-2",
          "bg-white",
        )}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />

      <label
        htmlFor={id}
        className={clsx(
          "toggle-label",
          "block overflow-hidden cursor-pointer",
          "transition-all duration-300 ease-in",
          "h-4",
          "bg-gray-300",
          "rounded-full",
        )}
      />
    </div>
  );
}

export default Switch;
