import { useState, useRef, useEffect } from "react";
import Popup from "reactjs-popup";
import LoadingBar from "react-top-loading-bar";
import { toast } from "react-toastify";
// import { deleteToken } from "firebase/messaging";

import { ReactComponent as LockIcon } from "../../assets/icons/ic_lock_dot.svg";
import { ReactComponent as EyeIcon } from "../../assets/icons/ic_eye.svg";

import { useAppDispatch } from "../../app/hooks";
// import { LocalStorage } from "../utils";
import { logout } from "../../features/auth";
import {
  useSettingAccountQuery,
  useUpdateSettingAccountMutation,
  useUpdatePasswordMutation,
  // useCountryListQuery,
} from "../../services";
// import { messaging } from "../../features/notification";

interface Props {
  saveSetting: boolean;
  setSaveSetting: (arg0: boolean) => void;
}

interface Password {
  [key: string]: string;
  current: string;
  new: string;
  repeat: string;
}

function SettingAccount({ saveSetting, setSaveSetting }: Props) {
  const { data: dataAccount, isLoading: isLoadingDataAccount } = useSettingAccountQuery();
  // const { data: countries, isLoading: isLoadingCountries } = useCountryListQuery();
  const [updateSettingAccount, { isLoading: isLoadingUpdateSettingAccount }] = useUpdateSettingAccountMutation();
  const [updatePassword, { isLoading: isLoadingUpdatePassword }] = useUpdatePasswordMutation();

  const [phoneCode, setPhoneCode] = useState<string>("");
  const [openChangePassword, setOpenChangePassword] = useState<boolean>(false);
  const [password, setPassword] = useState<Password>({
    current: "",
    new: "",
    repeat: "",
  });
  const [isShow, toggleShow] = useState<boolean[]>([false, false, false]);

  const [fullname, setFullname] = useState<string>("");
  const [phone, setPhone] = useState<string>("");

  const dispatch = useAppDispatch();
  const ref = useRef(null);

  const setPhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    const validatePhone = /.*[0-9\d]$/;

    if (validatePhone.test(value)) {
      setPhone(value);
    }
  };

  /**
   * Function to handle toggle show password
   * @param {number} index
   */
  const handleToggleShow = (index: number) => {
    toggleShow((prevState) => prevState.map((show, currentIndex) => {
      if (currentIndex === index) return !show;
      return show;
    }));
  };

  const handlePasswordChange = (event: InputChangeType) => {
    setPassword((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSavePassword = () => {
    if (password.new !== password.repeat) {
      toast.error("New password and repeated password doesn't match!");
      return;
    }

    updatePassword({ current_pass: password.current, new_pass: password.new })
      .unwrap()
      .then((res) => {
        if (res.status === "Success") {
          setOpenChangePassword(false);
          toast.success("Successfully update password");
        }
      })
      .catch((err) => {
        toast.error(err.data.message ?? err.message ?? "Failed to Update Password");
      });
  };

  useEffect(() => {
    if (!dataAccount?.data) return;

    if (saveSetting) {
      updateSettingAccount({
        fullname,
        phone_code: dataAccount.data.phone_code,
        phone_num: phone,
        country_code: dataAccount.data.country_code,
      })
        .unwrap()
        .then((res) => {
          if (res.status === "Success") toast.success("Successfully update account settings");
        })
        .catch((err) => toast.error(err.message))
        .finally(() => setSaveSetting(false));
    }
  }, [saveSetting]);

  useEffect(() => {
    if (dataAccount?.data) {
      const phoneNum = dataAccount?.data?.phone_num.replace(`+${dataAccount.data.phone_code}`, "");

      setFullname(dataAccount.data.fullname);
      setPhone(phoneNum);
      setPhoneCode(dataAccount.data.phone_code);
    }
  }, [dataAccount?.data]);

  useEffect(() => {
    if (!openChangePassword) {
      setPassword({
        current: "",
        new: "",
        repeat: "",
      });
    }
  }, [openChangePassword]);

  useEffect(() => {
    const progress = ref.current as any;
    if (isLoadingDataAccount || isLoadingUpdateSettingAccount || isLoadingUpdatePassword) {
      progress?.continuousStart();
    } else {
      progress?.complete();
    }
  }, [isLoadingDataAccount, isLoadingUpdateSettingAccount, isLoadingUpdatePassword]);

  // useEffect(() => {
  //   if (!countries?.data || !dataAccount?.data) return;

  //   const getCountry = countries.data.find((item) => item.code === dataAccount?.data?.country_code);

  //   if (getCountry) {
  //     setPhoneCode(getCountry.phonecode);
  //     LocalStorage.setValue("phoneCode", getCountry.phonecode);
  //   }
  // }, [countries?.data, dataAccount?.data]);

  return (
    <>
      <LoadingBar height={4} color="#0078D3" ref={ref} />

      <div className="w-full">
        <div className="flex flex-col lg:flex-row gap-4 p-6 md:pt-0 w-full">
          <div className="flex flex-col flex-1 gap-4">
            <div className="max-w-sm md:max-w-none lg:max-w-sm">
              <label htmlFor="name">Full Name</label>
              <input
                type="text"
                id="name"
                className="input-base mt-1"
                value={fullname}
                onChange={(e) => setFullname(e.target.value)}
              />
            </div>

            <div className="max-w-sm md:max-w-none lg:max-w-sm">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                id="email"
                className="input-base mt-1"
                value={dataAccount?.data?.email ?? ""}
                readOnly
                disabled
              />
            </div>
          </div>

          <div className="flex flex-col flex-1 gap-4">
            <div className="max-w-sm md:max-w-none lg:max-w-sm">
              <label htmlFor="phone">Phone</label>
              <div className="flex items-center relative">
                <span className="absolute left-2 text-sm mt-[0.2rem] font-semibold">{`+${phoneCode || 0}`}</span>
                <input
                  type="number"
                  id="phone"
                  placeholder="123456789"
                  className="input-base mt-1"
                  style={{
                    paddingLeft: `${
                      phoneCode
                        ? phoneCode.length === 1
                          ? 1.75
                          : phoneCode.length === 2
                            ? 2.5
                            : phoneCode.length
                        : 1.75
                    }rem`,
                  }}
                  defaultValue={phone}
                  onChange={(e) => setPhoneNumber(e)}
                />
              </div>
            </div>
            <div className="max-w-sm md:max-w-none lg:max-w-sm">
              <label htmlFor="country">Country</label>
              <input
                type="text"
                id="country"
                className="input-base mt-1"
                value={dataAccount?.data?.country_name ?? ""}
                readOnly
                disabled
              />
            </div>
          </div>
        </div>
        <div className="flex mt-4 gap-4 p-6 border-t border-line-gray w-full md:w-auto">
          <button
            type="button"
            title="Change Password"
            className="button-secondary px-4 py-2 text-sm border-line-gray rounded-md flex-1 md:flex-none flex items-center gap-1"
            onClick={() => setOpenChangePassword(true)}
          >
            <LockIcon className="h-4 w-4" />
            Change Password
          </button>

          <button
            type="button"
            title="Logout"
            className="button-secondary px-4 py-2 text-sm border-line-gray rounded-md flex-1 md:flex-none"
            onClick={() => {
              dispatch(logout());

              // deleteToken(messaging).then(() => {
              //   window.location.reload();
              //   dispatch(logout());
              // });
            }}
          >
            Logout
          </button>
        </div>
      </div>

      <Popup
        modal
        lockScroll
        closeOnDocumentClick
        open={openChangePassword}
        onClose={() => setOpenChangePassword(false)}
      >
        <div className="flex flex-col gap-3 p-8 font-montserrat bg-white md:rounded-lg fixed inset-0 md:relative">
          <h3 className="text-2xl text-center">Change Your Password</h3>
          <p className="text-center opacity-75 mb-4">Enter your new password.</p>

          {isShow.map((show, index) => {
            let title = "Current Password";
            let key = "current";

            if (index === 1) {
              title = "New Password";
              key = "new";
            }
            if (index === 2) {
              title = "Repeat Password";
              key = "repeat";
            }

            return (
              <div className="flex flex-col gap-1 mt-3" key={key}>
                <label htmlFor={title} className="text-sm text-secondary">
                  {title}
                </label>
                <div className="flex items-center gap-4 border border-line-gray rounded">
                  <input
                    type={show ? "text" : "password"}
                    id={title}
                    className="input-base border-0 focus:ring-0"
                    name={key}
                    onChange={handlePasswordChange}
                    value={password[key]}
                  />
                  <EyeIcon
                    className={`h-4 w-4 mr-4 cursor-pointer ${show ? "opacity-100" : "op opacity-50"}`}
                    onClick={() => handleToggleShow(index)}
                  />
                </div>
              </div>
            );
          })}

          <div className="flex justify-center gap-4 mt-4">
            <button className="button-primary py-2" onClick={handleSavePassword} title="Save" type="button">
              Save
            </button>

            <button
              title="Cancel"
              type="button"
              className="button-gray py-2"
              onClick={() => setOpenChangePassword(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </Popup>
    </>
  );
}

export default SettingAccount;
