import { useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { ReactComponent as SpinnerIcon } from "../assets/icons/ic_spinner.svg";
import { ReactComponent as LogoIcon } from "../assets/logo.svg";
import { ReactComponent as Illustration } from "../assets/illustration_email_verification.svg";
import { ReactComponent as LockIcon } from "../assets/icons/ic_lock.svg";
import { ReactComponent as EyeIcon } from "../assets/icons/ic_eye.svg";
import { useResetPasswordMutation } from "../services";

import { InputWithIcon } from "../components/atoms";

export interface ResetPasswordForm {
  password: string;
  confirmPassword: string;
}

const eyeIconClasss = (isShow: boolean) => {
  const passType = "w-5 h-5 ml-4 opacity-30";
  const textType = "w-5 h-5 ml-4 opacity-100";
  return isShow ? textType : passType;
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ResetPasswordPage() {
  const { push } = useHistory();
  const query = useQuery();
  const { control, handleSubmit } = useForm<ResetPasswordForm>({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });
  const [isShowPassword, setShowPassword] = useState<boolean[]>([false, false]);
  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  /**
   * @description Function to hanlde reset password
   * @param data<ResetPasswordForm>
   */
  const onNext: SubmitHandler<ResetPasswordForm> = (data) => {
    const uid = query.get("id");
    if (uid === null) return;

    if (data.password !== data.confirmPassword) {
      toast.error("Confirm password did not match!");
      return;
    }

    resetPassword({ uid, password: data.password })
      .unwrap()
      .then((res) => {
        toast.success(res.message);
        setTimeout(() => {
          push("/login");
        }, 1000);
      })
      .catch((error) => {
        toast.error(error.data?.message ?? "Failed to reset password");
      });
  };

  /**
   * @description Function to handle change form
   * @param index<number>
   */
  const handleInputPasswordType = (index: number) => {
    const passwords = isShowPassword.map((flag, currentIndex) => {
      if (currentIndex === index) return !flag;
      return flag;
    });
    setShowPassword(passwords);
  };

  return (
    <div className="relative w-full max-w-3xl pt-32 mx-auto">
      <Illustration className="absolute inset-1 hidden md:block" />

      <div className="font-montserrat relative bg-white rounded text-center w-full md:max-w-xl md:shadow-lg p-6 md:py-5 md:px-8 md:mx-auto">
        <LogoIcon className="w-11 h-11 mb-5 mx-auto" />
        <p className="font-bold text-lg mb-1">Forgot Password? 🔑</p>
        <p className="text-secondary text-xs mb-5">Enter your email address below and we&#39;ll get you back on track.</p>

        <form>
          <Controller
            control={control}
            name="password"
            render={({
              field: {
                onChange, value, name, ref,
              },
            }) => (
              <InputWithIcon
                leftIcon={<LockIcon className="w-5 h-5 mr-4" />}
                rightIcon={
                  <EyeIcon className={eyeIconClasss(isShowPassword[0])} onClick={() => handleInputPasswordType(0)} />
                }
                title="New Password"
                placeholder="***********"
                name={name}
                onChange={onChange}
                value={value}
                inputRef={ref({ required: true })}
                type={isShowPassword[0] ? "text" : "password"}
              />
            )}
          />

          <Controller
            control={control}
            name="confirmPassword"
            render={({
              field: {
                onChange, value, name, ref,
              },
            }) => (
              <InputWithIcon
                leftIcon={<LockIcon className="w-5 h-5 mr-4" />}
                rightIcon={
                  <EyeIcon className={eyeIconClasss(isShowPassword[1])} onClick={() => handleInputPasswordType(1)} />
                }
                title="Repeat New Password"
                placeholder="***********"
                name={name}
                onChange={onChange}
                value={value}
                inputRef={ref({ required: true })}
                type={isShowPassword[1] ? "text" : "password"}
              />
            )}
          />

          <button
            type="button"
            title={isLoading ? "Loading..." : "Reset Password"}
            disabled={isLoading}
            className="button-primary w-11/12 md:w-1/2 flex justify-self-start justify-center rounded font-normal text-sm fixed md:relative bottom-4 md:bottom-0 inset-x-4 md:inset-x-0 md:mt-14"
            onClick={handleSubmit(onNext)}
          >
            {isLoading ? <SpinnerIcon className="animate-spin h-4 w-4 mx-auto" /> : "Reset Password"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default ResetPasswordPage;
