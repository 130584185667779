import { memo } from "react";

import { ReactComponent as AcceptedIcon } from "../../assets/icons/order-status/ic_accepted.svg";
import { ReactComponent as CancelledIcon } from "../../assets/icons/order-status/ic_canceled.svg";
import { ReactComponent as ClosedIcon } from "../../assets/icons/order-status/ic_closed.svg";
import { ReactComponent as DeliveredIcon } from "../../assets/icons/order-status/ic_delivered.svg";
import { ReactComponent as OnDeliveryIcon } from "../../assets/icons/order-status/ic_on_delivery.svg";
import { ReactComponent as PreparingIcon } from "../../assets/icons/order-status/ic_preparing.svg";
import { ReactComponent as ReadyIcon } from "../../assets/icons/order-status/ic_ready.svg";
import { ReactComponent as WaitingIcon } from "../../assets/icons/order-status/ic_waiting.svg";

interface OrderStatusIconTypes extends React.SVGProps<SVGSVGElement> {
  id: string;
}

function OrderStatusIcon({ id, ...props }: OrderStatusIconTypes) {
  switch (id) {
  case "stat_preparing":
    return <PreparingIcon {...props} />;

  case "stat_waiting":
    return <WaitingIcon {...props} />;

  case "stat_ondelivery":
    return <OnDeliveryIcon {...props} />;

  case "stat_cancelled":
    return <CancelledIcon {...props} />;

  case "stat_accepted":
    return <AcceptedIcon {...props} />;

  case "stat_ready":
    return <ReadyIcon {...props} />;

  case "stat_delivered":
    return <DeliveredIcon {...props} />;

  case "stat_closed":
    return <ClosedIcon {...props} />;

  default:
    return null;
  }
}

export default memo(OrderStatusIcon);
