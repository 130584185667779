import clsx from "clsx";
import { memo } from "react";

import { ReactComponent as TotalCategoriesIcon } from "../assets/icons/reports-icons/total_categories.svg";
import { ReactComponent as TotalItemIcon } from "../assets/icons/reports-icons/total_items.svg";
import { ReactComponent as TotalOrdersIcon } from "../assets/icons/reports-icons/total_orders.svg";
import { ReactComponent as TotalRevenueIcon } from "../assets/icons/reports-icons/total_revenue.svg";
import { Formatter } from "../utils";

interface ReportCounterProps {
  categoryTotal: number;
  itemTotal: number;
  orderTotal: number;
  revenueTotal: number | string;
  className?: string;
}

function ReportCounter({
  categoryTotal = 0, itemTotal = 0, orderTotal = 0, revenueTotal = "0.00", className,
}: ReportCounterProps) {
  return (
    <div className={clsx("p-6", "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3", "w-full", className)}>
      <div className={clsx("border rounded-xl", "p-6", "flex flex-row items-center gap-4")}>
        <div>
          <TotalCategoriesIcon className="w-[45px] h-[45px]" />
        </div>
        <div>
          <p className="font-bold font-inter">
            {categoryTotal}
            {" "}
            categories
          </p>
          <small className="text-sm" style={{ color: "#8083A3" }}>Total categories</small>
        </div>
      </div>

      <div className={clsx("border rounded-xl", "p-6", "flex flex-row items-center gap-4")}>
        <div>
          <TotalItemIcon className="w-[45px] h-[45px]" />
        </div>
        <div>
          <p className="font-bold font-inter">
            {itemTotal}
            {" "}
            items
          </p>
          <small className="text-sm" style={{ color: "#8083A3" }}>Total items</small>
        </div>
      </div>

      <div className={clsx("border rounded-xl", "p-6", "flex flex-row items-center gap-4")}>
        <div>
          <TotalOrdersIcon className="w-[45px] h-[45px]" />
        </div>
        <div>
          <p className="font-bold font-inter">
            {orderTotal}
            {" "}
            orders
          </p>
          <small className="text-sm" style={{ color: "#8083A3" }}>Total orders</small>
        </div>
      </div>

      <div className={clsx("border rounded-xl", "p-6", "flex flex-row items-center gap-4")}>
        <div>
          <TotalRevenueIcon className="w-[45px] h-[45px]" />
        </div>
        <div>
          <p className="font-bold font-inter">
            USD
            {" "}
            {/* {revenueTotal === 0 ? "0.00" : Formatter.lastTwoDot(revenueTotal.toString())} */}
            {/* {Formatter.currency(revenueTotal as number).replace("$", "")} */}
            {revenueTotal}
          </p>
          <small className="text-sm" style={{ color: "#8083A3" }}>Total revenue</small>
        </div>
      </div>
    </div>
  );
}

export default memo(ReportCounter);
