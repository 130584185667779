import { useEffect, useState } from "react";

/**
 * @description Hook to debounce a function
 * @param value<T>
 * @param delay<number>
 * @returns debouncedValue<T>
 */
function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

export default useDebounce;
