/* eslint-disable import/no-extraneous-dependencies */
import defaultTheme from "tailwindcss/defaultTheme";

interface KeyString {
  [key: string]: string;
}

export const getBreakpointValue = (value: string): number => {
  const { screens } = defaultTheme;
  const screensAny = screens as KeyString;

  if (!screensAny) {
    return 0;
  }

  return parseInt(screensAny[value].replace("px", ""), 10);
};

export const getCurrentBreakpoint = (): string | null => {
  const { screens } = defaultTheme;
  if (!screens) return null;

  let currentBreakpoint: string | null = null;
  let biggestBreakpointValue = 0;
  Object.keys(screens).forEach((breakpoint) => {
    const breakpointValue = getBreakpointValue(breakpoint);

    if (
      breakpointValue > biggestBreakpointValue
      && window.innerWidth >= breakpointValue
    ) {
      biggestBreakpointValue = breakpointValue;
      currentBreakpoint = breakpoint;
    }
  });

  return currentBreakpoint;
};
