import { configureStore, ConfigureStoreOptions } from "@reduxjs/toolkit";
import {
  sessionServices,
  registerServices,
  dashboardServices,
  categoryServices,
  itemServices,
  variablesServices,
  uploadServices,
  localizeServices,
  themeServices,
  feedbackServices,
  modifierServices,
  settingServices,
  bannerServices,
  deviceServices,
  ingredientsServices,
  sectionServices,
  tableServices,
  promoServices,
  analyticServices,
  orderServices,
} from "../services";
import { authReducer } from "../features/auth";
import { selectableReducer } from "../features/selectable";
import { LocalStorage, SessionStorage } from "../utils";

export const createStore = (options?: ConfigureStoreOptions["preloadedState"] | undefined) => configureStore({
  reducer: {
    [sessionServices.reducerPath]: sessionServices.reducer,
    [registerServices.reducerPath]: registerServices.reducer,
    [categoryServices.reducerPath]: categoryServices.reducer,
    [itemServices.reducerPath]: itemServices.reducer,
    [variablesServices.reducerPath]: variablesServices.reducer,
    [uploadServices.reducerPath]: uploadServices.reducer,
    [modifierServices.reducerPath]: modifierServices.reducer,
    [localizeServices.reducerPath]: localizeServices.reducer,
    [themeServices.reducerPath]: themeServices.reducer,
    [feedbackServices.reducerPath]: feedbackServices.reducer,
    [modifierServices.reducerPath]: modifierServices.reducer,
    [settingServices.reducerPath]: settingServices.reducer,
    [bannerServices.reducerPath]: bannerServices.reducer,
    [deviceServices.reducerPath]: deviceServices.reducer,
    [ingredientsServices.reducerPath]: ingredientsServices.reducer,
    [sectionServices.reducerPath]: sectionServices.reducer,
    [tableServices.reducerPath]: tableServices.reducer,
    [promoServices.reducerPath]: promoServices.reducer,
    [analyticServices.reducerPath]: analyticServices.reducer,
    [orderServices.reducerPath]: orderServices.reducer,
    [dashboardServices.reducerPath]: dashboardServices.reducer,
    auth: authReducer,
    selectable: selectableReducer,
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    sessionServices.middleware,
    registerServices.middleware,
    categoryServices.middleware,
    itemServices.middleware,
    variablesServices.middleware,
    uploadServices.middleware,
    modifierServices.middleware,
    localizeServices.middleware,
    themeServices.middleware,
    feedbackServices.middleware,
    modifierServices.middleware,
    settingServices.middleware,
    bannerServices.middleware,
    deviceServices.middleware,
    ingredientsServices.middleware,
    sectionServices.middleware,
    tableServices.middleware,
    promoServices.middleware,
    analyticServices.middleware,
    orderServices.middleware,
    dashboardServices.middleware,
  ],
  ...options,
});

export const store = createStore();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

store.subscribe(() => {
  const { auth } = store.getState();

  if (auth.remember) {
    LocalStorage.setValue("token", auth.token);
    LocalStorage.setValue("refresh_token", auth.refresh_token);
  } else {
    SessionStorage.setValue("token", null);
    SessionStorage.setValue("refresh_token", null);
  }

  if (auth.user) {
    LocalStorage.setValue("user", auth.user);
  } else {
    LocalStorage.setValue("user", null);
  }

  LocalStorage.setValue("uid", auth.uid);
  LocalStorage.setValue("step", auth.step);
});
