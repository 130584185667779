/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from "clsx";
import {
  useCallback, useEffect, useRef, useState,
} from "react";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingBar from "react-top-loading-bar";
import Tippy from "@tippyjs/react/headless";

import { ReactComponent as EditIcon } from "../../assets/icons/ic_edit.svg";
import { ReactComponent as AlarmIcon } from "../../assets/icons/ic_alarm.svg";
import { ReactComponent as CardIcon } from "../../assets/icons/ic_cc.svg";
import { ReactComponent as ChevronUpDownIcon } from "../../assets/icons/ic_chevron_up_down.svg";
import { ReactComponent as CurrencyIcon } from "../../assets/icons/ic_currency.svg";
import { ReactComponent as FeedbackIcon } from "../../assets/icons/ic_feedback_setting.svg";
import { ReactComponent as GlobeIcon } from "../../assets/icons/ic_globe.svg";
import { ReactComponent as QuestionIcon } from "../../assets/icons/ic_help_outline.svg";
import { ReactComponent as OrdersIcon } from "../../assets/icons/ic_orders.svg";
import { ReactComponent as PackageIcon } from "../../assets/icons/ic_package.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/ic_plus.svg";
import { ReactComponent as QRWebIcon } from "../../assets/icons/ic_settings_qr_web.svg";
import { ReactComponent as StarIcon } from "../../assets/icons/ic_star.svg";
import { ReactComponent as VenueIcon } from "../../assets/icons/ic_venue.svg";
// import { ReactComponent as CartIcon } from "../../assets/icons/ic_cart.svg";

import { LoadingAnimation } from "..";
import { useAppSelector } from "../../app/hooks";
import { useQrLink } from "../../hooks";
import {
  SettingQRResponse, useCreatePromoMutation,
  useDeletePromoMutation,
  useSettingQRQuery,
  useToggleActivePromoMutation,
  useUpdatePromoMutation,
  useUpdateSettingQRMutation,
  type PromoCodeModel,
} from "../../services";
import { getBreakpointValue } from "../../utils";
import { Switch, Tooltip } from "../atoms";
import { ModalPromoCode } from "../modals";

interface Props {
  saveSetting: boolean;
  setSaveSetting: (arg0: boolean) => void;
}

const defaultSettingValue: SettingQRResponse = {
  cart_is_active: false,
  currency_is_active: false,
  feedback_is_active: false,
  language_is_active: false,
  venue_name_is_show: false,
  payment_is_active: false,
  order_is_active: false,
  delivery_charge: null,
  delivery_charge_is_active: false,
  minimum_order_amount: null,
  operating_hour_is_active: false,
  operating_hour_array: [
    {
      order_id: "order_delivery",
      list: [
        {
          day_id: "MON",
          is_open: false,
          time_open: null,
          time_close: null,
        },
        {
          day_id: "TUE",
          is_open: false,
          time_open: null,
          time_close: null,
        },
        {
          day_id: "WED",
          is_open: false,
          time_open: null,
          time_close: null,
        },
        {
          day_id: "THU",
          is_open: false,
          time_open: null,
          time_close: null,
        },
        {
          day_id: "FRI",
          is_open: false,
          time_open: null,
          time_close: null,
        },
        {
          day_id: "SAT",
          is_open: false,
          time_open: null,
          time_close: null,
        },
        {
          day_id: "SUN",
          is_open: false,
          time_open: null,
          time_close: null,
        },
      ],
    },
    {
      order_id: "order_pickup",
      list: [
        {
          day_id: "MON",
          is_open: false,
          time_open: null,
          time_close: null,
        },
        {
          day_id: "TUE",
          is_open: false,
          time_open: null,
          time_close: null,
        },
        {
          day_id: "WED",
          is_open: false,
          time_open: null,
          time_close: null,
        },
        {
          day_id: "THU",
          is_open: false,
          time_open: null,
          time_close: null,
        },
        {
          day_id: "FRI",
          is_open: false,
          time_open: null,
          time_close: null,
        },
        {
          day_id: "SAT",
          is_open: false,
          time_open: null,
          time_close: null,
        },
        {
          day_id: "SUN",
          is_open: false,
          time_open: null,
          time_close: null,
        },
      ],
    },
  ],
  order_type_array: [
    {
      id: "order_dinein",
      is_display: false,
    },
    {
      id: "order_pickup",
      is_display: false,
    },
    {
      id: "order_delivery",
      is_display: false,
    },
    {
      id: "order_drivetru",
      is_display: false,
    },
  ],
  payment_type_array: [
    {
      id: "pay_cash",
      is_display: false,
    },
    {
      id: "pay_credit",
      is_display: false,
    },
  ],
  // order_type_array: [],
  // payment_type_array: [],
  qr_code_bg_color: "ffffff",
  qr_code_color: "0078D3",
  promo_is_active: false,
  promo_array: [],
};

function SettingQR({ saveSetting, setSaveSetting }: Props) {
  const savedUser = useAppSelector(({ auth }) => auth.user);

  const shouldEnabled = savedUser?.features.includes("qrmobile");
  const ref = useRef(null);
  const isMobile = getBreakpointValue("md") > window.innerWidth;

  const { qrLink, qrLinkLoading } = useQrLink();
  const { data: dataQR, refetch: refetchSetting, isLoading: isLoadingDataQR } = useSettingQRQuery();
  const [updateSettingQR, { isLoading: isLoadingUpdateSettingQR }] = useUpdateSettingQRMutation();

  // Promo code
  const [promoCodeTemp, setPromoCodeTemp] = useState<PromoCodeModel & { id: string } | null>(null);
  const [createPromoCode, {
    isLoading: isCreatingPromoCode,
    isSuccess: isSuccessCreatingPromoCode,
  }] = useCreatePromoMutation();
  const [toggleActivePromoCode, { isLoading: isActivatingPromoCode }] = useToggleActivePromoMutation();
  const [updatePromoCode, {
    isLoading: isUpdatingPromoCode,
    isSuccess: isSuccessUpdatingPromoCode,
  }] = useUpdatePromoMutation();
  const [deletePromoCode, {
    isLoading: isDeletingPromoCode,
    isSuccess: isSuccessDeletingPromoCode,
  }] = useDeletePromoMutation();

  // const [isActiveLanguage, setActiveLangauge] = useState<boolean>(false);
  // const [isActiveCurrency, setActiveCurrency] = useState<boolean>(false);
  // const [isActiveCart, setActiveCart] = useState<boolean>(false);
  // const [isActiveFeedback, setActiveFeedback] = useState<boolean>(false);
  // const [isActiveVenue, setActiveVenue] = useState<boolean>(false);
  // const [welcomeTexts, setWelcomeTexts] = useState<CodeNameResponse[]>([]);
  // const [fgColor, setFgColor] = useState("0078D3");
  // const [bgColor, setBgColor] = useState("FFFFFF");

  const [settings, setSettings] = useState<SettingQRResponse>(defaultSettingValue);
  const [tab, setTab] = useState<string>("order_delivery");
  const [openModalPromoCode, setOpenModalPromoCode] = useState<boolean>(false);

  const downloadQR = () => {
    const svg = document.getElementById("qrcode-web");
    if (svg) {
      const svgData = new XMLSerializer().serializeToString(svg);
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = new Image();
      // eslint-disable-next-line func-names
      img.onload = function () {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx?.drawImage(img, 0, 0);
        const pngFile = canvas.toDataURL("image/png");

        const downloadLink = document.createElement("a");
        downloadLink.download = "qrcode";
        downloadLink.href = `${pngFile}`;
        downloadLink.click();
      };

      img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    }
  };

  const parseDay = (day_id: string) => {
    switch (day_id) {
    case "MON":
      return "Monday";

    case "TUE":
      return "Tuesday";

    case "WED":
      return "Wednesday";

    case "THU":
      return "Thursday";

    case "FRI":
      return "Friday";

    case "SAT":
      return "Saturday";

    case "SUN":
      return "Sunday";

    default:
      return "";
    }
  };

  const formatAMPM = (time: string): string => {
    const timeArr = time.split(":");
    const hours = timeArr[0];
    const minutes = timeArr[1];
    const ampm = parseInt(hours, 10) >= 12 ? "PM" : "AM";

    const hour = parseInt(hours, 10) % 12;
    const formattedHours = hour ? (hour < 10 ? `0${hour}` : hour) : "12";

    return `${formattedHours}:${minutes} ${ampm}`;
  };

  const toggle = useCallback((name: string, value: boolean): void => {
    setSettings((state) => ({
      ...state,
      [name]: value,
    }));
  }, [settings]);

  const handleCheckPaymentType = useCallback(({ id, is_display }: { id: string, is_display: boolean }) => {
    setSettings((state) => ({
      ...state,
      payment_type_array: state.payment_type_array.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            is_display: !is_display,
          };
        }

        return item;
      }),
    }));
  }, [settings.payment_type_array]);

  const handleCheckOrderingSetings = useCallback(({ id, is_display }: { id: string, is_display: boolean }) => {
    setSettings((state) => ({
      ...state,
      order_type_array: state.order_type_array.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            is_display: !is_display,
          };
        }

        return item;
      }),
    }));
  }, [settings.order_type_array]);

  const handleChangeTab = (tab_id: string) => {
    const sortedOperatingHour = [...(
      dataQR?.data?.operating_hour_array ?? defaultSettingValue.operating_hour_array
    )].sort((a, b) => {
      const x = a.order_id.toLowerCase();
      const y = b.order_id.toLowerCase();

      if (x < y) return -1;
      if (x > y) return 1;
      return 0;
    });

    setTab(tab_id);
    setSettings((state) => ({
      ...state,
      operating_hour_array: sortedOperatingHour,
    }));
  };

  const handleToggleOperatingHour = useCallback(({ id, is_open }: { id: string, is_open: boolean }) => {
    setSettings((state) => ({
      ...state,
      operating_hour_array: state.operating_hour_array.map(({ list, order_id }) => {
        if (tab === order_id) {
          return {
            order_id,
            list: list.map((item) => {
              if (item.day_id === id) {
                return {
                  ...item,
                  is_open: !is_open,
                };
              }

              return item;
            }),
          };
        }

        return {
          order_id,
          list,
        };
      }),
    }));
  }, [settings.operating_hour_array]);

  const handleSetOperationHour = useCallback((timeType: "open" | "close", day_id: string, time: string) => {
    setSettings((state) => ({
      ...state,
      operating_hour_array: state.operating_hour_array.map(({ list, order_id }) => {
        if (tab === order_id) {
          return {
            order_id,
            list: list.map((item) => {
              const fixedTime = time.split(":").concat("00").join(":");

              if (item.day_id === day_id) {
                return {
                  ...item,
                  time_open: timeType === "open" ? fixedTime : item.time_open,
                  time_close: timeType === "close" ? fixedTime : item.time_close,
                };
              }

              return item;
            }),
          };
        }

        return {
          order_id,
          list,
        };
      }),
    }));
  }, [settings.operating_hour_array]);

  // const handleCheckOrderMode = (event: ChangeEvent<HTMLInputElement>) => {

  // };

  const handleSubmitPromoCode = (data: PromoCodeModel, reset?: () => void) => {
    if (promoCodeTemp && promoCodeTemp.id) {
      updatePromoCode({
        id: data.id || promoCodeTemp.id,
        type: data.type,
        order_modes: data.order_modes,
        amount: data.amount,
        min_order: data.min_order,
      })
        .unwrap()
        .then((res) => {
          if (res.status === "Success") {
            toast.success(res.message || "Promo is updated");
            setOpenModalPromoCode(false);
            if (reset) reset();
            refetchSetting();
          }
        })
        .catch((err) => {
          toast.error(err?.data?.message || err?.message || "Failed to update promo");
        });
    } else {
      createPromoCode(data)
        .unwrap()
        .then((res) => {
          if (res.status === "Success") {
            toast.success(res.message || "Promo added");
            setOpenModalPromoCode(false);
            if (reset) reset();
            refetchSetting();
          }
        })
        .catch((err) => {
          toast.error(err?.data?.message || err?.message || "Failed to add promo");
        });
    }
  };

  const handleToggleActivePromoCode = (id: string) => {
    toggleActivePromoCode({ id })
      .unwrap()
      .then((res) => {
        if (res.status === "Success") {
          toast.success(res.message || "Promo is Updated");
          refetchSetting();
        }
      })
      .catch((err) => {
        toast.error(err?.data?.message || err?.message || "Failed to update promo");
      });
  };

  const handleDeletePromoCode = (id: string | null, reset?: () => void) => {
    if (!id) return;

    deletePromoCode({ id })
      .unwrap()
      .then((res) => {
        if (res.status === "Success") {
          toast.success(res.message || "Promo is Removed");
          setOpenModalPromoCode(false);
          if (reset) reset();
          refetchSetting();
        }
      })
      .catch((err) => {
        toast.error(err?.data?.message || err?.message || "Failed to delete promo");
      });
  };

  useEffect(() => {
    if (promoCodeTemp) {
      setOpenModalPromoCode(true);
    }
  }, [promoCodeTemp]);

  useEffect(() => {
    if (saveSetting) {
      updateSettingQR({
        // welcome_texts: welcomeTexts.map(({code, name}) => ({lang: code, value: name})),
        // qr_code_color: fgColor,
        // qr_code_bg_color: bgColor,
        // venue_name_is_show: isActiveVenue,
        // language_is_active: isActiveLanguage,
        // currency_is_active: isActiveCurrency,
        // cart_is_active: isActiveCart,
        // feedback_is_active: isActiveFeedback,
        ...settings,
      })
        .unwrap()
        .then((res) => {
          if (res.status === "Success") toast.success("Successfully update qr settings");
        })
        .catch((err) => toast.error(err.message))
        .finally(() => {
          setSaveSetting(false);
          refetchSetting();
        });
    }
  }, [saveSetting]);

  useEffect(() => {
    if (dataQR?.data) {
      const { data } = dataQR;

      const sortedOperatingHour = [...data.operating_hour_array].sort((a, b) => {
        const x = a.order_id.toLowerCase();
        const y = b.order_id.toLowerCase();

        if (x < y) return -1;
        if (x > y) return 1;
        return 0;
      });

      setSettings((state) => ({
        ...state,
        ...data,
        operating_hour_array: sortedOperatingHour,
      }));
    }
  }, [dataQR?.data]);

  useEffect(() => {
    const progress = ref.current as any;
    if (
      isLoadingDataQR
      || isLoadingUpdateSettingQR
      || isCreatingPromoCode
      || isActivatingPromoCode
      || isUpdatingPromoCode
      || isDeletingPromoCode
    ) {
      progress?.continuousStart();
    } else {
      progress?.complete();
    }
  }, [
    isLoadingDataQR,
    isLoadingUpdateSettingQR,
    isCreatingPromoCode,
    isActivatingPromoCode,
    isUpdatingPromoCode,
    isDeletingPromoCode,
  ]);

  return (
    <>
      <LoadingBar height={4} color="#0078D3" ref={ref} />

      <div className={`flex flex-col lg:flex-row gap-4 md:gap-16 px-4 md:px-6 ${shouldEnabled ? "" : " opacity-50"}`}>
        {/* Left Menu */}
        <div className="flex flex-col flex-1 gap-6 lg:w-1/2">
          <div className="flex flex-col gap-1 border-b pb-7">
            <div className="flex gap-1 items-center justify-between mb-2">
              <div className="flex flex-col">
                <div className="flex items-center w-full">
                  <OrdersIcon className="h-5 w-5 mr-2" />
                  <div className="font-semibold text-lg mt-1 flex flex-row items-center">
                    Ordering Settings

                    <Tooltip content="If you disable Ordering Settings, your customers will not allow to orders. Mobile Menu will display an information text." placement="top" className="p-1">
                      <QuestionIcon className="mx-1 w-4 h-4 cursor-help" />
                    </Tooltip>
                  </div>
                </div>
                <p className="font-medium mb-1">Display all ordering</p>
                <p className="text-sm text-secondary">
                  Additional info for this setting can be placed here
                </p>
              </div>
              <div className="flex items-center gap-2">
                <Switch
                  id="toggle-order_is_active"
                  title="Toggle Order Setting"
                  name="order_is_active"
                  checked={settings.order_is_active}
                  onChange={(e) => toggle(e.target.name, e.target.checked)}
                  disabled={!shouldEnabled}
                />
                <p className="">Yes</p>
              </div>
            </div>

            <div className="flex flex-col pr-12 gap-2">
              {settings.order_type_array.length > 0 && settings.order_type_array.map(({ id, is_display }, index) => {
                const key = index.toString();

                return (
                  <div className="flex items-center justify-between font-medium" key={key}>
                    {id === "order_dinein" && (
                      <label htmlFor={id} className="flex items-center">
                        Dine-in

                        <Tooltip
                          content={(
                            <span>
                              Each table will have its own QR code for your guests to scan and submit their
                              orders from. Please go to the
                              {" "}
                              <Link to="/tables" className="underline text-active">Tables</Link>
                              {" "}
                              tab to register your tables and you can download the QR codes there.
                            </span>
                          )}
                          placement="top"
                          className="p-1"
                        >
                          <QuestionIcon className="mx-1 w-4 h-4 cursor-help has-tooltip" />
                        </Tooltip>
                      </label>
                    )}

                    {id === "order_pickup" && (
                      <label htmlFor={id}>
                        Pick-up
                      </label>
                    )}

                    {id === "order_delivery" && (
                      <label htmlFor={id}>
                        Delivery
                      </label>
                    )}

                    {id === "order_drivetru" && (
                      <label htmlFor={id}>
                        Drive-through
                      </label>
                    )}

                    <input
                      id={id}
                      name={id}
                      title={is_display ? "Uncheck" : "Check"}
                      type="checkbox"
                      checked={is_display}
                      onChange={() => handleCheckOrderingSetings({ id, is_display })}
                      // disabled={!settings.order_is_active}
                      className={clsx([
                        "form-tick",
                        "appearance-none cursor-pointer rounded",
                        "border border-gray-300 checked:border-transparent",
                        "focus:outline-none",
                        "bg-white checked:bg-check checked:bg-primary",
                        "h-4 w-4",
                      ])}
                    />
                  </div>
                );
              })}
            </div>
          </div>

          <div className="flex flex-col gap-1 border-b pb-7">
            <div className="flex gap-1 items-center justify-between mb-2">
              <div className="flex flex-col">
                <div className="flex items-center w-full">
                  <CardIcon className="h-5 w-5 mr-2" />
                  <div className="font-semibold text-lg mt-1 flex flex-row items-center">
                    Payment Type
                  </div>
                </div>
                <p className="font-medium mb-1">Display all payment type</p>
                <p className="text-sm text-secondary">
                  Additional info for this setting can be placed here
                </p>
              </div>
              <div className="flex items-center gap-2">
                <Switch
                  id="toggle-payment"
                  title="Toggle Payment Type"
                  name="payment_is_active"
                  checked={settings.payment_is_active}
                  onChange={(e) => toggle(e.target.name, e.target.checked)}
                  disabled={!shouldEnabled}
                />
                <p className="">Yes</p>
              </div>
            </div>

            <div className="flex flex-col pr-12 gap-2">
              {settings.payment_type_array.length > 0
              && settings.payment_type_array.map(({ id, is_display }, index) => {
                const key = index.toString();

                return (
                  <div className="flex items-center justify-between font-medium" key={key}>
                    {id === "pay_cash" && (
                      <label htmlFor={id}>
                        Cash
                      </label>
                    )}

                    {id === "pay_credit" && (
                      <label htmlFor={id}>
                        Credit Card
                      </label>
                    )}

                    <input
                      id={id}
                      name={id}
                      title={id === "pay_cash" ? "Cash" : "Credit Card"}
                      type="checkbox"
                      checked={is_display}
                      onChange={() => handleCheckPaymentType({ id, is_display })}
                      // disabled={!settings.payment_is_active}
                      className={clsx([
                        "form-tick",
                        "appearance-none cursor-pointer rounded",
                        "border border-gray-300 checked:border-transparent",
                        "focus:outline-none",
                        "bg-white checked:bg-check checked:bg-primary",
                        "h-4 w-4",
                      ])}
                    />
                  </div>
                );
              })}
            </div>
          </div>

          <div className="flex flex-col gap-1 border-b pb-7">
            <div className="flex gap-1 items-center justify-between mb-2">
              <div className="flex flex-col">
                <div className="flex items-center w-full">
                  <PackageIcon className="h-5 w-5 mr-2" />
                  <div className="font-semibold text-lg mt-1 flex flex-row items-center">
                    Delivery Charge
                  </div>
                </div>
                <p className="font-medium mb-1">Display Delivery change</p>
                <p className="text-sm text-secondary">
                  Additional info for this setting can be placed here
                </p>
              </div>
              <div className="flex items-center gap-2">
                <Switch
                  id="toggle-delivery-chard"
                  title="Toggle Delivery Charge"
                  name="delivery_charge_is_active"
                  checked={settings.delivery_charge_is_active}
                  onChange={(e) => toggle(e.target.name, e.target.checked)}
                  disabled={!shouldEnabled}
                />
                <p className="">Yes</p>
              </div>
            </div>

            <div className="flex flex-col gap-4">
              <div className="flex flex-row items-center justify-between">
                <span>Delivery charge</span>

                <div className="flex items-center max-w-[150px] ml-3">
                  <input
                    type="number"
                    min="0"
                    disabled={!shouldEnabled}
                    className="input-base text-sm rounded-r-none border-r-0"
                    placeholder="0.00"
                    value={settings.delivery_charge || undefined}
                    onChange={(event) => {
                      const value = parseFloat(event.target.value.toString());

                      setSettings((state) => ({
                        ...state,
                        delivery_charge: Number.isNaN(value) ? null : value,
                      }));
                    }}
                  />
                  <div className="p-2 border bg-line-gray h-full rounded-r-md text-sm">
                    USD
                  </div>
                </div>
              </div>

              <div className="flex flex-row items-center justify-between">
                <span>Minimum Order Amount</span>

                <div className="flex items-center max-w-[150px] ml-3">
                  <input
                    type="number"
                    min="0"
                    disabled={!shouldEnabled}
                    className="input-base text-sm rounded-r-none border-r-0"
                    placeholder="0.00"
                    value={settings.minimum_order_amount || undefined}
                    onChange={(event) => {
                      const value = parseInt(event.target.value.toString(), 10);

                      setSettings((state) => ({
                        ...state,
                        minimum_order_amount: Number.isNaN(value) ? null : value,
                      }));
                    }}
                  />
                  <div className="p-2 border bg-line-gray h-full rounded-r-md text-sm">
                    USD
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-1">
            <div className="flex gap-1 items-center justify-between mb-2">
              <div className="flex flex-col">
                <div className="flex items-center w-full">
                  <AlarmIcon className="h-5 w-5 mr-2" />
                  <div className="font-semibold text-lg mt-1 flex flex-row items-center">
                    Operating Hours

                    <Tooltip content="If you disable time selection, your customers will not allow to orders by Delivery & Pick-up. Mobile Menu will display an information text." placement="top" className="p-1">
                      <QuestionIcon className="mx-1 w-4 h-4 cursor-help" />
                    </Tooltip>
                    {/* <div className="tooltip-dark py-2 px-4 rounded-md shadow-lg mb-32 ml-[20px] bg-black text-white font-normal max-w-[260px] text-xs">
                      If you disable time selection, your customers will not allow to orders by Delivery & Pick-up.
                      {" "}
                      Mobile Menu will display an information text.

                      <div className="tooltip-arrow w-3 h-3 rotate-45 -bottom-1.5 bg-black absolute left-1/2" /> */}
                    {/* </div> */}
                  </div>
                </div>
                <p className="font-medium mb-1">Display Operating Hours</p>
                <p className="text-sm text-secondary">
                  Additional info for this setting can be placed here
                </p>
              </div>
              <div className="flex items-center gap-2">
                <Switch
                  id="toggle-operating_hour_is_active"
                  title="Toggle Order Setting"
                  name="operating_hour_is_active"
                  checked={settings.operating_hour_is_active}
                  onChange={(e) => toggle(e.target.name, e.target.checked)}
                  disabled={!shouldEnabled}
                />
                <p className="">Yes</p>
              </div>
            </div>

            <div className="flex flex-col mt-4">
              <div className="flex flex-row items-center gap-4">
                {settings.operating_hour_array.map(({ order_id }, index) => {
                  const key = index.toString();

                  return (
                    <button
                      type="button"
                      className={clsx([
                        "font-bold pb-1",
                        "border-b-2",
                        tab === order_id ? "text-active border-b-primary" : "border-b-transparent",
                      ])}
                      onClick={() => handleChangeTab(order_id)}
                      key={key}
                    >
                      {order_id === "order_pickup" && "Pick-up"}
                      {order_id === "order_delivery" && "Delivery"}
                    </button>
                  );
                })}
              </div>

              <div className="w-full flex flex-col overflow-x-auto gap-4 pb-2.5 py-5">
                {settings.operating_hour_array.map(({ list, order_id }, index) => {
                  const key = index.toString();

                  return tab === order_id && (
                    <div className="flex flex-col gap-4" key={key}>
                      {list.map(({
                        day_id, is_open, time_close, time_open,
                      }, nestedIndex) => {
                        const nestedKey = nestedIndex.toString();

                        return (
                          <div className="flex items-center gap-4 justify-start h-10" key={nestedKey}>
                            <span className="block min-w-[128px]">
                              {parseDay(day_id)}
                            </span>

                            <div className="min-w-[120px] lg:min-w-[140px] flex flex-row items-center gap-4">
                              <Switch
                                id={`toggle-open-${day_id}`}
                                name={day_id}
                                checked={is_open}
                                disabled={!shouldEnabled}
                                title={`Toggle Open at ${parseDay(day_id)}`}
                                className="min-w-[32px]"
                                onChange={() => handleToggleOperatingHour({
                                  id: day_id,
                                  is_open,
                                })}
                              />

                              <span>{is_open ? "Open" : "Closed"}</span>
                            </div>

                            {is_open && (
                              <div className="flex flex-row items-center justify-start gap-4">
                                {("showPicker" in HTMLInputElement.prototype) ? (
                                  <button
                                    type="button"
                                    className="border rounded-md relative flex flex-row items-center"
                                    onClick={() => {
                                      const input = document.getElementById(`toggle-time-open-${day_id}`) as HTMLInputElement & {
                                    showPicker: () => void;
                                  };

                                      if (input) {
                                        input.showPicker();
                                      }
                                    }}
                                  >
                                    <input
                                      type="time"
                                      id={`toggle-time-open-${day_id}`}
                                      value={time_open || "09:00:00"}
                                      onChange={(event) => handleSetOperationHour("open", day_id, event.target.value)}
                                      className="py-2 px-3 rounded-md absolute -z-10 opacity-0 invisible"
                                    />
                                    <span className="min-w-[100px] whitespace-nowrap py-2 px-3 rounded-md">{formatAMPM(time_open ?? "09:00:00")}</span>
                                    <ChevronUpDownIcon className="w-7 h-7 text-primary opacity-70" />
                                  </button>
                                )
                                  : (
                                    <label htmlFor={`toggle-time-open-${day_id}`} className="border rounded-md">
                                      <input
                                        type="time"
                                        id={`toggle-time-open-${day_id}`}
                                        value={time_open || "17:00:00"}
                                        onChange={(event) => handleSetOperationHour("open", day_id, event.target.value)}
                                        className="w-[140px] py-2 px-3 rounded-md custom-input-icon"
                                      />
                                    </label>
                                  )}

                                <span className="uppercase text-sm">to</span>

                                {("showPicker" in HTMLInputElement.prototype) ? (
                                  <button
                                    type="button"
                                    className="border rounded-md relative flex flex-row items-center"
                                    onClick={() => {
                                      const input = document.getElementById(`toggle-time-close-${day_id}`) as HTMLInputElement & {
                                    showPicker: () => void;
                                  };

                                      if (input) {
                                        input.showPicker();
                                      }
                                    }}
                                  >
                                    <input
                                      type="time"
                                      id={`toggle-time-close-${day_id}`}
                                      value={time_close || "09:00:00"}
                                      onChange={(event) => handleSetOperationHour("close", day_id, event.target.value)}
                                      className="py-2 px-3 rounded-md absolute -z-10 opacity-0 invisible"
                                    />
                                    <span className="min-w-[100px] whitespace-nowrap py-2 px-3 rounded-md">{formatAMPM(time_close ?? "09:00:00")}</span>
                                    <ChevronUpDownIcon className="w-7 h-7 text-primary opacity-70" />
                                  </button>
                                )
                                  : (
                                    <label htmlFor={`toggle-time-close-${day_id}`} className="border rounded-md">
                                      <input
                                        type="time"
                                        id={`toggle-time-close-${day_id}`}
                                        value={time_close || "17:00:00"}
                                        onChange={(event) => handleSetOperationHour("close", day_id, event.target.value)}
                                        className="w-[140px] py-2 px-3 rounded-md custom-input-icon"
                                      />
                                    </label>
                                  )}
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {/* {dataQR?.data?.welcome_texts.map((welcome) => (
            <div className="flex flex-col gap-1" key={welcome.lang_code}>
              <label htmlFor={'input-' + welcome.lang_code} className="text-secondary">
                Welcome Text ({welcome.lang_name})
              </label>
              <input
                type="text"
                id={'input-' + welcome.lang_code}
                className="input-base"
                placeholder={`Type your welcome text (${welcome.lang_name})`}
                value={welcomeTexts.find(({code}) => code === welcome.lang_code)?.name ?? ""}
                onChange={(e) => {
                  const currentTexts = welcomeTexts.map(text => {
                    if (text.code === welcome.lang_code) {
                      return {...text, name: e.target.value}
                    }
                    return text;
                  });
                  setWelcomeTexts(currentTexts)
                }}
                disabled={!shouldEnabled}
              />
            </div>
          ))} */}
        </div>

        {/* Right menu */}
        <div className="flex flex-col flex-1 gap-8 mt-4 md:mt-0">
          <div className="flex gap-1 items-center justify-between">
            <div className="flex flex-col">
              <div className="flex items-center">
                <GlobeIcon className="h-5 w-5 mr-2" />
                <p className="font-semibold text-lg mt-1">Language</p>
              </div>
              <p className="font-medium mb-1">Display Language Button</p>
              <p className="text-sm text-secondary">
                Additional info for this setting can be placed here
              </p>
            </div>

            <div className="flex items-center gap-2">
              <Switch
                id="toggle-language"
                name="language_is_active"
                checked={settings.language_is_active}
                disabled={!shouldEnabled}
                title="Toggle Language"
                onChange={(event) => toggle(event.target.name, event.target.checked)}
              />
              <p className="">Yes</p>
            </div>
          </div>

          <div className="flex gap-1 items-center justify-between">
            <div className="flex flex-col">
              <div className="flex items-center">
                <CurrencyIcon className="h-5 w-5 mr-2" />
                <p className="font-semibold text-lg mt-1">Currency</p>
              </div>
              <p className="font-medium mb-1">Display Prices</p>
              <p className="text-sm text-secondary">
                Additional info for this setting can be placed here
              </p>
            </div>
            <div className="flex items-center gap-2">
              <Switch
                id="toggle-currency"
                name="currency_is_active"
                checked={settings.currency_is_active}
                disabled={!shouldEnabled}
                title="Toggle Currency"
                onChange={(event) => toggle(event.target.name, event.target.checked)}
              />
              <p className="">Yes</p>
            </div>
          </div>

          <div className="flex gap-1 items-center justify-between">
            <div className="flex flex-col">
              <div className="flex items-center">
                <VenueIcon className="h-5 w-5 mr-2" />
                <p className="font-semibold text-lg mt-1">Welcome Page</p>
              </div>
              <p className="font-medium mb-1">Display Venue Name</p>
              <p className="text-sm text-secondary">
                Additional info for this setting can be placed here
              </p>
            </div>
            <div className="flex items-center gap-2">
              <Switch
                id="toggle-venue"
                checked={settings.venue_name_is_show}
                disabled={!shouldEnabled}
                name="venue_name_is_show"
                title="Toggle Venue name"
                onChange={(event) => toggle(event.target.name, event.target.checked)}
              />
              <p className="">Yes</p>
            </div>
          </div>

          <div className="flex gap-1 items-center justify-between">
            <div className="flex flex-col">
              <div className="flex items-center">
                <FeedbackIcon className="h-5 w-5 mr-2" />
                <p className="font-semibold text-lg mt-1">Feedback</p>
              </div>
              <p className="font-medium mb-1">Display Feedback Button</p>
              <p className="text-sm text-secondary">
                Additional info for this setting can be placed here
              </p>
            </div>
            <div className="flex items-center gap-2">
              <Switch
                id="toggle-feedback"
                checked={settings.feedback_is_active}
                name="feedback_is_active"
                disabled={!shouldEnabled}
                title="Toggle Feedback"
                onChange={(event) => toggle(event.target.name, event.target.checked)}
              />
              <p className="">Yes</p>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <div className="flex gap-1 items-center justify-between">
              <div className="flex flex-col">
                <div className="flex items-center">
                  <StarIcon className="h-5 w-5 mr-2" />
                  <p className="font-semibold text-lg mt-1">Promo Codes</p>
                </div>
                <p className="font-medium mb-1">Display Promo Codes </p>
                <p className="text-sm text-secondary">
                  Additional info for this setting can be placed here
                </p>
              </div>
              <div className="flex items-center gap-2">
                <Switch
                  id="toggle-promo-codes"
                  checked={settings.promo_is_active}
                  name="promo_is_active"
                  disabled={!shouldEnabled}
                  title="Toggle Promo Code"
                  onChange={(event) => toggle(event.target.name, event.target.checked)}
                />
                <p className="">Yes</p>
              </div>
            </div>

            {/**
             * TODO: Display promo code
             */}
            {settings.promo_array.length > 0 && (
              <div className="flex flex-col items-center justify-center w-full gap-2">
                {settings.promo_array.map((item) => {
                  const { id, is_active, code } = item;

                  return (
                    <div
                      className={clsx(
                        "border rounded bg-white",
                        "px-4 py-3 w-full",
                        "flex flex-row items-center justify-between gap-4",
                      )}
                      key={id}
                    >
                      <div className="flex flex-row items-center gap-2 w-1/2">
                        <span className="text-sm text-ellipsis overflow-hidden block">
                          {code}
                        </span>

                        <Tooltip content="Edit" placement="bottom">
                          <button
                            type="button"
                            title="Edit Promo code"
                            onClick={() => {
                              setPromoCodeTemp({
                                id,
                                code,
                                type: item.type,
                                amount: item.amount,
                                min_order: item.min_order_amount,
                                order_modes: item.order_modes,
                              });
                            }}
                          >
                            <EditIcon className="w-4 h-4" />
                          </button>
                        </Tooltip>
                      </div>

                      <div className="flex flex-row items-center gap-2">
                        <Switch
                          id={id}
                          name={id}
                          title={`toggle ${code}`}
                          checked={is_active}
                          onChange={() => handleToggleActivePromoCode(id)}
                          disabled={isActivatingPromoCode}
                        />
                        <span>Active</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}

            <div className="flex">
              <button
                type="button"
                className="button-primary px-6 flex items-center justify-center"
                disabled={!settings.promo_is_active}
                aria-disabled={!settings.promo_is_active}
                onClick={() => setOpenModalPromoCode(true)}
                title="Add Promo"
              >
                <PlusIcon className="h-4 w-4 mr-2" />
                Add
              </button>
            </div>
          </div>

          {/* <div className="flex gap-1 items-center justify-between">
            <div className="flex flex-col">
              <div className="flex items-center">
                <CartIcon className="h-5 w-5 mr-2" />
                <p className="font-semibold text-lg mt-1">Cart</p>
              </div>
              <p className="font-medium">Display Cart Button</p>
              <p className="text-sm text-secondary">
                Additional info for this setting can be placed here
              </p>
            </div>
            <div className="flex items-center gap-2">
              <div className="relative w-8 align-middle text-center select-none inline-block">
                <input
                  type="checkbox"
                  id="toggle-cart"
                  className="toggle-checkbox absolute block w-4 h-4 rounded-full bg-white border-2 appearance-none transition-all duration-300 ease-in cursor-pointer"
                  checked={settings.cart_is_active}
                  onChange={(e) => toggle(e.target.name, e.target.checked)}
                  disabled={!shouldEnabled}
                />
                <label
                  htmlFor="toggle-cart"
                  className="toggle-label block overflow-hidden h-4 rounded-full bg-gray-300 transition-all duration-300 ease-in cursor-pointer"
                />
              </div>
              <p className="">Yes</p>
            </div>
          </div> */}

          <div className="flex items-center gap-2">
            <QRWebIcon className="h-4 w-4" />
            <p className="font-medium text-lg">Your Cloud Menu QR Code</p>
          </div>

          <div className="flex flex-col items-center">
            {(qrLinkLoading || isLoadingDataQR) ? (
              <div className="h-full md:h-64 w-full md:w-64 flex items-center justify-center">
                <LoadingAnimation />
              </div>
            ) : (
              <QRCode
                id="qrcode-web"
                value={qrLink ?? ""}
                fgColor={`#${settings.qr_code_color}`}
                bgColor={`#${settings.qr_code_bg_color}`}
              />
            )}
            <button
              title="Download QR Code"
              type="button"
              className="button-primary px-4 py-2 mt-8 text-sm"
              onClick={downloadQR}
              disabled={!shouldEnabled}
            >
              Download QR Code
            </button>
            {shouldEnabled && (
              <a
                href={qrLink ?? ""}
                target="_blank"
                className="text-active font-bold mt-4 text-center"
                rel="noreferrer"
              >
                {process.env.REACT_APP_QR_URL}
              </a>
            )}
          </div>

          <div className="flex items-center h-10">
            <p className="flex-1 mr-4">QR Code Color</p>
            <div className="h-full w-10 rounded-l flex items-center justify-center bg-line-gray">
              #
            </div>
            <input
              type="text"
              className="input-base w-20 rounded-none"
              maxLength={6}
              onChange={(e) => setSettings((state) => ({
                ...state,
                qr_code_color: e.target.value,
              }))}
              value={settings.qr_code_color}
              disabled={!shouldEnabled}
            />
            <div
              className="h-full w-10 rounded-r border border-l-0 border-line-gray"
              style={{ background: `#${settings.qr_code_color}` }}
            />
          </div>

          <div className="flex items-center h-10">
            <p className="flex-1 mr-4">QR Code Background Color</p>
            <div className="h-full w-10 rounded-l flex items-center justify-center bg-line-gray">
              #
            </div>
            <input
              type="text"
              className="input-base w-20 rounded-none"
              maxLength={6}
              onChange={(e) => setSettings((state) => ({
                ...state,
                qr_code_bg_color: e.target.value,
              }))}
              value={settings.qr_code_bg_color}
              disabled={!shouldEnabled}
            />
            <div
              className="h-full w-10 rounded-r border border-l-0 border-line-gray"
              style={{ background: `#${settings.qr_code_bg_color}` }}
            />
          </div>
        </div>
      </div>

      <ModalPromoCode
        open={openModalPromoCode}
        defaultValue={promoCodeTemp}
        onClose={() => {
          setOpenModalPromoCode(false);
          if (promoCodeTemp) setPromoCodeTemp(null);
        }}
        onSubmit={handleSubmitPromoCode}
        onDelete={handleDeletePromoCode}
        isLoadingDeletePromoCode={isDeletingPromoCode}
        isLoadingCreatePromoCode={isCreatingPromoCode || isUpdatingPromoCode}
        // isLoading={isCreatingPromoCode || isUpdatingPromoCode || isDeletingPromoCode}
      />
    </>
  );
}

export default SettingQR;
