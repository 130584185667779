import clsx from "clsx";
import {
  format, subDays,
} from "date-fns";
import React, {
  useCallback,
  useEffect, useMemo, useRef, useState,
} from "react";
import Calendar from "react-calendar";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingBar from "react-top-loading-bar";
import Popup from "reactjs-popup";
import { type PopupActions } from "reactjs-popup/dist/types";
import screenfull from "screenfull";

import { ReactComponent as ChevronDownIcon } from "../assets/icons/ic_chevron_down.svg";
import { ReactComponent as FilterIcon } from "../assets/icons/ic_filter.svg";
import { ReactComponent as CalendarIcon } from "../assets/icons/ic_thin_calendar.svg";
import { ReactComponent as ReloadIcon } from "../assets/icons/ic_thin_repeat.svg";
import { ReactComponent as ZoomInIcon } from "../assets/icons/ic_zoom_in.svg";
import { ReactComponent as ZoomOutIcon } from "../assets/icons/ic_zoom_out.svg";

import {
  ContentHeader, LoadingAnimation, ModalConfirmation, Portal,
} from "../components";
import {
  OrderFilterPopup,
  OrderFilterTab,
  OrderList,
  type OrderFilterState,
  type Tab,
  OrderNotification,
} from "../components/orders";
import {
  ORDER_FILTER_LIST_TEXT,
} from "../constants";
import { useDebounce, usePreviousState } from "../hooks";
import {
  useDeleteMultipleOrderMutation,
  useGetAllOrdersQuery,
  useGetFilterListQuery,
  useOrderStatusTypeListQuery,
  useOrderTypeListQuery,
} from "../services";

interface NotificationType {
  type: "order" | "checkout";
  orderId: number;
  orderTypeId: string;
  tableName?: string;
}

const formatDate = (date: Date, type = "dd MMM yyyy") => format(date, type);

function OrdersPage() {
  const today = new Date();

  const calendarRef = useRef<PopupActions | null>(null);
  const loadingBarRef = useRef(null);
  const tabBorderBottom = useRef<HTMLDivElement | null>(null);
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search || window.location.search);

  const [keyword, setKeyword] = useState<string>(urlSearchParams.get("id") || "");
  const [isFullscreenActive, setIsFullscreenActive] = useState<boolean>(false);
  const [orderFilter, setOrderFilter] = useState<OrderFilterState>({
    order_types: [],
    status_types: [],
  });
  const [checkedId, setCheckedId] = useState<string[]>([]);
  const [expandId, setExpandId] = useState<string>(urlSearchParams.get("id") || "");
  // Pagination
  const [forcePage, setForcePage] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);
  const [itemOffset, setItemOffset] = useState<number>(0);
  const [itemLimit, setItemLimit] = useState<number>(10);
  // const [currentPage, setCurrentPage] = useState<number>(0);
  // Tab
  const [tabs, setTabs] = useState<Tab[]>([]);
  const [selectedTab, setSelectedTab] = useState<string>("");
  // Date picker
  // const [openDatePicker, setOpenDatePicker] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState([subDays(today, 7), today]);
  const [okDateRange, setOkDateRange] = useState([subDays(today, 7), today]);
  // Modal
  const [openModalConfirmation, setOpenModalConfirmation] = useState<boolean>(false);

  const {
    data: orderData, isLoading: isLoadingOrderData, isFetching: isFetchingOrderData, refetch,
  } = useGetAllOrdersQuery({
    filter_type: selectedTab,
    order_types: orderFilter.order_types.length > 0 ? orderFilter.order_types : undefined,
    status_types: orderFilter.status_types.length > 0 ? orderFilter.status_types : undefined,
    offset: itemOffset,
    limit: itemLimit,
    from: format(okDateRange[0], "yyyyMMdd"),
    to: format(okDateRange[1], "yyyyMMdd"),
  }, {
    skip: !selectedTab,
  });
  const {
    data: orderFilterListData,
    isLoading: isLoadingOrderFilterList,
    refetch: refetchFilterList,
  } = useGetFilterListQuery();
  const { data: orderStatusListData, isLoading: isLoadingOrderStatusList } = useOrderStatusTypeListQuery();
  const { data: orderTypeListData, isLoading: isLoadingOrderTypeList } = useOrderTypeListQuery();
  const [deleteMultipleOrder, { isLoading: isLoadingDeleteMultipleOrer }] = useDeleteMultipleOrderMutation();

  const debouncedKeyword = useDebounce<string>(keyword);
  const orders = useMemo(() => {
    if (!orderData?.data) return [];
    if (!debouncedKeyword) return orderData.data;

    const filtered = orderData?.data?.filter((order) => {
      if (order.table_name?.toLowerCase().includes(debouncedKeyword.toLowerCase())) return true;
      if (`${order.order_id}`?.includes(debouncedKeyword.toLowerCase())) return true;
      if (order.cust_name?.toLowerCase().includes(debouncedKeyword.toLowerCase())) return true;

      return false;
    });

    return filtered;
  }, [orderData?.data, debouncedKeyword]);

  const isLoading = isLoadingOrderData
  || isLoadingOrderFilterList
  || isLoadingOrderStatusList
  || isLoadingOrderTypeList;
  const isFetching = isFetchingOrderData;

  const isCheckedAll = useMemo(() => [...checkedId].map((id) => {
    if (orders?.find(({ id: itemId }) => itemId === id)) {
      return id;
    }

    return null;
  }).filter((id) => id).length === orders?.length, [orders, checkedId]);

  const toggleFullscreen = useCallback(() => {
    const root = document?.querySelector("body");

    if (screenfull.isFullscreen) {
      screenfull.exit();
    } else {
      screenfull.request(root || undefined);
    }
  }, [isFullscreenActive]);

  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemLimit) % (orderData?.total_item ?? 0);

    setItemOffset(newOffset);
    setForcePage(event.selected);
  };

  const handleOnCheck = (id: string) => {
    if (checkedId.indexOf(id) !== -1) {
      const removedChecklistId = checkedId.filter((ids) => ids !== id);

      setCheckedId(removedChecklistId);
    } else {
      setCheckedId((ids) => ([...ids, id]));
    }
  };

  const handleOnCheckAll = () => {
    if (!orders) return;

    if (isCheckedAll) {
      setCheckedId((prevState) => [
        ...prevState
          .filter(
            (id) => (orders.find(({ id: itemId }) => itemId === id) ? null : id),
          )
          .filter((id) => id),
      ]);
    } else {
      setCheckedId((prevState) => [...prevState, ...orders.map(({ id }) => id)]);
    }
  };

  const handleDeleteMultipleOrder = () => {
    if (checkedId.length === 0) return;

    deleteMultipleOrder({
      id_array: checkedId,
    })
      .unwrap()
      .then((res) => {
        if (res.status === "Success") {
          toast.success(res.message || "Orders removed");
          setCheckedId([]);

          Promise.all([
            refetch(),
            refetchFilterList(),
          ]).then(() => {
            setOpenModalConfirmation(false);
          });
        }
      })
      .catch((error) => {
        toast.error(error?.message || "error");
      });
  };

  useEffect(() => {
    const pageCounts = Math.ceil((orderData?.total_item || 0) / itemLimit);

    setPageCount(pageCounts);

    if (orders?.length === 0) {
      const tempItemOffset = itemOffset <= 0 ? 0 : itemOffset - itemLimit;
      const tempPage = tempItemOffset / itemLimit;

      setItemOffset(tempItemOffset);
      setForcePage((prevState) => ((prevState > 0 && prevState > tempPage) ? tempPage : prevState));
    }
  }, [orders, orderData?.total_item]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (screenfull.isEnabled) {
      if (screenfull.isFullscreen) {
        setIsFullscreenActive(screenfull.isFullscreen);
      }

      screenfull.on("change", () => {
        setIsFullscreenActive(screenfull.isFullscreen);
      });

      return () => {
        screenfull.on("change", () => {
          setIsFullscreenActive(screenfull.isFullscreen);
        });
      };
    }
  }, []);

  useEffect(() => {
    if (orderFilterListData && tabBorderBottom.current) {
      // if (orderFilterListData && selectedTab && tabBorderBottom.current) {
      const tab = document.getElementById(`tab-${selectedTab}`);

      tabBorderBottom.current.style.width = `${(tab?.scrollWidth || 28) - 28}px`;
      tabBorderBottom.current.style.left = `${tab?.offsetLeft || 0}px`;
    }
  }, [selectedTab]);

  useEffect(() => {
    if (orderFilterListData?.data && tabBorderBottom?.current) {
      const orderFilterList = orderFilterListData.data;
      const tempOrderFilterList: Tab[] = orderFilterList.map((item) => ({
        ...item,
        text: "",
      }));

      // eslint-disable-next-line no-restricted-syntax
      for (let x = 0; x < orderFilterList.length; x += 1) {
        const item = orderFilterList[x];
        const replacedItem = {
          ...item,
          text: ORDER_FILTER_LIST_TEXT[item.id as keyof typeof ORDER_FILTER_LIST_TEXT],
        };

        switch (item.id) {
        case "filter_all":
          tempOrderFilterList.splice(0, 1, replacedItem);
          break;

        case "filter_new":
          tempOrderFilterList.splice(1, 1, replacedItem);
          break;

        case "filter_prepare":
          tempOrderFilterList.splice(2, 1, replacedItem);
          break;

        case "filter_ready":
          tempOrderFilterList.splice(3, 1, replacedItem);
          break;

        case "filter_close":
          tempOrderFilterList.splice(4, 1, replacedItem);
          break;

        default:
          break;
        }
      }

      setTabs(tempOrderFilterList);

      if (location.hash && tempOrderFilterList.find(({ id }) => id === location.hash.replace(/#/gi, ""))) {
        setSelectedTab(location.hash.replace(/#/gi, ""));
      } else {
        setSelectedTab(tempOrderFilterList[0].id);
      }
    }
  }, [orderFilterListData?.data]);

  useEffect(() => {
    const progress = loadingBarRef.current as any;

    if (isLoading || (!isLoading && isFetching)) {
      progress?.continuousStart();
    } else {
      progress?.complete();
    }
  }, [isLoading, isFetching]);

  if (!orderFilterListData) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <LoadingAnimation />
      </div>
    );
  }

  return (
    <>
      <LoadingBar height={4} color="#0078D3" ref={loadingBarRef} />

      {/**
       *
       * Example Notification
       *

          {notification.map((item, index) => {
            const key = `${index}-${item.orderId}`;

            return (
              <Portal containerId="notification-root">
                <OrderNotification
                  type={item.type}
                  tableName={item.tableName}
                  orderId={item.orderId}
                  orderTypeId={item.orderTypeId}
                  isOpen
                  onClose={() => {
                    setNotification((prevState) => prevState.filter(({ orderId }) => orderId !== item.orderId));
                  }}
                  key={key}
                />
              </Portal>
            );
          })}

       * Example Checkout or Order Notification modal

          <OrderNotification
            type="order"
            orderId={1231}
            orderTypeId="Cash"
            tableName="Table 1"
            isModal
            isOpen
            onClose={() => console.log("test")}
          />
       */}

      <div className="flex-1">
        <ContentHeader
          title="Orders"
          subtitle="Here you can see all orders"
          onSearch={setKeyword}
          value={keyword}
          showAddButton={false}
          showBorderBottom={false}
        />

        {/* Tabs */}
        <div className="border-b mb-6">
          <OrderFilterTab
            data={tabs}
            selectedTab={selectedTab}
            onTabClick={setSelectedTab}
            ref={tabBorderBottom}
          />
        </div>

        {/* header / list orders */}
        <div className="px-4 md:px-6 md:pb-1 flex gap-2 md:gap-4 justify-between mb-4 overflow-x-auto">
          <div className="flex flex-auto md:flex md:flex-1 gap-2 md:max-w-[238.46px]">
            <OrderFilterPopup
              order_types={orderTypeListData?.data?.map(({ id }) => id) as string[] || []}
              status_types={orderStatusListData?.data?.map(({ id }) => id) as string[] || []}
              setOrderFilter={setOrderFilter}
              // eslint-disable-next-line react/no-unstable-nested-components
              triggers={(isOpen) => (
                <button
                  type="button"
                  title="Filter"
                  className="button-link md:button-gray md:w-full md:max-w-[238.46px] text-sm border border-line-gray rounded px-4 py-2 md:px-4 md:py-1 flex flex-1 justify-center md:justify-between items-center gap-2 md:text-black md:font-normal"
                  disabled={isFetching}
                >
                  <FilterIcon className="w-3 h-3 md:hidden" color="#0078D3" />
                  Filter
                  <ChevronDownIcon className={clsx("w-3 h-3 hidden md:block", isOpen && "transform rotate-180")} color="#000000" />
                </button>
              )}
            />
          </div>

          <div className="hidden md:flex md:flex-1 gap-4">
            <div className="flex flex-1 rounded border border-line-gray overflow-hidden">
              <Popup
                ref={calendarRef}
                position="bottom center"
                contentStyle={{
                  width: "auto",
                  maxWidth: 560,
                  background: "white",
                }}
                closeOnDocumentClick
                closeOnEscape
                onOpen={() => setDateRange(okDateRange)}
                trigger={(
                  <input
                    type="text"
                    className="w-full px-2 text-sm"
                    value={okDateRange?.map((date) => formatDate(date))?.join(" - ")}
                    readOnly
                  />
                )}
              >
                <Calendar selectRange showDoubleView onChange={setDateRange} returnValue="range" defaultValue={dateRange} />
                <div className="flex justify-end w-full">
                  <button
                    title="Ok"
                    type="button"
                    className="border rounded py-1 px-3 my-1 mr-1 text-sm self-end"
                    onClick={() => {
                      setOkDateRange(dateRange);
                      calendarRef.current?.close();
                    }}
                  >
                    Ok
                  </button>
                </div>
              </Popup>

              <button type="button" className="bg-gray-100 flex px-3 items-center cursor-pointer" onClick={() => calendarRef.current?.open()}>
                <CalendarIcon className="w-4 h-4" color="#666666" />
              </button>
            </div>
          </div>

          <div className="flex flex-auto gap-2 lg:gap-4 md:flex-none">
            <button
              type="button"
              title="Reload"
              className="button-link text-sm border border-line-gray rounded px-2 py-2 md:px-4 flex flex-1 md:flex-none justify-center items-center gap-2"
              onClick={refetch}
              disabled={isFetching}
            >
              <ReloadIcon className="w-3 h-3" color="#0078D3" />
              Reload
            </button>

            <button
              type="button"
              className="button-link text-sm border border-line-gray rounded px-2 py-2 md:px-4 flex flex-1 md:flex-none justify-center items-center gap-2 whitespace-nowrap md:whitespace-normal"
              onClick={() => toggleFullscreen()}
              disabled={!screenfull.isEnabled || isFetching}
              title={screenfull.isEnabled ? (isFullscreenActive ? "Exit Full screen" : "Enter Full screen") : "Fullscreen is not supported"}
            >
              {isFullscreenActive ? (
                <>
                  <ZoomInIcon className="w-3 h-3" color="#0078D3" />
                  Exit Full screen
                </>
              ) : (
                <>
                  <ZoomOutIcon className="w-3 h-3" color="#0078D3" />
                  Full screen
                </>
              )}
            </button>

            <button
              type="button"
              title="Delete Selected"
              className="hidden md:flex md:flex-1 button-error text-sm py-2 px-4 font-normal rounded"
              disabled={isFetching || checkedId.length <= 0}
              onClick={() => {
                setOpenModalConfirmation(true);
              }}
            >
              Delete Selected
            </button>
          </div>
        </div>

        <div className={clsx("w-full overflow-x-auto")}>
          <OrderList
            data={orders || []}
            refetch={() => {
              Promise.all([
                refetch(),
                refetchFilterList(),
              ]);
            }}
            // offset={itemOffset}
            limit={itemLimit}
            pageCount={pageCount}
            forcePage={forcePage}
            totalItem={orderData?.total_item || 0}
            expandId={expandId}
            isLoading={isFetching}
            isChecked={(id: string) => checkedId.indexOf(id) !== -1}
            isCheckedAll={(orders?.length || 0) > 0 && isCheckedAll}
            onCheck={handleOnCheck}
            onCheckAll={handleOnCheckAll}
            onPageClick={handlePageClick}
            // setOffset={setItemOffset}
            setLimit={setItemLimit}
            setPageCount={setPageCount}
            setExpandId={(id: string) => setExpandId(id)}
            showPagination
            showPageLimiter
            showCheckbox
          />
        </div>

        {/* Confirmation for delete multiple order */}
        <ModalConfirmation
          isOpen={openModalConfirmation}
          confirmationLoading={isLoadingDeleteMultipleOrer}
          confirmationText="Delete"
          content="Are you sure?"
          onCloseModal={() => {
            setOpenModalConfirmation(false);
          }}
          onConfirmModal={() => handleDeleteMultipleOrder()}
        />

      </div>
    </>
  );
}

export default OrdersPage;
