import {
  useEffect, useMemo, useRef, useState,
} from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingBar from "react-top-loading-bar";
// import theme from "tailwindcss/defaultTheme";

import { ReactComponent as EditIcon } from "../assets/icons/ic_edit.svg";
import { ReactComponent as DeleteIcon } from "../assets/icons/ic_trash_bold.svg";
import { ReactComponent as IllustrationNoItems } from "../assets/illustration_no_items.svg";
// import imagePlaceholder from "../assets/image_placeholder.png";

import {
  ContentHeader,
  LoadingAnimation,
  ModalConfirmation,
  // CardWithOptions,
  // Switch,
} from "../components";
import { Switch } from "../components/atoms";
import { useDebounce } from "../hooks";

import {
  useDeleteThemeMutation, useGetAllThemeQuery, usePublishThemeMutation, ThemeResponse,
} from "../services";

interface Confirmation {
  isOpen: boolean;
  data: string | null;
}

function ThemePage() {
  const [toggler, setToggler] = useState<boolean[]>([]);
  const [keyword, setKeyword] = useState<string>("");
  const [checklist, setChecklist] = useState<string[]>([]);
  const [modalConfirmation, setModalConfirmation] = useState<Confirmation>({
    isOpen: false,
    data: null,
  });
  const debouncedKeyword = useDebounce<string>(keyword, 500);
  const { push } = useHistory();
  const ref = useRef(null);

  const { data, isLoading, refetch } = useGetAllThemeQuery();
  const [publishTheme, { isLoading: isLoadingPublish }] = usePublishThemeMutation();
  const [deleteTheme, { isLoading: isLoadingDeleteItem }] = useDeleteThemeMutation();

  const onSearch = (text: string) => {
    setKeyword(text);
  };

  /**
   * @description Function to handle active theme (minimal 1 active theme)
   * @param index<number>
   */
  const handleIsActive = (index: number) => {
    if (!data?.data) return;

    if (data.data[index].is_active) {
      toast.error("Required one active theme");
      return;
    }

    const theme = data.data[index];
    if (theme) {
      let textIsPublish = "publish";
      let tempToggler = toggler.map((isPublish, current) => {
        const isCurrentIndex = current === index;
        if (isCurrentIndex) {
          textIsPublish = isPublish ? "unpublish" : "publish";
        }
        return isCurrentIndex ? !isPublish : isPublish;
      });

      setToggler(tempToggler);

      publishTheme(theme.id)
        .unwrap()
        .then(() => {
          toast.success(`${theme.name} ${textIsPublish}ed`);
          refetch();
        })
        .catch(() => {
          tempToggler = toggler.map((isPublish, current) => (current === index ? !isPublish : isPublish));

          setToggler(tempToggler);
          toast.error(`${theme.name} ${textIsPublish} failed`);
        });
    }
  };

  /**
   * @description Function to handle click edit theme
   * @param id<string>
   */
  const handleEdit = (id: string) => {
    push({
      pathname: "/theme",
      state: {
        theme_id: id,
      },
    });
  };

  /**
   * @description Function to handle click delete theme to show modal confirmation
   * @param id<string | null>
   */
  const handleDelete = (id: string | null) => {
    if (data?.data?.find((item) => item.id === id)?.is_active) {
      toast.error("Required one active theme");
      return;
    }

    setModalConfirmation({ isOpen: true, data: id });
  };

  /**
   * @description Function to handle callback modal confirmation to delete one or multiple theme
   */
  const callbackConfirmation = () => {
    // eslint-disable-next-line no-return-await
    const deleteThemeData = async (dataTheme: any) => await deleteTheme(dataTheme)
      .unwrap()
      .then(() => {
        toast.success("Successfully delete");
        setChecklist([]);
        refetch();
      })
      .catch(() => {
        toast.error("Failed to delete");
      })
      .finally(() => {
        setModalConfirmation((prevState) => ({
          ...prevState,
          isOpen: false,
        }));
      });

    if (checklist.length > 1) {
      checklist.map((checkedId) => deleteThemeData(checkedId));
      return;
    }

    const { data: modalConfirmationData } = modalConfirmation;

    if (!modalConfirmationData) return;

    deleteThemeData(modalConfirmationData);
  };

  /**
   * @description Function to handle check the theme
   * @param id<string>
   */
  const handleChecklist = (id: string) => {
    if (checklist.find((checkedId) => checkedId === id)) {
      setChecklist(checklist.filter((checkedId) => checkedId !== id));
    } else {
      setChecklist((prevState) => [...prevState, id]);
    }
  };

  /**
   * @description Function to handle search theme
   */
  const filteredComponents = useMemo((): ThemeResponse[] => {
    if (!data?.data) return [];
    if (Object.keys(data.data ?? {}).length === 0) return [];

    const filtered = data.data.filter(({ name }) => name.toLowerCase().includes(debouncedKeyword.toLowerCase()));
    setToggler(filtered.map(({ is_active }) => is_active) ?? []);
    return filtered;
  }, [data?.data, debouncedKeyword]);

  useEffect(() => {
    if (data?.data && Object.keys(data.data).length > 0) {
      setToggler(data.data.map(({ is_active }) => is_active) ?? []);
    }
  }, [data?.data]);

  useEffect(() => {
    const progress = ref.current as any;

    if (isLoading) {
      progress?.continuousStart();
    } else {
      progress?.complete();
    }
  }, [isLoading]);

  if (!data) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <LoadingAnimation />
      </div>
    );
  }

  return (
    <>
      <LoadingBar height={4} color="#0078D3" ref={ref} />
      <div className="flex-1">
        <ContentHeader
          title="All Theme"
          subtitle="Here you can see all theme that you have created. You can try to create a new one also!"
          onSearch={onSearch}
          value={keyword}
          buttonTitle="New Theme"
          onButtonClick={() => push("/theme")}
        />

        {!isLoading && (
        //   <div className={"my-3 mx-6 " + (filteredComponents.length > 0 ? "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6" : "")}>
        //     {filteredComponents.length > 0
        //       ? filteredComponents.map((theme, index) => (
        //         <CardWithOptions
        //           key={theme.id}
        //           item={{
        //             id: theme.id,
        //             title: theme.name,
        //             subtitle: "Theme",
        //             image: theme.background_img ? process.env.REACT_APP_BASE_URL + "/images/" + theme.background_img : imagePlaceholder,
        //             isActive: toggler[index] ?? theme.is_active,
        //           }}
        //           onIsPublish={() => handleIsActive(index)}
        //           isLoadingPublish={isLoadingPublish}
        //           onDelete={handleDelete}
        //           onEdit={handleEdit}
        //         />)
        //       ) : (
        //         <div className="h-full w-full pt-20 md:pt-36 flex-1 flex flex-col gap-1 justify-center items-center">
        //           <IllustrationNoItems className="h-64 w-64 mb-4" />

        //           <p className="text-lg">There's no  {debouncedKeyword.length > 0 ? debouncedKeyword : 'theme'}</p>
        //           <p className="text-secondary">You haven’t add any {debouncedKeyword.length > 0 ? (debouncedKeyword + ' in theme') : 'theme'}</p>
        //         </div>
        //       )
        //     }
        //   </div>
        // )}

          <div className="mx-4 mt-4">
            {data.data && data?.data?.length > 0 && checklist.length > 0 && (
              <div className="flex mt-4">
                <button
                  type="button"
                  title="Delete Selected"
                  disabled={isLoadingDeleteItem || isLoadingPublish}
                  className="button-error text-sm py-2 px-4 font-normal rounded"
                  onClick={() => (checklist.length > 1 ? handleDelete(null) : handleDelete(checklist[0]))}
                >
                  Delete Selected
                </button>
              </div>
            )}

            <div className="hidden md:flex w-full p-4 bg-gray-100 font-medium items-center md:mt-4">
              <div className="pr-4">
                <label className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    name="checked-demo"
                    className="form-tick appearance-none bg-white bg-check h-4 w-4 border border-gray-300 rounded checked:bg-primary checked:border-transparent focus:outline-none cursor-pointer"
                    disabled={isLoadingDeleteItem || isLoadingPublish}
                    onChange={() => {
                      if (data.data) {
                        if (data?.data?.length === checklist.length) {
                          setChecklist([]);
                        } else {
                          setChecklist(data?.data?.map(({ id }) => id));
                        }
                      }
                    }}
                    checked={data.data && data?.data?.length > 0 && checklist.length === data?.data?.length}
                  />
                </label>
              </div>
              <div className="flex flex-1 flex-col md:flex-row">
                <p className=" md:w-1/2 border-l md:pl-4">Theme Name</p>
              </div>
              <div className="flex flex-1 flex-col-reverse md:flex-row">
                <p className="border-l w-24 text-center">Active</p>
                <p className="md:pl-4 border-l flex-1">Action</p>
              </div>
            </div>

            {filteredComponents.length > 0 ? (
              filteredComponents.map((theme, index) => {
                const isChecked = !!checklist.find((checkedId) => checkedId === theme.id);

                const isActive = toggler[index] || theme.is_active;

                return (
                  <div
                    className="flex w-full text-sm p-4 md:border-b md:border-gray-100 rounded-md md:rounded-none shadow-lg md:shadow-none mb-3 md:mb-0 items-center"
                    key={theme.id}
                  >
                    <div className="pr-4 hidden md:block">
                      <label className="flex items-center space-x-3">
                        <input
                          key={isChecked ? "1" : "0"}
                          type="checkbox"
                          disabled={isLoadingDeleteItem || isLoadingPublish}
                          name="checked-modifiers"
                          className="form-tick appearance-none bg-white bg-check h-4 w-4 border border-gray-300 rounded checked:bg-primary checked:border-transparent focus:outline-none cursor-pointer"
                          onChange={() => handleChecklist(theme.id)}
                          defaultChecked={isChecked}
                        />
                      </label>
                    </div>
                    <div className="flex flex-1 flex-col md:flex-row gap-4 md:gap-0">
                      <p className="md:pl-4 md:w-1/2 md:font-normal">{theme.name}</p>
                      <p className="block md:hidden" />
                    </div>
                    <div className="flex flex-1 flex-col md:flex-row gap-4 md:gap-0">
                      <div className="md:w-24 text-right md:text-center">
                        <Switch
                          id={`toggle-${theme.id}`}
                          name={`toggle-${theme.id}`}
                          onChange={() => handleIsActive(index)}
                          checked={isActive}
                          // checked={toggler[index] ?? theme.is_active}
                          disabled={isLoadingDeleteItem || isLoadingPublish || filteredComponents.length === 1}
                        />

                        {/* <div className="relative inline-block w-8 align-middle text-center select-none">

                          <input
                            type="checkbox"
                            id={`toggle-${theme.id}`}
                            name={`toggle-${theme.id}`}
                            className="toggle-checkbox absolute block w-4 h-4 rounded-full bg-white border-2 appearance-none transition-all duration-300 ease-in cursor-pointer"
                            onChange={() => handleIsActive(index)}
                            checked={toggler[index] ?? theme.is_active}
                            disabled={isLoadingDeleteItem || isLoadingPublish || filteredComponents.length === 1}
                          />
                          <label
                            htmlFor={`toggle-${theme.id}`}
                            className="toggle-label block overflow-hidden h-4 rounded-full bg-gray-300 transition-all duration-300 ease-in cursor-pointer"
                          />
                        </div> */}
                      </div>

                      <div className="md:pl-4 flex flex-1 gap-4 justify-end md:justify-start">
                        <button type="button" title="Edit" className="flex items-center gap-2" onClick={() => handleEdit(theme.id)}>
                          <EditIcon className="h-4 w-4" />
                          {" "}
                          <span className="hidden md:block">Edit</span>
                        </button>

                        {/* {(filteredComponents.length > 1 && !isActive) && ( */}
                        {(filteredComponents.length > 1) && (
                          <button type="button" title="Delete" className="flex items-center gap-2" onClick={() => handleDelete(theme.id)} disabled={isActive}>
                            <DeleteIcon className="h-4 w-4" />
                            {" "}
                            <span className="hidden md:block">Delete</span>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="h-full w-full pt-20 md:pt-24 flex-1 flex flex-col gap-1 justify-center items-center">
                <IllustrationNoItems className="h-64 w-64 mb-4" />

                <p className="text-lg">
                  There&#39;s no
                  {" "}
                  {debouncedKeyword.length > 0 ? debouncedKeyword : "theme"}
                </p>
                <p className="text-secondary">
                  You haven&#39;t add any
                  {" "}
                  {debouncedKeyword.length > 0 ? `${debouncedKeyword} in theme` : "theme"}
                </p>
              </div>
            )}
          </div>
        )}
      </div>

      <ModalConfirmation
        isOpen={modalConfirmation.isOpen}
        content="Are you sure?"
        onCloseModal={() => setModalConfirmation({ isOpen: false, data: null })}
        onConfirmModal={callbackConfirmation}
        confirmationText="Delete"
        confirmationLoading={isLoadingDeleteItem}
      />
    </>
  );
}

export default ThemePage;
