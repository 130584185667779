import LocalStorage from "./local-storage";

const Builder = {
  async getQrLink(): Promise<string | null> {
    const baseUrl = process.env.REACT_APP_QR_URL;
    const uid: string | null = await LocalStorage.getValue("uid");

    if (uid) {
      // return baseUrl + '?token=' + uid
      return `${baseUrl}/menu/${uid}`;
    }

    return null;
  },
};

export default Builder;
