/* eslint-disable camelcase */
import { createApi } from "@reduxjs/toolkit/query/react";

import baseQuery from "./base-service";

interface DeviceModel {
  id: string;
  model: string;
  app_version: string;
  os: string;
  os_version: string;
  last_connected: string;
}

export const deviceServices = createApi({
  reducerPath: "devices",
  baseQuery,
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  endpoints: (builder) => ({
    devices: builder.query<HttpResponse<DeviceModel[]>, void>({
      query: () => ({
        url: "/device",
        method: "GET",
      }),
    }),
    deleteDevice: builder.mutation<HttpResponse<{}>, string>({
      query: (id) => ({
        url: "/device",
        method: "DELETE",
        body: { id },
      }),
    }),
    deleteMultipleDevices: builder.mutation<HttpResponse<{}>, string[]>({
      query: (id_array) => ({
        url: "/device/multiple",
        method: "DELETE",
        body: { id_array },
      }),
    }),
  }),
});

export const {
  useDevicesQuery,
  useDeleteDeviceMutation,
  useDeleteMultipleDevicesMutation,
} = deviceServices;
