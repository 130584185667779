const Formatter = {
  currency: (number: number, currency = "USD", country = "en-US") => {
    const num = number.toString();
    const formatted = `${num.substring(0, num.length - 2)}.${num.substring(num.length - 2)}`;
    const options: Intl.NumberFormatOptions = { style: "currency", currency: currency.toUpperCase(), notation: "compact" };

    return new Intl.NumberFormat(country, options).format(parseFloat(formatted));
  },
  lastTwoDot: (number: string) => `${number.substring(0, number.length - 2)}.${number.substring(number.length - 2)}`,
};

export default Formatter;
