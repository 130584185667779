import { useEffect, memo, useState } from "react";
import { useForm, useFieldArray, SubmitHandler } from "react-hook-form";
import { toast } from "react-toastify";

import {
  useStep3Mutation,
  useImageMutation,
  // useAutofillQuery,
} from "../../../services";
import { CardFormItem, LoadingAnimation } from "../../../components";
import { LocalStorage } from "../../../utils";
import { CategoryForm, CategoryFieldsForm } from "../model";

interface Props {
  nextStep: () => void;
  prevStep: () => void;
  resetStep: () => void;
  uid: string;
}

interface ImageField {
  image_id: string;
  path: string;
}

const defaultImageField: ImageField = {
  image_id: "",
  path: "",
};

const AddCategories = memo(({
  nextStep, prevStep, uid, resetStep,
}: Props) => {
  const {
    register, control, handleSubmit, reset, formState,
  } = useForm<CategoryForm>({
    mode: "onBlur",
  });
  const {
    fields,
    append,
    remove,
    // update,
  } = useFieldArray({
    control,
    name: "categories",
  });
  const [imageFields, setImageFields] = useState<ImageField[]>([defaultImageField]);
  // const { data: dataAutofill } = useAutofillQuery(
  //   { type: "category", count: fields.length },
  //   { skip: fields.length === 0 }
  // );
  const [postStep3, { isLoading: isLoadingPostStep3 }] = useStep3Mutation();
  const [postImage, { isLoading: isLoadingPostImage }] = useImageMutation();

  const handleNewCategory = () => {
    if (fields.length >= 3) return;
    append({ name: "", description: "", image: null });
    setImageFields((prevState) => [...prevState, defaultImageField]);
  };

  const onNext: SubmitHandler<{ categories: CategoryFieldsForm[] }> = (data) => {
    const categories = data.categories.map((category, index) => ({
      ...category,
      image: imageFields[index].image_id ?? "",
    }));

    postStep3({ uid, language: "en", categories })
      .unwrap()
      .then((res) => {
        LocalStorage.setValue("register-step3", { language: "en", categories });
        LocalStorage.setValue("register-step3-images", imageFields);
        if (res.data?.uid === uid) {
          nextStep();
        } else {
          resetStep();
        }
      })
      .catch((error) => {
        // resetStep(); // if uid not found or expired
        toast.error(error.data?.message ?? "Upload failed", {
          position: "top-center",
        });
      });
  };

  const uploadImage = (path: string | Blob, index: number) => {
    if (path === "") {
      const tempImages = imageFields;
      tempImages[index] = defaultImageField;
      setImageFields(tempImages);
      return;
    }
    const formData = new FormData();
    formData.append("uid", uid);

    if (path instanceof Blob) {
      formData.append("img", path, "category.jpg");
    } else {
      formData.append("img", path);
    }

    postImage(formData)
      .unwrap()
      .then((res) => {
        setImageFields((prevState) => {
          const newState = prevState.map((state, stateIndex) => {
            if (index === stateIndex && res.data) return res.data;
            return state;
          });
          return newState;
        });
      })
      .catch((error) => {
        toast.error(error.data?.message ?? "Failed to upload", {
          position: "top-center",
        });
      });
  };

  // const autofill = () => {
  //   if (!dataAutofill?.data) return;

  //   dataAutofill.data.forEach((data, index) => {
  //     update(index, { name: data.name, description: data.description });
  //     fetch(process.env.REACT_APP_CDN_URL + "/images/" + data.image).then(async (res) => {
  //       const blob = await res.blob();
  //       uploadImage(blob, index);
  //     });
  //   });
  // };

  useEffect(() => {
    const savedValue = LocalStorage.getValue<{
      language: "en";
      categories: CategoryFieldsForm[];
    }>("register-step3");
    const savedImages = LocalStorage.getValue<ImageField[]>("register-step3-images");

    if (savedImages) {
      setImageFields(savedImages);
    }

    if (savedValue) {
      reset({
        uid,
        language: savedValue.language,
        categories: savedValue.categories,
      });
    } else {
      handleNewCategory();
    }
  }, []);

  return (
    <>
      <h2 className="font-bold text-lg md:text-2xl mb-2">Add Categories</h2>

      <div className="flex w-full justify-between items-center mb-4">
        <p className="text-xs md:text-sm text-secondary">Try with “Starters” or “Desserts”</p>

        {/* <button className="button-link" onClick={autofill}>
          Auto-Fill
        </button> */}
      </div>

      {/* CARDS */}
      {fields.map((field, index) => (
        <CardFormItem
          key={field.id}
          onRemove={() => {
            remove(index);
            setImageFields((prevState) => prevState.filter((_, indexImage) => index !== indexImage));
          }}
          setImagePath={(path: string) => uploadImage(path, index)}
          type="category"
          image={imageFields && imageFields[index]?.path}
        >
          <div>
            <div className="flex flex-col md:flex-row w-full mb-4">
              <p className="text-xs mt-3 opacity-70 w-full md:w-5/12 mb-1 md:mb-0">Category Name</p>
              <input
                type="text"
                className="input-base text-sm flex-auto"
                placeholder="Category Name"
                onKeyDown={(e) => e.key === "," && e.preventDefault()}
                {...register(`categories.${index}.name` as const, {
                  required: true,
                })}
              />
            </div>

            <div className="flex flex-col md:flex-row w-full">
              <p className="text-xs mt-3 opacity-70 w-full md:w-5/12 mb-1 md:mb-0">Description</p>
              <textarea
                className="input-base text-sm flex-auto"
                placeholder="Type Category Description"
                rows={3}
                defaultValue=""
                {...register(`categories.${index}.description` as const)}
              />
            </div>
          </div>
        </CardFormItem>
      ))}

      {fields.length < 3 && (
        <button
          type="button"
          title="New Category"
          className="button-secondary w-full py-2 text-sm"
          onClick={handleNewCategory}
        >
          New Category
        </button>
      )}

      <div className="flex mt-6 w-full md:w-auto md:self-end">
        <button
          type="button"
          title="Back"
          className="button-secondary flex-grow md:flex-grow-0 mr-3"
          onClick={prevStep}
        >
          Back
        </button>

        <button
          type="button"
          className="button-primary flex-grow md:flex-grow-0"
          onClick={handleSubmit(onNext)}
          disabled={!formState.isValid || isLoadingPostStep3 || isLoadingPostImage}
        >
          {isLoadingPostStep3 || isLoadingPostImage ? (
            <div className="flex gap-1">
              <LoadingAnimation size={5} />
              {isLoadingPostStep3 && <span className="text-sm ml-2 font-normal">Loading</span>}
              {isLoadingPostImage && <span className="text-sm ml-2 font-normal">Uploading</span>}
            </div>
          ) : (
            <span>Next</span>
          )}
        </button>
      </div>
    </>
  );
});

export default AddCategories;
