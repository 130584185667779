/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  memo, useEffect, useRef, useState,
} from "react";
import Popup from "reactjs-popup";
import { PopupActions } from "reactjs-popup/dist/types";

import imagePlaceholder from "../assets/image_placeholder.png";
import SectionImage from "../assets/section-image.png";

import { ReactComponent as MoreIcon } from "../assets/icons/ic_more.svg";
import { ReactComponent as EditIcon } from "../assets/icons/ic_edit.svg";
import { ReactComponent as GlobeIcon } from "../assets/icons/ic_globe.svg";
import { ReactComponent as CopyIcon } from "../assets/icons/ic_copy.svg";
import { ReactComponent as RepeatIcon } from "../assets/icons/ic_repeat.svg";
import { ReactComponent as DeleteIcon } from "../assets/icons/ic_trash_bold.svg";
import { ReactComponent as DescriptionIcon } from "../assets/icons/ic_description.svg";

import { getBreakpointValue } from "../utils";
import { Switch } from "./atoms";

function Icon({
  name,
  isPublished,
}: {
  name: string;
  isPublished: boolean;
}) {
  if (name === "description") {
    return (
      <div className="flex justify-start">
        <div
          className={
            isPublished
              ? "rounded-full p-4 bg-primary bg-opacity-10"
              : "rounded-full p-4 bg-line-gray"
          }
        >
          <DescriptionIcon
            className="h-6 w-6"
            color={isPublished ? "#0078D3" : "#666666"}
          />
        </div>
      </div>
    );
  }

  return null;
}

interface Props {
  item: {
    id: string;
    title: string;
    subtitle: string;
    image?: string;
    icon?: string;
    isActive: boolean;
  };
  cardType?: "item" | "section";
  isLoadingPublish: boolean;
  disableSwitchButton?: boolean;
  disableDeleteButton?: boolean;
  onIsPublish: () => void;
  onMove?: (id: string) => void;
  onDelete: (id: string) => void;
  onEnter?: (id: string) => void;
  onEdit?: (id: string) => void;
  onDuplicate?: (id: string) => void;
  isLoadingDuplicate?: boolean;
  goToLocalize?: (id: string) => void;
  alwaysShowMenu?: boolean;
  editOnMenu?: boolean;
  dragable?: boolean;
}

const CardWithOptions = memo(
  ({
    item,
    onIsPublish,
    cardType = "item",
    isLoadingPublish,
    disableSwitchButton,
    disableDeleteButton,
    onDelete,
    onMove,
    onEnter,
    onEdit,
    onDuplicate,
    isLoadingDuplicate,
    goToLocalize,
    alwaysShowMenu = false,
    editOnMenu = false,
    dragable = false,
  }: Props) => {
    const ref = useRef<PopupActions>(null);
    const [open, setOpen] = useState<boolean>(false);

    const isMobile = getBreakpointValue("md") > window.innerWidth;

    useEffect(() => {
      if (!onDuplicate) return;
      if (!isLoadingDuplicate) {
        ref.current?.close();
      }
    }, [isLoadingDuplicate, onDuplicate]);

    return (
      <div
        className="shadow rounded md:pb-1 flex md:flex-col relative bg-white"
        onClick={() => {
          if (isMobile && onEnter) onEnter(item.id);
        }}
      >
        <div
          className={
            `flex-shrink-0 group rounded overflow-hidden md:w-full md:relative ${
              item.icon ? "p-3" : "h-24 md:h-40 w-24"}`
          }
        >
          {item.icon ? (
            <Icon name={item.icon} isPublished={item.isActive} />
          ) : (
            <img
              src={
                cardType === "section"
                  ? SectionImage
                  : item.image ?? imagePlaceholder
              }
              alt={item.title}
              className={`object-cover ${
                cardType === "section" ? "object-left" : "object-center"
              } h-full w-full`}
            />
          )}
          <div
            className={
              `hidden md:flex opacity-0 bg-black bg-opacity-50 transition-opacity ease-out flex-col w-full absolute inset-0 justify-center items-center${
                item.icon ? "" : " group-hover:opacity-100"}`
            }
          >
            {onEnter && (
              <button
                type="button"
                title="Enter"
                className="button-primary py-2 w-28 mb-3"
                onClick={() => onEnter(item.id)}
              >
                Enter
              </button>
            )}

            {!item.icon && onEdit && (
              <button
                type="button"
                title="Edit"
                className="button-success py-2 w-28"
                onClick={() => onEdit(item.id)}
              >
                Edit
              </button>
            )}
          </div>

          {/* duplicate button as client request to shown on modal open */}
          <button
            type="button"
            title="More"
            className={
              `hidden md:block absolute bottom-1 md:bottom-auto md:top-2 md:opacity-0 rounded p-1 bg-white right-2${
                open ? " md:opacity-100" : ""}`
            }
          >
            <MoreIcon />
          </button>

          <Popup
            ref={ref}
            position="bottom right"
            contentStyle={{ width: "auto", background: "white" }}
            closeOnDocumentClick
            closeOnEscape
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            trigger={(
              <button
                type="button"
                className={
                  `absolute bottom-1 md:bottom-auto md:top-2 rounded p-1 bg-white${
                    alwaysShowMenu
                      ? ""
                      : " md:opacity-0 group-hover:opacity-100"
                  }${dragable ? " right-10 md:right-2" : " right-2"}`
                }
              >
                <MoreIcon />
              </button>
            )}
          >
            <div className="w-auto flex flex-col">
              {onEdit && (
                <button
                  type="button"
                  title="Edit"
                  className={
                    `flex items-center hover:bg-gray-200 py-1 px-3 outline-none${
                      editOnMenu ? "" : " md:hidden"}`
                  }
                  onClick={() => onEdit(item.id)}
                >
                  <EditIcon className="h-4 w-4 mr-2 text-primary" />
                  <span className="text-primary text-sm">Edit</span>
                </button>
              )}

              {onMove && (
                <button
                  type="button"
                  title="Move"
                  className="flex items-center hover:bg-gray-200 py-1 px-3 outline-none"
                  onClick={() => onMove(item.id)}
                >
                  <RepeatIcon className="h-4 w-4 mr-2 text-primary" />
                  <span className="text-primary text-sm">Move</span>
                </button>
              )}

              {goToLocalize && (
                <button
                  type="button"
                  title="Localize"
                  className="flex items-center hover:bg-gray-200 py-1 px-3 outline-none"
                  onClick={() => goToLocalize(item.id)}
                >
                  <GlobeIcon className="h-4 w-4 mr-2 text-primary" />
                  <span className="text-primary text-sm">Localize</span>
                </button>
              )}

              {onDuplicate && (
                <button
                  type="button"
                  title="Duplicate"
                  className="flex items-center hover:bg-gray-200 py-1 px-3 outline-none"
                  onClick={() => onDuplicate(item.id)}
                >
                  <CopyIcon className="h-4 w-4 mr-2 text-primary" />
                  <span className="text-primary text-sm">Duplicate</span>
                </button>
              )}

              {onDelete && (
                <button
                  type="button"
                  title="Delete"
                  className="flex items-center hover:bg-gray-200 py-1 px-3 outline-none"
                  onClick={() => onDelete(item.id)}
                  disabled={disableDeleteButton}
                >
                  <DeleteIcon className="h-4 w-4 mr-2 text-primary" />
                  <span className="text-primary text-sm">Delete</span>
                </button>
              )}
            </div>
          </Popup>
        </div>

        <div className="flex flex-1 flex-col text-sm p-3 justify-between w-1/2 md:w-full">
          <div className="flex justify-between mb-1">
            <p className="font-semibold w-5/6 whitespace-nowrap overflow-hidden overflow-ellipsis">
              {item.title}
            </p>

            <Switch
              id={`toggle-${item.id}`}
              name={`toggle-${item.id}`}
              onChange={onIsPublish}
              checked={item.isActive}
              disabled={disableSwitchButton || isLoadingPublish}
            />

            {/* <div className="relative inline-block w-8 align-middle select-none z-10">
              <input
                type="checkbox"
                id={`toggle-${item.id}`}
                name={`toggle-${item.id}`}
                className="toggle-checkbox absolute block w-4 h-4 rounded-full bg-white border-2 appearance-none transition-all duration-300 ease-in cursor-pointer"
                onChange={onIsPublish}
                onClick={(e) => e.stopPropagation()}
                checked={item.isActive}
                disabled={disableSwitchButton || isLoadingPublish}
              />
              <label
                htmlFor={`toggle-${item.id}`}
                title="Publish"
                onClick={(e) => e.stopPropagation()}
                className="toggle-label block overflow-hidden h-4 rounded-full bg-gray-300 transition-all duration-300 ease-in cursor-pointer"
              />
            </div> */}
          </div>
          <p className="text-xs text-secondary">{item.subtitle}</p>
        </div>
      </div>
    );
  },
);

export default CardWithOptions;
