import { ReactNode, useCallback } from "react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  useSensor,
  useSensors,
  DragStartEvent,
  DragEndEvent,
  DragOverlay,
  MouseSensor,
  TouchSensor,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  arrayMove,
} from "@dnd-kit/sortable";
import { debounce } from "../utils";

interface RequiredID {
  id: string;
}

interface Props<T extends RequiredID> {
  children: ReactNode;
  overlayChildren: ReactNode;
  items: T[];
  setItems: (arg0: T[]) => void;
  setActiveId: (arg0: string | null) => void;
  onDragEnd: (ids: string[]) => void;
}

function SortableGridContainer<T extends RequiredID>({
  children,
  overlayChildren,
  items,
  setItems,
  setActiveId,
  onDragEnd,
}: Props<T>) {
  const reorderItems = useCallback(
    debounce(onDragEnd),
    [], // will be created only once initially
  );

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    setActiveId(active.id);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over?.id) return;
    if (active.id !== over.id) {
      const oldIndex = items.findIndex(({ id }) => id === active.id);
      const newIndex = items.findIndex(({ id }) => id === over.id);
      const arrayMoved = arrayMove(items, oldIndex, newIndex);
      setItems(arrayMoved);
      reorderItems(arrayMoved.map((item) => item.id));
    }
    setActiveId(null);
  };

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items}>{children}</SortableContext>
      <DragOverlay>{overlayChildren}</DragOverlay>
    </DndContext>
  );
}

export default SortableGridContainer;
