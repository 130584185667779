import { ReactComponent as ChevronDownIcon } from "../../assets/icons/ic_chevron_down.svg";

interface Options {
  value: string;
  label: string;
  disabled?: boolean;
}
interface SelectComponent<T> {
  name: string;
  options: Options[];
  value?: T;
  defaultValue?: T;
  onChange?: (e: SelectChangeType) => void;
  selectRef?: any;
  customClass?: string;
  placeholder?: string;
  disabled?: boolean
}

function Select<T extends string>({
  name,
  value,
  defaultValue,
  options,
  onChange,
  selectRef,
  customClass,
  disabled,
  placeholder,
}: SelectComponent<T>) {
  return (
    <div className="relative flex-grow">
      <select
        id={name}
        name={name}
        className={`input-base capitalize w-full ${customClass || ""}`}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        ref={selectRef}
        placeholder={placeholder}
        disabled={disabled}
      >
        {options.map(({ value: optionValue, label, disabled: disabledOption }) => (
          <option value={optionValue} key={optionValue + label} disabled={disabledOption}>
            {label}
          </option>
        ))}
      </select>

      <div className="absolute top-1/2 right-2 transform -translate-y-1/2 w-4 h-4">
        <ChevronDownIcon />
      </div>
    </div>
  );
}

export default Select;
