import clsx from "clsx";
import {
  ChangeEvent, forwardRef,
  useEffect, useMemo, useRef, useState,
} from "react";
import { exportComponentAsJPEG } from "react-component-export-image";
import Pagination from "react-paginate";
import QRCode from "react-qr-code";
import LoadingBar from "react-top-loading-bar";
import Popup from "reactjs-popup";
// import html2canvas from "html2canvas";

import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  ContentHeader, LoadingAnimation, ModalConfirmation,
} from "../components";
import {
  Table, TableBody, TableData, TableHead, TableHeader, TableRow,
} from "../components/atoms";
import { useDebounce, useQrLink } from "../hooks";
import {
  TableModel, useCreateTableMutation, useDeleteMultipleTableMutation, useDeleteTableMutation,
  useGetAllTableQuery,
  useUpdateTableMutation,
} from "../services";
import { getBreakpointValue } from "../utils";

import { ReactComponent as CloseIcon } from "../assets/icons/ic_close.svg";
import { ReactComponent as DownloadIcon } from "../assets/icons/ic_download.svg";
import { ReactComponent as EditIcon } from "../assets/icons/ic_edit.svg";
import { ReactComponent as DeleteIcon } from "../assets/icons/ic_trash_bold.svg";
import { ReactComponent as IllustrationNoItems } from "../assets/illustration_no_items.svg";

interface RenderDownloadQRProps {
  tableName: string;
  tableId: string;
  qrLink: string;
}

const tableFormDefaultValue = {
  id: "",
  name: "",
};

const limitPerPage: number[] = [
  10,
  25,
  50,
  100,
];

const RenderDownloadQR = forwardRef<any, RenderDownloadQRProps>(
  ({ tableName, tableId, qrLink }, ref) => (
    <div ref={ref} className="flex flex-col items-center justify-center w-full max-w-[479px] min-h-[493px] p-4 overflow-auto absolute left-0 -top-[750px]" id="qr-download">
      {(tableName && tableId) && (
        <>
          <QRCode
            id="qrcode-web"
            value={`${qrLink}?table_id=${tableId}&table=${tableName}`}
            fgColor="#000"
            size={445}
            height={445}
            width={445}
          />
          <span className="block text-center mt-4 font-bold">
            Table:
            {" "}
            {tableName}
          </span>
        </>
      )}
    </div>
  ),
);

function TablesPage() {
  const [keyword, setKeyword] = useState<string>("");
  const [checkedId, setCheckedId] = useState<string[]>([]);
  const [downloadQRTable, setDownloadQRTable] = useState<{
    id: string;
    name: string;
  }>({
    id: "",
    name: "",
  });
  const [editId, setEditId] = useState<string>("");
  const [deleteId, setDeleteId] = useState<string>("");
  const [isDataUpdated, setIsDataUpdated] = useState<boolean>(false);
  // modals
  const [isModalOpened, setModal] = useState<boolean>(false);
  const [isModalConfirmationOpened, setModalConfirmation] = useState<boolean>(false);
  // Pagination
  const [pageCount, setPageCount] = useState<number>(0);
  const [itemOffset, setItemOffset] = useState<number>(0);
  const [itemLimit, setItemLimit] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const { data: tableData, refetch: refetchData, isLoading: isLoadingTable } = useGetAllTableQuery();
  const [updateTable, { isLoading: isLoadingUpdateTable }] = useUpdateTableMutation();
  const [createTable, { isLoading: isLoadingCreateTable }] = useCreateTableMutation();
  const [deleteTable, { isLoading: isLoadingDeleteTable }] = useDeleteTableMutation();
  const [deleteMultipleTable, { isLoading: isLoadingDeleteMultipleTable }] = useDeleteMultipleTableMutation();

  const loadingBarRef = useRef(null);
  const qrDownloadRef = useRef<any>(null);
  const { qrLink: QRLink } = useQrLink();
  const debouncedKeyword = useDebounce(keyword, 500);
  const {
    register, reset, watch, handleSubmit,
  } = useForm<TableModel>({
    defaultValues: tableFormDefaultValue,
  });

  const isMobile = getBreakpointValue("md") > window.innerWidth;

  const data: TableModel[] = useMemo<TableModel[]>(
    () => [...(tableData?.data || [])]
      .filter(({ name }) => name.toLowerCase().includes(debouncedKeyword.toLowerCase())),
    [tableData?.data, debouncedKeyword],
  );
  const slicedTables: TableModel[] = useMemo<TableModel[]>(
    () => data.slice(itemOffset, itemOffset + itemLimit),
    [data, itemOffset, itemLimit],
  );

  const checkAll = () => setCheckedId(() => [...slicedTables.map(({ id }) => id)]);
  const uncheckAll = () => setCheckedId([]);

  const handleCheck = (id: string) => {
    if (checkedId.find((ids) => ids === id)) {
      const removedChecklist = checkedId.filter((ids) => ids !== id);

      setCheckedId(removedChecklist);
    } else {
      setCheckedId((ids) => [...ids, id]);
    }
  };

  const handleSetDataPerPage = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = Number.isNaN(parseInt(event.target.value, 10)) ? 0 : parseInt(event.target.value, 10);

    setItemLimit(value);
    setItemOffset(value * currentPage);
    setIsDataUpdated(true);
  };

  const handleOnSubmit: SubmitHandler<TableModel> = (formData) => {
    const table = data.find(({ id }) => id === formData.id);

    if (table) {
      updateTable({
        ...table,
        name: formData.name,
      })
        .unwrap()
        .then((res) => {
          toast.success(res.message ?? "Table updated!");
          refetchData();
        }).catch((error) => {
          toast.error(error.data?.message ?? "Failed to update table");
        });
    } else {
      createTable(formData)
        .unwrap()
        .then((res) => {
          toast.success(res.message || "Table added!");
          refetchData();
        })
        .catch((error) => {
          toast.error(error.data?.message ?? "Failed to add table");
        });
    }

    setModal(false);
    reset(tableFormDefaultValue);
  };

  const handleDeleteTable = () => {
    deleteTable(deleteId)
      .unwrap()
      .then((res) => {
        toast.success(res.message || "Table deleted!");
        refetchData();
      })
      .catch((error) => {
        toast.error(error.data?.message ?? "Failed to delete table");
      })
      .finally(() => {
        setModalConfirmation(false);
        setDeleteId("");
        setIsDataUpdated(true);
      });
  };

  const handleDeleteMultipleTable = () => {
    deleteMultipleTable({
      id_array: checkedId,
    })
      .unwrap()
      .then((res) => {
        toast.success(res.message || "Table deleted!");
        refetchData();
      })
      .catch((error) => {
        toast.error(error.data?.message ?? "Failed to delete table");
      })
      .finally(() => {
        setModalConfirmation(false);
        uncheckAll();
        setIsDataUpdated(true);
      });
  };

  const downloadQR = async (table: TableModel) => {
    if (!qrDownloadRef.current) return;

    setDownloadQRTable({
      id: table.id,
      name: table.name,
    });
  };

  useEffect(() => {
    const progress = loadingBarRef.current as any;

    async function downloadQRCode() {
      if (downloadQRTable.name) {
        await toast.promise(
          exportComponentAsJPEG(qrDownloadRef, {
            fileName: `qr-table-${downloadQRTable.name.split(" ").join("-")}.jpg`,
          }),
          {
            success: "QR Code Downloaded!",
            error: "Failed to generate QR Code",
            pending: "Generating QR Code...",
          },
        ).finally(() => setDownloadQRTable({
          id: "",
          name: "",
        }));
      } else {
        progress?.complete();
      }
    }

    downloadQRCode();
  }, [downloadQRTable]);

  useEffect(() => {
    if (editId) {
      const table = data.find(({ id: tableId }) => tableId === editId);

      if (table) {
        reset(table);
        setModal(true);
      }
    } else {
      setModal(false);
      reset(tableFormDefaultValue);
    }
  }, [editId]);

  useEffect(() => {
    if (debouncedKeyword) {
      setItemOffset(0);
      setCurrentPage(0);
    }
  }, [debouncedKeyword]);

  useEffect(() => {
    if (!tableData) return;

    const pageCounts = Math.ceil((data.length ?? 0) / itemLimit);

    setPageCount(pageCounts);
    uncheckAll();
    setIsDataUpdated(true);
  }, [tableData, itemOffset, itemLimit, debouncedKeyword]);

  useEffect(() => {
    if (tableData?.data) {
      setIsDataUpdated(true);
    }
  }, [tableData]);

  useEffect(() => {
    if (isDataUpdated) {
      const newOffset = data.length === 0
        ? (itemOffset <= 0 ? 0 : itemOffset - itemLimit)
        : (slicedTables.length === 0 ? 0 : itemOffset);
      const newPage = data.length === 0
        ? (currentPage === 0 ? 0 : currentPage - 1)
        : (slicedTables.length === 0 ? 0 : currentPage);

      setItemOffset(newOffset);
      setCurrentPage(newPage);

      setIsDataUpdated(false);
    }
  }, [isDataUpdated]);

  useEffect(() => {
    const progress = loadingBarRef.current as any;

    if (isLoadingTable
      || isLoadingUpdateTable
      || isLoadingCreateTable
      || isLoadingDeleteMultipleTable
      || isLoadingDeleteTable) {
      progress?.continuousStart();
    } else {
      progress?.complete();
    }
  }, [isLoadingTable, isLoadingUpdateTable, isLoadingCreateTable, isLoadingDeleteMultipleTable, isLoadingDeleteTable]);

  if (!tableData) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <LoadingAnimation />
      </div>
    );
  }

  return (
    <>
      <LoadingBar height={4} color="#0078D3" ref={loadingBarRef} />

      <div className="flex flex-col flex-1 max-h-[90vh]">
        <ContentHeader
          title="Tables"
          subtitle="Here you can see all tables"
          onSearch={setKeyword}
          value={keyword}
          buttonTitle="New Table"
          onButtonClick={() => setModal(true)}
        />

        {/* delete button */}
        {data.length > 0 && checkedId.length > 0 && (
          <div className="flex mt-4 px-4">
            <button
              type="button"
              title="Delete Selected"
              className="button-error text-sm py-2 px-4 font-normal rounded"
              onClick={() => {
                setModalConfirmation(true);
              }}
            >
              Delete Selected
            </button>
          </div>
        )}

        <Table className={clsx(
          "mx-4",
        )}
        >
          <TableHead className={clsx(
            "hidden md:flex md:items-center",
            "w-full bg-gray-100",
            "font-medium",
            "p-4 mt-4",
          )}
          >
            <TableHeader className="pr-4">
              <label className="flex items-center space-x-3">
                <input
                  type="checkbox"
                  name="check-all"
                  className={clsx(
                    "form-tick",
                    "appearance-none cursor-pointer rounded",
                    "border border-gray-300 checked:border-transparent",
                    "focus:outline-none",
                    "bg-white checked:bg-check checked:bg-primary",
                    "h-4 w-4",
                  )}
                  onChange={() => {
                    if (slicedTables.length === checkedId.length) {
                      const tables: string[] = slicedTables.map(({ id: tableId }) => tableId);

                      setCheckedId((prevState) => [
                        ...prevState
                          .filter((id) => (!tables.find((tableId) => tableId === id) ? id : null))
                          .filter((id) => id),
                      ]);
                    } else {
                      checkAll();
                    }
                  }}
                  checked={checkedId.length > 0 && checkedId.length === slicedTables.length}
                />
              </label>
            </TableHeader>
            <TableHeader className={clsx(
              "pl-4",
              "flex-1",
              "border-l",
            )}
            >
              Table Name
            </TableHeader>
            <TableHeader className={clsx(
              "pl-4",
              "flex-1",
              "border-l",
            )}
            >
              Action
            </TableHeader>
          </TableHead>

          <TableBody className="min-h-[65%] mb-6">
            {slicedTables.length > 0 ? (
              slicedTables.map((table) => {
                const isChecked = !!checkedId.find((id) => id === table.id);

                return (
                  <TableRow
                    key={table.id}
                    className={clsx(
                      "rounded-md md:rounded-none",
                      "shadow-lg md:shadow-none",
                      "flex items-center",
                      "w-full",
                      "text-sm",
                      "p-4 mb-3 mt-4 md:mt-0 md:mb-0",
                      "md:border-b md:border-gray-100",
                    )}
                  >
                    <TableData className="pr-4 hidden md:block">
                      <label className="flex items-center space-x-3">
                        <input
                          key={isChecked ? "1" : "0"} // hack to force update
                          type="checkbox"
                          name="checked-banner"
                          className={clsx(
                            "form-tick",
                            "appearance-none cursor-pointer rounded",
                            "border border-gray-300 checked:border-transparent",
                            "focus:outline-none",
                            "bg-white checked:bg-check checked:bg-primary",
                            "h-4 w-4",
                          )}
                          onChange={() => handleCheck(table.id)}
                          checked={isChecked}
                        />
                      </label>
                    </TableData>

                    <div className="flex flex-row flex-wrap md:flex-nowrap gap-4 w-full">
                      <TableData className={clsx(
                        "flex flex-col flex-1 gap-2 md:flex-row",
                        "w-full md:w-1/2",
                      )}
                      >
                        {/* <p className="md:pl-4 flex-1 text-sm">{table.name}</p> */}
                        <p className="md:pl-4 flex-1 font-bold text-sm">{table.name}</p>
                      </TableData>

                      <TableData className={clsx(
                        "flex justify-end md:justify-start gap-4 md:gap-4",
                        "md:pl-4",
                        "w-full md:w-1/2",
                      )}
                      >
                        <button
                          type="button"
                          title="Edit"
                          // className="flex items-center gap-2"
                          className="flex items-center gap-2 font-bold"
                          onClick={() => setEditId(table.id)}
                        >
                          <EditIcon className="h-4 w-4 text-[#666]" />
                          {" "}
                          <span className="hidden md:block">Edit</span>
                        </button>

                        <button
                          type="button"
                          title="Delete"
                          // className="flex items-center gap-2"
                          className="flex items-center gap-2 font-bold"
                          onClick={() => {
                            setDeleteId(table.id);
                            setModalConfirmation(true);
                          }}
                        >
                          <DeleteIcon className="h-4 w-4 text-[#666]" />
                          {" "}
                          <span className="hidden md:block">Delete</span>
                        </button>

                        <button
                          type="button"
                          title="Download"
                          // className="flex items-center gap-2"
                          className="flex items-center gap-2 font-bold"
                          onClick={() => downloadQR(table)}
                        >
                          <DownloadIcon className="h-4 w-4 text-[#666]" />
                          {" "}
                          <span className="hidden md:block">Download</span>
                        </button>
                      </TableData>
                    </div>
                  </TableRow>
                );
              })
            ) : (
              <div className="h-full w-full mt-16 flex-1 flex flex-col gap-1 justify-center items-center">
                <IllustrationNoItems className="h-64 w-64 mb-4" />

                <p className="text-lg">
                  There&#39;s no
                  {" "}
                  {debouncedKeyword.length > 0 ? debouncedKeyword : "table"}
                </p>
                <p className="text-secondary">
                  You haven&#39;t add any
                  {" "}
                  {debouncedKeyword.length > 0 ? `${debouncedKeyword} in table` : "table"}
                </p>
              </div>
            )}
          </TableBody>
        </Table>

        <div className="flex flex-col md:flex-row items-center justify-center md:justify-between mx-4 pb-8">
          {/* Paginations */}
          {data.length > itemLimit ? (
            <div className="flex-1 mb-6">
              <Pagination
                breakLabel="..."
                nextLabel="›"
                previousLabel="‹"
                marginPagesDisplayed={1}
                onPageChange={({ selected }: { selected: number }) => {
                  const newOffset = (selected * itemLimit);

                  setItemOffset(newOffset);
                  setCurrentPage(selected);
                }}
                forcePage={currentPage}
                pageRangeDisplayed={4}
                pageCount={pageCount}
                containerClassName="flex gap-2 items-center text-sm font-bold"
                activeClassName="text-active border-primary outline-primary"
                activeLinkClassName="text-active border-primary outline-primary"
                pageClassName="rounded border border-gray-300 w-8 h-8 flex items-center justify-center font-bold"
                previousClassName="rounded border border-gray-300 w-8 h-8 flex items-center justify-center"
                nextClassName="rounded border border-gray-300 w-8 h-8 flex items-center justify-center"
                pageLinkClassName="block w-full text-center focus:outline-none"
                nextLinkClassName="block w-full text-center focus:outline-none"
                previousLinkClassName="block w-full text-center focus:outline-none"
              />
            </div>
          ) : (
            <div className="flex-1" />
          )}

          {/* Limit items per page */}
          <div className="flex items-center justify-end w-full md:w-auto">
            <span className="font-bold mr-4">Show:</span>

            <select
              value={itemLimit}
              onChange={handleSetDataPerPage}
              className="border border-[#d9d9d9] bg-transparent rounded-[4px] py-1 px-3 font-bold"
            >
              {limitPerPage.map((number) => (
                <option value={number} key={number.toString()}>
                  {number}
                  {" "}
                  per page
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <RenderDownloadQR
        ref={qrDownloadRef}
        tableId={downloadQRTable.id}
        tableName={downloadQRTable.name}
        qrLink={QRLink || ""}
      />

      {/* Modal confirmation */}
      <ModalConfirmation
        isOpen={isModalConfirmationOpened}
        content="Are you sure?"
        onCloseModal={() => setModalConfirmation(false)}
        onConfirmModal={deleteId ? handleDeleteTable : handleDeleteMultipleTable}
        confirmationText="Delete"
        confirmationLoading={isLoadingDeleteTable || isLoadingDeleteMultipleTable}
      />

      {/* Modal add or edit Table */}
      <Popup
        modal
        open={isModalOpened}
        onClose={() => (editId ? setEditId("") : setModal(false))}
        closeOnEscape
        lockScroll
        contentStyle={{
          maxHeight: isMobile ? "100%" : "80%",
          maxWidth: isMobile ? "90%" : 500,
          width: "100%",
          overflowY: "auto",
          backgroundColor: "white",
          borderRadius: 8,
          padding: 0,
        }}
      >
        <form
          onSubmit={handleSubmit(handleOnSubmit)}
          className="w-full md:w-auto inset-0 md:relative py-6 bg-white"
        >
          <div className="flex items-center justify-between pb-5 px-4 border-b-[1px]">
            <span className="font-semibold pr-4">{editId ? "Edit Table" : "New Table"}</span>

            <button
              type="button"
              title="Close Modal"
              onClick={() => (editId ? setEditId("") : setModal(false))}
            >
              <CloseIcon className="w-5 h-5" />
            </button>
          </div>

          <div className="flex flex-col items-center justify-center w-full p-6">
            {editId && (() => {
              const table = data.find(({ id }) => id === editId) || null;

              return (
                <QRCode
                  id="qrcode-web"
                  value={`${QRLink}/?table_id=${table?.id}&table=${table?.name}`}
                  fgColor="#000"
                  size={189}
                />
              );
            })()}

            <div
              id="input-group"
              className={clsx(
                "flex flex-col",
                "w-full max-w-[393.72px]",
                editId ? "mt-5" : "",
              )}
            >
              <label htmlFor="name" className="mb-2">Table Name</label>
              <input
                type="text"
                {...register("name")}
                className="input-base w-full"
              />
            </div>
          </div>

          <div className="flex items-center justify-center gap-4 px-4 md:px-6 w-full md:w-auto">
            <button
              type="submit"
              disabled={editId ? (
                data.find(({ id }) => id === editId)?.name === watch().name
              ) : (
                watch().name === "" || watch().name.length === 0
              )}
              className="button-primary py-2 px-5 flex-1 md:flex-none md:w-[155px]"
            >
              Save
            </button>

            <button
              type="button"
              title="Cancel"
              className="button-gray py-2 px-5 flex-1 md:flex-none md:w-[155px]"
              onClick={(e) => {
                e.preventDefault();

                if (editId) {
                  setEditId("");
                  return;
                }

                setModal(false);
                reset(tableFormDefaultValue);
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </Popup>
    </>
  );
}

export default TablesPage;
