import { useEffect, useState, useRef } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingBar from "react-top-loading-bar";

import { ReactComponent as DetailIcon } from "../assets/icons/ic_detail.svg";
import { ReactComponent as ThumbnailIcon } from "../assets/icons/ic_thumbnail.svg";
import { ReactComponent as GridIcon } from "../assets/icons/ic_menu.svg";
import { ReactComponent as ListIcon } from "../assets/icons/ic_list.svg";
import { ReactComponent as ChevronDownIcon } from "../assets/icons/ic_chevron_down.svg";
import { DropUploader, ModalConfirmation, LoadingAnimation } from "../components";
import {
  useImageMutation,
  usePostCategoryMutation,
  useUpdateCategoryMutation,
  useGetCategoryQuery,
  AddCategoryRequest,
  DisplayAs,
  NumberOfColumns,
  useUpdateCategoryPhotoMutation,
} from "../services";
import { useAppSelector } from "../app/hooks";

interface Confirmation {
  isOpen: boolean;
  data: AddCategoryRequest | "close" | null;
}

function CategoriesAddPage() {
  const { state } = useLocation<StateCategoryID>();
  const { goBack } = useHistory();
  const uid = useAppSelector(({ auth }) => auth.uid);

  const [modalConfirmation, setModalConfirmation] = useState<Confirmation>({
    isOpen: false,
    data: null,
  });

  const { data: dataCategory, isLoading: isLoadingGetCategory } = useGetCategoryQuery(state?.category_id ?? "", {
    skip: !state?.category_id ?? true,
  });
  const {
    register, handleSubmit, reset, setValue, formState, watch,
  } = useForm<AddCategoryRequest>({
    defaultValues: {
      name: "",
      description: null,
      image: null,
      displayType: null,
      numOfCol: null,
      isPublished: true,
    },
  });

  const [postImage, { isLoading: isLoadingImage, isError: isErrorImage }] = useImageMutation();
  const [updateCategoryPhoto, { isLoading: isLoadingUpdateCategoryPhoto }] = useUpdateCategoryPhotoMutation();
  const [updateCategory, { isLoading: isLoadingUpdate }] = useUpdateCategoryMutation();
  const [createCategory, { isLoading: isLoadingCreate }] = usePostCategoryMutation();

  const ref = useRef(null);
  const displayType = watch("displayType");

  /**
   * @description Handle submit form data
   * @param data
   * @returns void
   */
  const onSubmit: SubmitHandler<AddCategoryRequest> = (data) => {
    // START VALIDATION
    const errorRequired: string[] = [];
    if (data.name === "") errorRequired.push("Name");
    if (errorRequired.length > 0) {
      toast.error(`${errorRequired.join(" & ")} is required`, { toastId: "error-category" });
      return;
    }

    if (data.displayType === "grid" && !(data.numOfCol === "2" || data.numOfCol === "3")) {
      toast.error("Display grid required number of columns", { toastId: "error-category" });
      return;
    }
    // END VALIDATION

    setModalConfirmation({ isOpen: true, data });
  };

  /**
   * @description Handle callback confirmation from onSubmit function
   * @returns
   */
  const callbackConfirmation = () => {
    const { data } = modalConfirmation;

    if (data === "close") {
      goBack();
      return;
    }

    if (!data) return;

    if (data.description === null) {
      data.description = "";
    }

    if (data.displayType === null) {
      data.displayType = "list";
    }

    if (data.displayType === "list") {
      data.numOfCol = "0";
    }

    if (state?.category_id) {
      const id = state.category_id;
      const { image } = data;

      let firstUpdate = dataCategory?.data?.image === image
        ? Promise.resolve({}) : updateCategoryPhoto({ id, photo: image }).unwrap();

      if (dataCategory?.data?.image && image === "") {
        firstUpdate = updateCategoryPhoto({ id, photo: null }).unwrap();
      }
      const secondUpdate = updateCategory({ ...data, id }).unwrap();

      Promise.all([firstUpdate, secondUpdate])
        .then(goBack)
        .catch((error) => {
          toast.error(error.data?.message ?? "Failed to create category");
        });
    } else {
      createCategory(data)
        .unwrap()
        .then(goBack)
        .catch((error) => {
          toast.error(error.data?.message ?? "Failed to create category");
        });
    }

    setModalConfirmation((prevState) => ({ ...prevState, isOpen: false }));
  };

  /**
   * @description Function to handle upload image
   * @param path<string>
   * @returns void
   */
  const uploadImage = (path: string) => {
    if (path === "") {
      setValue("image", "", {
        shouldDirty: true,
      });
      return;
    }
    if (!uid) return;

    const formData = new FormData();
    formData.append("uid", uid);
    formData.append("img", path);

    postImage(formData)
      .unwrap()
      .then((res) => {
        if (res.data?.image_id) {
          setValue("image", res.data.image_id, {
            shouldDirty: true,
          });
        }
      })
      .catch((error) => {
        setValue("image", "", {
          shouldDirty: true,
        });
        toast.error(error.data?.message ?? "Failed to upload");
      });
  };

  useEffect(() => {
    if (state?.category_id && dataCategory?.data) {
      const {
        name, description, image, display_as, num_of_columns, publish_status,
      } = dataCategory.data;

      const category: AddCategoryRequest = {
        name,
        description,
        image,
        displayType: display_as as DisplayAs,
        numOfCol: num_of_columns.toString() as NumberOfColumns,
        isPublished: publish_status,
      };
      reset(category);
    }
  }, [state?.category_id, dataCategory, reset]);

  useEffect(() => {
    const progress = ref.current as any;
    if (isLoadingGetCategory) {
      progress?.continuousStart();
    } else {
      progress?.complete();
    }
  }, [isLoadingGetCategory]);

  return (
    <>
      <LoadingBar height={4} color="#0078D3" ref={ref} />
      <div className="flex flex-col flex-1">
        <div className="flex justify-between p-4 border-b border-line-gray">
          <div className="flex items-center">
            <button
              className="bg-transparent"
              type="button"
              title="Back"
              onClick={goBack}
            >
              <ChevronDownIcon className="h-6 w-6 transform rotate-90 mr-2" />
            </button>
            <h2 className="font-bold text-2xl">{state?.category_id ? "Update Category" : "Add New Category"}</h2>
          </div>

          <div className="hidden md:flex">
            <button
              type="button"
              title="Cancel"
              className="button-gray py-2 px-4 mr-4"
              onClick={() => {
                if (formState.isDirty) {
                  setModalConfirmation({ isOpen: true, data: "close" });
                } else {
                  goBack();
                }
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="button-primary py-2 px-4"
              onClick={handleSubmit(onSubmit)}
              disabled={!formState.isDirty || isLoadingImage || isLoadingUpdate}
            >
              {isLoadingCreate || isLoadingImage || isLoadingUpdate || isLoadingUpdateCategoryPhoto ? (
                <div className="flex">
                  <LoadingAnimation size={5} />
                  {isLoadingImage && <span className="text-sm ml-2 font-normal">uploading image</span>}
                  {(isLoadingCreate || isLoadingUpdate) && <span className="text-sm ml-2 font-normal">Saving</span>}
                </div>
              ) : (
                <span>Save</span>
              )}
            </button>
          </div>
        </div>

        <div className="p-4 md:p-6">
          <form action="">
            <div className="flex items-center mb-1">
              <DetailIcon />
              <p className="font-semibold text-lg ml-1">Category Details</p>
            </div>
            <p className="text-sm text-secondary opacity-70 mb-7">Fill the category details below</p>

            <div className="flex flex-col md:flex-row mb-7 md:items-center">
              <p className="md:w-4/12 mb-2 md:mb-0 font-medium">Name</p>
              <input
                type="text"
                className="input-base flex-1 max-w-xl"
                placeholder="Add a category name"
                {...register("name")}
              />
            </div>

            <div className="flex flex-col md:flex-row mb-7">
              <p className="md:w-4/12 mb-2 md:mb-0 font-medium">Description</p>
              <textarea
                rows={3}
                className="input-base flex-1 max-w-xl"
                placeholder="Type a category description"
                {...register("description")}
              />
            </div>

            <div className="flex flex-col md:flex-row mb-7 items-start">
              <div className="flex items-center md:w-4/12 mb-2 md:mb-0">
                <ThumbnailIcon />
                <p className="font-semibold text-lg ml-1">image</p>
              </div>

              <div className="flex flex-col">
                <DropUploader
                  type="primary"
                  withText
                  size="big"
                  setFilePath={uploadImage}
                  preview={dataCategory?.data?.image}
                  uploadFailed={isErrorImage}
                />
                <input type="text" {...register("image")} className="hidden" />
              </div>
            </div>

            <div className="flex mb-7 justify-between md:justify-start items-center">
              <p className="md:w-4/12 mb-2 md:mb-0 font-medium">Display As</p>
              <div className="flex items-center gap-2">
                <input
                  type="radio"
                  id="radio-grid"
                  {...register("displayType")}
                  className="radio-text hidden"
                  value="grid"
                />
                <label htmlFor="radio-grid">
                  <GridIcon className="h-4 w-4" />
                  Grid
                </label>

                <input
                  type="radio"
                  id="radio-list"
                  {...register("displayType")}
                  className="radio-text hidden"
                  value="list"
                />
                <label htmlFor="radio-list">
                  <ListIcon className="h-4 w-4" />
                  List
                </label>
              </div>
            </div>

            {displayType === "grid" && (
              <div className="flex mb-7 justify-between md:justify-start items-center">
                <p className="md:w-4/12 mb-2 md:mb-0 font-medium">Number of Columns</p>
                <div className="flex items-center gap-2">
                  <input
                    {...register("numOfCol")}
                    type="radio"
                    id="radio-col-2"
                    className="radio-text hidden"
                    value="2"
                  />
                  <label htmlFor="radio-col-2">2</label>

                  <input
                    {...register("numOfCol")}
                    type="radio"
                    id="radio-col-3"
                    className="radio-text hidden"
                    value="3"
                  />
                  <label htmlFor="radio-col-3">3</label>
                </div>
              </div>
            )}
            <div className="flex justify-between md:justify-start mb-7 items-center">
              <p className="md:w-4/12 mb-2 md:mb-0 font-medium">Publish Status</p>
              <div className="flex items-center">
                <div className="relative inline-block w-8 mr-2 align-middle select-none">
                  <input
                    type="checkbox"
                    {...register("isPublished")}
                    id="isPublished"
                    className="toggle-checkbox absolute block w-4 h-4 rounded-full bg-white border-2 appearance-none transition-all duration-300 ease-in cursor-pointer"
                  />
                  <label
                    htmlFor="isPublished"
                    className="toggle-label block overflow-hidden h-4 rounded-full bg-gray-300 transition-all duration-300 ease-in cursor-pointer"
                  />
                </div>
                <p>Published</p>
              </div>
            </div>
          </form>
        </div>

        <button
          type="button"
          className="button-primary py-2 px-4 m-4 mt-0 md:hidden"
          onClick={handleSubmit(onSubmit)}
          disabled={!formState.isDirty || isLoadingImage || isLoadingUpdate}
        >
          {isLoadingCreate || isLoadingImage || isLoadingUpdate || isLoadingUpdateCategoryPhoto ? (
            <div className="flex">
              <LoadingAnimation size={5} />
              {isLoadingImage && <span className="text-sm ml-2 font-normal">uploading image</span>}
              {(isLoadingCreate || isLoadingUpdate) && <span className="text-sm ml-2 font-normal">Saving</span>}
            </div>
          ) : (
            <span>Save</span>
          )}
        </button>
      </div>

      <ModalConfirmation
        isOpen={modalConfirmation.isOpen}
        content="Are you sure?"
        onCloseModal={() => setModalConfirmation({ isOpen: false, data: null })}
        onConfirmModal={callbackConfirmation}
        confirmationText={modalConfirmation.data === "close" ? "Close" : "Save"}
      />
    </>
  );
}

export default CategoriesAddPage;
