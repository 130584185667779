import {
  memo, useEffect, useRef, useState,
} from "react";
import { toast } from "react-toastify";
import LoadingBar from "react-top-loading-bar";

import { LocalStorage } from "../../../utils";
import { LoadingAnimation } from "../../../components";
import { useStep2Mutation, useOutputListQuery } from "../../../services";
import { ReactComponent as CheckmarkIcon } from "../../../assets/icons/ic_check_blue.svg";
import { ReactComponent as ChecknoneIcon } from "../../../assets/icons/ic_check_none.svg";

interface Props {
  nextStep: () => void;
  prevStep: () => void;
  resetStep: () => void;
  uid: string;
}

const Checkbox = memo(({ isChecked }: { isChecked: boolean }) => (isChecked ? <CheckmarkIcon /> : <ChecknoneIcon />));

const SolutionChecklist = memo(
  ({
    nextStep, prevStep, resetStep, uid,
  }: Props) => {
    const [checked, setChecked] = useState<string[]>([]);
    const { data: dataOutputList, isLoading: isLoadingGetOutputList } = useOutputListQuery();
    const [post, { isLoading: isLoadingPostStep2 }] = useStep2Mutation();
    const ref = useRef(null);

    const isChecked = (value: string): boolean => checked.includes(value);

    const handleChange = (e: InputChangeType) => {
      const { id } = e.target;
      if (checked.includes(id)) {
        setChecked((prevState) => prevState.filter((state) => state !== id));
      } else {
        setChecked((prevState) => [...prevState, id]);
      }
    };

    const cardClass = (isCardChecked: boolean): string => {
      const baseClass = "cursor-pointer rounded-lg p-6 mt-8 flex border transition-colors duration-300 ease-in-out";
      return (
        baseClass
        + (isCardChecked
          ? " bg-primary bg-opacity-5 border border-primary"
          : " border-line-gray")
      );
    };

    const handleClickNext = () => {
      post({ uid, output_types: checked }).unwrap()
        .then((res) => {
          if (res.data?.uid === uid) {
            LocalStorage.setValue("register-step2", { output_types: checked });
            nextStep();
          } else {
            resetStep();
          }
        })
        .catch((error) => {
          // resetStep(); // if uid not found or expired
          toast.error(error.data?.message ?? "Failed to upload", {
            position: "top-center",
          });
        });
    };

    useEffect(() => {
      const savedValue = LocalStorage.getValue<{ output_types: string[] }>("register-step2");

      if (savedValue) {
        setChecked(savedValue.output_types);
      }
    }, []);

    useEffect(() => {
      const progress = ref.current as any;
      if (isLoadingGetOutputList) {
        progress?.continuousStart();
      } else {
        progress?.complete();
      }
    }, [isLoadingGetOutputList]);

    return (
      <>
        <LoadingBar height={4} color="#0078D3" ref={ref} />
        <h2 className="font-bold text-lg md:text-2xl mb-2">
          Choose the solutions you need
        </h2>
        <p className="text-xs md:text-sm text-secondary">
          You can also add or remove solutions later
        </p>

        {dataOutputList?.data?.map((item) => (
          <label htmlFor={item.code} key={item.code}>
            <input
              type="checkbox"
              id={item.code}
              className="hidden"
              checked={isChecked(item.code)}
              onChange={handleChange}
            />
            <div className={cardClass(isChecked(item.code))}>
              <div className="w-6 h-6 mr-4">
                <Checkbox isChecked={isChecked(item.code)} />
              </div>
              <div className="flex flex-col">
                <h3 className="font-bold text-lg">{item.name}</h3>
                <p className="text-sm">{item.description}</p>
              </div>
            </div>
          </label>
        ))}

        <div className="flex mt-6 w-full md:w-auto md:self-end">
          <button
            type="button"
            title="Back"
            className="button-secondary flex-grow md:flex-grow-0 mr-3"
            onClick={prevStep}
          >
            Back
          </button>
          <button
            type="button"
            className="button-primary flex-grow md:flex-grow-0"
            onClick={handleClickNext}
            disabled={checked.length === 0}
          >
            {isLoadingPostStep2 ? (
              <LoadingAnimation size={5} />
            ) : (
              <span>Next</span>
            )}
          </button>
        </div>
      </>
    );
  },
);

export default SolutionChecklist;
