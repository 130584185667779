import Tippy, { type TippyProps } from "@tippyjs/react";
import { memo } from "react";

function Tooltip({ children, arrow = true, ...props }: TippyProps) {
  return (
    <Tippy
      arrow={arrow}
      theme="dark"
      interactive
      {...props}
    >
      {children}
    </Tippy>
  );
}

export default memo(Tooltip);
