/* eslint-disable camelcase */
import { createApi } from "@reduxjs/toolkit/query/react";
import { CodeNameResponse } from "./variables-services";

import baseQuery from "./base-service";

interface DetailModiferItemModel {
  id: string | null;
  name: string;
  price: number;
  is_active: boolean;
}

export interface DetailModiferModel {
  id?: string;
  group_name: string;
  modifier_items: DetailModiferItemModel[];
}

interface ModiferResponse {
  id: string;
  group_name: string;
  modifiers_total: number;
  is_active: boolean;
}

interface LanguageListType extends CodeNameResponse {
  type: string;
}

export interface ModifierLocalizeContents {
  id: string;
  label_name: string;
  text: string;
}
export interface ModifierLocalizeModifier {
  lang_id: string;
  is_show: boolean;
  modifier_contents: ModifierLocalizeContents[];
}
export interface ModifierLocalizeDatas {
  id: string;
  name: string;
  modifier: ModifierLocalizeModifier[];
}

interface ModifierLocalizeResponse {
  main_language: string;
  language_list: LanguageListType[];
  datas: ModifierLocalizeDatas[];
}

interface UpdateModifierLocalizeDataRequest {
  lang_id: string;
  modifier_contents: {
    id: string;
    text: string;
  }[];
}
interface UpdateModifierLocalizeRequest {
  id: string;
  data: UpdateModifierLocalizeDataRequest[];
}

export const modifierServices = createApi({
  reducerPath: "modifier",
  baseQuery,
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  endpoints: (builder) => ({
    getAllModifier: builder.query<HttpResponse<ModiferResponse[]>, void>({
      query: () => ({
        url: "/modifier",
        method: "GET",
      }),
    }),
    getModifier: builder.query<HttpResponse<DetailModiferModel>, string>({
      query: (id) => ({
        url: `/modifier/single/${id}`,
        method: "GET",
      }),
    }),
    postModifier: builder.mutation<HttpResponse<{}>, DetailModiferModel>({
      query: (body) => ({
        url: "/modifier",
        method: "POST",
        body,
      }),
    }),
    updateModifier: builder.mutation<HttpResponse<{}>, DetailModiferModel>({
      query: (body) => ({
        url: "/modifier",
        method: "PUT",
        body,
      }),
    }),
    activeModifier: builder.mutation<HttpResponse<{}>, string>({
      query: (id) => ({
        url: "/modifier/toggleActive",
        method: "PUT",
        body: { id },
      }),
    }),
    deleteModifier: builder.mutation<HttpResponse<{}>, string>({
      query: (id) => ({
        url: "/modifier",
        method: "DELETE",
        body: { id },
      }),
    }),
    deleteMultipleModifiers: builder.mutation<HttpResponse<{}>, string[]>({
      query: (id_array) => ({
        url: "/modifier/multiple",
        method: "DELETE",
        body: { id_array },
      }),
    }),
    getAllModifierLocal: builder.query<HttpResponse<ModifierLocalizeResponse>, void>({
      query: () => ({
        url: "/modifier/local",
        method: "GET",
      }),
    }),
    updateModifierLocal: builder.mutation<HttpResponse<{}>, UpdateModifierLocalizeRequest>({
      query: (body) => ({
        url: "/modifier/local/update",
        method: "PUT",
        body,
      }),
    }),
    toggleActiveModifierLocal: builder.mutation<HttpResponse<{}>, {id: string; language_id: string;}>({
      query: (body) => ({
        url: "/modifier/local/toggleActive",
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  useGetAllModifierQuery,
  useGetModifierQuery,
  usePostModifierMutation,
  useUpdateModifierMutation,
  useActiveModifierMutation,
  useDeleteModifierMutation,
  useDeleteMultipleModifiersMutation,
  useGetAllModifierLocalQuery,
  useUpdateModifierLocalMutation,
  useToggleActiveModifierLocalMutation,
} = modifierServices;
