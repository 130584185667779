import { useState, useRef, useEffect } from "react";
import LoadingBar from "react-top-loading-bar";
import { toast } from "react-toastify";
import { format, parseISO } from "date-fns";

import { ReactComponent as DeleteIcon } from "../assets/icons/ic_trash_bold.svg";
import { ReactComponent as IllustrationNoItems } from "../assets/illustration_no_items.svg";

import { ContentHeader, LoadingAnimation, ModalConfirmation } from "../components";
import { useDevicesQuery, useDeleteDeviceMutation, useDeleteMultipleDevicesMutation } from "../services";

interface Confirmation {
  isOpen: boolean;
  data: string | null;
}

function DevicesPage() {
  const ref = useRef(null);

  const [checklist, setChecklist] = useState<string[]>([]);
  const [modalConfirmation, setModalConfirmation] = useState<Confirmation>({
    isOpen: false,
    data: null,
  });

  const [deleteDevice] = useDeleteDeviceMutation();
  const [deleteMultipleDevices] = useDeleteMultipleDevicesMutation();
  const { data: dataDevices, isLoading: isLoadingDevices, refetch } = useDevicesQuery();

  /**
   * @description Function to handle select the device
   * @param id<string>
   * @returns void
   */
  const handleChecklist = (id: string) => {
    if (checklist.find((checkedID) => checkedID === id)) {
      const removedChecklist = checklist.filter((checkedID) => checkedID !== id);
      setChecklist(removedChecklist);
    } else {
      setChecklist((prevState) => [...prevState, id]);
    }
  };

  /**
   * @description Function to handle delete the device
   * @param id<string>
   * @returns void
   */
  const handleDeleteDevice = (id: string) => {
    setModalConfirmation({ isOpen: true, data: id });
  };

  /**
   * @description Function to handle delete multiple devices
   * @returns void
   */
  const handleDeleteMultipleDevices = () => {
    deleteMultipleDevices(checklist)
      .unwrap()
      .then(() => {
        refetch();
        toast.success("Successfully delete selected devices");
      })
      .catch(() => {
        toast.error("Failed to delete selected devices");
      });
  };

  /**
   * @description Function to handle callback confirmation from handleDeleteDevice function
   * @returns void
   */
  const callbackConfirmation = () => {
    if (!modalConfirmation.data) return;

    deleteDevice(modalConfirmation.data)
      .unwrap()
      .then(() => {
        refetch();
        toast.success("Successfully delete device");
      })
      .catch((error) => {
        toast.error(error.data?.message ?? "Failed to create device");
      })
      .finally(() => {
        setModalConfirmation({ isOpen: false, data: null });
      });
  };

  useEffect(() => {
    const progress = ref.current as any;
    if (isLoadingDevices) {
      progress?.continuousStart();
    } else {
      progress?.complete();
    }
  }, [isLoadingDevices]);

  const devices = dataDevices?.data ?? [];

  if (!dataDevices) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <LoadingAnimation />
      </div>
    );
  }

  return (
    <>
      <LoadingBar height={4} color="#0078D3" ref={ref} />
      <div className="flex-1">
        <ContentHeader title="Devices" subtitle="On this page you can manage your devices" />

        <div className="mx-4">
          {/* delete button */}
          {devices.length > 0 && checklist.length > 0 && (
            <div className="flex mt-4">
              <button
                type="button"
                title="Delete Selected"
                className="button-error text-sm py-2 px-4 font-normal rounded"
                onClick={handleDeleteMultipleDevices}
              >
                Delete Selected
              </button>
            </div>
          )}

          <div className="hidden md:flex w-full p-4 bg-gray-100 font-medium items-center mt-4">
            <div className="pr-4">
              <label className="flex items-center space-x-3">
                <input
                  type="checkbox"
                  name="checked-demo"
                  className="form-tick appearance-none bg-white bg-check h-4 w-4 border border-gray-300 rounded checked:bg-primary checked:border-transparent focus:outline-none cursor-pointer"
                  onChange={() => {
                    if (devices.length === checklist.length) {
                      setChecklist([]);
                    } else {
                      setChecklist(devices.map(({ id }) => id));
                    }
                  }}
                  checked={devices.length > 0 && checklist.length === devices.length}
                />
              </label>
            </div>
            <p className="pl-4 border-l flex-1">Model</p>
            <p className="pl-4 border-l w-36">App Version</p>
            <p className="pl-4 border-l w-28">OS</p>
            <p className="pl-4 border-l w-32">OS Version</p>
            <p className="pl-4 border-l w-52">Last Connected</p>
            <p className="px-4 w-28 border-l">Action</p>
          </div>

          {devices.length > 0 ? (
            devices.map((device) => {
              const isChecked = !!checklist.find((checkedID) => checkedID === device.id);

              return (
                <div
                  className="flex w-full text-sm py-6 px-3 md:p-4 md:border-b md:border-gray-100 rounded-md md:rounded-none shadow-lg md:shadow-none mb-3 md:mb-0 items-center"
                  key={device.id}
                >
                  {/* desktop view */}
                  <div className="hidden md:block pr-4">
                    <label className="flex items-center space-x-3">
                      <input
                        key={isChecked ? "1" : "0"} // hack to force update
                        type="checkbox"
                        name="checked-devices"
                        className="form-tick appearance-none bg-white bg-check h-4 w-4 border border-gray-300 rounded checked:bg-primary checked:border-transparent focus:outline-none cursor-pointer"
                        onChange={() => handleChecklist(device.id)}
                        checked={isChecked}
                      />
                    </label>
                  </div>
                  <div className="hidden md:flex flex-1 flex-col md:flex-row gap-4 md:gap-0">
                    <div className="pl-4 flex-1">{device.model}</div>
                    <div className="pl-8 w-36">{device.app_version}</div>
                    <div className="pl-4 w-28">{device.os}</div>
                    <div className="pl-4 w-32">{device.os_version}</div>
                    <div className="pl-4 w-52">
                      {device.last_connected
                        ? format(parseISO(device.last_connected), "EEE, dd MMM yyyy - HH:mm")
                        : "-"}
                    </div>
                    <div className="px-4 w-28">
                      <button
                        className="flex items-center gap-2"
                        type="button"
                        title="Delete"
                        onClick={() => handleDeleteDevice(device.id)}
                      >
                        <DeleteIcon className="h-4 w-4" />
                        {" "}
                        <span className="hidden md:block">Delete</span>
                      </button>
                    </div>
                  </div>

                  {/* mobile view */}
                  <div className="flex flex-col gap-4 relative text-xs w-full md:hidden">
                    <p className="font-semibold">{device.model}</p>

                    <div className="flex gap-2">
                      <p className="font-semibold text-secondary w-5/12">App Version</p>
                      <p className="w-7/12">{device.app_version}</p>
                    </div>

                    <div className="flex gap-2">
                      <p className="font-semibold text-secondary w-5/12">OS (Version)</p>
                      <p className="w-7/12">
                        {device.os}
                        {" "}
                        (
                        {device.os_version}
                        )
                      </p>
                    </div>

                    <div className="flex gap-2">
                      <p className="font-semibold text-secondary w-5/12">Last Connected</p>
                      <p className="w-7/12">
                        {device.last_connected
                          ? format(parseISO(device.last_connected), "EEE, dd MMM yyyy - HH:mm")
                          : "-"}
                      </p>
                    </div>

                    <button
                      type="button"
                      title="Delete"
                      className="absolute -top-2 right-0 p-2"
                      onClick={() => handleDeleteDevice(device.id)}
                    >
                      <DeleteIcon className="h-4 w-4" />
                      {" "}
                    </button>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="h-full w-full mt-16 flex-1 flex flex-col gap-1 justify-center items-center">
              <IllustrationNoItems className="h-64 w-64 mb-4" />

              <p className="text-lg">No device</p>
              <p className="text-secondary">You haven&#39;t connect to any device</p>
            </div>
          )}
        </div>

        <ModalConfirmation
          isOpen={modalConfirmation.isOpen}
          content="Are you sure?"
          onCloseModal={() => setModalConfirmation({ isOpen: false, data: null })}
          onConfirmModal={callbackConfirmation}
          confirmationText="Delete"
          confirmationLoading={false}
        />
      </div>
    </>
  );
}

export default DevicesPage;
