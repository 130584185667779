import { useState, memo, useEffect } from "react";

import { useStep1Mutation } from "../../../services";
import { LoadingAnimation } from "../../../components";
import { LocalStorage } from "../../../utils";

interface Props {
  nextStep: () => void;
  setUid: SetStateType<string>;
}

const TrialInput = memo(({ nextStep, setUid }: Props) => {
  const [businessName, setBusinessName] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [post, { isLoading }] = useStep1Mutation();

  const handleChange = (e: InputChangeType) => {
    setBusinessName(e.target.value);
  };

  const handleClickNext = () => {
    post({ bus_name: businessName })
      .unwrap()
      .then((res) => {
        if (res.data?.uid) {
          setUid(res.data.uid);
          LocalStorage.setValue("register-step1", { bus_name: businessName });
          nextStep();
        } else {
          throw Error("UID not found");
        }
      })
      .catch((error) => {
        setErrorMessage(error.data?.message);
      });
  };

  useEffect(() => {
    const savedValue = LocalStorage.getValue<{ bus_name: string }>("register-step1");

    if (savedValue) {
      setBusinessName(savedValue.bus_name);
    }
  }, []);

  return (
    <div className="w-full pb-6 md:pb-14 md:px-8">
      <h2 className="font-bold text-lg md:text-2xl mb-4 md:mb-6">
        Get Started for free 14-day trial
      </h2>

      <p className="text-xs md:text-sm mb-2 text-secondary">
        Write your business name
      </p>

      <input
        type="text"
        placeholder="What is the name of your business?"
        className="input-base"
        onChange={handleChange}
        value={businessName}
      />
      {errorMessage && <p className="text-sm text-red-500">{errorMessage}</p>}

      <button
        type="button"
        title={isLoading ? "Loading..." : "Next"}
        className="button-primary self-center w-11/12 md:w-auto md:px-16 absolute md:relative inset-x-4 md:inset-0 bottom-4 md:bottom-0 md:mt-16"
        onClick={handleClickNext}
        disabled={businessName.length < 3}
      >
        {isLoading ? (
          <LoadingAnimation size={5} />
        ) : (
          <span>Next</span>
        )}
      </button>
    </div>
  );
});

export default TrialInput;
