import { useEffect, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { ReactComponent as Illustration } from "../assets/illustration_email_verification.svg";
import { ReactComponent as LogoIcon } from "../assets/logo.svg";

import { useAppDispatch, useAppSelector } from "../app/hooks";
import { LoginForm, LoginRequest, setCredentials } from "../features/auth";
import { getFCMToken } from "../features/notification";
import { useLoginMutation } from "../services";
import { LocalStorage } from "../utils";

function LoginPage() {
  const savedRemember = useAppSelector(({ auth }) => auth.remember);
  const { fcm_token: fcmToken } = useAppSelector(({ auth }) => auth);
  const [isLoadingFCMToken, setIsLoadingFCMToken] = useState<boolean>(false);
  const [remember, setRemember] = useState<boolean>(false);
  const { push } = useHistory();
  const [login, { isLoading: isLoadingLogin }] = useLoginMutation();
  const dispatch = useAppDispatch();

  const isLoading = isLoadingLogin || isLoadingFCMToken;

  /**
   * @description Function to handle login
   * @param {LoginRequest} data
   */
  const onSubmit: SubmitHandler<LoginRequest> = async (data) => {
    const { email, password } = data;
    let fcm_token = fcmToken;

    if (!fcm_token) {
      setIsLoadingFCMToken(true);

      await getFCMToken()
        .then((firebaseMessagingToken) => {
          // dispatch(setCredentials({ fcm_token }));
          fcm_token = firebaseMessagingToken as string;

          LocalStorage.setValue("fcmToken", fcm_token);
        })
        .finally(() => {
          setIsLoadingFCMToken(false);
        });
    }

    if (email === "") {
      toast.error("Email is required");
      return;
    }

    if (password === "") {
      toast.error("Password is required");
      return;
    }

    LocalStorage.setValue("remember", remember);

    const dataEmailLowerCased: LoginRequest = {
      password,
      email: email.toLowerCase(),
      token: fcm_token || undefined,
    };

    login(dataEmailLowerCased)
      .unwrap()
      .then((res) => {
        if (res.data) {
          dispatch(setCredentials({ ...res.data, step: null, remember }));
          // push("/categories");
          push("/dashboard");
        }
      })
      .catch((error) => {
        toast.error(error.data?.message ?? "Failed to login");
      });
  };

  const goToRegister = () => {
    push("/");
  };

  const goToForgotPassword = () => {
    push("/forgot-password");
  };

  useEffect(() => {
    setRemember(savedRemember);
  }, [savedRemember]);

  return (
    <div className="relative w-full max-w-3xl pt-24 md:pt-32 mx-auto">
      <Illustration className="absolute inset-1 hidden md:block" />

      <div className="font-montserrat relative bg-white rounded text-center w-full md:max-w-xl md:shadow-lg p-6 md:py-5 md:px-8 md:mx-auto">
        <LogoIcon className="w-11 h-11 mb-5 mx-auto" />
        <p className="font-bold text-lg mb-1">Welcome to Cloud Menu! 👋🏻</p>
        <p className="text-secondary text-xs mb-5">Please Log-in to your account</p>

        <div className="flex justify-between w-full items-center mb-3 flex-col md:flex-row">
          <p className="font-body font-bold text-2xl mb-3 md:mb-0">Log In</p>
          <p className="text-xs mb-3 md:mb-0">
            Don&#39;t Have an Account?
            {" "}
            <button
              type="button"
              title="Create Account"
              className="button-link"
              onClick={goToRegister}
            >
              Create Account
            </button>
          </p>
        </div>

        <LoginForm
          isLoading={isLoading}
          onSubmit={onSubmit}
          goToForgotPassword={goToForgotPassword}
          setRemember={setRemember}
          remember={remember}
        />
      </div>
    </div>
  );
}

export default LoginPage;
