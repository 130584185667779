import { onMessage } from "firebase/messaging";
import { useEffect, useState } from "react";
import { useIntercom } from "react-use-intercom";

import { useAppDispatch, useAppSelector } from "../app/hooks";
import routes from "../app/route-config";
import {
  HeaderUserDesktop, HeaderUserMobile, Portal, Sidebar,
} from "../components";
import { setCredentials } from "../features/auth";
import {
  messaging,
  setupMessaging,
  requestNotificationPermission,
  getFCMToken,
  registerFCMServiceWorker,
  // showNotification,
} from "../features/notification";
import { useUserDetailQuery, useRefreshTokenMutation } from "../services";
import { OrderNotification } from "./orders";
import { ORDER_TYPE_LIST_TEXT, PAYMENT_TYPE_LIST_TEXT } from "../constants";
import { LocalStorage } from "../utils";

interface Props {
  children: JSX.Element | (JSX.Element | null)[];
}

const baseSidebarClass = "w-full h-full md:w-64 transition-transform transform md:translate-x-0 z-20";

function DashboardLayout({ children }: Props) {
  const { fcm_token, token, refresh_token } = useAppSelector(({ auth }) => auth);
  const dispatch = useAppDispatch();
  const [isSidebarShow, setSidebarShow] = useState<boolean>(false);
  const { data } = useUserDetailQuery();
  const { boot, show } = useIntercom();
  const [marginTop, setMarginTop] = useState<string>("0px");

  const [notifications, setNotifications] = useState<any[]>([]);

  const [refreshToken] = useRefreshTokenMutation();

  const body = document.querySelector("body") as HTMLBodyElement;

  const toggleSidebar = () => {
    setSidebarShow(!isSidebarShow);
  };

  useEffect(() => {
    const handler = setInterval(() => {
      setMarginTop(body.style.marginTop || "0px");
    }, 100);

    return () => clearInterval(handler);
  }, []);

  useEffect(() => {
    (async () => {
      await setupMessaging()
        .then(() => {
          onMessage(messaging, (payload) => {
            console.log({ payload });

            const linkUrl = payload.data?.link_url || `${process.env.REACT_APP_DASHBOARD_URL}/orders?id=${payload.data?.order_id}`;
            const notificationPayload = {
              ...payload,
              click_action: linkUrl,
              data: {
                ...payload.data,
                click_action: linkUrl,
                link_url: linkUrl,
              },
            };

            // showNotification(notificationPayload);
            setNotifications((prev) => [...prev, notificationPayload]);
          });
        });
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await setupMessaging()
        .then(async () => {
          const currentFCMToken = await getFCMToken();

          if (fcm_token !== currentFCMToken
            || (
              (!fcm_token && token)
              || (!fcm_token && refresh_token)
              || (!fcm_token && (refresh_token && token))
            )
          ) {
            LocalStorage.setValue("fcmToken", currentFCMToken);

            refreshToken({
              refresh_token: refresh_token || "",
              fcm_token: currentFCMToken as string,
            })
              .unwrap()
              .then((res) => {
                dispatch(setCredentials({
                  ...res.data,
                }));
              });
          }
        });
    })();
  }, []);

  // useEffect(() => {
  //   let unsubscribe: Unsubscribe | null = null;
  //   const permission = "Notification" in window && (Notification.permission || window.Notification.permission);

  //   if (permission === "granted") {
  //     unsubscribe = onMessage(messaging, (payload) => {
  //       console.log({ payload });

  //       const linkUrl = payload.data?.link_url || `${process.env.REACT_APP_DASHBOARD_URL}/orders?id=${payload.data?.order_id}`;
  //       const notificationPayload = {
  //         ...payload,
  //         click_action: linkUrl,
  //         data: {
  //           ...payload.data,
  //           click_action: linkUrl,
  //           link_url: linkUrl,
  //         },
  //       };

  //       showNotification(notificationPayload);
  //       setNotifications((prev) => [...prev, notificationPayload]);
  //     });
  //   }

  //   return () => unsubscribe?.();
  // }, []);

  useEffect(() => {
    if (data?.data) {
      boot({ name: data.data.name, email: data.data.email });
      dispatch(setCredentials({ user: data.data }));
    }
  }, [data?.data]);

  if (!data?.data) return null;

  return (
    <div className="flex h-screen relative">

      {notifications.map((notification, index) => {
        console.log({ notification });

        const key = `notification-${notification.data.order_id || index}`;
        const isDineIn = notification.data?.order_type?.includes("order_dinein");
        const orderTypeId = ORDER_TYPE_LIST_TEXT[notification?.data?.order_type as keyof typeof ORDER_TYPE_LIST_TEXT];
        const paymentMethodId = PAYMENT_TYPE_LIST_TEXT[
          notification?.data?.payment_method as keyof typeof PAYMENT_TYPE_LIST_TEXT
        ];

        return (
          <Portal containerId="notification-root" key={key}>
            <OrderNotification
              key={key}
              type={isDineIn ? "checkout" : "order"}
              tableName={notification.data?.table}
              orderId={notification.data?.order_id || 0}
              orderTypeId={orderTypeId || notification.data?.order_type || ""}
              paymentMethodId={paymentMethodId || notification.data?.payment_method || ""}
              isOpen
              onClose={() => {
                setNotifications(
                  (prevState) => prevState.filter(({ messageId }) => messageId !== notification.messageId),
                );
              }}
            />
          </Portal>
        );
      })}

      <div
        className={
          isSidebarShow ? `${baseSidebarClass} absolute z-30` : `${baseSidebarClass} fixed z-30 -translate-x-full`
        }
      >
        <Sidebar toggleSidebar={toggleSidebar} routes={routes.privateRoutes} data={data.data} marginTop={marginTop} />
      </div>

      <div
        className={
          `flex flex-col flex-1 min-w-0 transition-[height] duration-[250ms] ease-linear ${
            isSidebarShow ? "h-0 overflow-x-hidden" : "md:ml-64"}`
        }
        style={{
          height: `calc(100vh - ${marginTop})`,
        }}
      >
        {/* DESKTOP ONLY HEADER */}
        <HeaderUserDesktop routes={routes.privateRoutes} marginTop={marginTop} />

        <HeaderUserMobile toggleSidebar={toggleSidebar} show={show} />

        {/* SEARCHBAR */}
        {children}
      </div>
    </div>
  );
}

export default DashboardLayout;
