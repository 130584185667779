import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { ReactComponent as LogoIcon } from "../assets/logo.svg";
import { ReactComponent as Illustration } from "../assets/illustration_email_verification.svg";
import { HeaderFrontPage } from "../components";
import { useResendVerificationMutation } from "../services";

interface VerificationType {
  email?: string;
}

function EmailVerificationPage() {
  const { state } = useLocation<VerificationType>();
  const { push } = useHistory();
  const [resendEmail] = useResendVerificationMutation();

  /**
   * @description Function to handle resend verification email
   * @returns
   */
  const resend = () => {
    if (!state?.email) return;

    resendEmail({ email: state?.email })
      .unwrap()
      .then((res) => {
        toast.success(res.message);
      })
      .catch((error) => {
        toast.error(error.data?.message ?? "Failed to resend email");
      });
  };

  if (!state?.email) {
    push("/");
  }

  return (
    <>
      <HeaderFrontPage onlyLoginButton />
      <div className="relative w-full max-w-3xl pt-32 mx-auto">
        <Illustration className="absolute inset-1 hidden md:block md:mt-8" />

        <div className="font-montserrat relative bg-white rounded text-center w-full md:max-w-xl md:shadow-lg p-6 md:py-5 md:px-24 md:mx-auto">
          <LogoIcon className="w-11 h-11 mb-5 mx-auto" />
          <p className="font-bold text-lg mb-11">Verify your email ✉️</p>

          <p className="text-secondary mb-11">
            We&#39;ve sent a link to your email address:
            {" "}
            {state?.email}
            , Please follow the link inside to continue.
          </p>

          <div className="flex justify-center w-full text-secondary mb-11">
            <p>Didn&#39;t receive an email?</p>
            {" "}
            <button type="button" title="Resend" className="button-link ml-2" onClick={resend}>
              Resend
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmailVerificationPage;
