import { createApi } from "@reduxjs/toolkit/query/react";

import baseQuery from "./base-service";

export interface Date {
  date: string;
  value: number;
}

export interface Revenue {
  order_type_id: string;
  dates: Date[];
}

export interface PaymentMethod {
  id: string;
  value: string;
}

export interface OrderType {
  id: string;
  value: number;
}

export interface TopItem {
  name: string | {
    lang_id: string;
    name: string;
    description: string;
    isShow: true;
  };
  value: number;
  image?: string;
}

export interface AnalyticResponse {
  category_total: number;
  item_total: number;
  order_total: number;
  revenue_total: string;
  revenues: Revenue[];
  payment_methods: PaymentMethod[];
  order_types: OrderType[];
  top_items: TopItem[];
}

export const analyticServices = createApi({
  reducerPath: "analytic",
  baseQuery,
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  endpoints: (builder) => ({
    getAnalytic: builder.query<HttpResponse<AnalyticResponse>, { from: number | string, to: number | string }>({
      query: ({ from, to }) => ({
        url: `/analytic?from=${
          Number.parseInt(typeof from === "string" ? from : from.toString(), 10)
        }&to=${
          Number.parseInt(typeof to === "string" ? to : to.toString(), 10)
        }`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetAnalyticQuery,
} = analyticServices;
