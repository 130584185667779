import { ReactNode } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import handlerImage from "../assets/drag-handler.png";

interface Props {
  id: string;
  style: React.CSSProperties;
  useHandler?: boolean;
  positionHandler?: "top" | "bottom";
  children: ReactNode;
  disabled?: boolean;
}

function SortableItem({
  id, style, useHandler = false, positionHandler = "top", children, disabled,
}: Props) {
  const {
    attributes, listeners, setNodeRef, transform, transition,
  } = useSortable({ id });

  const defaultStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return useHandler ? (
    <div ref={setNodeRef} style={{ ...defaultStyle, ...style }} className="relative">
      {(positionHandler === "top" && !disabled) && (
        <button
          className="bg-white rounded px-2 z-20 absolute top-2 left-1/2 transform -translate-x-1/2 h-6 w-8"
          type="button"
          {...attributes}
          {...listeners}
        >
          <img src={handlerImage} alt="sortable handler" />
        </button>
      )}

      {children}

      {(positionHandler === "bottom" && !disabled) && (
        <button
          className="bg-white rounded px-2 z-20 absolute bottom-2 right-2 h-6 w-8"
          type="button"
          {...attributes}
          {...listeners}
        >
          <img src={handlerImage} alt="sortable handler" />
        </button>
      )}
    </div>
  ) : (
    <div ref={setNodeRef} style={{ ...defaultStyle, ...style }} {...attributes} {...listeners}>
      {children}
    </div>
  );
}

export default SortableItem;
