import { MouseEvent, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { ReactComponent as AccountIcon } from "../assets/icons/ic_settings_account.svg";
import { ReactComponent as BillingIcon } from "../assets/icons/ic_settings_billing.svg";
import { ReactComponent as GeneralIcon } from "../assets/icons/ic_settings_general.svg";
import { ReactComponent as QRIcon } from "../assets/icons/ic_settings_qr.svg";
import { ReactComponent as TabletIcon } from "../assets/icons/ic_settings_tablet.svg";

import { useAppSelector } from "../app/hooks";
import {
  ContentHeader,
  ModalConfirmation,
} from "../components";
import {
  SettingAccount,
  SettingBilling,
  SettingGeneral,
  SettingQR,
  SettingTablet,
} from "../components/settings";

interface Confirmation {
  isOpen: boolean;
  data: string | null;
}

type TabType = "general" | "tablet" | "qr menu" | "account" | "billing";

interface Tabs {
  type: TabType;
  name: string;
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
}

const tabs: Tabs[] = [
  { type: "general", name: "General Settings", icon: GeneralIcon },
  { type: "tablet", name: "Tablet Menu", icon: TabletIcon },
  { type: "qr menu", name: "QR Menu", icon: QRIcon },
  { type: "account", name: "Account Settings", icon: AccountIcon },
  { type: "billing", name: "Billing & Plans", icon: BillingIcon },
];

function SettingPage() {
  const { hash } = useLocation<{ selectedTab: TabType }>();
  const savedUser = useAppSelector(({ auth }) => auth.user);
  const enableTablet = savedUser?.features.includes("tablet");
  const enableQR = savedUser?.features.includes("qrmobile");

  const { state } = useLocation<{ tab: TabType }>();
  const [modalConfirmation, setModalConfirmation] = useState<Confirmation>({
    isOpen: false,
    data: null,
  });
  const [selectedTab, setSelectedTab] = useState<TabType>("general");
  const [saveSetting, setSaveSetting] = useState<boolean>(false);
  const [usedTabs, setUsedTabs] = useState<Tabs[]>(tabs);

  /**
   * @description Function to handle tab change
   * @param e<MouseEvent<HTMLButtonElemenet>>
   */
  const handleSelectedTab = (e: MouseEvent<HTMLButtonElement>) => {
    setSelectedTab(e.currentTarget.id as TabType);
  };

  const callbackConfirmation = () => {
    // eslint-disable-next-line no-useless-return
    if (!modalConfirmation.data) return;
  };

  const handleSaveSetting = (save: boolean) => setSaveSetting(save);

  useEffect(() => {
    if (hash) {
      const tab = hash.replace("#", "") as TabType;
      setSelectedTab(tab);
    }
  }, []);

  useEffect(() => {
    if (state) setSelectedTab(state.tab);
  }, [state]);

  useEffect(() => {
    if (!enableQR) {
      setUsedTabs((prevState) => prevState.filter(({ type }) => type !== "qr menu"));
    }
    if (!enableTablet) {
      setUsedTabs((prevState) => prevState.filter(({ type }) => type !== "tablet"));
    }
  }, [enableTablet, enableQR]);

  return (
    <div className="flex-1 pb-16">
      <ContentHeader
        title="Settings"
        subtitle="Set all your general settings here"
        buttonTitle={selectedTab === "billing" ? undefined : "Save"}
        buttonTitleLoading={selectedTab === "billing" ? undefined : saveSetting}
        onButtonClick={() => setSaveSetting(true)}
        showAddButton={false}
      />

      {/* tabs */}
      <div className="flex mb-6">
        <div className="flex items-center bg-gray-100 w-full fixed bottom-0 md:relative z-10 overflow-x-auto">
          {usedTabs.map((field) => (
            <button
              id={field.type}
              key={field.type}
              type="button"
              className={
                `flex flex-1 md:flex-none justify-center items-center gap-2 p-4 md:px-6 font-medium${
                  field.type === selectedTab ? " bg-white text-active cursor-not-allowed" : ""}`
              }
              // disabled={field.type === selectedTab}
              onClick={handleSelectedTab}
            >
              <field.icon className="h-6 w-6 md:h-4 md:w-4" />
              <span className="hidden md:block">{field.name}</span>
            </button>
          ))}
        </div>
      </div>

      {/* contents */}
      {selectedTab === "general" && <SettingGeneral saveSetting={saveSetting} setSaveSetting={handleSaveSetting} />}
      {selectedTab === "tablet" && <SettingTablet saveSetting={saveSetting} setSaveSetting={handleSaveSetting} />}
      {selectedTab === "qr menu" && <SettingQR saveSetting={saveSetting} setSaveSetting={handleSaveSetting} />}
      {selectedTab === "account" && <SettingAccount saveSetting={saveSetting} setSaveSetting={handleSaveSetting} />}
      {selectedTab === "billing" && <SettingBilling />}

      {selectedTab !== "billing" && (
        <div className="m-4 mt-8 md:hidden">
          <button
            type="button"
            title="Save"
            className="button-primary w-full"
            onClick={() => setSaveSetting(true)}
          >
            Save
          </button>
        </div>
      )}

      <ModalConfirmation
        isOpen={modalConfirmation.isOpen}
        content="Are you sure?"
        onCloseModal={() => setModalConfirmation({ isOpen: false, data: null })}
        onConfirmModal={callbackConfirmation}
        confirmationText="Save"
        confirmationLoading={false}
      />
    </div>
  );
}

export default SettingPage;
