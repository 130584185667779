import { useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";

import { ReactComponent as EmailIcon } from "../../../assets/icons/ic_mail.svg";
import { ReactComponent as LockIcon } from "../../../assets/icons/ic_lock.svg";
import { ReactComponent as EyeIcon } from "../../../assets/icons/ic_eye.svg";

import { LoginRequest } from "../../../features/auth";
import { LoadingAnimation } from "../../../components";
import { InputWithIcon } from "../../../components/atoms";

const eyeIconClasss = (isShow: boolean) => {
  const passType = "w-5 h-5 ml-4 opacity-30";
  const textType = "w-5 h-5 ml-4 opacity-100";
  return isShow ? textType : passType;
};

interface Props {
  isLoading: boolean;
  onSubmit: SubmitHandler<LoginRequest>;
  goToForgotPassword: () => void;
  setRemember: SetStateType<boolean>;
  remember: boolean;
}

function LoginForm({
  isLoading, onSubmit, goToForgotPassword, setRemember, remember,
}: Props) {
  const [isShowPassword, setShowPassword] = useState<boolean>(false);
  const { control, handleSubmit } = useForm<LoginRequest>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const handleInputPasswordType = () => {
    setShowPassword(!isShowPassword);
  };

  return (
    <form>
      <Controller
        control={control}
        name="email"
        render={({
          field: {
            onChange, value, name, ref,
          },
        }) => (
          <InputWithIcon
            leftIcon={<EmailIcon className="w-5 h-5 mr-4" />}
            title="Your Email"
            placeholder="example@example.com"
            name={name}
            inputRef={ref({ required: true })}
            onChange={onChange}
            disabled={isLoading}
            value={value}
          />
        )}
      />

      <Controller
        control={control}
        name="password"
        render={({
          field: {
            onChange, value, name, ref,
          },
        }) => (remember
          ? (
            <InputWithIcon
              leftIcon={<LockIcon className="w-5 h-5 mr-4" />}
              rightIcon={(
                <EyeIcon
                  className={eyeIconClasss(isShowPassword)}
                  onClick={handleInputPasswordType}
                />
              )}
              disabled={isLoading}
              title="Your Password"
              placeholder="***********"
              name={name}
              inputRef={ref({ required: true })}
              type={isShowPassword ? "text" : "password"}
              onChange={onChange}
              value={value}
            />
          ) : (
            <InputWithIcon
              leftIcon={<LockIcon className="w-5 h-5 mr-4" />}
              rightIcon={(
                <EyeIcon
                  className={eyeIconClasss(isShowPassword)}
                  onClick={handleInputPasswordType}
                />
              )}
              title="Your Password"
              placeholder="***********"
              disabled={isLoading}
              name={name}
              inputRef={ref({ required: true })}
              type="text"
              onChange={onChange}
              value={value}
              secureText={!isShowPassword}
            />
          ))}
      />

      <div className="flex justify-between w-full items-center mb-8">
        <label
          className="flex items-center space-x-3 cursor-pointer"
          htmlFor="remember"
        >
          <input
            type="checkbox"
            name="remember"
            disabled={isLoading}
            id="remember"
            className="form-tick appearance-none bg-white bg-check h-6 w-6 border border-gray-300 rounded-md checked:bg-primary checked:border-transparent focus:outline-none"
            checked={remember}
            onChange={() => {
              setRemember(!remember);
            }}
          />
          <p className="text-gray-700 font-normal">
            Remember Me
          </p>
        </label>

        <button
          className="button-link"
          onClick={goToForgotPassword}
          type="button"
        >
          Forgot password?
        </button>
      </div>

      <button
        className="button-primary w-11/12 md:w-1/3 flex justify-self-start justify-center rounded font-normal text-sm fixed md:relative bottom-4 md:bottom-0 inset-x-4 md:inset-x-0"
        disabled={isLoading}
        onClick={handleSubmit(onSubmit)}
        type="submit"
      >
        {isLoading ? (
          <LoadingAnimation size={5} />
        ) : (
          <span>Log in</span>
        )}
      </button>
    </form>
  );
}

export default LoginForm;
