/* eslint-disable camelcase */
import { createApi } from "@reduxjs/toolkit/query/react";

import baseQuery from "./base-service";

export interface CategoryListResponse {
  id: string;
  name: string;
  image?: string;
  is_published: boolean;
  total_items: number;
}

interface AllCategoryResponse {
  name: string;
  description: string;
  categories: CategoryListResponse[];
}

interface GetCategoriesResponse {
  categories: {
    id: string;
    name: string;
  }[]
}

interface CategoryResponse {
  id: string;
  name: string;
  description: string;
  image: string;
  display_as: string;
  num_of_columns: number;
  publish_status: boolean
}

export type DisplayAs = "list" | "grid";
export type NumberOfColumns = "0" | "2" | "3";

export interface AddCategoryRequest {
  name: string;
  description: string | null;
  image: string | null;
  displayType: DisplayAs | null;
  numOfCol: NumberOfColumns | null;
  isPublished: boolean;
}
export interface UpdateCategoryRequest {
  id: string;
  name: string;
  description: string | null;
  image: string | null;
  displayType: DisplayAs | null;
  numOfCol: NumberOfColumns | null;
  isPublished: boolean;
}
export interface UpdateCategoryHeaderRequest {
  name?: string;
  description?: string;
}

export const categoryServices = createApi({
  reducerPath: "category",
  baseQuery,
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  endpoints: (builder) => ({
    getAllCategory: builder.query<HttpResponse<AllCategoryResponse>, void>({
      query: () => ({
        url: "/category",
        method: "GET",
      }),
    }),
    getCategoryList: builder.query<HttpResponse<GetCategoriesResponse>, void>({
      query: () => ({
        url: "/category/list",
        method: "GET",
      }),
    }),
    getCategory: builder.query<HttpResponse<CategoryResponse>, string>({
      query: (id) => ({
        url: `/category/single/${id}`,
        method: "GET",
      }),
    }),
    postCategory: builder.mutation<HttpResponse<{}>, AddCategoryRequest>({
      query: (body) => ({
        url: "/category",
        method: "POST",
        body,
      }),
    }),
    updateCategory: builder.mutation<HttpResponse<{}>, UpdateCategoryRequest>({
      query: (body) => ({
        url: "/category",
        method: "PUT",
        body,
      }),
    }),
    updateCategoryHeader: builder.mutation<
      HttpResponse<{}>,
      UpdateCategoryHeaderRequest
    >({
      query: (body) => ({
        url: "/category/header",
        method: "PUT",
        body,
      }),
    }),
    publishCategory: builder.mutation<HttpResponse<{}>, string>({
      query: (id) => ({
        url: "/category/publish",
        method: "PUT",
        body: { id },
      }),
    }),
    deleteCategory: builder.mutation<HttpResponse<{}>, string>({
      query: (id) => ({
        url: "/category",
        method: "DELETE",
        body: { id },
      }),
    }),
    updateCategoryPhoto: builder.mutation<HttpResponse<{}>, {id: string; photo: string | null;}>({
      query: (body) => ({
        url: "/category/photo",
        method: "PUT",
        body,
      }),
    }),
    duplicateCategory: builder.mutation<HttpResponse<{}>, {id: string}>({
      query: (body) => ({
        url: "/category/duplicate",
        method: "POST",
        body,
      }),
    }),
    dragAndDropCategory: builder.mutation<HttpResponse<{}>, {category_id_array: string[]}>({
      query: (body) => ({
        url: "/category/drag",
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  useGetAllCategoryQuery,
  useGetCategoryListQuery,
  useGetCategoryQuery,
  usePostCategoryMutation,
  useUpdateCategoryMutation,
  useUpdateCategoryHeaderMutation,
  usePublishCategoryMutation,
  useDeleteCategoryMutation,
  useUpdateCategoryPhotoMutation,
  useDuplicateCategoryMutation,
  useDragAndDropCategoryMutation,
} = categoryServices;
