import {
  useState, MouseEvent, useMemo, useEffect,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { subDays } from "date-fns";

import {
  ContentHeader, ModalConfirmation, FeedbackForms, FeedbackResults,
} from "../components";
import { ReactComponent as ResultIcon } from "../assets/icons/ic_survey_results.svg";
import { ReactComponent as FormIcon } from "../assets/icons/ic_survey_forms.svg";

interface Confirmation {
  isOpen: boolean;
  data: string | null;
}

type TabType = "result" | "form";

function FeedbackPage() {
  const { push } = useHistory();
  const { hash } = useLocation<{ selectedTab: TabType }>();
  const [keyword, setKeyword] = useState<string>("");
  const [modalConfirmation, setModalConfirmation] = useState<Confirmation>({
    isOpen: false,
    data: null,
  });
  const [selectedTab, setSelectedTab] = useState<TabType>("result");

  const today = new Date();
  const [okDateRange, setOkDateRange] = useState<Date[]>([subDays(today, 7), today]);

  const isResultTab = useMemo(() => selectedTab === "result", [selectedTab]);
  const isFormTab = useMemo(() => selectedTab === "form", [selectedTab]);

  const handleSelectedTab = (e: MouseEvent<HTMLButtonElement>) => {
    setSelectedTab(e.currentTarget.id as TabType);
    setKeyword("");
  };

  const callbackConfirmation = () => {
    // console.log("Callback log");
    // eslint-disable-next-line no-useless-return
    if (!modalConfirmation.data) return;
  };

  useEffect(() => {
    if (hash) {
      const tab = hash.replace("#", "") as TabType;
      setSelectedTab(tab);
    }
  }, []);

  useEffect(() => {
    push(`#${selectedTab}`);
  }, [selectedTab]);

  return (
    <div className="flex-1 pb-16">
      <ContentHeader
        title="Feedbacks"
        subtitle="On this page you can create your own survey form"
        buttonTitle="New Form"
        onButtonClick={() => push("/feedback")}
        onSearch={selectedTab === "form" ? setKeyword : undefined}
        value={keyword}
        showAddButton={selectedTab === "form"}
      />

      {/* tabs */}
      <div className="flex mb-6">
        <div className="flex items-center bg-gray-100 w-full fixed bottom-0 md:relative z-20">
          <button
            type="button"
            className={
              `flex flex-1 md:flex-none justify-center items-center gap-2 p-4 md:px-6 font-medium${
                isResultTab ? " bg-white text-active cursor-not-allowed" : ""}`
            }
            id="result"
            onClick={handleSelectedTab}
          >
            <ResultIcon className="h-6 w-6 md:h-4 md:w-4" />
            <span className="hidden md:block">Survey Results</span>
          </button>
          <button
            type="button"
            className={
              `flex flex-1 md:flex-none justify-center items-center gap-2 p-4 md:px-6 font-medium${
                isFormTab ? " bg-white text-active cursor-not-allowed" : ""}`
            }
            id="form"
            onClick={handleSelectedTab}
          >
            <FormIcon className="h-6 w-6 md:h-4 md:w-4" />
            <span className="hidden md:block">Survey Forms</span>
          </button>
        </div>
      </div>

      {/* contents */}
      {selectedTab === "result" && <FeedbackResults okDateRange={okDateRange} setOkDateRange={setOkDateRange} />}
      {selectedTab === "form" && <FeedbackForms keyword={keyword} />}

      <ModalConfirmation
        isOpen={modalConfirmation.isOpen}
        content="Are you sure?"
        onCloseModal={() => setModalConfirmation({ isOpen: false, data: null })}
        onConfirmModal={callbackConfirmation}
        confirmationText="Delete"
        confirmationLoading={false}
      />
    </div>
  );
}

export default FeedbackPage;
