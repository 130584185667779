import { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingBar from "react-top-loading-bar";

import { ReactComponent as ChevronDownIcon } from "../assets/icons/ic_chevron_down.svg";
import { ReactComponent as GlobeIcon } from "../assets/icons/ic_globe.svg";
import { ReactComponent as SpinnerIcon } from "../assets/icons/ic_spinner.svg";

import {
  useUpdateFeedbackFormLocalMutation,
  useGetFeedbackFormLocalQuery,
  useToggleFeedbackFormLocalMutation,
} from "../services";

interface FormType {
  lang_id: string;
  is_show?: boolean;
  questions: {
    id: string;
    text: string;
  }[];
}

function FeedbaclLocalizePage() {
  const [form, setForm] = useState<FormType[]>();
  const { state } = useLocation<StateFeedbackFormID>();
  const ref = useRef(null);

  const { data, refetch, isLoading } = useGetFeedbackFormLocalQuery(state.feedback_form_id ?? "", {
    skip: !state.feedback_form_id,
  });
  const [updateLocal, { isLoading: isLoadingUpdateLocal }] = useUpdateFeedbackFormLocalMutation();
  const [toggleActiveLanguage] = useToggleFeedbackFormLocalMutation();

  const { goBack, replace } = useHistory();

  /**
   * @description Function to handle toggle active language
   * @param lang<string>
   * @returns void
   */
  const publishIsShow = (lang: string) => {
    if (!state.feedback_form_id) return;

    toggleActiveLanguage({ id: state.feedback_form_id, language_id: lang })
      .unwrap()
      .then((res) => {
        if (res.status === "Success") {
          toast.success("Successfully toggle language");
          setTimeout(() => {
            refetch();
          }, 1000);
        }
      })
      .catch(() => {
        toast.error("Failed to toggle language");
      });
  };

  /**
   * @description Function to handle change form data
   * @param e<Event | any>
   * @param lang<string>
   * @param name<keyof FormType>
   * @param id<string>
   * @returns void
   */
  const handleChange = (e: any, lang: string, name: keyof FormType, id?: string) => {
    const newForm = form?.map((dataForm) => {
      if (dataForm.lang_id === lang) {
        if (name === "is_show") {
          publishIsShow(lang);
          return {
            ...dataForm,
            is_show: e.target.checked,
          };
        }

        return {
          ...dataForm,
          questions: dataForm.questions.map((question) => {
            if (question.id === id) {
              return {
                id,
                text: e.target.value,
              };
            }
            return question;
          }),
        };
      }

      return dataForm;
    });

    setForm(newForm);
  };

  /**
   * @description Function to handle update feedback from localization
   * @returns void
   */
  const handleSave = () => {
    if (!(state.feedback_form_id && form)) return;

    updateLocal({ id: state.feedback_form_id, data: form })
      .unwrap()
      .then((res) => {
        if (res.status === "Success") {
          toast.success("Successfully update feedback form localization");
          goBack();
        }
      })
      .catch(() => {
        toast.error("Failed to update feedback form localization");
      });
  };

  useEffect(() => {
    if (!state.feedback_form_id) {
      replace("/feedbacks");
      return;
    }

    if (data?.data) {
      setForm(data.data.datas);
    }
  }, [state.feedback_form_id, data?.data, replace]);

  useEffect(() => {
    const progress = ref.current as any;
    if (isLoading) {
      progress?.continuousStart();
    } else {
      progress?.complete();
    }
  }, [isLoading]);

  if (!data?.data) {
    return (
      <div className="flex flex-col flex-1">
        <div className="flex justify-between p-6 mb-4 border-b border-line-gray">
          <h2 className="font-bold text-base md:text-2xl">Localization Feedback</h2>
        </div>
      </div>
    );
  }

  // TODO: revert to menu
  if (data.data.language_list.length === 1) {
    return (
      <div className="flex flex-1 flex-col items-center justify-center p-6 text-center">
        <GlobeIcon className="w-16 h-16 mb-6" />
        <p className="font-bold text-lg mb-2">You only select English for your default language</p>
        <p className="text-sm mb-8">Activate other languages that will be displayed on your menu in general settings</p>
        <button type="button" title="Go to General Settings" className="button-primary">Go to General Settings</button>
      </div>
    );
  }

  return (
    <div className="flex flex-col flex-1">
      <LoadingBar height={4} color="#0078D3" ref={ref} />

      <div className="flex justify-between p-6 mb-4 border-b border-line-gray">
        <div className="flex items-center">
          <button type="button" title="Back" className="bg-transparent" onClick={goBack}>
            <ChevronDownIcon className="h-6 w-6 transform rotate-90 mr-2" />
          </button>
          <h2 className="font-bold text-base md:text-2xl">Localization Feedback</h2>
        </div>

        <div className="hidden md:flex">
          <button type="button" title="Cancel" className="button-gray py-2 px-4 mr-4" onClick={goBack}>
            Cancel
          </button>
          <button type="button" className="button-primary py-2 px-4" onClick={handleSave} disabled={isLoadingUpdateLocal}>
            {isLoadingUpdateLocal ? (
              <div className="flex">
                <SpinnerIcon className="animate-spin h-5 w-5" viewBox="0 0 24 24" />
                <span className="text-sm ml-2 font-normal">Saving</span>
              </div>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>

      <div className="flex flex-col justify-between relative h-full">
        <div className="px-6 pb-6 w-full overflow-x-auto whitespace-nowrap flex-1">
          {form?.map((formItem) => {
            const isRTL = data.data?.language_list.find((lang) => lang.code === formItem.lang_id)?.type === "RTL";

            return (
              <div className="inline-block md:h-full shadow-lg rounded w-80 md:w-96 mr-4" key={formItem.lang_id}>
                <div className="p-4 border-b border-line-gray">
                  <div className="flex">
                    <p className="font-bold flex-1">
                      {data.data?.language_list?.find(({ code }) => formItem.lang_id === code)?.name}
                    </p>
                    {formItem.lang_id !== data.data?.main_language && (
                      <div className="flex items-center gap-2">
                        <p>Show</p>
                        <div className="relative inline-block w-8 mr-2 align-middle select-none">
                          <input
                            type="checkbox"
                            name="is_show"
                            id="is_show"
                            className="toggle-checkbox absolute block w-4 h-4 rounded-full bg-white border-2 appearance-none transition-all duration-300 ease-in cursor-pointer"
                            onChange={(e) => handleChange(e, formItem.lang_id, "is_show")}
                            checked={formItem.is_show}
                          />
                          <label
                            htmlFor="is_show"
                            className="toggle-label block overflow-hidden h-4 rounded-full bg-gray-300 transition-all duration-300 ease-in cursor-pointer"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <p
                    className={
                      `text-secondary text-xs mt-2 ${
                        formItem.lang_id === data.data?.main_language ? "opacity-100" : "opacity-0"}`
                    }
                  >
                    Default Language
                  </p>
                </div>

                <form className={`p-4 flex flex-col${isRTL ? " form-rtl" : ""}`}>
                  {formItem.questions.map((question) => (
                    <input
                      id={question.id}
                      type="text"
                      className="input-base mb-4"
                      onChange={(e) => handleChange(e, formItem.lang_id, "questions", question.id)}
                      value={question.text}
                    />
                  ))}
                </form>
              </div>
            );
          })}
        </div>

        <button type="button" className="md:hidden button-primary m-4" onClick={handleSave} disabled={isLoadingUpdateLocal}>
          {isLoadingUpdateLocal ? (
            <div className="flex">
              <SpinnerIcon className="animate-spin h-5 w-5" viewBox="0 0 24 24" />
              <span className="text-sm ml-2 font-normal">Saving</span>
            </div>
          ) : (
            "Save"
          )}
        </button>
      </div>
    </div>
  );
}

export default FeedbaclLocalizePage;
