import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { userDetailModel } from "../../services";
import { LocalStorage, SessionStorage } from "../../utils";
import { AuthResponse } from "./model";
import { deleteFCMToken } from "../notification";

interface AuthSliceState extends AuthResponse {
  fcm_token: string | null;
  step: number | null;
  user: userDetailModel | null;
  remember: boolean;
}

const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: LocalStorage.getValue("token") || SessionStorage.getValue("token"),
    refresh_token: LocalStorage.getValue("refresh_token") || SessionStorage.getValue("refresh_token"),
    fcm_token: LocalStorage.getValue("fcmToken"),
    uid: LocalStorage.getValue("uid"),
    step: LocalStorage.getValue("step"),
    user: LocalStorage.getValue("user"),
    remember: true,
  } as AuthSliceState,
  reducers: {
    setCredentials: (state, { payload }: PayloadAction<Partial<AuthSliceState>>) => ({
      ...state,
      ...payload,
    }),
    setUser: (state, { payload }: PayloadAction<userDetailModel>) => {
      state.user = payload;
    },
    logout: (state) => {
      state.uid = null;
      state.token = null;
      state.refresh_token = null;
      state.fcm_token = null;
      state.step = null;
      state.user = null;
      state.remember = true;

      // LocalStorage.clearAll();
      // SessionStorage.clearAll();

      // if (window.location.pathname !== "/login" || state.token) window.location.reload();

      deleteFCMToken().then(() => {
        LocalStorage.clearAll();
        SessionStorage.clearAll();
        // if (window.location.pathname !== "/login" || state.token) window.location.reload();
      });
    },
    updateToken: (state, { payload }: PayloadAction<AuthResponse>) => {
      state.token = payload.token;
      state.uid = payload.uid;
      state.refresh_token = payload.refresh_token;
    },
  },
});

export const {
  setCredentials, setUser, logout, updateToken,
} = authSlice.actions;

export default authSlice.reducer;
