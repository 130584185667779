/* eslint-disable camelcase */
import { createApi } from "@reduxjs/toolkit/query/react";

import baseQuery from "./base-service";

export const uploadServices = createApi({
  reducerPath: "upload",
  baseQuery,
  endpoints: (builder) => ({
    image: builder.mutation<HttpResponse<{image_id: string, path: string;}>, FormData>({
      query: (body) => ({
        url: "/uploadimg",
        method: "POST",
        body,
      }),
    }),
    video: builder.mutation<HttpResponse<{video_id: string, path: string;}>, FormData>({
      query: (body) => ({
        url: "/uploadvideo",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useImageMutation, useVideoMutation } = uploadServices;
