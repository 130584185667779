import DropUploader from "./drop-uploader";

import { ReactComponent as TrashIcon } from "../assets/icons/ic_trash.svg";

interface Props {
  children: JSX.Element;
  onRemove: () => void;
  setImagePath: (path: string) => void;
  type: "category" | "item";
  image?: string;
}

function CardFormItem({
  children, onRemove, setImagePath, type, image,
}: Props) {
  return (
    <div className="w-full bg-white rounded shadow-md mb-4" key={image}>
      <div className="flex p-4">
        <DropUploader type="secondary" setFilePath={setImagePath} preview={image} />

        <div className="flex flex-col flex-grow ml-4">
          {/* should pass a form here */}
          {children}
        </div>
      </div>
      <div className="p-4 border-t border-line-gray flex justify-end">
        <button className="button-footer flex" onClick={onRemove} type="button">
          <TrashIcon className="w-4 h-4 mr-1" />
          <span>
            Remove
            {type.toString()}
          </span>
        </button>
      </div>
    </div>
  );
}

export default CardFormItem;
