import clsx from "clsx";
import {
  Fragment,
  memo, useEffect, useMemo, useState,
  type FormEvent,
} from "react";
import Popup from "reactjs-popup";

import { ReactComponent as CloseIcon } from "../../assets/icons/ic_close.svg";
import { ReactComponent as SpinnerIcon } from "../../assets/icons/ic_spinner.svg";

import { ORDER_STATUS_LIST_TEXT } from "../../constants";
import { useOrderStatusTypeListQuery } from "../../services";
import { OrderStatusIcon } from "../orders";

type SubmitEventHandler = {
  orderStatusId: string;
  cancelNote: string;
}

interface ModalChangeOrderStatusProps {
  currentStatus: string;
  orderType: string;
  orderId?: string;
  cancellationNote?: string;
  isOpen?: boolean;
  isLoading?: boolean;
  onClose?: () => void;
  onSubmit?: ({ orderStatusId, cancelNote }: SubmitEventHandler) => void;
}

function ModalChangeOrderStatus({
  currentStatus,
  orderId,
  orderType,
  cancellationNote,
  isOpen,
  isLoading,
  onSubmit,
  onClose,
}: ModalChangeOrderStatusProps) {
  const [status, setStatus] = useState<string>(currentStatus || "");
  const [note, setNote] = useState<string>(cancellationNote || "");

  const { data: orderStatusData } = useOrderStatusTypeListQuery();

  const orderStatusTypeList = useMemo(() => orderStatusData?.data || [], [orderStatusData?.data]);

  const handleOnChange = (event: InputChangeType) => {
    const { value } = event.target;

    setStatus(value);
  };

  const handleOnSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSubmit?.({
      orderStatusId: status,
      cancelNote: note,
    });
  };

  useEffect(() => {
    setStatus(currentStatus || "");
  }, [currentStatus]);

  useEffect(() => {
    setNote(cancellationNote || "");
  }, [cancellationNote]);

  return (
    <Popup
      modal
      open={isOpen}
      onClose={onClose}
      closeOnEscape
      lockScroll
      contentStyle={{
        maxWidth: 505,
        width: "100%",
        backgroundColor: "white",
        borderRadius: 8,
      }}
      overlayStyle={{
        padding: "16px",
        overflowY: "auto",
        msOverflowY: "auto",
      }}
    >
      <form
        onSubmit={handleOnSubmit}
        className={clsx("bg-white", "rounded-lg")}
      >
        <div className={clsx("w-full", "p-4 md:py-6 md:px-[18px]", "border-b", "flex flex-row items-center justify-between gap-4")}>
          <span className="font-medium pr-4">
            Change Order Status
            {orderId ? ` of #${orderId}` : ""}
          </span>

          <button
            type="button"
            title="Close Modal"
            onClick={onClose}
          >
            <CloseIcon className="w-5 h-5" />
          </button>
        </div>

        <div className={clsx("p-4 md:px-[18px]", "flex flex-col items-center justify-center gap-4")}>
          {orderStatusTypeList?.map(({ id }) => {
            const isActive = status === id;

            if (orderType !== "order_delivery" && (id === "stat_ondelivery" || id === "stat_delivered")) {
              return null;
            }

            return (
              <Fragment key={id}>
                <label
                  htmlFor={id}
                  className={clsx(
                    "w-full shadow-md",
                    "p-4 md:px-6",
                    "flex flex-row items-center justify-between gap-4",
                    "rounded-md border",
                    (isActive) ? "border-[#DC5C4B]" : "border-transparent",
                    isLoading && "cursor-not-allowed",
                    isLoading && !isActive && "opacity-50",
                  )}
                >
                  <OrderStatusIcon id={id} className="h-[30px] w-[30px]" />

                  <span className="flex-1">{ORDER_STATUS_LIST_TEXT[id as keyof typeof ORDER_STATUS_LIST_TEXT]}</span>

                  <input
                    type="radio"
                    name="order-status"
                    id={id}
                    value={id}
                    onChange={handleOnChange}
                    className={clsx("input-radio hidden")}
                    disabled={isLoading}
                    defaultChecked={currentStatus === id}
                  />

                  <div className="input-radio-icon-pastel-red" />
                </label>

                {id === "stat_cancelled" && isActive && (
                  <div className={clsx(
                    "w-full",
                  )}
                  >
                    <label htmlFor="cancellation-note" className="mb-2 block">
                      Cancellation Note
                    </label>
                    <textarea
                      name="cancellation-note"
                      id="cancellation-note"
                      rows={5}
                      onChange={(event) => {
                        setNote(event.target.value);
                      }}
                      placeholder="Enter cancellation note"
                      className={clsx("w-full input-base")}
                      value={note}
                      disabled={isLoading}
                    />
                  </div>
                )}
              </Fragment>
            );
          })}
        </div>

        <div className="flex items-center justify-center gap-4 px-4 py-4 w-full md:w-auto">
          <button
            type="submit"
            title="Save"
            className={clsx(
              "button-primary py-2 px-5 flex-1 md:flex-none",
              "h-11 md:min-w-[100px]",
            )}
            disabled={isLoading || (status === "stat_cancelled" ? (note === cancellationNote) : currentStatus === status)}
          >
            {isLoading ? (
              <SpinnerIcon className="animate-spin h-4 w-4 mx-auto" />
            ) : "Save"}
          </button>

          <button
            type="button"
            title="Cancel"
            className={clsx(
              "button-gray py-2 px-5 flex-1 md:flex-none",
              "h-11 md:min-w-[100px]",
            )}
            disabled={isLoading}
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </form>
    </Popup>
  );
}

export default memo(ModalChangeOrderStatus);
