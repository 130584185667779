/* eslint-disable camelcase */
import { createApi } from "@reduxjs/toolkit/query/react";

import baseQuery from "./base-service";
import { LoginRequest, AuthResponse, RefreshTokenRequest } from "../features/auth";

export const sessionServices = createApi({
  reducerPath: "session",
  baseQuery,
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  endpoints: (builder) => ({
    login: builder.mutation<HttpResponse<AuthResponse>, LoginRequest>({
      query: (credentials) => ({
        url: "/user/login",
        method: "POST",
        body: credentials,
      }),
    }),
    forgotPassword: builder.mutation<HttpResponse<{}>, {email: string}>({
      query: (body) => ({
        url: "/forgotPassword",
        method: "POST",
        body,
      }),
    }),
    resetPassword: builder.mutation<HttpResponse<{}>, {uid: string, password: string}>({
      query: (body) => ({
        url: "/resetPassword",
        method: "POST",
        body,
      }),
    }),
    refreshToken: builder.mutation<HttpResponse<AuthResponse>, RefreshTokenRequest>({
      query: (body) => ({
        url: "/user/refresh",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useLoginMutation, useForgotPasswordMutation, useResetPasswordMutation, useRefreshTokenMutation,
} = sessionServices;
