import { useHistory } from "react-router-dom";
import { ReactComponent as Illustration } from "../assets/illustration_404.svg";

function NotFoundPage() {
  const history = useHistory();

  function handleClick() {
    history.push("/");
  }

  return (
    <div className="w-96 mx-auto text-center pt-16">
      <h2 className="font-montserrat font-bold text-2xl mb-2">Page Not Found</h2>
      <p className="text-sm text-secondary mb-10 px-14">The requested URL /error was not found on this server.</p>

      <button
        type="button"
        title="Back to Home"
        className="button-primary font-montserrat font-normal mb-10"
        onClick={handleClick}
      >
        Back to home
      </button>

      <Illustration className="w-10/12 mx-auto md:w-full" />
    </div>
  );
}

export default NotFoundPage;
