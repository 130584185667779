import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const selectableSlice = createSlice({
  name: "selectable",
  initialState: [] as string[],
  reducers: {
    addSelected: (state, { payload: id }: PayloadAction<string>) => {
      state.push(id);
    },
    addMultipleSelected: (state, { payload: ids }: PayloadAction<string[]>) => [...state, ...ids],
    removeSelected: (state, { payload: id }: PayloadAction<string>) => state.filter((currentID) => currentID !== id),
    resetSelected: () => [],
  },
});

export const {
  addSelected, addMultipleSelected, removeSelected, resetSelected,
} = selectableSlice.actions;

export default selectableSlice.reducer;
