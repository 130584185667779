export const ORDER_FILTER_LIST_TEXT = {
  filter_all: "All",
  filter_new: "New",
  filter_prepare: "Preparing",
  filter_ready: "Ready",
  filter_close: "Closed",
};

export const ORDER_TYPE_LIST_TEXT = {
  all: "All",
  order_pickup: "Pick up",
  order_dinein: "Dine-in Mobile",
  order_dinein_qrmobile: "Dine-in Mobile",
  order_dinein_tablet: "Dine-in Tablet",
  order_delivery: "Delivery",
  order_drivetru: "Drive-through",
};

export const ORDER_STATUS_LIST_TEXT = {
  stat_preparing: "Preparing",
  stat_waiting: "Waiting for Approval",
  stat_ondelivery: "On Delivery",
  stat_cancelled: "Cancelled",
  stat_accepted: "Accepted",
  stat_ready: "Ready",
  stat_delivered: "Delivered",
  stat_closed: "Closed",
};
