/* eslint-disable camelcase */
import { createApi } from "@reduxjs/toolkit/query/react";

import baseQuery from "./base-service";

export interface ButtonTextTheme {
  lang_id: string;
  name: string;
}

export interface ThemeResponse {
  id: string;
  name: string;
  button_texts: ButtonTextTheme[];
  is_active: boolean;
  background_img: string;
  // logo_img: string;
  button_shape: string;
}

export interface AddThemeRequest {
  name: string;
  button_texts: ButtonTextTheme[];
  background: string;
  button_shape: string;
  // logo: string;
}
export interface UpdateThemeRequest {
  id: string;
  button_texts: ButtonTextTheme[];
  button_shape: string;
}

export const themeServices = createApi({
  reducerPath: "theme",
  baseQuery,
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  endpoints: (builder) => ({
    getAllTheme: builder.query<HttpResponse<ThemeResponse[]>, void>({
      query: () => ({
        url: "/theme",
        method: "GET",
      }),
    }),
    getTheme: builder.query<HttpResponse<ThemeResponse>, string>({
      query: (id) => ({
        url: `/theme/single/${id}`,
        method: "GET",
      }),
    }),
    postTheme: builder.mutation<HttpResponse<{}>, AddThemeRequest>({
      query: (body) => ({
        url: "/theme",
        method: "POST",
        body,
      }),
    }),
    updateTheme: builder.mutation<HttpResponse<{}>, UpdateThemeRequest>({
      query: (body) => ({
        url: "/theme",
        method: "PUT",
        body,
      }),
    }),
    updateThemeLogo: builder.mutation<HttpResponse<{}>, {id: string; logo: string | null;}>({
      query: (body) => ({
        url: "/theme/logo",
        method: "PUT",
        body,
      }),
    }),
    updateThemeBackground: builder.mutation<HttpResponse<{}>, {id: string; background: string | null;}>({
      query: (body) => ({
        url: "/theme/background",
        method: "PUT",
        body,
      }),
    }),
    publishTheme: builder.mutation<HttpResponse<{}>, string>({
      query: (id) => ({
        url: "/theme/publish",
        method: "PUT",
        body: { id },
      }),
    }),
    deleteTheme: builder.mutation<HttpResponse<{}>, string>({
      query: (id) => ({
        url: "/theme",
        method: "DELETE",
        body: { id },
      }),
    }),
  }),
});

export const {
  useGetAllThemeQuery,
  useGetThemeQuery,
  usePostThemeMutation,
  useUpdateThemeMutation,
  useUpdateThemeBackgroundMutation,
  useUpdateThemeLogoMutation,
  usePublishThemeMutation,
  useDeleteThemeMutation,
} = themeServices;
