import { FocusEvent } from "react";

interface InputWithIconProps {
  leftIcon?: JSX.Element | JSX.Element[];
  rightIcon?: JSX.Element;
  name: string;
  title: string;
  placeholder: string;
  inputRef: any;
  type?: string;
  isValid?: boolean;
  errorMessage?: string;
  additionalLeftInfo?: string;
  value: string | number;
  onChange: (e: InputChangeType) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  secureText?: boolean;
}

function InputWithIcon({
  leftIcon,
  rightIcon,
  name,
  inputRef,
  value,
  onChange,
  onBlur,
  title,
  placeholder,
  type,
  isValid,
  errorMessage,
  additionalLeftInfo,
  disabled,
  secureText,
}: InputWithIconProps) {
  return (
    <div className="mb-6">
      <div className={`flex rounded border px-4 py-2 items-center focus-within:border-primary transform transition-colors duration-300 ease-in ${isValid ? "border-secondary" : "border-line-gray"}`}>
        {leftIcon}

        <div className="flex flex-col flex-1 mb-1">
          <p className="font-montserrat text-xs text-left mb-1">{title}</p>

          <div className="flex w-full">
            {additionalLeftInfo && <p className="text-sm mr-1">{additionalLeftInfo}</p>}

            <input
              name={name}
              type={type ?? "text"}
              className={`w-full text-sm bg-none border-0 focus:outline-none focus:ring-0${secureText ? " text-secure" : ""}`}
              placeholder={placeholder}
              ref={inputRef}
              value={value}
              onChange={onChange}
              onBlur={onBlur && onBlur}
              disabled={disabled ?? false}
            />
          </div>
        </div>

        {rightIcon}
      </div>

      {errorMessage && <p className="text-sm text-red-500 mt-1">{errorMessage}</p>}
    </div>
  );
}

export default InputWithIcon;
