import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { IntercomProvider } from "react-use-intercom";

import "react-calendar/dist/Calendar.css";
import "react-toastify/dist/ReactToastify.css";
import "reactjs-popup/dist/index.css";
import "tippy.js/dist/tippy.css";

import "./index.css";

import { store } from "./app/store";
import { Router } from "./components";
import * as FirebaseMessagingSW from "./sw/firebase-messaging-sw";
import * as serviceWorker from "./sw/service-worker";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN as string,
  // integrations: [new BrowserTracing()],
  integrations: [
    new Integrations.BrowserTracing(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <ToastContainer position="top-center" />
    <Provider store={store}>
      <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID as string}>
        <Router />
      </IntercomProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// Register Firebase Messaging Service Worker
FirebaseMessagingSW.unregister();
// FirebaseMessagingSW.register();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// serviceWorker.unregister();
