import { createApi } from "@reduxjs/toolkit/query/react";

import baseQuery from "./base-service";
import { type FeedbackResultModel } from "./feedback-services";
import { type GetAllOrderResponse } from "./order-services";
import { type InvoiceModel } from "./setting-services";

interface DashboardSummariesReport {
  category_total: number;
  item_total: number;
  order_total: number;
  revenue_total: string | number;
}

interface DashboardRecents {
  orders: GetAllOrderResponse[];
  feedbacks: FeedbackResultModel[];
  billings: InvoiceModel[];
}

export const dashboardServices = createApi({
  reducerPath: "dashboard",
  baseQuery,
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  endpoints: (builder) => ({
    getDashboardSummariesReport: builder.query<HttpResponse<DashboardSummariesReport>, { from: string, to: string }>({
      query: ({ from, to }) => ({
        url: "/summary",
        params: {
          from,
          to,
        },
        method: "GET",
      }),
    }),
    getDashboardRecents: builder.query<HttpResponse<DashboardRecents>, void>({
      query: () => ({
        url: "/summary/recent",
        method: "GET",
      }),
    }),
  }),
});

export const { useGetDashboardRecentsQuery, useGetDashboardSummariesReportQuery } = dashboardServices;
