import {
  Redirect, Route, RouteProps, useLocation,
} from "react-router-dom";

export type PrivateRouteProps = {
  isAuthenticated: boolean;
} & RouteProps;

export default function PrivateRoute({
  isAuthenticated,
  ...routeProps
}: PrivateRouteProps) {
  const location = useLocation();
  const pathname = "/login";

  if (isAuthenticated) {
    return <Route {...routeProps} exact />;
  }

  return <Redirect to={{ pathname, state: { from: location } }} />;
}
