import { useEffect, useState, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingBar from "react-top-loading-bar";

import { useForm, SubmitHandler } from "react-hook-form";

import { ReactComponent as ChevronDownIcon } from "../assets/icons/ic_chevron_down.svg";
import { ReactComponent as ThumbnailIcon } from "../assets/icons/ic_thumbnail.svg";
import { ReactComponent as CampaignIcon } from "../assets/icons/ic_campaign.svg";
import {
  useGetBannerQuery,
  useImageMutation,
  usePostBannerMutation,
  useUpdateBannerMutation,
  useUpdateBannerImageMutation,
  AddBannerRequest,
} from "../services";
import { DropUploader, ModalConfirmation, LoadingAnimation } from "../components";
import { useAppSelector } from "../app/hooks";

interface Confirmation {
  isOpen: boolean;
  data: AddBannerRequest | null;
}

function BannerPage() {
  const uid = useAppSelector(({ auth }) => auth.uid);
  const { state } = useLocation<{ banner_id?: string }>();
  const { goBack } = useHistory();

  const {
    register, handleSubmit, reset, formState, setValue,
  } = useForm<AddBannerRequest>();

  const { errors } = formState;
  const [postImage, { isLoading: isLoadingImage }] = useImageMutation();
  const [
    updateBannerImage,
    {
      isLoading: isLoadingUpdateBannerImage,
      isError: isErrorImage,
    },
  ] = useUpdateBannerImageMutation();
  const [createBanner, { isLoading: isLoadingCreateBanner }] = usePostBannerMutation();
  const [updateBanner, { isLoading: isLoadingUpdateBanner }] = useUpdateBannerMutation();
  const { data: dataBanner, isLoading: isLoadingBanner } = useGetBannerQuery(state?.banner_id ?? "", {
    skip: !state?.banner_id ?? true,
  });
  const [modalConfirmation, setModalConfirmation] = useState<Confirmation>({
    isOpen: false,
    data: null,
  });
  const ref = useRef(null);

  /**
   * @description Handle submit form
   * @param data
   * @returns
   */
  const onSubmit: SubmitHandler<AddBannerRequest> = (data) => {
    if (!data.name) {
      toast.error("Name is required");
      return;
    }
    // if (!data.desc) {
    //   toast.error("Description is required");
    //   return;
    // }
    if (!data.image) {
      toast.error("Image is required");
      return;
    }
    setModalConfirmation({ isOpen: true, data });
  };

  /**
   * @description Handle callback modal confirmation to create/update banner
   * @returns
   */
  const callbackConfirmation = () => {
    const { data } = modalConfirmation;

    if (!data) return;

    if (state?.banner_id) {
      const id = state.banner_id;
      const { image } = data;

      const firstUpdate = updateBanner({ ...data, id }).unwrap();
      let secondUpdate = dataBanner?.data?.image === image
        ? Promise.resolve({}) : updateBannerImage({ id, image }).unwrap();

      if (dataBanner?.data?.image && image === "") {
        secondUpdate = updateBannerImage({ id, image: null }).unwrap();
      }

      Promise.all([firstUpdate, secondUpdate])
        .then(goBack)
        .catch((error) => {
          toast.error(error.data?.message ?? "Failed to update banner");
        });
    } else {
      createBanner(data)
        .unwrap()
        .then(goBack)
        .catch((error) => {
          toast.error(error.data?.message ?? "Failed to create banner");
        });
    }

    setModalConfirmation((prevState) => ({ ...prevState, isOpen: false }));
  };

  /**
   * @description Handle Upload image
   * @param path<string>
   */
  const uploadImage = (path: string) => {
    if (path === "") {
      setValue("image", "", { shouldDirty: true });
      return;
    }
    if (!uid) return;

    const formData = new FormData();
    formData.append("uid", uid);
    formData.append("img", path);

    postImage(formData)
      .unwrap()
      .then((res) => {
        if (res.data?.image_id) {
          setValue("image", res.data.image_id, { shouldDirty: true });
        }
      })
      .catch((error) => {
        toast.error(error.data?.message ?? "Failed to upload");
        setValue("image", "", { shouldDirty: true });
      });
  };

  useEffect(() => {
    if (state?.banner_id) {
      if (!dataBanner?.data) return;

      reset(dataBanner.data);
    } else {
      reset({
        name: "",
        desc: "",
        image: "",
        is_published: true,
      });
    }
  }, [state?.banner_id, dataBanner, reset]);

  useEffect(() => {
    if (errors.image) {
      toast.error("Image is required");
    }
  }, [errors.image]);

  useEffect(() => {
    const progress = ref.current as any;
    if (isLoadingBanner) {
      progress?.continuousStart();
    } else {
      progress?.complete();
    }
  }, [isLoadingBanner]);

  return (
    <>
      <LoadingBar height={4} color="#0078D3" ref={ref} />
      <div className="flex flex-col flex-1 md:p-0">
        <div className="flex justify-between p-4 border-b border-line-gray">
          <div className="flex items-center">
            <button
              className="bg-transparent"
              onClick={goBack}
              type="button"
              title="Back"
            >
              <ChevronDownIcon className="h-6 w-6 transform rotate-90 mr-2" />
            </button>

            <h2 className="font-bold text-2xl">{state?.banner_id ? "Update Banner" : "Add New Banner"}</h2>
          </div>

          <div className="hidden md:flex">
            <button
              className="button-gray py-2 px-4 mr-4"
              onClick={goBack}
              type="button"
              title="Cancel"
            >
              Cancel
            </button>

            <button
              type="button"
              className="button-primary py-2 px-4"
              onClick={handleSubmit(onSubmit)}
              disabled={!formState.isDirty || isLoadingImage}
            >
              {isLoadingCreateBanner || isLoadingUpdateBanner || isLoadingImage || isLoadingUpdateBannerImage ? (
                <div className="flex flex-row justify-center items-center w-full">
                  <LoadingAnimation size={5} />
                  {isLoadingImage && <span className="text-sm ml-2 font-normal">uploading image</span>}
                  {(isLoadingCreateBanner || isLoadingUpdateBanner || isLoadingUpdateBannerImage) && (
                    <span className="text-sm ml-2 font-normal">Saving</span>
                  )}
                </div>
              ) : (
                <span>Save</span>
              )}
            </button>
          </div>
        </div>

        <div className="px-4 md:px-6 h-full">
          <form onSubmit={(e) => e.preventDefault()} className="flex flex-col md:flex-row h-full">
            {/* LEFT PANE */}
            <div className="flex flex-1 flex-col py-6 gap-8 md:border-r md:border-line-gray">
              <div className="flex flex-col w-full">
                <p className="font-semibold text-lg mb-1 flex">
                  <ThumbnailIcon className="mr-2" />
                  Banner
                </p>
                <p className="text-sm text-secondary opacity-70 mb-10">Choose the content of the banner</p>

                <DropUploader
                  type="primary"
                  withText
                  size="custom"
                  customClassName="w-auto h-48 rounded-xl md:mr-6"
                  setFilePath={uploadImage}
                  preview={dataBanner?.data?.image}
                  uploadFailed={isErrorImage}
                />
                <input type="text" {...register("image")} className="hidden" />
              </div>
            </div>

            {/* RIGHT PANE */}
            <div className="flex flex-1 flex-col gap-4 py-6 md:pl-6">
              <div>
                <p className="font-semibold text-lg mb-1 flex">
                  <CampaignIcon className="mr-2" />
                  Banner Details
                </p>
                <p className="text-sm text-secondary opacity-70 mb-4">Fill the banner details below</p>
              </div>

              <div className="flex flex-col md:flex-row md:items-center mb-4">
                <p className="md:w-5/12 mb-2 md:mb-0">Banner name:</p>
                <input
                  disabled={isLoadingCreateBanner || isLoadingUpdateBanner}
                  type="text"
                  className="input-base flex-1"
                  placeholder="Title"
                  {...register("name")}
                />
              </div>

              <div className="flex flex-col md:flex-row md:items-center mb-4">
                <p className="md:w-5/12 mb-2 md:mb-0 self-start">Banner description:</p>
                <textarea
                  rows={4}
                  className="input-base flex-1"
                  placeholder="Type a banner description"
                  disabled={isLoadingCreateBanner || isLoadingUpdateBanner}
                  {...register("desc")}
                />
              </div>

              <div className="flex flex-col md:flex-row md:items-center">
                <p className="md:w-5/12 mb-2 md:mb-0 self-start">Publish Status:</p>
                <div className="relative inline-block w-8 align-middle text-center select-none">
                  <input
                    type="checkbox"
                    id="toggle-is-published"
                    disabled={isLoadingCreateBanner || isLoadingUpdateBanner}
                    className="toggle-checkbox absolute block w-4 h-4 rounded-full bg-white border-2 appearance-none transition-all duration-300 ease-in cursor-pointer"
                    {...register("is_published")}
                  />
                  <label
                    htmlFor="toggle-is-published"
                    className="toggle-label block overflow-hidden h-4 rounded-full bg-gray-300 transition-all duration-300 ease-in cursor-pointer"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>

        <button
          type="button"
          className="button-primary py-2 px-4 m-4 mt-0 md:hidden"
          onClick={handleSubmit(onSubmit)}
          disabled={!formState.isDirty || isLoadingImage}
        >
          {isLoadingCreateBanner || isLoadingUpdateBanner || isLoadingImage || isLoadingUpdateBannerImage ? (
            <div className="flex flex-row justify-center items-center w-full">
              <LoadingAnimation size={5} />
              {isLoadingImage && <span className="text-sm ml-2 font-normal">uploading image</span>}
              {(isLoadingCreateBanner || isLoadingUpdateBanner || isLoadingUpdateBannerImage) && (
                <span className="text-sm ml-2 font-normal">Saving</span>
              )}
            </div>
          ) : (
            <span>Save</span>
          )}
        </button>
      </div>

      <ModalConfirmation
        isOpen={modalConfirmation.isOpen}
        content="Are you sure?"
        onCloseModal={() => setModalConfirmation({ isOpen: false, data: null })}
        onConfirmModal={callbackConfirmation}
        confirmationText="Save"
      />
    </>
  );
}

export default BannerPage;
