/* eslint-disable camelcase */
import { createApi } from "@reduxjs/toolkit/query/react";

import baseQuery from "./base-service";

export interface CodeNameResponse {
  code: string;
  name: string;
}

interface OutputListResponse extends CodeNameResponse {
  description: string;
}

interface CountryListResponse extends CodeNameResponse {
  code: string;
  country: string;
  phonecode: string;
}

interface LanguageModel {
  code: string;
  name: string;
  type: string;
}

interface CategoryListResponse {
  id: string;
  name: string;
}

type PlanType = "qrmobile" | "tablet";
export interface userDetailModel {
  name?: string;
  bus_name: string;
  email: string;
  isUpgrade: boolean;
  features: PlanType[];
}
export interface userInfoModel {
  main_currency: string;
}

interface UserLangResponse {
  lang_id: string;
  lang_name: string;
}

interface AutoFillResponse {
  name: string;
  description: string;
  image: string;
  category?: string;
}

type NutritionListResponse = {
  unit: string;
  id: string;
};

type OrderStatusTypeListResponse = {
  id: string;
};

export const variablesServices = createApi({
  reducerPath: "variables",
  baseQuery,
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  endpoints: (builder) => ({
    userDetail: builder.query<HttpResponse<userDetailModel>, void>({
      query: () => ({
        url: "/user/detail",
        method: "GET",
      }),
    }),
    userInfo: builder.query<HttpResponse<userInfoModel>, void>({
      query: () => ({
        url: "/user/info",
        method: "GET",
      }),
    }),
    userLanguageList: builder.query<HttpResponse<UserLangResponse[]>, void>({
      query: () => ({
        url: "/user/languageList",
        method: "GET",
      }),
    }),
    outputList: builder.query<HttpResponse<OutputListResponse[]>, void>({
      query: () => ({
        url: "/outputList",
        method: "GET",
      }),
    }),
    currencyList: builder.query<HttpResponse<CodeNameResponse[]>, void>({
      query: () => ({
        url: "/currencyList",
        method: "GET",
      }),
    }),
    businessList: builder.query<HttpResponse<CodeNameResponse[]>, void>({
      query: () => ({
        url: "/businessList",
        method: "GET",
      }),
    }),
    countryList: builder.query<HttpResponse<CountryListResponse[]>, void>({
      query: () => ({
        url: "/countryList",
        method: "GET",
      }),
    }),
    mainLanguageList: builder.query<HttpResponse<LanguageModel[]>, void>({
      query: () => ({
        url: "/languageList/main",
        method: "GET",
      }),
    }),
    otherLanguageList: builder.query<HttpResponse<LanguageModel[]>, void>({
      query: () => ({
        url: "/languageList/other",
        method: "GET",
      }),
    }),
    categoryList: builder.query<HttpResponse<CategoryListResponse[]>, string>({
      query: (uid) => ({
        url: `/categoryList/${uid}`,
        method: "GET",
      }),
    }),
    autofill: builder.query<HttpResponse<AutoFillResponse[]>, { type: string; count: number; names?: string }>({
      query: ({ type, count, names }) => {
        let query = `type=${type}&count=${count}`;
        if (names) {
          query += `&category_names=${names}`;
        }
        return {
          url: `/autofill?${query}`,
          method: "GET",
        };
      },
    }),
    publish: builder.mutation<HttpResponse<{}>, void>({
      query: () => ({
        url: "/publish",
        method: "POST",
      }),
    }),
    nutritionList: builder.query<HttpResponse<NutritionListResponse[]>, void>({
      query: () => ({
        url: "/nutritionList",
        method: "GET",
      }),
    }),
    orderTypeList: builder.query<HttpResponse<{ id: string }[]>, void>({
      query: () => ({
        url: "/orderTypeList",
        method: "GET",
      }),
    }),
    promoTypeList: builder.query<HttpResponse<{ id: string }[]>, void>({
      query: () => ({
        url: "/promoTypeList",
        method: "GET",
      }),
    }),
    orderStatusTypeList: builder.query<HttpResponse<OrderStatusTypeListResponse[]>, void>({
      query: () => ({
        url: "/orderStatusList",
        method: "GET",
      }),
    }),
  }),
});

export const {
  useUserDetailQuery,
  useUserInfoQuery,
  useUserLanguageListQuery,
  useOutputListQuery,
  useCurrencyListQuery,
  useBusinessListQuery,
  useCountryListQuery,
  useMainLanguageListQuery,
  useOtherLanguageListQuery,
  useCategoryListQuery,
  useAutofillQuery,
  useNutritionListQuery,
  usePublishMutation,
  useOrderTypeListQuery,
  usePromoTypeListQuery,
  useOrderStatusTypeListQuery,
} = variablesServices;
