/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from "clsx";
import { format, utcToZonedTime } from "date-fns-tz";
import { useEffect, useRef, useState } from "react";
import Paginatation from "react-paginate";
import { toast } from "react-toastify";
import LoadingBar from "react-top-loading-bar";

import { LoadingAnimation } from "..";
import { ReactComponent as InvoicesIcon } from "../../assets/icons/ic_invoices.svg";
import {
  useGetBillingQuery,
  useGetInvoicesQuery,
  useGetManageBillingQuery,
  useReactiveQuery,
  type InvoiceModel,
} from "../../services";
import { Formatter, getBreakpointValue } from "../../utils";

interface SettingBillingProps {
  padding?: 4 | 6;
  showPagination?: boolean;
  invoices?: InvoiceModel[];
}

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

const parseDateUTC = (date: string) => utcToZonedTime(date, timeZone);
// let newDate = new Date(date);
// newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset());
// return newDate;

function SettingBilling({ padding = 6, showPagination = true, invoices }: SettingBillingProps) {
  const ref = useRef(null);
  const limit = 10;
  const [offset, setOffset] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);
  const [reactivateBilling, setReactivateBilling] = useState<boolean>(false);
  const {
    data: dataBilling,
    isLoading: isLoadingBilling,
    error: errorBilling,
    refetch: refetchBilling,
  } = useGetBillingQuery();
  const {
    data: dataManageBilling,
    isLoading: isLoadingManageBilling,
    error: errorManageBilling,
    refetch: refetchManageBilling,
  } = useGetManageBillingQuery();
  const {
    data: dataInvoices,
    isLoading: isLoadingInvoices,
    error: errorInvoices,
    refetch: refetchInvoices,
  } = useGetInvoicesQuery({ offset, limit }, {
    skip: typeof invoices !== "undefined",
  });
  const {
    data: dataReactiveBilling,
    isLoading: isLoadingReactiveBilling,
    isSuccess: successReactiveBilling,
    error: errorReactiveBilling,
  } = useReactiveQuery(undefined, { skip: !reactivateBilling });
  const isMobile = getBreakpointValue("md") > window.innerWidth;

  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * limit) % (dataInvoices?.total_item ?? 0);
    setOffset(newOffset);
  };

  const handleReactivate = () => {
    setReactivateBilling(true);
  };

  useEffect(() => {
    if (errorInvoices) toast.error(errorInvoices);
    if (errorBilling) toast.error(errorBilling);
    if (errorManageBilling) toast.error(errorManageBilling);
  }, [errorInvoices, errorBilling, errorManageBilling]);

  useEffect(() => {
    if (!dataInvoices) return;

    const pageCounts = Math.ceil((dataInvoices.total_item ?? 0) / limit);
    setPageCount(pageCounts);
  }, [dataInvoices, offset]);

  useEffect(() => {
    const progress = ref.current as any;
    if (isLoadingInvoices || isLoadingBilling || isLoadingManageBilling) {
      progress?.continuousStart();
    } else {
      progress?.complete();
    }
  }, [isLoadingInvoices, isLoadingBilling, isLoadingManageBilling]);

  useEffect(() => {
    if (successReactiveBilling) {
      toast.success("Successfully reactivate billing");
      refetchBilling();
      refetchManageBilling();
      refetchInvoices();
    }
  }, [successReactiveBilling]);

  useEffect(() => {
    if (errorReactiveBilling) {
      toast.error(dataReactiveBilling?.message ?? errorReactiveBilling ?? "Failed to reactivate billing");
    }
  }, [errorReactiveBilling, dataReactiveBilling]);

  return (
    <>
      <LoadingBar height={4} color="#0078D3" ref={ref} />
      <div className="w-full">
        <div className={clsx("p-4 pt-2 border-b border-line-gray flex flex-col md:flex-row gap-4 justify-between", padding === 6 && "md:p-6 md:pt-0")}>
          <div className="flex flex-col">
            <p>Account Valid Until</p>
            <p className="font-bold capitalize">
              {dataBilling?.data
                ? format(parseDateUTC(dataBilling.data.valid_until), "dd MMM yyyy", { timeZone })
                : "-"}
            </p>
          </div>
          <div className="flex flex-col">
            <p>Subscription Price</p>
            <p className="font-bold">
              { dataBilling?.data
                ? `${dataBilling.data.currency.toUpperCase()} ${Formatter.lastTwoDot(dataBilling.data.price.toString())} Paid every ${dataBilling.data.interval}`
                : "-"}
            </p>
          </div>
          <div className="flex flex-col">
            <p>Subscription</p>
            <p className="font-bold">
              {dataBilling?.data ? dataBilling.data.subscription : "-"}
            </p>
          </div>
          <div className="flex flex-col md:items-center md:justify-center gap-2">
            {(dataBilling?.data === null && dataBilling.status === "Success") && (
              <button
                type="button"
                title={isLoadingReactiveBilling ? "Loading..." : "Reactive"}
                className="button-success text-center font-normal md:py-2 md:px-4 w-full"
                onClick={handleReactivate}
              >
                {isLoadingReactiveBilling ? <LoadingAnimation size={4} /> : "Reactive"}
              </button>
            )}

            <a
              className="button-primary font-normal md:py-2 md:px-4"
              href={dataManageBilling?.data?.url ?? "#"}
            >
              Manage Subscription
            </a>
          </div>
        </div>

        <div className={clsx("p-4", padding === 6 && "md:p-6 pt-0")}>
          {/* header invoices */}
          {(invoices || dataInvoices?.data) && (
            <div className={clsx("flex flex-col mt-0", showPagination ? "mb-8" : "mb-4")}>
              <div className="flex items-center">
                <InvoicesIcon className="h-5 w-5 mr-2" />
                <p className="font-semibold text-lg mt-1">Invoices</p>
              </div>

              {!isLoadingInvoices && (
                <p className="text-sm text-secondary">
                  {(invoices || dataInvoices?.data || []).length > 0
                    ? "History of your Invoice"
                    : "You don’t have invoice history yet"}
                </p>
              )}
            </div>
          )}

          {/* invoices */}
          {((invoices || dataInvoices?.data) ?? []).length > 0 && (
            <div className="hidden md:flex w-full p-4 bg-gray-100 font-medium items-center">
              <div className="pr-4">
                <p>No</p>
              </div>
              <div className="flex flex-1 flex-col-reverse md:flex-row">
                <p className="md:pl-4 md:w-1/2 border-l">Due Date</p>
                <div className="flex md:w-1/2 flex-row-reverse md:flex-row">
                  <p className="md:pl-4 flex-1 border-l">Plan</p>
                  <p className="md:pl-4 md:flex-1 border-l">Amount</p>
                </div>
              </div>
              <p className="md:pl-4 border-l w-32">Status</p>
              <p className="md:pl-4 border-l w-32">Action</p>
            </div>
          )}

          {/* {(invoices ?? [])?.map((data, index) => { */}
          {((invoices || dataInvoices?.data) ?? [])?.map((data, index) => {
            const { is_paid } = data;
            const statusStyle = is_paid
              ? "bg-green-100 text-success"
              : "bg-red-100 text-error";

            const openDetail = () => {
              window.open(data.detail_link, "_blank");
            };

            const dateStart = data.period.start
              ? format(parseDateUTC(data.period.start), "dd MMM yyyy", {
                timeZone,
              })
              : "-";

            const dateEnd = data.period.end
              ? format(parseDateUTC(data.period.end), "dd MMM yyyy", {
                timeZone,
              })
              : "-";

            const dateInvoice = data.interval === "month"
              ? dateStart
              : `${dateStart} - ${dateEnd}`;

            return (
              <div
                key={data.id}
                className="flex w-full text-sm p-4 md:border-b md:border-gray-100 rounded-md md:rounded-none shadow-lg md:shadow-none mb-3 md:mb-0 items-center"
                onClick={() => {
                  if (isMobile) openDetail();
                }}
              >
                <div className="pr-4 hidden md:block">
                  <p>{index + 1}</p>
                </div>

                <div className="flex flex-1 flex-col-reverse md:flex-row">
                  <p className="md:pl-4 md:w-1/2 mt-2 md:mt-0">{dateInvoice}</p>
                  <div className="flex md:w-1/2 flex-row-reverse md:flex-row">
                    <p className="pl-4 flex-1 font-medium md:font-normal">
                      {data.interval === "year" && "Annual"}
                      {" "}
                      {data.plan}
                    </p>
                    <p className="md:pl-4 md:flex-1">
                      {data.price === 0 ? "-" : `${data.currency.toUpperCase()} ${Formatter.lastTwoDot(data.price.toString())}`}
                    </p>
                  </div>
                </div>

                <div className="md:pl-4 md:w-32 flex justify-between items-center">
                  <span className={`px-2 py-1 rounded text-xs ${statusStyle}`}>
                    {data.is_paid ? "Paid" : "Unpaid"}
                  </span>
                </div>

                <div className="md:pl-4 md:w-32 flex justify-between items-center">
                  <button
                    type="button"
                    title="View"
                    className="hidden md:block button-link text-sm font-light"
                    onClick={openDetail}
                  >
                    View
                  </button>
                </div>
              </div>
            );
          })}

          {showPagination && (dataInvoices?.total_item ?? 0) > 10 && (
            <Paginatation
              // breakLabel="..."
              nextLabel="›"
              previousLabel="‹"
              marginPagesDisplayed={8}
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              containerClassName="flex gap-2 items-center text-sm mt-8"
              activeClassName="text-active border-primary outline-primary"
              pageClassName="rounded border border-gray-300 w-6 h-6 flex items-center justify-center"
              previousClassName="rounded border border-gray-300 w-6 h-6 flex items-center justify-center"
              nextClassName="rounded border border-gray-300 w-6 h-6 flex items-center justify-center"
              pageLinkClassName="block w-full text-center focus:outline-none"
              nextLinkClassName="block w-full text-center focus:outline-none"
              previousLinkClassName="block w-full text-center focus:outline-none"
            />
          )}
        </div>
      </div>
    </>
  );
}

export default SettingBilling;
