import { useHistory } from "react-router-dom";
import { ReactComponent as LogoIcon } from "../../assets/logo.svg";

function HeaderFrontPage({
  onlyLoginButton = false,
}: {
  onlyLoginButton?: boolean;
}) {
  const history = useHistory();

  function handleClick() {
    history.push("/login");
  }

  return (
    <header className={`w-full border-b border-line-gray p-5 md:py-3 text-sm flex flex-col md:flex-row items-center${onlyLoginButton ? " justify-end" : ""}`}>
      {!onlyLoginButton && <LogoIcon className="mt-10 md:mt-0" />}
      {!onlyLoginButton && (
        <p className="px-3 my-3 md:my-0 text-secondary md:flex-1 md:text-right">
          Already have an account?
        </p>
      )}
      <button
        className="px-10 md:px-5 py-1 border border-line-gray rounded-full text-active font-bold justify-items-end"
        onClick={handleClick}
        type="button"
      >
        Login
      </button>
    </header>
  );
}

export default HeaderFrontPage;
