import clsx from "clsx";
import { motion } from "framer-motion";
import { memo, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Popup from "reactjs-popup";

import CheckoutRequestIcon from "../../assets/checkout-request-icon.gif";
import OrderReceivedIcon from "../../assets/order-received-icon.gif";

import { getBreakpointValue } from "../../utils";
import { ORDER_TYPE_LIST_TEXT } from "../../constants";

interface OrderNotificationProps {
  type: string;
  // type: "order" | "checkout";
  buttonText?: string;
  orderId: number | string;
  orderTypeId: string;
  paymentMethodId?: string;
  tableName?: string;
  isModal?: boolean;
  isOpen?: boolean;
  onClose?: () => void;
}

function OrderNotification({
  type,
  buttonText,
  orderId,
  orderTypeId,
  paymentMethodId,
  tableName,
  isModal,
  isOpen,
  onClose,
}: OrderNotificationProps) {
  const history = useHistory();

  const isMobile = getBreakpointValue("md") > window.innerWidth;
  // const paymentMethod = paymentMethodId === "pay_cash" ? "Cash" : "Credit Card";

  const getAction = () => {
    history.push(`/orders?id=${orderId}`);
    onClose?.();
  };

  useEffect(() => {
    if (!isModal) {
      // const timeout = setTimeout(() => {
      //   onClose?.();
      // }, 7500);

      // return () => clearTimeout(timeout);
      setTimeout(() => {
        onClose?.();
      }, 7500);
    }
  }, []);

  if (type === "checkout") {
    if (isModal) {
      return (
        <Popup
          modal
          open={isOpen}
          onClose={onClose}
          closeOnEscape
          lockScroll
          contentStyle={{
            maxHeight: isMobile ? "100%" : "80%",
            maxWidth: 489.33,
            width: "100%",
            padding: 0,
          }}
          overlayStyle={{
            padding: "16px",
            overflowY: "auto",
          }}
        >
          <div className={clsx("bg-white rounded-lg", "p-4 md:p-6", "flex flex-col items-center justify-center")}>
            <img src={CheckoutRequestIcon} alt="Checkout Request Icon" className="w-[91px] h-[91px] mb-4" />
            <p className={clsx("font-medium text-[22px] leading-7", "mb-2")}>Checkout Request!</p>

            <div className={clsx("flex flex-row items-end justify-between gap-4", "w-full")}>
              <div className={clsx("pt-2 font-nunito-sans", "w-full")}>
                <p className={clsx("mb-2")}>
                  <span className="font-bold">order:</span>
                  {" "}
                  <span className="font-semibold">
                    #
                    {orderId}
                  </span>
                </p>

                {tableName && (
                  <p className={clsx("mb-2")}>
                    <span className="font-bold">Table:</span>
                    {" "}
                    <span className="font-semibold">{tableName}</span>
                  </p>
                )}

                <p>
                  <span className="font-bold">Payment Method:</span>
                  {" "}
                  <span className="font-semibold">{paymentMethodId}</span>
                </p>
              </div>

              <button
                type="button"
                className={clsx("button-primary", "whitespace-nowrap")}
                onClick={() => getAction()}
              >
                {buttonText || "See order"}
              </button>
            </div>
          </div>
        </Popup>
      );
    }

    return (
      <motion.div
        initial={{
          scale: 0,
        }}
        animate={{
          scale: 1,
        }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20,
        }}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        exit={{
          scale: 0,
        }}
        className="notification"
      >
        <div id={`notification-content-${orderId}`} className="pt-2 font-nunito-sans">
          <p className={clsx("font-medium text-[22px] leading-7", "mb-2")}>Checkout Request!</p>
          <p className="mb-2">A checkout request received.</p>
          <p className={clsx("mb-2")}>
            <span className="font-bold">order:</span>
            {" "}
            <span className="font-semibold">
              #
              {orderId}
            </span>
          </p>

          {tableName && (
            <p className={clsx("mb-2")}>
              <span className="font-bold">Table:</span>
              {" "}
              <span className="font-semibold">{tableName}</span>
            </p>
          )}

          <p className={clsx("mb-2")}>
            <span className="font-bold">Payment Method:</span>
            {" "}
            <span className="font-semibold">{paymentMethodId}</span>
          </p>
        </div>
        <div>
          <button
            type="button"
            className={clsx("button-primary")}
            onClick={() => getAction()}
          >
            {buttonText || "See order"}
          </button>
        </div>
      </motion.div>
    );
  }

  if (isModal) {
    return (
      <Popup
        modal
        open={isOpen}
        onClose={onClose}
        closeOnEscape
        lockScroll
        contentStyle={{
          maxHeight: isMobile ? "100%" : "80%",
          maxWidth: 489.33,
          width: "100%",
          padding: 0,
        }}
        overlayStyle={{
          padding: "16px",
          overflowY: "auto",
        }}
      >
        <div className={clsx("bg-white rounded-lg", "p-4 md:p-6", "flex flex-col items-center justify-center")}>
          <img src={OrderReceivedIcon} alt="Order Received Icon" className="w-[91px] h-[91px] mb-4" />
          <p className={clsx("font-medium text-[22px] leading-7", "mb-2")}>New Order Received!</p>
          <p className="mb-6">Waiting for Approval</p>

          <button
            type="button"
            onClick={() => getAction()}
            className={clsx("button-primary", "whitespace-nowrap")}
          >
            {buttonText || "See order"}
          </button>
        </div>
      </Popup>
    );
  }

  return (
    <motion.div
      initial={{
        scale: 0,
      }}
      animate={{
        scale: 1,
      }}
      transition={{
        type: "spring",
        stiffness: 260,
        damping: 20,
      }}
      whileHover={{ scale: 1.025 }}
      whileTap={{ scale: 0.975 }}
      exit={{
        scale: 0,
      }}
      className="notification"
    >
      <div id={`notification-content-${orderId}`} className={clsx("font-nunito-sans", "pt-2")}>
        <p className={clsx("mb-2")}>{`You have a new order #${orderId} for ${orderTypeId}.`}</p>
        <p>Waiting for Approval</p>
      </div>
      <div>
        <button type="button" onClick={() => getAction()} className={clsx("button-primary whitespace-nowrap", "px-6")}>{buttonText || "See order"}</button>
      </div>
    </motion.div>
  );
}

export default memo(OrderNotification);
