import clsx from "clsx";
import { format } from "date-fns";
import {
  Fragment,
  memo,
  useEffect,
  useRef,
  useState,
  type Dispatch,
  type SetStateAction,
} from "react";
import Pagination from "react-paginate";
import { useHistory, useLocation } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { toast } from "react-toastify";

import { ReactComponent as CreditCardIcon } from "../../assets/icons/ic_cc.svg";
import { ReactComponent as ChangeStatusIcon } from "../../assets/icons/ic_change_status.svg";
import { ReactComponent as ChangeTableIcon } from "../../assets/icons/ic_change_table.svg";
import { ReactComponent as ChevronDownIcon } from "../../assets/icons/ic_chevron_down.svg";
import { ReactComponent as DescriptionIcon } from "../../assets/icons/ic_description_2.svg";
import { ReactComponent as OrderDetailsIcon } from "../../assets/icons/ic_order_details.svg";
import { ReactComponent as SummaryIcon } from "../../assets/icons/ic_order_summary.svg";
import { ReactComponent as PrinterIcon } from "../../assets/icons/ic_printer.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/ic_trash_2.svg";
import { ReactComponent as UserIcon } from "../../assets/icons/ic_user_outline.svg";
import OrderStatusIcon from "./order-status-icon";

import { ModalConfirmation } from "../../components";
import { ORDER_STATUS_LIST_TEXT, ORDER_TYPE_LIST_TEXT, PAYMENT_TYPE_LIST_TEXT } from "../../constants";
import {
  useDeleteOrderItemMutation,
  useDeleteOrderMutation,
  useSettingGeneralQuery,
  useUpdateOrderStatusMutation,
  useUpdateOrderTableMutation,
  type GetAllOrderResponse,
} from "../../services";
import {
  Table, TableBody,
  TableData,
  TableHead,
  TableHeader,
  TableRow,
  Tooltip,
} from "../atoms";
import { ModalChangeOrderStatus, ModalChangeTable } from "../modals";
import OrderSummary from "./order-summary";

interface OrderListProps {
  data: GetAllOrderResponse[];
  refetch?: () => void;
  showPageLimiter?: boolean;
  showPagination?: boolean;
  showCheckbox?: boolean;
  expandId?: string;
  setExpandId?: (id: string) => void;
  isLoading?: boolean;
  isChecked?: (id: string) => boolean;
  isCheckedAll?: boolean;
  onCheck?: (id: string) => void;
  onCheckAll?: () => void;
  onPageClick?: (event: { selected: number }) => void;
  className?: string;
  totalItem?: number;
  // offset?: number;
  limit?: number;
  pageCount?: number;
  forcePage?: number;
  // setOffset?: Dispatch<SetStateAction<number>>;
  setLimit?: Dispatch<SetStateAction<number>>;
  setPageCount?: Dispatch<SetStateAction<number>>;
}

function OrderList({
  data,
  refetch,
  className,
  showPageLimiter,
  showCheckbox,
  showPagination,
  expandId,
  setExpandId,
  isLoading,
  isChecked,
  isCheckedAll,
  onCheck,
  onCheckAll,
  onPageClick,
  totalItem = 0,
  limit = 10,
  pageCount = 0,
  forcePage,
  setLimit,
  setPageCount,
}: OrderListProps) {
  const [openModalConfirmation, setOpenModalConfirmation] = useState<boolean>(false);
  const [openModalChangeTable, setOpenModalChangeTable] = useState<boolean>(false);
  const [openModalChangeOrderStatus, setOpenModalChangeOrderStatus] = useState<boolean>(false);
  const [tempTable, setTempTable] = useState<{
    id: string,
    tableId: string,
    tableName: string
  }>({
    id: "",
    tableId: "",
    tableName: "",
  });
  const [tempOrderStatus, setTempOrderStatus] = useState({
    id: "",
    orderId: "",
    orderTypeId: "",
    currentStatusId: "",
    cancellationNote: "",
  });
  const [temp, setTemp] = useState<{
    id: string;
    orderId: string;
    type: string;
  }>({
    id: "",
    orderId: "",
    type: "",
  });
  const [tempOrderSummary, setTempOrderSummary] = useState<GetAllOrderResponse | null>(null);

  const printRef = useRef<HTMLDivElement | null>(null);
  const history = useHistory();
  const location = useLocation();

  const { data: settingGeneralData } = useSettingGeneralQuery();
  const [deleteItem, { isLoading: isLoadingDeleteItem }] = useDeleteOrderItemMutation();
  const [deleteOrder, { isLoading: isLoadingDeleteOrder }] = useDeleteOrderMutation();
  const [updateTable, { isLoading: isLoadingUpdateTable }] = useUpdateOrderTableMutation();
  const [updateOrderStatus, { isLoading: isLoadingUpdateOrderStatus }] = useUpdateOrderStatusMutation();

  const isLoadingDeleteItemAndOrder = isLoadingDeleteItem || isLoadingDeleteOrder;

  const handleOnClickClose = () => {
    setTemp({
      id: "",
      orderId: "",
      type: "",
    });

    setOpenModalConfirmation(false);
  };

  /**
   * Function to handle Delete items or orders
   */
  const handleOnClickConfirm = () => {
    if (!temp.orderId || !temp.type) return;

    if (temp.type === "item") {
      deleteItem({
        itemId: temp.id,
        orderId: temp.orderId,
      })
        .unwrap()
        .then((res) => {
          if (res.status === "Success") {
            toast.success(res.message || "Order is Updated");
            setTemp({
              id: "",
              orderId: "",
              type: "",
            });
          }
        })
        .catch((error) => {
          toast.error(error?.message || "Error");
        })
        .finally(() => {
          setOpenModalConfirmation(false);
          refetch?.();
        });
    } else {
      deleteOrder({
        id: temp.orderId,
      })
        .unwrap()
        .then((res) => {
          if (res.status === "Success") {
            toast.success(res.message || "Order is Updated");
            setTemp({
              id: "",
              orderId: "",
              type: "",
            });
          }
        })
        .catch((error) => {
          toast.error(error?.message || "Error");
        })
        .finally(() => {
          setOpenModalConfirmation(false);
          refetch?.();
        });
    }
  };

  const handleOnClickCloseModalChangeTable = () => {
    setOpenModalChangeTable(false);
  };

  const handleOnClickSaveModalChangeTable = () => {
    if (tempTable.tableId && tempTable.id) {
      updateTable({
        id: tempTable.id,
        table_id: tempTable.tableId,
      })
        .unwrap()
        .then((res) => {
          if (res.status === "Success") {
            toast.success(res.message || "Order is Updated");
            setTempTable({
              id: "",
              tableName: "",
              tableId: "",
            });
            setOpenModalChangeTable(false);
            refetch?.();
          }
        })
        .catch((error) => {
          toast.error(error?.message || "Error");
        });
    }
  };

  /**
   * Handle change order status
   * @param {string} orderStatusId string
   * @param {string} cancellationNote string
   */
  const handleChangeOrderStatus = (orderStatusId: string, cancellationNote: string) => {
    if (tempOrderStatus.id) {
      updateOrderStatus({
        id: tempOrderStatus.id,
        order_status_id: orderStatusId,
        cancel_note: orderStatusId === "stat_cancelled" ? cancellationNote : "",
      })
        .unwrap()
        .then((res) => {
          if (res.status === "Success") {
            toast.success(res.message || "Order is Updated");
            setTempOrderStatus({
              id: "",
              orderId: "",
              orderTypeId: "",
              currentStatusId: "",
              cancellationNote: "",
            });
            setOpenModalChangeOrderStatus(false);
            refetch?.();
          }
        })
        .catch((error) => {
          toast.error(error?.message || "Error");
        });
    }
  };

  useEffect(() => {
    if (showPagination && (!data || data.length === 0)) return;

    const pageCounts = Math.ceil(totalItem / limit);

    setPageCount?.(pageCounts);

    if (expandId && data.length > 0) {
      const tempData = data.find(({ order_id }) => `${order_id}` === expandId);

      if (tempData) {
        setTempOrderSummary((prevState) => (prevState?.id === tempData.id ? null : tempData));
      }
    }
  }, [data]);

  return (
    <>
      <Table className={clsx(className || [
        "px-4 md:px-6", "mb-4", "md:min-w-[768px]",
      ])}
      >
        <TableHead className={clsx(
          "hidden md:flex md:items-center",
          "w-full bg-[#FAFAFA]",
          "font-medium",
          "p-4",
        )}
        >
          {showCheckbox && (
            <TableHeader className={clsx("flex-1 max-w-[32px]")}>
              <label className="flex items-center space-x-3">
                <input
                  type="checkbox"
                  name="check-all"
                  checked={isCheckedAll}
                  onChange={onCheckAll}
                  disabled={isLoading}
                  className={clsx(
                    "form-tick",
                    "appearance-none cursor-pointer rounded",
                    "border border-gray-300 checked:border-transparent",
                    "focus:outline-none",
                    "bg-white checked:bg-check checked:bg-primary",
                    "h-4 w-4",
                  )}
                />
              </label>
            </TableHeader>
          )}

          <TableHeader className={clsx(
            showCheckbox && "pl-4",
            "flex-1",
            "max-w-[75px]",
          // "border-l",
          )}
          >
            ID
          </TableHeader>
          <TableHeader className={clsx(
            "pl-4",
            "flex-1",
          // "border-l",
          )}
          >
            Status
          </TableHeader>
          <TableHeader className={clsx(
            "pl-4",
            "flex-1",
          // "border-l",
          )}
          >
            Type
          </TableHeader>
          <TableHeader className={clsx(
            "pl-4",
            "flex-1",
          // "border-l",
          )}
          >
            Table
          </TableHeader>
          <TableHeader className={clsx(
            "pl-4",
            "flex-1",
          // "border-l",
          )}
          >
            Sent Time
          </TableHeader>
          <TableHeader className={clsx(
            "pl-4",
            "flex-1",
          // "border-l",
          )}
          >
            Total
          </TableHeader>
          <TableHeader className={clsx(
            "pl-4",
            "flex-1",
          // "border-l",
          )}
          >
            <span>Action</span>
          </TableHeader>
        </TableHead>

        {(data && data.length > 0) ? (
          <TableBody className={clsx(
            "w-full",
            "flex flex-col gap-4",
          )}
          >
            {data?.map((item) => {
              const isExpand = expandId === `${item.order_id}`;
              const isTablet = item.order_type_id === "order_dinein_tablet";
              const isDelivery = item.order_type_id === "order_delivery";
              const isDineIn = item.order_type_id.match(/dinein/);

              return (
                <TableRow
                  key={item.id}
                  id={`row-${item.id}`}
                  className={clsx(
                    "relative",
                    "flex flex-col items-center",
                    "w-full",
                    "border rounded-lg md:rounded-none md:border-t-0 md:border-r-0 md:border-l-0",
                    "scroll-mt-4 md:scroll-mt-2",
                    expandId === item.id ? "md:border-transparent" : "md:border-[#FAFAFA]",
                  )}
                >
                  <TableRow
                    className={clsx(
                      "rounded-md md:rounded-none",
                      "relative",
                      "flex items-center",
                      "w-full",
                      "text-sm",
                      "p-4 mt-4 md:mt-0 md:mb-0",
                    )}
                  >
                    {showCheckbox && (
                      <TableData className="pr-4 hidden md:block max-w-[32px]">
                        <label className="flex items-center space-x-3">
                          <input
                            key={isChecked?.(item.id) ? "1" : "0"} // hack to force update
                            onChange={() => onCheck?.(item.id)}
                            checked={isChecked?.(item.id)}
                            type="checkbox"
                            name="checked-banner"
                            disabled={isLoading}
                            className={clsx(
                              "form-tick",
                              "appearance-none cursor-pointer rounded",
                              "border border-gray-300 checked:border-transparent",
                              "focus:outline-none",
                              "bg-white checked:bg-check checked:bg-primary",
                              "h-4 w-4",
                            )}
                          />
                        </label>
                      </TableData>
                    )}

                    <div className={clsx("flex flex-1 flex-col md:flex-row flex-wrap gap-4 md:gap-0 md:flex-nowrap w-full", "font-medium")}>
                      <TableData className={clsx(
                        // "md:flex-1",
                        "md:flex-1 md:flex md:items-center",
                        "md:max-w-[75px]",
                        showCheckbox && "md:pl-4",
                        // "hidden md:flex",
                      )}
                      >
                        <p className="hidden md:block">{item.order_id}</p>

                        <div className={clsx("flex flex-row items-center justify-start gap-2 md:hidden")}>
                          <OrderStatusIcon id={item.order_status_id} className={clsx("h-5 w-5")} />
                          <span>
                            {ORDER_STATUS_LIST_TEXT[
                            item.order_status_id as keyof typeof ORDER_STATUS_LIST_TEXT
                            ]}
                          </span>
                        </div>
                      </TableData>

                      <TableData className={clsx("flex-1 md:flex md:items-center", "md:pl-4", "text-xs md:text-sm")}>
                        <div className={clsx("flex-row items-center justify-start gap-4", "hidden md:flex")}>
                          <OrderStatusIcon id={item.order_status_id} className={clsx("h-5 w-5")} />
                          <span>
                            {ORDER_STATUS_LIST_TEXT[
                            item.order_status_id as keyof typeof ORDER_STATUS_LIST_TEXT
                            ]}
                          </span>
                        </div>

                        <div className={clsx("flex flex-row items-center justify-start gap-x-4", "md:hidden")}>
                          <p className={clsx("flex flex-1 max-w-[30%]", "font-semibold")}>ID</p>
                          <p>{item.order_id}</p>
                        </div>
                      </TableData>

                      <TableData className={clsx("flex-1 md:flex md:items-center", "md:pl-4", "text-xs md:text-sm")}>
                        <div className={clsx("flex flex-row items-center justify-start gap-x-4")}>
                          <p className={clsx("flex flex-1 max-w-[30%] md:hidden", "font-semibold")}>Type</p>
                          <p>{ORDER_TYPE_LIST_TEXT[item.order_type_id as keyof typeof ORDER_TYPE_LIST_TEXT]}</p>
                        </div>
                      </TableData>

                      <TableData className={clsx("flex-1 md:flex md:items-center", "md:pl-4", "text-xs md:text-sm")}>
                        <div className={clsx("flex flex-row items-center justify-start gap-x-4")}>
                          <p className={clsx("flex flex-1 max-w-[30%] md:hidden", "font-semibold")}>Table</p>
                          <p>{item.table_name || "Non"}</p>
                        </div>
                      </TableData>

                      <TableData className={clsx("flex-1 flex items-center", "md:pl-4", "text-xs md:text-sm")}>
                        {/* <TableData className={clsx("flex-1 flex flex-row items-center justify-start gap-x-4", "md:pl-4", "text-xs md:text-sm")}> */}
                        <div className={clsx("flex-1 flex flex-row items-center justify-start gap-x-4")}>
                          <p className={clsx("flex flex-1 max-w-[30%] md:hidden", "font-semibold")}>Sent Time</p>
                          <p>{format(new Date(item.sent_time), "dd.MM.yyyy HH:mm:ss")}</p>
                        </div>
                      </TableData>

                      <TableData className={clsx("flex-1 md:flex md:items-center", "md:pl-4", "text-xs md:text-sm")}>
                        <div className={clsx("flex flex-row items-center justify-start gap-x-4")}>
                          <p className={clsx("flex flex-1 max-w-[30%] md:hidden", "font-semibold")}>Total</p>
                          <p className="flex flex-1 gap-2">
                            {settingGeneralData?.data?.currency || "USD"}
                            {" "}
                            {item.price_total}
                          </p>
                        </div>
                      </TableData>

                      <TableData className={clsx("flex-1", "md:pl-4", "text-xs md:text-sm", "absolute right-4 top-4 md:static", "flex md:flex-wrap flex-row items-center justify-end md:justify-start gap-4 xl:gap-5")}>
                        {isDineIn ? (
                          <Tooltip
                            content="Change Table"
                            placement="top"
                          >
                            <button
                              type="button"
                              title="Change Table"
                              onClick={() => {
                                setTempTable({
                                  id: item.id,
                                  tableId: "",
                                  tableName: item.table_name,
                                });
                                setOpenModalChangeTable(true);
                              }}
                            >
                              <ChangeTableIcon className="w-4 h-4" />
                            </button>
                          </Tooltip>
                        ) : (
                          <div className="w-4 h-4 lg:hidden xl:block" />
                        )}

                        <Tooltip
                          content="Change Status"
                          placement="top"
                        >
                          <button
                            type="button"
                            title="Change Status"
                            onClick={() => {
                              setTempOrderStatus({
                                id: item.id,
                                orderId: item.order_id.toString(),
                                orderTypeId: item.order_type_id,
                                currentStatusId: item.order_status_id,
                                cancellationNote: item.cancel_notes,
                              });
                              setOpenModalChangeOrderStatus(true);
                            }}
                          >
                            <ChangeStatusIcon className="w-4 h-4" />
                          </button>
                        </Tooltip>

                        <Tooltip
                          content="Delete"
                          placement="top"
                        >
                          <button
                            type="button"
                            title="Delete"
                            onClick={() => {
                              setTemp({
                                id: "",
                                orderId: item.id,
                                type: "order",
                              });
                              setOpenModalConfirmation(true);
                            }}
                          >
                            <TrashIcon className="w-4 h-4" />
                          </button>
                        </Tooltip>

                        <Tooltip
                          content="Order Details"
                          placement="top"
                        >
                          <button
                            type="button"
                            title="Order Details"
                            onClick={() => {
                              setExpandId?.(isExpand ? "" : `${item.order_id}`);
                              setTempOrderSummary((prevState) => (prevState?.id === item.id ? null : item));

                              if (location.pathname === "/orders") {
                                history.push("/orders");
                              }

                              setTimeout(() => {
                                if (!isExpand) {
                                  document?.getElementById(`row-${item.id}`)?.scrollIntoView({
                                    behavior: "smooth",
                                  });
                                }
                              }, 300);
                            }}
                          >
                            <OrderDetailsIcon className={clsx("w-4 h-4", isExpand ? "transform rotate-180" : "")} />
                          </button>
                        </Tooltip>
                      </TableData>
                    </div>
                  </TableRow>

                  <div
                    className={clsx(
                      "w-full overflow-hidden",
                      "bg-[#FAFAFA]",
                      "transition-all duration-300 ease-in-out",
                    )}
                    style={{
                      height: isExpand ? (document?.getElementById(`order-detail-${item.order_id}`)?.scrollHeight || "auto") : 0,
                    }}
                  >
                    <div className={clsx("w-full", "p-4 md:p-6")} id={`order-detail-${item.order_id}`}>
                      <div className={clsx("mb-7", "flex flex-col gap-4 md:items-center md:flex-row md:justify-between")}>
                        <h2 className={clsx("text-xl font-medium")}>
                          {ORDER_TYPE_LIST_TEXT[item.order_type_id as keyof typeof ORDER_TYPE_LIST_TEXT]}
                          {" "}
                          #
                          {item.order_id}
                        </h2>

                        <div className="flex flex-row items-center justify-end">
                          <div className={clsx("flex w-full md:w-auto flex-col md:flex-row items-center justify-end", "border rounded-md")}>
                            <ReactToPrint
                              // eslint-disable-next-line react/no-unstable-nested-components
                              trigger={() => (
                                <button
                                  type="button"
                                  title="Print"
                                  className={clsx(
                                    "md:rounded-l-md",
                                    "bg-[#FBFCFD] hover:bg-gray-100 focus:bg-gray-100",
                                    "whitespace-nowrap text-sm",
                                    "flex flex-row items-center justify-center gap-2",
                                    "py-2 px-4",
                                    "w-full md:w-auto",
                                  )}
                                >
                                  <PrinterIcon className="w-4 h-4" />
                                  Print
                                </button>
                              )}
                              content={() => printRef.current}
                            />

                            {isDineIn && (
                              <button
                                type="button"
                                title="Change Table"
                                className={clsx(
                                  "border-t md:border-t-0 md:border-l",
                                  "bg-[#FBFCFD] hover:bg-gray-100 focus:bg-gray-100",
                                  "whitespace-nowrap text-sm",
                                  "flex flex-row items-center justify-center gap-2",
                                  "py-2 px-4",
                                  "w-full md:w-auto",
                                )}
                                onClick={() => {
                                  setTempTable({
                                    id: item.id,
                                    tableId: "",
                                    tableName: item.table_name,
                                  });
                                  setOpenModalChangeTable(true);
                                }}
                              >
                                <ChangeTableIcon className="w-4 h-4" />
                                Change Table
                              </button>
                            )}

                            <button
                              type="button"
                              title="Change Status"
                              className={clsx(
                                "border-t md:border-t-0 md:border-l",
                                "bg-[#FBFCFD] hover:bg-gray-100 focus:bg-gray-100",
                                "whitespace-nowrap text-sm",
                                "flex flex-row items-center justify-center gap-2",
                                "py-2 px-4",
                                "w-full md:w-auto",
                              )}
                              onClick={() => {
                                setTempOrderStatus({
                                  id: item.id,
                                  orderId: item.order_id.toString(),
                                  orderTypeId: item.order_type_id,
                                  currentStatusId: item.order_status_id,
                                  cancellationNote: item.cancel_notes,
                                });
                                setOpenModalChangeOrderStatus(true);
                              }}
                            >
                              <ChangeStatusIcon className="w-4 h-4" />
                              Change Status
                            </button>

                            <button
                              type="button"
                              title="Delete"
                              className={clsx(
                                "border-t md:border-t-0 md:border-l rounded-r-md",
                                "bg-[#FBFCFD] hover:bg-gray-100 focus:bg-gray-100",
                                "whitespace-nowrap text-sm",
                                "flex flex-row items-center justify-center gap-2",
                                "py-2 px-4",
                                "w-full md:w-auto",
                              )}
                              onClick={() => {
                                setTemp({
                                  id: "",
                                  orderId: item.id,
                                  type: "order",
                                });
                                setOpenModalConfirmation(true);
                              }}
                            >
                              <TrashIcon className="w-4 h-4" />
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* Order Details */}
                      <div className="mb-6">
                        <h3 className={clsx("font-bold", "pb-3 mb-3", "border-b border-dotted border-b-[#CFCFCF]")}>
                          <DescriptionIcon className={clsx("w-4 h-4 md:h-5 md:w-5 inline align-middle", "mr-3")} />
                          <span className="text-sm md:text-base">Order Details</span>
                        </h3>

                        <div className={clsx("flex flex-col gap-y-2 md:flex-row md:flex-wrap md:gap-y-3", "md:max-w-2xl", "text-[13px] md:text-[15px] leading-normal font-nunito-sans")}>
                          <div className={clsx("md:w-1/2", "md:pr-4")}>
                            <span className="font-bold">Sent Time:</span>
                            {" "}
                            <span>{format(new Date(item.sent_time), "dd.MM.yyyy HH:mm:ss")}</span>
                          </div>

                          <div className={clsx("md:w-1/2", "md:pr-4")}>
                            <span className="font-bold">Update Time:</span>
                            {" "}
                            <span>{format(new Date(item.update_time), "dd.MM.yyyy HH:mm:ss")}</span>
                          </div>

                          {isTablet ? (
                            <div className={clsx("md:w-1/2", "md:pr-4")}>
                              <span className="font-bold">Guests:</span>
                              {" "}
                              <span>{item.guest_count}</span>
                            </div>
                          ) : (
                            <div className={clsx("md:w-1/2", "md:pr-4")}>
                              <span className="font-bold">Source:</span>
                              {" "}
                              <span>{isTablet ? "Tablet" : "Mobile"}</span>
                            </div>
                          )}

                          {isDineIn ? (
                            <div className={clsx("md:w-1/2", "md:pr-4")}>
                              <span className="font-bold">Table:</span>
                              {" "}
                              <span>{item.table_name}</span>
                            </div>
                          ) : (
                            <div className={clsx("md:w-1/2", "md:pr-4")}>
                              <span className="font-bold">Notes:</span>
                              {" "}
                              <span>{item.notes}</span>
                            </div>
                          )}

                          {isTablet ? (
                            <div className={clsx("md:w-1/2", "md:pr-4")}>
                              <span className="font-bold">Source:</span>
                              {" "}
                              <span>Tablet</span>
                            </div>
                          ) : (
                            <div className={clsx("md:w-1/2", "md:pr-4")}>
                              <span className="font-bold">Payment Method:</span>
                              {" "}
                              <span>
                                {
                                  PAYMENT_TYPE_LIST_TEXT[item.payment_type_id as keyof typeof PAYMENT_TYPE_LIST_TEXT]
                                }
                              </span>
                            </div>
                          )}

                          {isDineIn && (
                            <>
                              <div className={clsx("md:w-1/2", "md:pr-4")}>
                                <span className="font-bold">Notes:</span>
                                {" "}
                                <span>{item.notes}</span>
                              </div>

                              {isTablet && (
                                <div className={clsx("md:w-1/2", "md:pr-4")}>
                                  <span className="font-bold">Payment Method:</span>
                                  {" "}
                                  <span>
                                    {
                                      PAYMENT_TYPE_LIST_TEXT[
                                        item.payment_type_id as keyof typeof PAYMENT_TYPE_LIST_TEXT
                                      ]
                                    }
                                  </span>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>

                      {/* Payment Details */}
                      <div className="mb-6">
                        <h3 className={clsx("font-bold", "pb-3 mb-3", "border-b border-dotted border-b-[#CFCFCF]")}>
                          <CreditCardIcon className={clsx("w-4 h-4 md:h-5 md:w-5 inline align-middle", "mr-3")} />
                          <span className="text-sm md:text-base">Payment Details</span>
                        </h3>

                        <div className={clsx("flex flex-col gap-y-2 md:flex-row md:flex-wrap md:gap-y-3", "md:max-w-2xl", "text-[13px] md:text-[15px] leading-normal font-nunito-sans")}>
                          <div className={clsx("md:w-1/2", "md:pr-4")}>
                            <span className="font-bold">Subtotal:</span>
                            {" "}
                            <span>
                              {settingGeneralData?.data?.currency || "USD"}
                              {" "}
                              {item.price_subtotal}
                            </span>
                          </div>

                          <div className={clsx("md:w-1/2", "md:pr-4")}>
                            <span className="font-bold">Total:</span>
                            {" "}
                            <span>
                              {settingGeneralData?.data?.currency || "USD"}
                              {" "}
                              {item.price_total}
                            </span>
                          </div>

                          {isDelivery ? (
                            <div className={clsx("md:w-1/2", "md:pr-4")}>
                              <span className="font-bold">Delivery Fee:</span>
                              {" "}
                              <span>
                                {settingGeneralData?.data?.currency || "USD"}
                                {" "}
                                {item.price_delivery}
                              </span>
                            </div>
                          ) : (
                            <div className={clsx("md:w-1/2", "md:pr-4")}>
                              <span className="font-bold">Promo Code:</span>
                              {" "}
                              <span>
                                {item.promo_code}
                              </span>
                            </div>
                          )}

                          <div className={clsx("md:w-1/2", "md:pr-4")}>
                            <span className="font-bold">Discount:</span>
                            {" "}
                            <span>
                              {settingGeneralData?.data?.currency || "USD"}
                              {" "}
                              {item.price_discount}
                            </span>
                          </div>

                          {isDelivery && (
                            <div className={clsx("md:w-1/2", "md:pr-4")}>
                              <span className="font-bold">Promo Code:</span>
                              {" "}
                              <span>
                                {item.promo_code}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>

                      {/* Customer Details */}
                      {!isDineIn && (
                        <div className="mb-6">
                          <h3 className={clsx("font-bold", "pb-3 mb-3", "border-b border-dotted border-b-[#CFCFCF]")}>
                            <UserIcon className={clsx("w-4 h-4 md:h-5 md:w-5 inline align-middle", "mr-3")} />
                            <span className="text-sm md:text-base">Customer Details</span>
                          </h3>

                          <div className={clsx("flex flex-col gap-y-2 md:flex-row md:flex-wrap md:gap-y-3", "md:max-w-2xl", "text-[13px] md:text-[15px] leading-normal font-nunito-sans")}>
                            <div className={clsx("md:w-1/2", "md:pr-4")}>
                              <span className="font-bold">Name:</span>
                              {" "}
                              <span>
                                {item.cust_name}
                              </span>
                            </div>

                            <div className={clsx("md:w-1/2", "md:pr-4")}>
                              <span className="font-bold">Phone:</span>
                              {" "}
                              <span>
                                {item.cust_phone}
                              </span>
                            </div>

                            <div className={clsx("md:w-1/2", "md:pr-4")}>
                              <span className="font-bold">Email:</span>
                              {" "}
                              <span>
                                {item.cust_email}
                              </span>
                            </div>

                            {isDelivery ? (
                              <div className={clsx("md:w-1/2", "md:pr-4")}>
                                <span className="font-bold">Address:</span>
                                {" "}
                                <span>
                                  {item.cust_address}
                                </span>
                              </div>
                            ) : (
                              <div className={clsx("md:w-1/2", "md:pr-4")}>
                                <span className="font-bold">Plate number:</span>
                                {" "}
                                <span>
                                  {item.cust_plate_num}
                                </span>
                              </div>
                            )}

                            {isDelivery && (
                              <>
                                <div className={clsx("md:w-1/2", "md:pr-4")}>
                                  <span className="font-bold">Building:</span>
                                  {" "}
                                  <span>
                                    {item.cust_building}
                                  </span>
                                </div>

                                <div className={clsx("md:w-1/2", "md:pr-4")}>
                                  <span className="font-bold">Zone:</span>
                                  {" "}
                                  <span>
                                    {item.cust_zone}
                                  </span>
                                </div>

                                <div className={clsx("md:w-1/2", "md:pr-4")}>
                                  <span className="font-bold">Street:</span>
                                  {" "}
                                  <span>
                                    {item.cust_street}
                                  </span>
                                </div>

                                <div className={clsx("md:w-1/2", "md:pr-4")}>
                                  <span className="font-bold">Apartment:</span>
                                  {" "}
                                  <span>
                                    {item.cust_apart}
                                  </span>
                                </div>
                              </>
                            )}

                          </div>
                        </div>
                      )}

                      {/* Order Summary */}
                      <div className="mb-6">
                        <h3 className={clsx("font-bold", "pb-3 mb-3", "border-b border-dotted border-b-[#CFCFCF]")}>
                          <SummaryIcon className={clsx("w-4 h-4 md:h-5 md:w-5 inline align-middle", "mr-3")} />
                          <span className="text-sm md:text-base">Order Summary</span>
                        </h3>

                        <div className={clsx("overflow-x-auto w-full", "text-xs md:text-[13px] md:leading-normal")}>
                          <table className="table-auto md:w-full gap-3 whitespace-nowrap md:whitespace-normal">
                            <thead className="text-left border-b">
                              <tr>
                                <th className="pb-2">Item</th>
                                <th className="pb-2 pl-4 md:pl-6">QTY</th>
                                <th className="pb-2 pl-4 md:pl-6">Unit Price</th>
                                <th className="pb-2 pl-4 md:pl-6">Total</th>
                                <th className="pb-2 pl-4 md:pl-6">Sent Time</th>
                                <th className="pb-2 pl-4 md:pl-6">Notes</th>
                                <th className="pb-2 pl-4 md:pl-6">Action</th>
                              </tr>
                            </thead>

                            <tbody>
                              {item.orders.map((subItem) => (
                                <Fragment key={subItem.id}>
                                  <tr className={clsx("align-top", subItem.modifiers.length === 0 ? "border-b" : "")}>
                                    <td className="py-2 font-medium">
                                      <span>{subItem.name}</span>
                                    </td>
                                    <td className="py-2 pl-4 md:pl-6">{subItem.qty}</td>
                                    <td className="py-2 pl-4 md:pl-6">
                                      {settingGeneralData?.data?.currency || "USD"}
                                      {" "}
                                      {subItem.price_unit}
                                    </td>
                                    <td className="py-2 pl-4 md:pl-6">
                                      {settingGeneralData?.data?.currency || "USD"}
                                      {" "}
                                      {subItem.price_total}
                                    </td>
                                    <td className="py-2 pl-4 md:pl-6">{format(new Date(subItem.sent_time), "dd.MM.yyyy HH:mm:ss")}</td>
                                    <td className="py-2 pl-4 md:pl-6">{subItem.notes}</td>
                                    <td className="py-2 pl-4 md:pl-6">
                                      <button
                                        type="button"
                                        title="Delete item"
                                        onClick={() => {
                                          setTemp({
                                            id: subItem.id,
                                            orderId: item.id,
                                            type: "item",
                                          });
                                          setOpenModalConfirmation(true);
                                        }}
                                      >
                                        <TrashIcon className="w-4 h-4" />
                                      </button>
                                    </td>
                                  </tr>

                                  {subItem.modifiers.map((mod, index) => {
                                    const isLastModifier = subItem.modifiers.length - 1 === index;

                                    return (
                                      <tr key={`${mod.name}-${index}`} className={clsx("align-top text-[#858585]", isLastModifier ? "border-b mb-1" : "")}>
                                        <td className="pb-2 font-medium">
                                          <span>
                                            +
                                            {" "}
                                            {mod.name}
                                          </span>
                                        </td>
                                        <td className="pb-2 pl-4 md:pl-6" />
                                        <td className="pb-2 pl-4 md:pl-6">
                                          {settingGeneralData?.data?.currency || "USD"}
                                          {" "}
                                          {mod.price_unit}
                                          {" "}
                                          +
                                        </td>
                                        <td className="pb-2 pl-4 md:pl-6">
                                          {settingGeneralData?.data?.currency || "USD"}
                                          {" "}
                                          {mod.price_total}
                                          {" "}
                                          +
                                        </td>
                                        <td className="pb-2 pl-4 md:pl-6" />
                                        <td className="pb-2 pl-4 md:pl-6" />
                                        <td className="pb-2 pl-4 md:pl-6" />
                                      </tr>
                                    );
                                  })}
                                </Fragment>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </TableRow>
              );
            })}
          </TableBody>
        ) : (
          <div className="w-full p-4">
            No Order is Found
          </div>
        )}
      </Table>

      {(data && data.length > 0) && showPagination && (
        <div className="flex gap-4 p-4 md:px-6 w-full justify-between">
          {totalItem >= limit ? (
            <Pagination
              // breakLabel="..."
              nextLabel="›"
              previousLabel="‹"
              disabledClassName="cursor-not-allowed"
              marginPagesDisplayed={1}
              onPageChange={onPageClick}
              forcePage={forcePage}
              pageRangeDisplayed={4}
              pageCount={pageCount}
              containerClassName="flex gap-2 items-center text-sm mx-auto md:mx-0"
              activeClassName="text-active border-primary outline-primary"
              pageClassName="rounded border border-gray-300 w-6 h-6 md:w-8 md:h-8 flex items-center justify-center"
              previousClassName="rounded border border-gray-300 w-6 h-6 md:w-8 md:h-8 flex items-center justify-center"
              nextClassName="rounded border border-gray-300 w-6 h-6 md:w-8 md:h-8 flex items-center justify-center"
              pageLinkClassName="block w-full text-center focus:outline-none"
              nextLinkClassName="block w-full text-center focus:outline-none"
              previousLinkClassName="block w-full text-center focus:outline-none"
            />
          ) : (
            <div />
          )}

          {showPageLimiter && (
            <div className="hidden md:flex gap-4 items-center">
              <p>Show:</p>

              <div className="relative flex items-center h-8">
                <select
                  className="absolute border border-line-gray rounded-md inset-0 py-1 pr-24 pl-2 h-8 appearance-none text-sm"
                  value={limit}
                  onChange={(e) => {
                    if (!showPagination || !showPageLimiter) return;

                    if (Number(e.target.value) !== limit) {
                      setLimit?.(Number(e.target.value) ?? 5);
                    }
                  }}
                >
                  {[5, 10, 20].map((number) => (
                    <option value={number} key={number}>
                      {number}
                    </option>
                  ))}
                </select>

                <p className="text-sm relative z-10 ml-8 pointer-events-none">per page</p>
                <div className="transform w-4 h-4 relative z-10 mx-2 pointer-events-none">
                  <ChevronDownIcon />
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <ModalConfirmation
        isOpen={openModalConfirmation}
        onConfirmModal={handleOnClickConfirm}
        onCloseModal={handleOnClickClose}
        confirmationText="Delete"
        content={temp.type === "item" ? "Are you sure you want to delete this item?" : "Are you sure you want to delete this order?"}
        confirmationLoading={isLoadingDeleteItemAndOrder}
      />

      <ModalChangeTable
        isOpen={openModalChangeTable}
        currentTable={tempTable.tableName}
        value={tempTable.tableId || "choose"}
        isLoading={isLoadingUpdateTable}
        onChange={(event) => {
          setTempTable((prevState) => ({
            ...prevState,
            tableId: event.target.value,
          }));
        }}
        onClickSave={handleOnClickSaveModalChangeTable}
        onClose={handleOnClickCloseModalChangeTable}
      />

      <ModalChangeOrderStatus
        isOpen={!!tempOrderStatus.currentStatusId && openModalChangeOrderStatus}
        currentStatus={tempOrderStatus.currentStatusId}
        cancellationNote={tempOrderStatus.cancellationNote}
        isLoading={isLoadingUpdateOrderStatus}
        orderId={tempOrderStatus.orderId}
        orderType={tempOrderStatus.orderTypeId}
        onClose={() => {
          setTempOrderStatus({
            id: "",
            orderId: "",
            orderTypeId: "",
            currentStatusId: "",
            cancellationNote: "",
          });
          setOpenModalChangeOrderStatus(false);
        }}
        onSubmit={({ orderStatusId, cancelNote }) => handleChangeOrderStatus(orderStatusId, cancelNote)}
      />

      <OrderSummary
        data={tempOrderSummary}
        ref={printRef}
        currency={settingGeneralData?.data?.currency || "USD"}
      />
    </>
  );
}

export default memo(OrderList);
