/* eslint-disable camelcase */
import { createApi } from "@reduxjs/toolkit/query/react";

import baseQuery from "./base-service";
import {
  RegisterStepResponse,
  RegisterStep5Response,
  RegisterStep1,
  RegisterStep2,
  RegisterStep3,
  RegisterStep4,
  RegisterStep5,
} from "../features/auth";

const sub_url = "/user/register/trial";
const method = "POST";

export const registerServices = createApi({
  reducerPath: "register",
  baseQuery,
  endpoints: (builder) => ({
    step1: builder.mutation<HttpResponse<RegisterStepResponse>, RegisterStep1>({
      query: (body) => ({
        url: `${sub_url}/step1`,
        method,
        body,
      }),
    }),
    step2: builder.mutation<HttpResponse<RegisterStepResponse>, RegisterStep2>({
      query: (body) => ({
        url: `${sub_url}/step2`,
        method,
        body,
      }),
    }),
    step3: builder.mutation<HttpResponse<RegisterStepResponse>, RegisterStep3>({
      query: (body) => ({
        url: `${sub_url}/step3`,
        method,
        body,
      }),
    }),
    step4: builder.mutation<HttpResponse<RegisterStepResponse>, RegisterStep4>({
      query: (body) => ({
        url: `${sub_url}/step4`,
        method,
        body,
      }),
    }),
    step5: builder.mutation<HttpResponse<RegisterStep5Response>, RegisterStep5>({
      query: (body) => ({
        url: `${sub_url}/step5`,
        method,
        body,
      }),
    }),
    verifyEmail: builder.mutation<HttpResponse<{}>, {email: string}>({
      query: (body) => ({
        url: "/checkValidEmail",
        method,
        body,
      }),
    }),
    resendVerification: builder.mutation<HttpResponse<{}>, {email: string}>({
      query: (body) => ({
        url: "/sendEmailVerif",
        method,
        body,
      }),
    }),
  }),
});

export const {
  useStep1Mutation,
  useStep2Mutation,
  useStep3Mutation,
  useStep4Mutation,
  useStep5Mutation,
  useResendVerificationMutation,
  useVerifyEmailMutation,
} = registerServices;
