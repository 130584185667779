import { forwardRef, ReactNode } from "react";

import handlerImage from "../assets/drag-handler.png";

interface Props {
  id: string;
  useHandler?: boolean;
  positionHandler?: "top" | "bottom";
  children: ReactNode
}

const SortableItemOverlay = forwardRef<HTMLDivElement, Props>(({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  id, useHandler = false, positionHandler = "top", children, ...props
}, ref) => (useHandler ? (
  <div {...props} ref={ref}>
    {positionHandler === "top" && (
      <button
        className="bg-white rounded px-2 z-20 absolute top-2 left-1/2 transform -translate-x-1/2 h-6 w-8"
        type="button"
      >
        <img src={handlerImage} alt="sortable handler" />
      </button>
    )}

    {children}

    {positionHandler === "bottom" && (
      <button
        className="bg-white rounded px-2 z-20 absolute bottom-2 right-2 h-6 w-8"
        type="button"
      >
        <img src={handlerImage} alt="sortable handler" />
      </button>
    )}
  </div>
) : (
  <div {...props} ref={ref}>{children}</div>
)));

export default SortableItemOverlay;
