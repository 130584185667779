/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable default-param-last */
import {
  useState, useEffect, useRef, Fragment,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingBar from "react-top-loading-bar";

import { ReactComponent as ChevronIcon } from "../assets/icons/ic_chevron_down.svg";
import { ReactComponent as GlobeIcon } from "../assets/icons/ic_globe.svg";

import {
  useGetAllLocalizeQuery,
  useUpdateLocalizeMenuMutation,
  useUpdateLocalizeCategoryMutation,
  useUpdateLocalizeItemMutation,
  useToggleActiveMenuLanguageMutation,
  useToggleActiveOtherLanguageMutation,
  ItemPriceLocalize,
  LocalizeCategoryGetResponse,
  LocalizeMenuWithPriceGetResponse,
} from "../services";
import { getBreakpointValue } from "../utils";

type SelectedMenuType = "menu" | "category" | "item";

interface FormType {
  lang_id: string;
  name: string;
  description: string;
  isShow: boolean;
  prices?: ItemPriceLocalize[];
}

interface ItemType {
  id: string;
  object?: string;
  languages: LocalizeMenuWithPriceGetResponse[];
  items_on_sections?: {
    id: string;
    object?: string;
    languages: LocalizeMenuWithPriceGetResponse[];
  }[];
}

function LocalizePage() {
  const [form, setForm] = useState<FormType[]>();
  const [formInit, setFormInit] = useState<FormType[]>();
  const { state } = useLocation<StateCategoryID>();
  const ref = useRef(null);
  const { goBack } = useHistory();
  const isMobile = getBreakpointValue("md") > window.innerWidth;

  const { data: dataMenu, refetch, isLoading: isLoadingAllLocalize } = useGetAllLocalizeQuery();
  const [updateMenu, { isLoading: isLoadingUpdateMenu }] = useUpdateLocalizeMenuMutation();
  const [updateCategory, { isLoading: isLoadingUpdateCategory }] = useUpdateLocalizeCategoryMutation();
  const [updateItem, { isLoading: isLoadingUpdateItem }] = useUpdateLocalizeItemMutation();

  const [toggleActiveMenuLanguage, { isLoading: isLoadingActivatingMenu }] = useToggleActiveMenuLanguageMutation();
  const [toggleActiveOtherLanguage,
    { isLoading: isLoadingActivatingOtherLanguage },
  ] = useToggleActiveOtherLanguageMutation();

  const isLoading = isLoadingAllLocalize
  || isLoadingUpdateMenu
  || isLoadingUpdateCategory
  || isLoadingUpdateItem
  || isLoadingActivatingMenu
  || isLoadingActivatingOtherLanguage;

  const [expandMenu, setExpandMenu] = useState<string>("");
  const [expandID, setExpandID] = useState<string>("");
  const [expandSectionID, setExpandSectionID] = useState<string>("");
  const [expandCategoryHeight, setExpandCategoryHeight] = useState<number>(0);
  const [isFormShow, setFormShow] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<{
    type: SelectedMenuType;
    id: string;
    data: FormType[];
  }>({
    type: "menu",
    id: "",
    data: [],
  });

  /**
   * @description Function to handle toggle active language
   * @param id<string>
   * @param language_id<string>
   * @param type<SelectMenuType>
   */
  const publishIsShow = (id: string, language_id: string, type: SelectedMenuType) => {
    if (type === "menu") {
      toggleActiveMenuLanguage({ language_id })
        .unwrap()
        .then((res) => {
          if (res.status === "Success") {
            toast.success("Successfully toggle language");
            refetch();
          }
        })
        .catch(() => {
          toast.error("Failed to toggle language");
        });
    } else {
      toggleActiveOtherLanguage({ id, language_id, type })
        .unwrap()
        .then((res) => {
          if (res.status === "Success") {
            toast.success("Successfully toggle language");
            refetch();
          }
        })
        .catch(() => {
          toast.error("Failed to toggle language");
        });
    }
  };

  /**
   * @description Function to handle change form
   * @param e<any>
   * @param lang<string>
   * @param name<keyof FormType>
   * @param id<string>
   */
  const handleChange = (e: any, lang: string, name: keyof FormType, id?: string) => {
    const newForm = form?.map((dataForm) => {
      if (dataForm.lang_id === lang) {
        if (id !== undefined && name === "prices" && dataForm.prices) {
          const selectedPrice = dataForm.prices.find((price) => price.id === id);

          if (selectedPrice) {
            const newPrice = {
              ...selectedPrice,
              definition: e.target.value,
            };

            return {
              ...dataForm,
              prices: dataForm.prices.map((price) => {
                if (price.id === id) return newPrice;
                return price;
              }),
            };
          }
        }

        if (name === "isShow") {
          publishIsShow(selectedItem.id, lang, selectedItem.type);
          return {
            ...dataForm,
            [name]: e.target.checked,
          };
        }

        return {
          ...dataForm,
          [name]: e.target.value,
        };
      }

      return dataForm;
    });

    setForm(newForm);
  };

  /**
   * @description Function to handle show toast success
   * @param id<string>
   */
  const toastSuccess = (id: string) => {
    const toastId = `localize-success-${id}`;
    if (toast.isActive(toastId)) {
      toast.update(toastId);
    } else {
      toast.success(`Successfully update ${id}`, { toastId });
    }
  };

  /**
   * @description Function to handle show toast error
   * @param err<any>
   * @param type<string>
   */
  const toastError = (err: any, type: string) => {
    toast.error(err?.data?.message ?? `Failed to update ${type}`, {
      toastId: `localize-error-${type}`,
    });
  };

  /**
   * @description Function to handle save localization data
   */
  const handleSave = () => {
    if (!form) return;
    if (JSON.stringify(form) === JSON.stringify(formInit)) return;

    const { type, id } = selectedItem;

    if (type === "menu") {
      updateMenu({ data: form })
        .unwrap()
        .then((res) => {
          if (res.status === "Success") {
            setFormInit(form);
            refetch();
            toastSuccess("menu");
          }
        })
        .catch((err) => {
          toastError(err, "menu");
        });
    }

    if (type === "category") {
      updateCategory({ id, data: form })
        .unwrap()
        .then((res) => {
          if (res.status === "Success") {
            setFormInit(form);
            refetch();
            toastSuccess("category");
          }
        })
        .catch((err) => {
          toastError(err, "category");
        });
    }

    if (type === "item") {
      updateItem({ id, data: form })
        .unwrap()
        .then((res) => {
          if (res.status === "Success") {
            setFormInit(form);
            refetch();
            toastSuccess("item");
          }
        })
        .catch((err) => {
          toastError(err, "item");
        });
    }
  };

  const isSectionExpand = (id: string) => expandSectionID === id;
  const getHeight = (item: ItemType[]) => {
    const height: number[] = [];

    item.forEach((subItem: ItemType) => {
      const elementHeight = document.getElementById(subItem.id)?.clientHeight ?? 0;
      height.push(elementHeight);
    });

    return height.reduce((a: number, b: number) => a + b, 0);
  };

  /**
   * Handle toggle expand category
   * @param type - item or chevron
   * @param category - category data
   */
  const handleExpandCategory = (type = "title", category: LocalizeCategoryGetResponse) => {
    if (type === "title") {
      setFormShow(true);
      setExpandID(category.id === expandID ? "" : category.id);
      setExpandSectionID(expandSectionID && "");
      setExpandCategoryHeight(category.id === expandID ? 0 : getHeight(category.items));
      setSelectedItem({
        type: "category",
        id: category.id,
        data: category.languages,
      });
      return;
    }

    setExpandID(category.id === expandID ? "" : category.id);
    setExpandSectionID(expandSectionID && "");
    // setExpandCategoryHeight(category.id === expandID ? 0 : category.items.length * 1.5);
    setExpandCategoryHeight(category.id === expandID ? 0 : getHeight(category.items));
  };

  /**
   * Handle toggle expand section item
   * @param type - item or chevron
   * @param categoryHeight - active category height
   * @param section - section data
   * @returns
   */
  const handleExpandSection = (type = "title", categoryHeight = 0, section: any) => {
    if (type === "title") {
      setFormShow(true);
      setExpandSectionID(isSectionExpand(section.id) ? "" : section.id);
      setExpandCategoryHeight(
        !isSectionExpand(section.id)
          ? categoryHeight + getHeight(section.items_on_section && section.items_on_section)
          : categoryHeight,
      );
      setSelectedItem({
        type: "item",
        id: section.id,
        data: section.languages,
      });
      return;
    }

    setExpandSectionID(isSectionExpand(section.id) ? "" : section.id);
    setExpandCategoryHeight(
      !isSectionExpand(section.id)
        ? categoryHeight + getHeight(section.items_on_section && section.items_on_section)
        : categoryHeight,
    );
  };

  useEffect(() => {
    if (dataMenu?.data?.menu) {
      if (selectedItem.id && expandID) return;
      const { name } = dataMenu.data.menu[0];

      setExpandMenu(name);
      if (state?.category_id) {
        const category = dataMenu?.data?.categories.find(({ id }) => id === state.category_id);
        if (category) {
          setExpandID(category.id);
          setSelectedItem({
            type: "category",
            id: category.id,
            data: category.languages,
          });
        }
      } else {
        const data: FormType[] = dataMenu.data.menu;
        setSelectedItem({ type: "menu", id: name, data });
      }
    }
  }, [dataMenu, state?.category_id]);

  useEffect(() => {
    setForm(selectedItem.data);
    setFormInit(selectedItem.data);
  }, [selectedItem]);

  useEffect(() => {
    const progress = ref.current as any;
    if (isLoading) {
      progress?.continuousStart();
    } else {
      progress?.complete();
    }
  }, [isLoading]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!dataMenu?.data) return <></>;

  const {
    menu, categories, main_language, language_list,
  } = dataMenu?.data ?? {};
  const mainMenu = menu.find((m) => m.lang_id === main_language);

  if (language_list.length === 1) {
    return (
      <div className="flex flex-1 flex-col items-center justify-center p-6 text-center">
        <GlobeIcon className="w-16 h-16 mb-6" />
        <p className="font-bold text-lg mb-2">You only select English for your default language</p>
        <p className="text-sm mb-8">Activate other languages that will be displayed on your menu in general settings</p>
        <button type="button" title="Go to General Settings" className="button-primary">Go to General Settings</button>
      </div>
    );
  }

  return (
    <div className="flex flex-col flex-1">
      <LoadingBar height={4} color="#0078D3" ref={ref} />

      <div className="flex justify-between p-6 mb-4 border-b border-line-gray">
        <div className="flex items-center">
          <button
            type="button"
            title="Back"
            className="bg-transparent"
            disabled={isLoading}
            onClick={() => {
              if (isMobile && isFormShow) {
                setFormShow(false);
              } else {
                goBack();
              }
            }}
          >
            <ChevronIcon className="h-6 w-6 transform rotate-90 mr-2" />
          </button>
          <h2 className="font-bold text-base md:text-2xl">Localization Categories and items</h2>
        </div>

        {/* <div className="hidden md:flex">
          <button className="button-gray py-2 px-4 mr-4" onClick={goBack}>
            Cancel
          </button>
          <button
            className="button-primary py-2 px-4"
            onClick={handleSave}
            disabled={
              isLoadingUpdateItem ||
              isLoadingUpdateCategory ||
              isLoadingUpdateMenu
            }
          >
            {isLoadingUpdateItem ||
            isLoadingUpdateCategory ||
            isLoadingUpdateMenu ? (
              <div className="flex">
                <SpinnerIcon
                  className="animate-spin h-5 w-5"
                  viewBox="0 0 24 24"
                />
                <span className="text-sm ml-2 font-normal">Saving</span>
              </div>
            ) : (
              "Save"
            )}
          </button>
        </div> */}
      </div>

      <div className="flex flex-1 relative">
        {/* LEFT PANE || CHOICES */}
        <div className="flex flex-col w-full min-h-full overflow-auto px-4 md:w-3/12">
          {mainMenu && (
            <div key={mainMenu.name} className="px-2">
              <div className="flex items-center cursor-pointer">
                <p
                  className="flex-1 py-2 font-semibold text-lg"
                  onClick={() => {
                    setFormShow(true);
                    setExpandMenu(expandMenu ? "" : mainMenu.name);
                    setSelectedItem({
                      type: "menu",
                      id: mainMenu.name,
                      data: dataMenu.data?.menu ?? [],
                    });
                  }}
                >
                  {mainMenu.name}
                </p>
                <ChevronIcon
                  className={
                    `w-4 h-4 duration-200 ease-in transition-transform transform ${
                      expandMenu ? "rotate-180" : "rotate-0"}`
                  }
                  onClick={() => {
                    setExpandMenu(expandMenu ? "" : mainMenu.name);
                  }}
                />
              </div>

              <div
                className="duration-200 ease-in transition-height"
                style={{
                  height: expandMenu ? `${categories.length * 2.25}rem` : 0,
                }}
              >
                {expandMenu && (
                  <ul className="flex flex-col text-xs duration-200 ease-in transition-height overflow-hidden">
                    {categories.map((category) => {
                      const isExpand = category.id === expandID;

                      return (
                        <li key={category.id} className="leading-6 cursor-pointer">
                          <div key={category.id} className="cursor-pointer">
                            <div className="flex items-center">
                              <p
                                className="flex-1 py-2 font-semibold text-sm"
                                onClick={() => handleExpandCategory("title", category)}
                              >
                                {category.languages.find((lang) => lang.lang_id === main_language)?.name ?? ""}
                              </p>
                              <ChevronIcon
                                className={
                                  `w-4 h-4 duration-200 ease-in transition-transform transform ${
                                    isExpand ? "rotate-180" : "rotate-0"}`
                                }
                                onClick={() => handleExpandCategory("chevron", category)}
                              />
                            </div>
                            <ul
                              className="flex flex-col pl-2 text-xs duration-200 ease-in transition-height overflow-hidden"
                              style={{ height: `${isExpand ? expandCategoryHeight : 0}px` }}
                            >
                              {category.items?.map((item) => {
                                if (item.object === "section") {
                                  return (
                                    <Fragment key={item.id}>
                                      <div className="flex items-center cursor-pointer" id={item.id}>
                                        <p
                                          className="flex-1 py-1 font-semibold"
                                          onClick={() => handleExpandSection("chevron", getHeight(category.items), item)}
                                        >
                                          {item.languages.find((lang) => lang.lang_id === main_language)?.name ?? ""}
                                        </p>

                                        <ChevronIcon
                                          className={
                                            `w-4 h-4 duration-200 ease-in transition-transform transform ${
                                              isSectionExpand(item.id) ? "rotate-180" : "rotate-0"}`
                                          }
                                          onClick={() => handleExpandSection("chevron", getHeight(category.items), item)}
                                        />
                                      </div>

                                      <ul
                                        className="flex flex-col px-2 text-xs duration-200 ease-in transition-height overflow-hidden"
                                        style={{
                                          height: `${
                                            isSectionExpand(item.id)
                                              ? item.items_on_section
                                                && getHeight(item.items_on_section) + expandCategoryHeight
                                              : 0
                                          }px`,
                                        }}
                                      >
                                        {item.items_on_section
                                          && item.items_on_section.map((subItem) => (
                                            <li
                                              key={subItem.id}
                                              className="leading-6 cursor-pointer"
                                              id={subItem.id}
                                              onClick={() => {
                                                setSelectedItem({
                                                  type: "item",
                                                  id: subItem.id,
                                                  data: subItem.languages,
                                                });
                                                setFormShow(true);
                                              }}
                                            >
                                              {subItem.languages.find((lang) => lang.lang_id === main_language)?.name
                                                ?? ""}
                                            </li>
                                          ))}
                                      </ul>
                                    </Fragment>
                                  );
                                }

                                return (
                                  <li
                                    key={item.id}
                                    id={item.id}
                                    className="leading-6"
                                    onClick={() => {
                                      setSelectedItem({
                                        type: "item",
                                        id: item.id,
                                        data: item.languages,
                                      });
                                      setFormShow(true);
                                    }}
                                  >
                                    {item.languages.find((lang) => lang.lang_id === main_language)?.name ?? ""}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            </div>
          )}
        </div>

        {/* RIGHT PANE || CONTENT */}
        <div
          className={
            isFormShow
              ? "absolute md:relative bg-white flex flex-col md:gap-4 w-full md:w-9/12 h-full justify-between min-w-0"
              : "hidden md:flex md:w-9/12 min-w-0"
          }
        >
          <div className="px-4 pb-4 w-full overflow-x-auto whitespace-nowrap flex-1">
            {form?.map((formItem) => {
              const isRTL = dataMenu.data?.language_list.find((lang) => lang.code === formItem.lang_id)?.type === "RTL";

              return (
                <div
                  className="inline-block align-top md:h-full shadow-lg rounded w-80 md:w-96 mr-4"
                  key={formItem.lang_id}
                >
                  <div className="p-4 border-b border-line-gray">
                    <div className="flex">
                      <p className="font-bold flex-1">
                        {dataMenu.data?.language_list?.find(({ code }) => formItem.lang_id === code)?.name}
                      </p>
                      {formItem.lang_id !== main_language && (
                        <div className="flex items-center gap-2">
                          <p>Show</p>
                          <div className="relative inline-block w-8 mr-2 align-middle select-none">
                            <input
                              type="checkbox"
                              name={`isShow_${formItem.lang_id}`}
                              id={`isShow_${formItem.lang_id}`}
                              className="toggle-checkbox absolute block w-4 h-4 rounded-full bg-white border-2 appearance-none transition-all duration-300 ease-in cursor-pointer"
                              disabled={isLoading}
                              onChange={(e) => {
                                handleChange(e, formItem.lang_id, "isShow");
                              }}
                              checked={formItem.isShow}
                            />
                            <label
                              htmlFor={`isShow_${formItem.lang_id}`}
                              className="toggle-label block overflow-hidden h-4 rounded-full bg-gray-300 transition-all duration-300 ease-in cursor-pointer"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <p
                      className={
                        `text-secondary text-xs mt-2 ${
                          formItem.lang_id === main_language ? "opacity-100" : "opacity-0"}`
                      }
                    >
                      Default Language
                    </p>
                  </div>

                  <form className={isRTL ? "p-4 form-rtl" : "p-4"}>
                    <label htmlFor={`${formItem.lang_id}_name`} className="block mb-2">
                      Name
                    </label>
                    <input
                      id={`${formItem.lang_id}_name`}
                      type="text"
                      className="input-base mb-4"
                      value={formItem.name}
                      onChange={(e) => handleChange(e, formItem.lang_id, "name")}
                      onBlur={handleSave}
                    />

                    <label htmlFor={`${formItem.lang_id}_description`} className="block mb-2">
                      Description
                    </label>
                    <textarea
                      rows={3}
                      id={`${formItem.lang_id}_description`}
                      className="input-base mb-4"
                      value={formItem.description}
                      onChange={(e) => handleChange(e, formItem.lang_id, "description")}
                      onBlur={handleSave}
                    />

                    {formItem.prices
                      && formItem.prices.length > 1
                      && formItem.prices.map(({ price, id, definition }) => (
                        <Fragment key={`${formItem.lang_id}-${id}`}>
                          <label htmlFor={`${formItem.lang_id}-${id}`} className="block mb-2">
                            {`Price definition for ${price}`}
                          </label>
                          <textarea
                            rows={3}
                            id={`${formItem.lang_id}-${id}`}
                            className="input-base mb-4"
                            value={`${definition}`}
                            onChange={(e) => handleChange(e, formItem.lang_id, "prices", id)}
                            onBlur={handleSave}
                          />
                        </Fragment>
                      ))}
                  </form>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocalizePage;
