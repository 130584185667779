import { useEffect, useState } from "react";
import { Builder } from "../utils";

/**
 *  @description Hook to use QR Link
 *  @returns {
 *    qrLink: string,
 *    setQrLink: (value: string) => void,
 *  }
 */
function useQrLink() {
  const [qrLink, setQrLink] = useState<string | null>(null);
  const [qrLinkLoading, setQrLinkLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchQRLink = async () => {
      setQrLinkLoading(true);
      const link = await Builder.getQrLink();

      setQrLink(link);
      setQrLinkLoading(false);
    };

    fetchQRLink();
  }, []);

  return { qrLink, qrLinkLoading };
}

export default useQrLink;
