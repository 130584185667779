/* eslint-disable camelcase */
import { createApi } from "@reduxjs/toolkit/query/react";

import baseQuery from "./base-service";

export interface QuestionsFeedback {
  id?: string;
  question: string;
  answer_type: string;
  is_required: boolean;
}

export type AnswerType = "rating" | "yesno" | "text" | "smiley";
export interface FeedbacksFormModel {
  id: string;
  name: string;
  question_total: number;
  created_at: string;
  is_published: false;
}

export interface FeedbackFormModel {
  id?: string;
  name: string;
  header: string;
  questions: QuestionsFeedback[];
}

interface FeedbackResultRecord {
  question: string;
  answer_type: string;
  answer: string;
}

export interface FeedbackResultModel {
  id?: string;
  date: string;
  form_name: string;
  isOpen: boolean;
  feedback_number?: number;
  records?: FeedbackResultRecord[]
}

interface LocalizeModel {
  code: string;
  name: string;
  type: string;
}
interface LocalizeQuestionModel {
  id: string;
  text: string;
}

interface LocalizeDataModel {
  lang_id: string;
  is_show?: boolean;
  questions: LocalizeQuestionModel[]
}

interface FeedbackFormLocalModel {
  main_language: string;
  language_list: LocalizeModel[];
  currency: string;
  datas: LocalizeDataModel[]
}

interface FeedbackQuery {
  status: string;
  offset: number;
  limit: number;
  from: string;
  to: string;
}

export const feedbackServices = createApi({
  reducerPath: "feedback",
  baseQuery,
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  endpoints: (builder) => ({
    getAllFeedbackResult: builder.query<HttpResponse<FeedbackResultModel[]>, FeedbackQuery>({
      query: ({
        status, offset, limit, from, to,
      }) => ({
        url: `/feedback/list?status=${status}&offset=${offset}&limit=${limit}&from=${from}&to=${to}`,
        method: "GET",
      }),
    }),
    exportSelectedFeedbacks: builder.query<HttpResponse<{file_path: string;}>, FeedbackQuery>({
      query: ({
        status, offset, limit, from, to,
      }) => ({
        url: `/feedback/download?status=${status}&offset=${offset}&limit=${limit}&from=${from}&to=${to}`,
        method: "GET",
      }),
    }),
    getFeedbackResult: builder.query<HttpResponse<FeedbackResultModel>, string | undefined>({
      query: (id) => ({
        url: `/feedback/single/${id}`,
        method: "GET",
      }),
    }),
    toggleFeedbackOpen: builder.mutation<HttpResponse<{}>, {
      id: string;
    }>({
      query: (body) => ({
        url: "/feedback/toogleOpen",
        method: "PUT",
        body,
      }),
    }),
    deleteMultipleFeedbacks: builder.mutation<HttpResponse<{}>, string[]>({
      query: (id_array) => ({
        url: "/feedback/multiple",
        method: "DELETE",
        body: { id_array },
      }),
    }),
    postFeedbackForm: builder.mutation<HttpResponse<{}>, FeedbackFormModel>({
      query: (body) => ({
        url: "/feedback/form",
        method: "POST",
        body,
      }),
    }),
    getAllFeedbackForm: builder.query<HttpResponse<FeedbacksFormModel[]>, void>({
      query: () => ({
        url: "/feedback/form",
        method: "GET",
      }),
    }),
    getFeedbackForm: builder.query<HttpResponse<FeedbackFormModel>, string>({
      query: (id) => ({
        url: `/feedback/form/single/${id}`,
        method: "GET",
      }),
    }),
    updateFeedbackForm: builder.mutation<HttpResponse<{}>, FeedbackFormModel>({
      query: (body) => ({
        url: "/feedback/form",
        method: "PUT",
        body,
      }),
    }),
    publishFeedbackForm: builder.mutation<HttpResponse<{}>, string>({
      query: (id) => ({
        url: "/feedback/form/publish",
        method: "PUT",
        body: { id },
      }),
    }),
    deleteFeedbackForm: builder.mutation<HttpResponse<{}>, string>({
      query: (id) => ({
        url: "/feedback/form",
        method: "DELETE",
        body: { id },
      }),
    }),
    duplicateFeedbackForm: builder.mutation<HttpResponse<{}>, {id: string}>({
      query: (body) => ({
        url: "/feedback/form/duplicate",
        method: "POST",
        body,
      }),
    }),
    getFeedbackFormLocal: builder.query<HttpResponse<FeedbackFormLocalModel>, string>({
      query: (id) => ({
        url: `/feedback/form/local/single/${id}`,
        method: "GET",
      }),
    }),
    updateFeedbackFormLocal: builder.mutation<HttpResponse<{}>, {
      id: string;
      data: LocalizeDataModel[];
    }>({
      query: (body) => ({
        url: "/feedback/form/local/update",
        method: "PUT",
        body,
      }),
    }),
    toggleFeedbackFormLocal: builder.mutation<HttpResponse<{}>, {
      id: string;
      language_id: string;
    }>({
      query: (body) => ({
        url: "/feedback/form/local/toggleActive",
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  useGetAllFeedbackResultQuery,
  useExportSelectedFeedbacksQuery,
  useGetFeedbackResultQuery,
  useGetAllFeedbackFormQuery,
  useToggleFeedbackOpenMutation,
  useDeleteMultipleFeedbacksMutation,
  useGetFeedbackFormQuery,
  usePostFeedbackFormMutation,
  useUpdateFeedbackFormMutation,
  usePublishFeedbackFormMutation,
  useDeleteFeedbackFormMutation,
  useDuplicateFeedbackFormMutation,
  useGetFeedbackFormLocalQuery,
  useUpdateFeedbackFormLocalMutation,
  useToggleFeedbackFormLocalMutation,
} = feedbackServices;
