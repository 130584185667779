import logo from "../../assets/logo.png";
import { ReactComponent as ChatIcon } from "../../assets/icons/ic_chat_outline.svg";
import { ReactComponent as HumbergerMenuIcon } from "../../assets/icons/ic_humberger_menu.svg";

interface Props {
  toggleSidebar: () => void;
  show: () => void;
}
function HeaderUserMobile({ toggleSidebar, show }: Props) {
  return (
    <div className="w-full p-3 flex justify-between items-center md:hidden">
      <div className="flex">
        <img src={logo} alt="Logo" className="h-9 w-9" />
      </div>
      <div className="flex gap-3">
        <button
          className="p-1"
          onClick={show}
          type="button"
        >
          <ChatIcon className="w-4 h-4 opacity-50" />
        </button>
        <button
          type="button"
          className="p-1"
          onClick={toggleSidebar}
        >
          <HumbergerMenuIcon className="w-5 h-5 tran4for4 text-secondary" />
        </button>
      </div>
    </div>
  );
}

export default HeaderUserMobile;
