import {
  useRef, useState, memo, useEffect, useCallback,
} from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import LoadingBar from "react-top-loading-bar";

import { ReactComponent as UserIcon } from "../../../assets/icons/ic_user_outline.svg";
import { ReactComponent as EmailIcon } from "../../../assets/icons/ic_mail.svg";
import { ReactComponent as PhoneIcon } from "../../../assets/icons/ic_phone.svg";
import { ReactComponent as LockIcon } from "../../../assets/icons/ic_lock.svg";
import { ReactComponent as EyeIcon } from "../../../assets/icons/ic_eye.svg";
import { ReactComponent as CheckIcon } from "../../../assets/icons/ic_check_green.svg";
import { ReactComponent as SpinnerIcon } from "../../../assets/icons/ic_spinner.svg";
import { ReactComponent as ChevronDownIcon } from "../../../assets/icons/ic_chevron_down.svg";

import { LocalStorage } from "../../../utils";
import { RegisterStep5 } from "../model";
import { InputWithIcon } from "../../../components/atoms";
import {
  useCountryListQuery,
  useBusinessListQuery,
  useStep5Mutation,
  useVerifyEmailMutation,
} from "../../../services";

interface RegisterProps {
  prevStep: () => void;
  nextStep: (number?: number, value?: string) => void;
  uid: string;
}

const eyeIconClasss = (isShow: boolean) => {
  const passType = "w-5 h-5 ml-4 opacity-30";
  const textType = "w-5 h-5 ml-4 opacity-100";
  return isShow ? textType : passType;
};

// const pattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const validateEmail = (email: string) => email.toLowerCase().match(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
) !== null;

const Register = memo(
  ({ prevStep, nextStep, uid }: RegisterProps) => {
    const [agreement, setAgreement] = useState<boolean>(false);
    const [phoneCode, setPhoneCode] = useState<string>("");
    const [isShowPassword, setShowPassword] = useState<boolean>(false);
    const {
      register,
      handleSubmit,
      formState,
      watch,
      control,
      setValue,
      setFocus,
    } = useForm<RegisterStep5>({
      mode: "onBlur",
      defaultValues: {
        uid,
        name: "",
        email: "",
        password: "",
        phone_code: "",
        phone_number: "",
        bus_type: "",
        country: "",
      },
    });
    const { errors } = formState;

    const { data: countries, isLoading: isLoadingCountryList } = useCountryListQuery();
    const { data: business, isLoading: isLoadingBusinessList } = useBusinessListQuery();
    const [postStep5, { isLoading: isLoadingPostStep5 }] = useStep5Mutation();
    const [verifyEmail, { isLoading: isLoadingVerifyEmail, isSuccess: isEmailAvailable }] = useVerifyEmailMutation();
    const [isEmailValid, setEmailValid] = useState<boolean>(false);

    const ref = useRef(null);

    const selectedCountry = watch("country");
    const selectedPhoneCode = useCallback(
      (country: string) => countries?.data?.find(({ code }) => code === country)?.phonecode,
      [countries?.data],
    );

    const handleInputPasswordType = () => {
      setShowPassword(!isShowPassword);
    };

    const clearSavedData = () => {
      LocalStorage.clearAll();
    };

    const onSubmit: SubmitHandler<RegisterStep5> = (data) => {
      if (!isEmailValid) {
        setFocus("email");
        return;
      }

      if (!agreement) return;
      const { phone_number, country } = data;
      const code = selectedPhoneCode(country);
      let number = phone_number; // trim first 0
      if (phone_number?.charAt(0) === "0") {
        number = phone_number.substring(1);
      }

      postStep5({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...data, uid, phone_number: `+${code}${number}`, phone_code: code!,
      })
        .unwrap()
        .then((res) => {
          nextStep(6, res.data?.email);
          clearSavedData();
        })
        .catch((error) => {
          // resetStep(); // if uid not found or expired
          toast.error(error.data?.message ?? "Failed to upload");
        });
    };

    useEffect(() => {
      const code = selectedPhoneCode(selectedCountry);

      if (code) {
        setPhoneCode(code);
        setValue("phone_code", code);
      }
    }, [selectedCountry, selectedPhoneCode]);

    useEffect(() => {
      const countriesData = countries?.data;
      if (!countriesData) return;

      fetch("https://ipapi.co/country_code_iso3", {
        mode: "cors",
      })
        .then((res) => res.text())
        .then((text) => {
          const userCountry = countriesData.find((country) => country.code === text);
          if (userCountry?.code) {
            setValue("country", userCountry.code);
          } else {
            setValue("country", countriesData[0].code);
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    }, [countries?.data]);

    useEffect(() => {
      if (business?.data) {
        setValue("bus_type", business.data[0].code);
      }
    }, [business?.data]);

    useEffect(() => {
      const progress = ref.current as any;
      if (isLoadingCountryList || isLoadingBusinessList) {
        progress?.continuousStart();
      } else {
        progress?.complete();
      }
    }, [isLoadingCountryList, isLoadingBusinessList]);

    return (
      <form>
        <LoadingBar height={4} color="#0078D3" ref={ref} />
        <Controller
          control={control}
          name="name"
          defaultValue=""
          rules={{ required: { value: true, message: "Name is required" } }}
          render={({
            field: {
              onChange, value, name, ref: controllerRef,
            },
          }) => {
            const handleChange = (e: InputChangeType) => {
              const number = /\d/;
              if (!number.test(e.target.value)) onChange(e);
            };

            return (
              <InputWithIcon
                leftIcon={<UserIcon className="w-5 h-5 mr-4" />}
                title="Full Name"
                placeholder="John Doe"
                name={name}
                inputRef={controllerRef}
                onChange={handleChange}
                value={value}
                errorMessage={errors.name?.message}
              />
            );
          }}
        />

        <Controller
          control={control}
          name="email"
          rules={{ required: { value: true, message: "Email is required" } }}
          render={({
            field: {
              onChange, value, name, ref: controllerRef,
            },
          }) => (
            <InputWithIcon
              leftIcon={<EmailIcon className="w-5 h-5 mr-4" />}
              rightIcon={
                (isEmailValid && isEmailAvailable) ? <CheckIcon className="w-5 h-5 ml-4" /> : undefined
              }
              title="Your Email"
              placeholder="example@example.com"
              name={name}
              inputRef={controllerRef}
              isValid={isEmailValid && isEmailAvailable}
              onChange={onChange}
              onBlur={(e) => {
                const { value: blurredValue } = e.target;
                if (blurredValue === "") return;

                const validated = validateEmail(blurredValue);

                setEmailValid(validated);

                if (!validated) {
                  toast.error("Correct email format is required", { toastId: "email error" });
                  return;
                }

                verifyEmail({ email: blurredValue }).unwrap().catch((error) => {
                  toast.error(error.data?.message ?? "Email is already Used", { toastId: "email used" });
                });
              }}
              value={value}
              errorMessage={errors.email?.message}
              disabled={isLoadingVerifyEmail}
            />
          )}
        />

        <Controller
          control={control}
          name="password"
          rules={{ required: { value: true, message: "Password is required" }, minLength: { value: 6, message: "Please enter at least 6 characters" } }}
          render={({
            field: {
              onChange, value, name, ref: controllerRef,
            },
          }) => (
            <InputWithIcon
              leftIcon={<LockIcon className="w-5 h-5 mr-4" />}
              rightIcon={(
                <EyeIcon
                  className={eyeIconClasss(isShowPassword)}
                  onClick={handleInputPasswordType}
                />
              )}
              title="Your Password"
              placeholder="***********"
              type={isShowPassword ? "text" : "password"}
              name={name}
              value={value}
              onChange={onChange}
              inputRef={controllerRef}
              errorMessage={errors.password?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="phone_number"
          defaultValue=""
          rules={{ required: { value: true, message: "Phone number is required" } }}
          render={({
            field: {
              onChange, value, name, ref: controllerRef,
            },
          }) => {
            const handleChange = (e: InputChangeType) => {
              const number = /^\d+$/;
              const isMatch = e.target.value.match(number);
              if (isMatch ?? e.target.value === "") onChange(e);
            };

            return (
              <InputWithIcon
                leftIcon={<PhoneIcon className="w-5 h-5 mr-4" key="icon" />}
                additionalLeftInfo={`+${phoneCode}`}
                title="Phone Number"
                type="tel"
                placeholder="123456789"
                inputRef={controllerRef}
                name={name}
                value={value}
                onChange={handleChange}
                errorMessage={errors.phone_number?.message}
              />
            );
          }}
        />

        <div className="flex gap-4">
          <div className="flex flex-col w-1/2 rounded border border-line-gray px-4 py-3 mb-7 focus-within:border-primary transform transition-colors duration-300 ease-in">
            <p className="font-montserrat text-xs">Business Type</p>
            <div className="flex">
              <select
                id="bus_type"
                {...register("bus_type", { required: true })}
                className="font-montserrat px-0 flex-1 text-sm focus:outline-none appearance-none overflow-hidden"
              >
                {business?.data?.map((bus) => (
                  <option key={bus.code} value={bus.code}>{bus.name}</option>
                ))}
              </select>
              <ChevronDownIcon className="h-4 w-4" />
            </div>
          </div>

          <div className="flex flex-col w-1/2 rounded border border-line-gray px-4 py-3 mb-7 focus-within:border-primary transform transition-colors duration-300 ease-in">
            <p className="font-montserrat text-xs">Country</p>
            <div className="flex">
              <select
                id="country"
                {...register("country", { required: true })}
                className="font-montserrat px-0 flex-1 text-sm focus:outline-none appearance-none overflow-hidden"
              >
                {countries?.data?.map((country) => (
                  <option key={country.code} value={country.code}>{country.country}</option>
                ))}
              </select>
              <ChevronDownIcon className="h-4 w-4" />
            </div>
          </div>
        </div>

        <label
          className="flex items-center space-x-3 mb-10 cursor-pointer"
          htmlFor="aggrement"
        >
          <input
            type="checkbox"
            name="agreement"
            id="aggrement"
            className="form-tick appearance-none bg-white bg-check h-6 w-6 border border-gray-300 rounded-md checked:bg-primary checked:border-transparent focus:outline-none"
            checked={agreement}
            onChange={() => {
              setAgreement(!agreement);
            }}
          />
          <p className="text-gray-700 dark:text-white font-normal">
            I Agree to the
            {" "}
            <a className="button-link" href={`${process.env.REACT_APP_LANDING_PAGE_URL}/terms.html`} target="_blank" rel="noreferrer">
              Terms and Conditions
            </a>
          </p>
        </label>

        <div className="flex justify-center mt-6">
          <button
            type="button"
            title="Back"
            className="button-secondary flex-grow md:flex-grow-0 mr-3"
            onClick={prevStep}
          >
            Back
          </button>

          <button
            type="button"
            className="button-primary flex-grow md:flex-grow-0"
            onClick={handleSubmit(onSubmit)}
            disabled={!agreement}
          >
            {isLoadingPostStep5 ? (
              <SpinnerIcon
                className="animate-spin h-5 w-5"
                viewBox="0 0 24 24"
              />
            ) : (
              <span>Next</span>
            )}
          </button>
        </div>
      </form>
    );
  },
);

export default Register;
