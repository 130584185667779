/* eslint-disable @typescript-eslint/no-empty-function */
import {
  useEffect, useMemo, useRef, useState,
} from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import LoadingBar from "react-top-loading-bar";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Popup from "reactjs-popup";

import { ReactComponent as IllustrationNoItems } from "../assets/illustration_no_items.svg";
import { ReactComponent as SpinnerIcon } from "../assets/icons/ic_spinner.svg";
import { ReactComponent as ChevronIcon } from "../assets/icons/ic_chevron_down.svg";
import imagePlaceholder from "../assets/image_placeholder.png";

import {
  ContentHeader,
  CardWithOptions,
  LoadingAnimation,
  ModalConfirmation,
  SortableItem,
  SortableItemOverlay,
  SortableGridContainer,
} from "../components";

import { useDebounce } from "../hooks";

import {
  useGetAllSectionQuery,
  useUpdateSectionHeaderMutation,
  usePublishItemMutation,
  useDeleteItemMutation,
  useMoveItemMutation,
  useDuplicateItemMutation,
  useUpdateDragAndDropSectionMutation,
  useGetSectionListQuery,
  useGetCategoryListQuery,
} from "../services";

import type { ItemListResponse } from "../services";

interface FormHeader {
  name: string;
  description: string;
}

interface Confirmation {
  isOpen: boolean;
  data: string | null;
}

function SectionsPage() {
  const { register, handleSubmit, setValue } = useForm<FormHeader>();
  const [keyword, setKeyword] = useState<string>("");
  const [filteredComponents, setFilteredComponents] = useState<ItemListResponse[] | []>([]);
  const [activeId, setActiveId] = useState<string | null>(null);
  const [toggler, setToggler] = useState<boolean[]>([]);
  const [moveItem, setMoveItem] = useState<string | null>(null);
  const [modalConfirmation, setModalConfirmation] = useState<Confirmation>({
    isOpen: false,
    data: null,
  });
  const [selectedItemToMove, setItemToMove] = useState<{
    id: string;
    type: string | "section" | "category";
  }>({
    id: "",
    type: "",
  });

  const debouncedKeyword = useDebounce<string>(keyword, 500);
  const ref = useRef(null);
  const { push, goBack, replace } = useHistory();
  const { state } = useLocation<StateID>();

  const { data: categoryList } = useGetCategoryListQuery();
  const { data: sectionList } = useGetSectionListQuery(state?.category_id);
  const [updateSectionHeader] = useUpdateSectionHeaderMutation();
  const [publishItem, { isLoading: isLoadingPublish }] = usePublishItemMutation();
  const [deleteItem, { isLoading: isLoadingDeleteItem }] = useDeleteItemMutation();
  const [updateMoveItem, { isLoading: isLoadingMoveItem }] = useMoveItemMutation();
  const [duplicateItem, { isLoading: isLoadingDuplicateItem }] = useDuplicateItemMutation();
  const [dragAndDropItems, { isLoading: isLoadingdragAndDropItems }] = useUpdateDragAndDropSectionMutation();
  const { data, isLoading, refetch } = useGetAllSectionQuery(
    {
      id: state?.section_id,
    },
    {
      skip: !state?.section_id ?? true,
    },
  );

  const activeField = useMemo(
    () => filteredComponents.find(({ id }) => id === activeId),
    [filteredComponents, activeId],
  );

  /**
   * @description Function to handle update section header
   * @param param0<{ name: string; description: string }>
   */
  const onSubmit: SubmitHandler<FormHeader> = ({ name, description }) => {
    if ((data?.data?.name === name && data?.data?.description === description) || state?.section_id === undefined) {
      return;
    }

    updateSectionHeader({ section_id: state?.section_id, name, description })
      .unwrap()
      .then(() => {
        toast.success("Successfully update section");
        refetch();
      })
      .catch(() => {
        toast.error("Failed update section");
      });
  };

  /**
   * @description Function to handle drag end
   * @param ids<string[]>
   */
  const handleDragEnd = (ids: string[]) => {
    if (state.section_id) {
      dragAndDropItems({
        section_id: state.section_id,
        item_id_array: ids,
      });
    }
  };

  /**
   * @description Function to handle publish item
   * @param index<number>
   */
  const handleIsPublish = (index: number) => {
    const item = data?.data?.items[index];
    if (item) {
      let textIsPublish = "publish";
      let tempToggler = toggler.map((isPublish, current) => {
        const isCurrentIndex = current === index;
        if (isCurrentIndex) {
          textIsPublish = isPublish ? "unpublish" : "publish";
        }
        return isCurrentIndex ? !isPublish : isPublish;
      });

      setToggler(tempToggler);

      publishItem(item.id)
        .unwrap()
        .then(() => {
          toast.success(`${item.name} ${textIsPublish}ed`);
        })
        .catch(() => {
          tempToggler = toggler.map((isPublish, current) => (current === index ? !isPublish : isPublish));

          setToggler(tempToggler);
          toast.error(`${item.name} ${textIsPublish} failed`);
        });
    }
  };

  /**
   * @description Function to handle open modal confirmation delete item
   * @param id<string>
   */
  const handleDelete = (id: string) => {
    setModalConfirmation({ isOpen: true, data: id });
  };

  /**
   * @description Function to handle callback confirmation from handleDelete function
   */
  const callbackConfirmation = () => {
    const { data: modalConfirmationData } = modalConfirmation;

    if (!modalConfirmationData) return;

    deleteItem(modalConfirmationData)
      .unwrap()
      .then(() => {
        toast.success("Successfully delete item");
        refetch();
      })
      .catch(() => {
        toast.error("Failed to delete item");
      })
      .finally(() => {
        setModalConfirmation((prevState) => ({ ...prevState, isOpen: false }));
      });
  };

  /**
   * @description Function to handle duplicate item
   * @param id<string>
   */
  const handleDuplicate = (id: string) => {
    duplicateItem({ id })
      .unwrap()
      .then(() => {
        refetch();
      })
      .catch((error: any) => {
        toast.error(error.data?.message ?? "Failed to duplicate item");
      });
  };

  /**
   * @description Function to handle edit item (redirect to /item page with state item_id, category_id, section_id)
   * @param id<string>
   */
  const handleEdit = (id: string) => {
    push({
      pathname: "/item",
      state: {
        item_id: id,
        category_id: state?.category_id,
        section_id: state?.section_id,
      },
    });
  };

  /**
   * @description Function to handle move item to another section or category
   */
  const onMoveItem = () => {
    if (!moveItem) return;

    updateMoveItem({
      id: moveItem,
      category_id: selectedItemToMove.type === "category" ? selectedItemToMove.id : state.category_id ?? "",
      section_id: selectedItemToMove.type === "section" ? selectedItemToMove.id : "",
    })
      .unwrap()
      .then(() => {
        toast.success("Successfully move item");
        refetch();
      })
      .catch(() => {
        toast.error("Failed to move item");
      })
      .finally(() => {
        setMoveItem(null);
      });
  };

  /**
   * @description Function to handle render section list
   */
  const renderSectionList = () => {
    const filteredSectionList = sectionList?.data?.sections?.filter(({ id }) => id !== state?.section_id);

    return (
      sectionList
      && filteredSectionList
      && sectionList?.data?.sections
      && filteredSectionList?.length > 0 && (
        <optgroup label="Sections">
          {filteredSectionList?.map((section: { id: string; name: string }) => (
            <option key={section.id} value={`${section.id}-section`}>
              {section.name}
            </option>
          ))}
        </optgroup>
      )
    );
  };

  useEffect(() => {
    const progess = ref.current as any;

    if (isLoading || isLoadingdragAndDropItems) {
      progess?.continuousStart();
    } else {
      progess?.complete();
    }
  }, [isLoading, isLoadingdragAndDropItems]);

  useEffect(() => {
    if (!state?.section_id) {
      replace("/categories");
      return;
    }

    if (!state?.section_id && state?.category_id) {
      replace("/items", {
        state: {
          category_id: state.category_id,
        },
      });
    }
  }, [state?.section_id, state?.category_id]);

  useEffect(() => {
    if (data?.data) {
      setValue("name", data.data.name);
      setValue("description", data.data.description);
    }
  }, [data?.data]);

  useEffect(() => {
    if (!data?.data?.items || Object.keys(data?.data ?? {}).length === 0) {
      setFilteredComponents([]);
      return;
    }

    const filtered = data.data.items
      .filter(({ name }: ItemListResponse) => name.toLowerCase()
        .includes(debouncedKeyword.toLowerCase()));

    setFilteredComponents(filtered);
  }, [debouncedKeyword, data?.data]);

  useEffect(() => {
    const responseData = data?.data;

    if (responseData) {
      setToggler(responseData.items?.map(({ is_published }: ItemListResponse) => is_published) ?? []);
      setValue("name", responseData.name);
      setValue("description", responseData.description);
    }
  }, [data?.data]);

  useEffect(() => {
    setToggler(filteredComponents.map(({ is_published }) => is_published) ?? []);
  }, [filteredComponents]);

  if (!data) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <LoadingAnimation />
      </div>
    );
  }

  return (
    <>
      <LoadingBar height={4} color="#0078D3" ref={ref} />
      <div className="flex-1">
        {moveItem && (
          <Popup
            open={moveItem !== null}
            contentStyle={{
              borderRadius: 8,
              padding: "1rem",
              background: "transparent",
              border: 0,
            }}
            onClose={() => setMoveItem(null)}
            closeOnDocumentClick
          >
            <div className="flex flex-col justify-between gap-12 fixed md:relative w-full md:h-full inset-0 top-auto md:inset-auto p-4 md:p-6 bg-white rounded-t-xl md:rounded-b-xl">
              <div className="flex flex-col md:flex-row md:items-center md:mt-8 gap-4 relative">
                <p className="text-secondary">
                  Move
                  {" "}
                  {data?.data?.items.find(({ id }: { id: string }) => id === moveItem)?.name}
                  {" "}
                  to :
                </p>
                <select
                  name="categories"
                  className="input-base flex-1"
                  onChange={(e) => {
                    // Get the id of the selected item and set to selectedCategory
                    const getDataType = e.target.value.split("-")[1];
                    const getId = e.target.value.split("-")[0];

                    setItemToMove({
                      id: getId,
                      type: getDataType,
                    });
                  }}
                  value={`${
                    !selectedItemToMove.id && !selectedItemToMove.type
                      ? ""
                      : `${selectedItemToMove.id}-${selectedItemToMove.type}`
                  }`}
                >
                  <option value="" disabled>
                    Select Category
                    {" "}
                    {sectionList?.data?.sections && sectionList?.data?.sections?.length > 1 ? "/ Section" : ""}
                  </option>
                  <optgroup label="Categories">
                    {categoryList
                      && categoryList?.data?.categories.map((category) => (
                        <option key={category.id} value={`${category.id}-category`}>
                          {category.name}
                        </option>
                      ))}
                  </optgroup>

                  {/*
                    Function to check if the moveItem id is section or an item
                  */}
                  {renderSectionList()}
                </select>
                <ChevronIcon className="h-5 w-5 absolute top-12 md:top-2 right-2" />
              </div>

              <div className="flex w-full justify-end mt-4">
                <button
                  type="button"
                  title="Close"
                  className="button-secondary py-2 w-1/2 md:w-1/4 mr-4"
                  onClick={() => setMoveItem(null)}
                >
                  Close
                </button>
                <button
                  type="button"
                  title={isLoadingMoveItem ? "Moving..." : "Move"}
                  disabled={isLoadingMoveItem}
                  className="button-primary py-2 w-1/2 md:w-1/4 flex items-center justify-center"
                  onClick={onMoveItem}
                >
                  {isLoadingMoveItem ? (
                    <SpinnerIcon className="animate-spin h-4 w-4 m-2" />
                  ) : (
                    <>
                      <span>Move</span>
                      <ChevronIcon className="h-5 w-5 transform -rotate-90 ml-3" color="white" />
                    </>
                  )}
                </button>
              </div>
            </div>
          </Popup>
        )}

        <ContentHeader
          title={data?.data?.name ?? "Section"}
          subtitle="Here you can manage your section. You can add, edit, delete and move items."
          onSearch={setKeyword}
          value={keyword}
          buttonTitle="New Item"
          backButton={goBack}
          onButtonClick={() => push("/item", {
            section_id: state?.section_id,
            category_id: state?.category_id,
          })}
        />

        <form className="m-6 w-10/12 md:w-1/3 flex items-start">
          <div>
            <input
              className="input-base border-0 py-0 pl-0 text-lg font-bold mb-1 border-b border-transparent rounded-none focus:border-black focus:ring-0 focus:border-b-1"
              type="text"
              {...register("name")}
              placeholder="Type an item name"
              onBlur={handleSubmit(onSubmit)}
            />
            <input
              className="input-base border-0 py-0 pl-0 text-sm border-b border-transparent rounded-none focus:border-black focus:ring-0 focus:border-b-1"
              type="text"
              {...register("description")}
              placeholder="Type an item description"
              onBlur={handleSubmit(onSubmit)}
            />
          </div>
        </form>

        {!isLoading && (
          <div
            className={
              `my-3 mx-6 ${
                filteredComponents.length > 0 ? "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6" : ""}`
            }
          >
            {filteredComponents.length > 0 ? (
              <SortableGridContainer
                items={filteredComponents}
                setItems={setFilteredComponents}
                setActiveId={setActiveId}
                onDragEnd={handleDragEnd}
                overlayChildren={
                  activeId ? (
                    <SortableItemOverlay id={activeId} useHandler positionHandler="bottom">
                      <CardWithOptions
                        item={{
                          id: activeField?.id ?? "",
                          title: activeField?.name ?? "",
                          subtitle: `${activeField?.currency} ${activeField?.prices?.[0]?.toString()}`,
                          image: activeField?.image
                            ? `${process.env.REACT_APP_CDN_URL}/images/${activeField.image}`
                            : imagePlaceholder,
                          isActive: !!activeField?.is_published,
                        }}
                        onEdit={() => {}}
                        onIsPublish={() => {}}
                        onDelete={() => {}}
                        isLoadingPublish={false}
                        dragable
                      />
                    </SortableItemOverlay>
                  ) : null
                }
              >
                {filteredComponents.map(({
                  id, name, prices, image, currency, is_published,
                }, index) => (
                  <SortableItem
                    key={id}
                    id={id}
                    style={{ opacity: id === activeId ? 0 : 1 }}
                    useHandler
                    positionHandler="bottom"
                    disabled={!!debouncedKeyword}
                  >
                    <CardWithOptions
                      key={id}
                      item={{
                        id,
                        title: name,
                        subtitle: `${currency} ${prices?.[0]?.toString()}`,
                        image: image ? `${process.env.REACT_APP_CDN_URL}/images/${image}` : imagePlaceholder,
                        isActive: toggler[index] ?? is_published,
                      }}
                      onIsPublish={() => handleIsPublish(index)}
                      isLoadingPublish={isLoadingPublish}
                      onDelete={handleDelete}
                      onMove={setMoveItem}
                      onEdit={handleEdit}
                      onDuplicate={handleDuplicate}
                      isLoadingDuplicate={isLoadingDuplicateItem}
                      dragable
                    />
                  </SortableItem>
                ))}
              </SortableGridContainer>
            ) : (
              <div className="h-full w-full flex-1 flex flex-col gap-1 justify-center items-center">
                <IllustrationNoItems className="h-64 w-64 mb-4" />

                <p className="text-lg">
                  There&#39;s no
                  {debouncedKeyword.length > 0 ? debouncedKeyword : "item"}
                </p>
                <p className="text-secondary">
                  You haven&#39;t add any
                  {" "}
                  {debouncedKeyword.length > 0 ? `${debouncedKeyword} in item` : "item"}
                </p>
              </div>
            )}
          </div>
        )}
      </div>

      <ModalConfirmation
        isOpen={modalConfirmation.isOpen}
        content="Are you sure?"
        onCloseModal={() => setModalConfirmation({ isOpen: false, data: null })}
        onConfirmModal={callbackConfirmation}
        confirmationText="Delete"
        confirmationLoading={isLoadingDeleteItem}
      />
    </>
  );
}

export default SectionsPage;
