import clsx from "clsx";
import { memo, useState } from "react";

import { ReactComponent as BookIcon } from "../assets/icons/ic_book.svg";
import { ReactComponent as ChevronDownIcon } from "../assets/icons/ic_chevron_down.svg";
import { ReactComponent as PlayIcon } from "../assets/icons/ic_play_circle_outline.svg";
import { ReactComponent as PlusIcon } from "../assets/icons/ic_plus.svg";
import { ReactComponent as SearchIcon } from "../assets/icons/ic_search.svg";
import { ReactComponent as SpinnerIcon } from "../assets/icons/ic_spinner.svg";

interface Props {
  title: string;
  subtitle: string;
  value?: string;
  onSearch?: (text: string) => void;
  buttonTitle?: string;
  buttonTitleLoading?: boolean;
  secondaryButtonTitle?: string;
  onButtonClick?: () => void;
  onSecondaryButtonClick?: () => void;
  backButton?: () => void;
  showAddButton?: boolean;
  showBorderBottom?: boolean;
}

const ContentHeader = memo(
  ({
    title,
    subtitle,
    onSearch,
    value = "",
    buttonTitle,
    buttonTitleLoading = false,
    secondaryButtonTitle,
    onButtonClick,
    onSecondaryButtonClick,
    backButton,
    showAddButton = true,
    showBorderBottom = true,
  }: Props) => {
    const [isSearchShow, setSearchShow] = useState<boolean>(false);

    const handleChange = (e: InputChangeType) => {
      if (onSearch) onSearch(e.target.value);
    };

    const handlePlusIconClick = () => {
      if (isSearchShow) {
        setSearchShow(false);
      } else if (onButtonClick) {
        onButtonClick();
      }
    };

    return (
      <div className={clsx("flex flex-col p-6", showBorderBottom && "border-b border-line-gray")}>
        <div className="flex justify-between items-center mb-3">
          {isSearchShow ? (
            <input
              type="text"
              className="flex-1 input-base mr-2"
              placeholder="Search here..."
              onChange={handleChange}
              value={value}
            />
          ) : (
            <div className="flex w-10/12 min-w-0">
              {backButton && (
                <button className="bg-transparent focus:outline-none" onClick={backButton} type="button" title="Back">
                  <ChevronDownIcon className="h-6 w-6 transform rotate-90 mr-2" />
                </button>
              )}
              <h2 className="font-bold text-2xl pr-4 overflow-hidden overflow-ellipsis whitespace-nowrap">{title}</h2>
            </div>
          )}

          {/* Header Actions */}
          <div className="hidden md:flex md:gap-4">
            {onSearch && (
              <input
                type="text"
                className="input-base px-4"
                placeholder="Search"
                onChange={handleChange}
                value={value}
              />
            )}

            {secondaryButtonTitle && (
              <button
                type="button"
                className="py-2 whitespace-nowrap button-gray text-base"
                onClick={onSecondaryButtonClick}
              >
                {secondaryButtonTitle}
              </button>
            )}

            {buttonTitle && (
              <button
                type="button"
                className="py-2 whitespace-nowrap button-primary text-base"
                onClick={onButtonClick}
                disabled={buttonTitleLoading}
              >
                {buttonTitleLoading ? (
                  <div className="flex items-center gap-1">
                    <SpinnerIcon className="animate-spin h-4 w-4" viewBox="0 0 24 24" />
                    <p>Loading</p>
                  </div>
                ) : (
                  buttonTitle
                )}
              </button>
            )}
          </div>

          <div className="flex gap-2 md:hidden">
            {onSearch && (
              <button className="px-1" type="button" title="Search">
                <SearchIcon className="w-6 h-5" onClick={() => setSearchShow(true)} />
              </button>
            )}

            {(buttonTitle && showAddButton) && (
              <button className="px-1" type="button">
                <PlusIcon
                  className={`w-6 h-5 transition-transform transform duration-300 ${
                    isSearchShow ? "rotate-45" : "rotate-0"
                  }`}
                  onClick={() => {
                    if (isSearchShow && onSearch) onSearch("");
                    handlePlusIconClick();
                  }}
                />
              </button>
            )}

            {onSearch && !showAddButton && (
              <button type="button" className={clsx("px-1", (isSearchShow) ? "" : "hidden")}>
                <PlusIcon
                  className={`w-6 h-5 transition-transform transform duration-300 ${
                    isSearchShow ? "rotate-45" : "rotate-0"
                  }`}
                  onClick={() => handlePlusIconClick()}
                />
              </button>
            )}
          </div>
        </div>

        {/* Header Subtitle */}
        <p className="text-secondary text-sm">{subtitle}</p>

        {/* Header Links */}
        <div className="flex mt-4">
          <button className="button-link flex items-center mr-6" type="button" title="Watch a video">
            <PlayIcon />
            <span className="text-sm font-bold ml-2">Watch a Video</span>
          </button>

          <button className="button-link flex items-center" type="button" title="How to get started">
            <BookIcon />
            <a
              className="text-sm font-bold ml-2"
              href={process.env.REACT_APP_HOW_T0_GET_STARTED}
              target="_blank"
              rel="noreferrer"
            >
              How to Get Started
            </a>
          </button>
        </div>
      </div>
    );
  },
);

export default ContentHeader;
