/* eslint-disable camelcase */
import { createApi } from "@reduxjs/toolkit/query/react";

import baseQuery from "./base-service";
import { CodeNameResponse } from "./variables-services";
import { type PromoCodeModel } from "./promo-services";

interface SettingVenueResponse {
  venue_id: string;
  pincode: string;
}

export interface SettingGeneralResponse {
  info: {
    business_name: string;
    address: string;
    country: CodeNameResponse;
    logo?: string;
  };
  language: {
    default: CodeNameResponse;
    other: CodeNameResponse[];
  };
  feedback_email: string;
  currency: string;
}

export interface SettingGeneralRequest {
  business_name: string;
  address: string;
  default_lang_code: string;
  other_langs_code: string[];
  feedback_email: string;
  currency_code: string;
  logo?: string | null;
}

export interface SettingTabletModel {
  language_is_active: boolean;
  currency_is_active: boolean;
  feedback_is_active: boolean;
  // order_is_active: boolean;
  tables_is_active: boolean;
  guest_is_active: boolean;
  payment_is_active: boolean;
  payment_type_array: {
    id: string;
    is_display: boolean;
  }[];
  cart_is_active: boolean;
}

export interface SettingQRResponse {
  // venue_is_active: boolean;
  venue_name_is_show: boolean;
  language_is_active: boolean;
  currency_is_active: boolean;
  feedback_is_active: boolean;
  cart_is_active: boolean;
  // welcome_texts: {
  //   lang_code: string;
  //   lang_name: string;
  //   value: string;
  // }[];
  qr_code_color: string;
  qr_code_bg_color: string;
  delivery_charge: number | null;
  delivery_charge_is_active: boolean;
  minimum_order_amount: number | null;
  operating_hour_is_active: boolean;
  operating_hour_array: {
    order_id: string;
    list: {
      day_id: string;
      is_open: boolean;
      time_close: string | null;
      time_open: string | null;
    }[];
  }[];
  order_is_active: boolean;
  order_type_array: {
    id: string;
    is_display: boolean;
  }[];
  payment_is_active: boolean;
  payment_type_array: {
    id: string;
    is_display: boolean;
  }[];
  promo_is_active: boolean;
  promo_array: Array<
    Pick<PromoCodeModel, "code" | "type" | "amount" | "order_modes"> & {
      id: string;
      min_order_amount: number;
      is_active: boolean;
    }
  >;
}

interface SettingQRRequest {
  venue_name_is_show: boolean;
  language_is_active: boolean;
  currency_is_active: boolean;
  cart_is_active: boolean;
  feedback_is_active: boolean;
  // welcome_texts: {
  //   lang:string;
  //   value:string;
  // }[];
  qr_code_color: string;
  qr_code_bg_color: string;
  delivery_charge: number | null;
  delivery_charge_is_active: boolean;
  minimum_order_amount: number | null;
  operating_hour_is_active: boolean;
  operating_hour_array: {
    order_id: string;
    list: {
      day_id: string;
      is_open: boolean;
      time_close: string | null;
      time_open: string | null;
    }[];
  }[];
  order_is_active: boolean;
  order_type_array: {
    id: string;
    is_display: boolean;
  }[];
  payment_is_active: boolean;
  payment_type_array: {
    id: string;
    is_display: boolean;
  }[];
  promo_is_active: boolean;
  promo_array: SettingQRResponse["promo_array"];
}

interface SettingAccountResponse {
  fullname: string;
  email: string;
  phone_code: string;
  phone_num: string;
  country_code: string;
  country_name: string;
}
interface SettingAccountRequest {
  fullname: string;
  phone_code: string;
  phone_num: string;
  country_code: string;
}

interface UpdatePasswordRequest {
  current_pass: string;
  new_pass: string;
}

export interface InvoiceModel {
  id: string;
  currency: string;
  period: {
    start: string;
    end: string;
  };
  interval: string;
  plan: string;
  price: number;
  is_paid: boolean;
  detail_link: string;
}

export interface CardModel {
  id: string;
  name: string | null;
  brand: string;
  last4_card_number: string;
  is_default: boolean;
}

export interface CardRequestModel {
  number: string;
  exp_month: string;
  exp_year: string;
  cvc: string;
  name: string;
}

interface BillingResponse {
  valid_until: string;
  price: string;
  currency: string;
  subscription: string;
  interval: string;
}
interface ManageBillingResponse {
  url: string;
}

export const settingServices = createApi({
  reducerPath: "settings",
  baseQuery,
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  endpoints: (builder) => ({
    venueID: builder.query<HttpResponse<SettingVenueResponse>, void>({
      query: () => ({
        url: "/setting/venueid",
        method: "GET",
      }),
    }),
    updateVenue: builder.mutation<HttpResponse<{}>, { venue_id: string; pincode: string }>({
      query: ({ venue_id, pincode }) => ({
        url: "/setting/venueid",
        method: "PUT",
        body: { venue_id, pincode },
      }),
    }),
    settingGeneral: builder.query<HttpResponse<SettingGeneralResponse>, void>({
      query: () => ({
        url: "/setting/general",
        method: "GET",
      }),
    }),
    updateSettingGeneral: builder.mutation<HttpResponse<{}>, SettingGeneralRequest>({
      query: (body) => ({
        url: "/setting/general",
        method: "PUT",
        body,
      }),
    }),
    settingTablet: builder.query<HttpResponse<SettingTabletModel>, void>({
      query: () => ({
        // url: "/setting/tabletmenu",
        url: `${process.env.REACT_APP_BASE_URL as string}/api/v2/web/setting/tabletmenu`,
        method: "GET",
      }),
    }),
    updateSettingTablet: builder.mutation<HttpResponse<{}>, SettingTabletModel>({
      query: (body) => ({
        // url: "/setting/tabletmenu",
        url: `${process.env.REACT_APP_BASE_URL as string}/api/v2/web/setting/tabletmenu`,
        method: "PUT",
        body,
      }),
    }),
    settingQR: builder.query<HttpResponse<SettingQRResponse>, void>({
      query: () => ({
        // url: "/setting/qrmenu",
        url: `${process.env.REACT_APP_BASE_URL as string}/api/v2/web/setting/qrmenu`,
        method: "GET",
      }),
    }),
    updateSettingQR: builder.mutation<HttpResponse<{}>, SettingQRRequest>({
      query: (body) => ({
        // url: "/setting/qrmenu",
        url: `${process.env.REACT_APP_BASE_URL as string}/api/v2/web/setting/qrmenu`,
        method: "PUT",
        body,
      }),
    }),
    settingAccount: builder.query<HttpResponse<SettingAccountResponse>, void>({
      query: () => ({
        url: "/setting/account",
        method: "GET",
      }),
    }),
    updateSettingAccount: builder.mutation<HttpResponse<{}>, SettingAccountRequest>({
      query: (body) => ({
        url: "/setting/account",
        method: "PUT",
        body,
      }),
    }),
    updatePassword: builder.mutation<HttpResponse<{}>, UpdatePasswordRequest>({
      query: (body) => ({
        url: "/setting/changePassword",
        method: "PUT",
        body,
      }),
    }),
    getBilling: builder.query<HttpResponse<BillingResponse>, void>({
      query: () => ({
        url: "/billing",
        method: "GET",
      }),
    }),
    getManageBilling: builder.query<HttpResponse<ManageBillingResponse>, void>({
      query: () => ({
        url: "/billing/manage",
        method: "GET",
      }),
    }),
    getInvoices: builder.query<HttpResponse<InvoiceModel[]>, { offset: number; limit: number }>({
      query: ({ offset, limit }) => ({
        url: `/billing/invoice?offset=${offset}&limit=${limit}`,
        method: "GET",
      }),
    }),
    getPlans: builder.query<HttpResponse<{}>, void>({
      query: () => ({
        url: "/billing/plan",
        method: "GET",
      }),
    }),
    getCards: builder.query<HttpResponse<CardModel[]>, void>({
      query: () => ({
        url: "/billing/mycard",
        method: "GET",
      }),
    }),
    addCard: builder.mutation<HttpResponse<{}>, CardRequestModel>({
      query: (body) => ({
        url: "/billing/mycard",
        method: "POST",
        body,
      }),
    }),
    reactive: builder.query<HttpResponse<{}>, void>({
      query: (body) => ({
        url: "/billing/reactive",
        method: "GET",
        body,
      }),
    }),
  }),
});

export const {
  useVenueIDQuery,
  useUpdateVenueMutation,
  useSettingGeneralQuery,
  useUpdateSettingGeneralMutation,
  useSettingTabletQuery,
  useUpdateSettingTabletMutation,
  useSettingQRQuery,
  useUpdateSettingQRMutation,
  useSettingAccountQuery,
  useUpdateSettingAccountMutation,
  useUpdatePasswordMutation,
  useGetBillingQuery,
  useGetManageBillingQuery,
  useGetInvoicesQuery,
  useGetPlansQuery,
  useGetCardsQuery,
  useAddCardMutation,
  useReactiveQuery,
} = settingServices;
