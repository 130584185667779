interface Props {
  steps: number
  activeStep: number
}

const stepClass = "relative w-8 h-8 rounded-full";
const completedStepClass = `${stepClass} bg-secondary text-white`;
const activeStepClass = `${stepClass} bg-primary text-white step-active`;
const inactiveStepClass = `${stepClass} bg-step-gray text-tertiary step-inactive`;

function Stepper({ steps, activeStep }: Props) {
  let guardedActiveStep = activeStep;
  if (activeStep > steps) guardedActiveStep = steps;
  if (activeStep < 1) guardedActiveStep = 1;

  const activeBarWidth = `${(1 / (steps - 1)) * 100}%`;

  const getStepClass = (index: number) => {
    const plusOne = index + 1;

    if (plusOne < guardedActiveStep) return completedStepClass;
    if (plusOne === guardedActiveStep) return activeStepClass;
    return inactiveStepClass;
  };

  return (
    <div className="flex justify-between w-full md:w-10/12 relative mx-auto">
      <div className="w-full h-1 bg-step-gray absolute top-1/2 transform -translate-y-2/4" />
      <div
        style={{
          width: activeBarWidth,
          transform: `scaleX(${guardedActiveStep - 1})`,
          transformOrigin: "0%",
        }}
        className="h-1 bg-secondary absolute top-1/2 transform -translate-y-2/4 transition-transform"
      />
      {Array(steps).fill(null).map((_, index) => (
        <div className={getStepClass(index)} key={index}>
          <p className="text-sm absolute top-1/2 left-1/2 transform -translate-x-2/4 -translate-y-2/4">
            {index + 1}
          </p>
        </div>
      ))}
    </div>
  );
}

export default Stepper;
