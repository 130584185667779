import { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { toast } from "react-toastify";

import { ContentHeader, LoadingAnimation, ModalConfirmation } from "../components";
import { useDebounce } from "../hooks";

import { ReactComponent as EditIcon } from "../assets/icons/ic_edit.svg";
import { ReactComponent as DeleteIcon } from "../assets/icons/ic_trash_bold.svg";
import { ReactComponent as IllustrationNoItems } from "../assets/illustration_no_items.svg";

import {
  useGetAllBannerQuery,
  useDeleteBannerMutation,
  useDeleteMultipleBannersMutation,
  usePublishBannerMutation,
} from "../services";

interface Confirmation {
  isOpen: boolean;
  data: string | null;
}

function BannerPage() {
  const ref = useRef(null);
  const { push } = useHistory();
  const [modalConfirmation, setModalConfirmation] = useState<Confirmation>({
    isOpen: false,
    data: null,
  });
  const [checklist, setChecklist] = useState<string[]>([]);
  const [keyword, setKeyword] = useState<string>("");
  const debouncedKeyword = useDebounce<string>(keyword, 500);

  const { data: dataBanner, isLoading: isLoadingBanners, refetch } = useGetAllBannerQuery();
  const [deleteBanner, { isLoading: isLoadingDeleteBanner }] = useDeleteBannerMutation();
  const [deleteMultipleBanners, { isLoading: isLoadingDeleteMultipleBanner }] = useDeleteMultipleBannersMutation();
  const [publishBanner, { isLoading: isLoadingPublishBanner }] = usePublishBannerMutation();

  /**
   * @description Handle checklist
   * @param id<string>
   */
  const handleChecklist = (id: string) => {
    if (checklist.find((checkedID) => checkedID === id)) {
      const removedChecklist = checklist.filter((checkedID) => checkedID !== id);
      setChecklist(removedChecklist);
    } else {
      setChecklist((prevState) => [...prevState, id]);
    }
  };

  /**
   * @description Handle search banner
   */
  const searchBanners = dataBanner?.data
    ? dataBanner.data.filter(({ name }) => name.toLowerCase().includes(debouncedKeyword.toLowerCase()))
    : [];

  /**
   * @description Handle toggle active banner
   * @param id<string>
   */
  const toggleActive = (id: string) => {
    const isPublish = dataBanner?.data?.find((banner) => banner.id === id)?.is_published ?? false;

    publishBanner(id)
      .unwrap()
      .then(() => {
        refetch();
        toast.success(`${isPublish ? "Unpublish" : "Publish"} banner is success`);
      })
      .catch(() => {
        toast.error(`${isPublish ? "Unpublish" : "Publish"} banner is failed`);
      });
  };

  /**
   * @description Handle delete banner
   * @param id
   */
  const handleDeleteBanner = (id: string) => {
    setModalConfirmation({ isOpen: true, data: id });
  };

  /**
   * @description Handle delete multiple banners
   */
  const handleDeleteMultipleBanners = () => {
    deleteMultipleBanners(checklist)
      .unwrap()
      .then(() => {
        refetch();
        toast.success("Successfully deleted selected banners");
      })
      .catch(() => {
        toast.error("Failed to delete selected banners");
      });
  };

  /**
   * @description callback confirmation to delete banner
   * @returns
   */
  const callbackConfirmation = () => {
    if (!modalConfirmation.data) return;

    deleteBanner(modalConfirmation.data)
      .unwrap()
      .then(() => {
        refetch();
        toast.success("Successfully delete banner");
      })
      .catch((error: any) => {
        toast.error(error.data?.message ?? "Failed to delete banner");
      })
      .finally(() => {
        setModalConfirmation({ isOpen: false, data: null });
      });
  };

  useEffect(() => {
    const progress = ref.current as any;

    if (isLoadingBanners || isLoadingPublishBanner || isLoadingDeleteBanner || isLoadingDeleteMultipleBanner) {
      progress?.continuousStart();
    } else {
      progress?.complete();
    }
  }, [isLoadingBanners, isLoadingPublishBanner, isLoadingDeleteBanner || isLoadingDeleteMultipleBanner]);

  const banner = dataBanner?.data ?? [];

  if (!dataBanner) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <LoadingAnimation />
      </div>
    );
  }

  return (
    <>
      <LoadingBar height={4} color="#0078D3" ref={ref} />
      <div className="flex-1">
        <ContentHeader
          title="Banner"
          subtitle="On this page you can manage your own banner"
          buttonTitle="New Banner"
          onButtonClick={() => push("/banner")}
          onSearch={setKeyword}
          value={keyword}
        />

        <div className="mx-4">
          {/* delete button */}
          {banner.length > 0 && checklist.length > 0 && (
            <div className="flex mt-4">
              <button
                type="button"
                title="Delete Selected"
                className="button-error text-sm py-2 px-4 font-normal rounded"
                onClick={handleDeleteMultipleBanners}
                disabled={isLoadingDeleteBanner || isLoadingDeleteMultipleBanner}
              >
                Delete Selected
              </button>
            </div>
          )}

          <div className="hidden md:flex w-full p-4 bg-gray-100 font-medium items-center mt-4">
            <div className="pr-4">
              <label className="flex items-center space-x-3">
                <input
                  type="checkbox"
                  name="checked-demo"
                  className="form-tick appearance-none bg-white bg-check h-4 w-4 border border-gray-300 rounded checked:bg-primary checked:border-transparent focus:outline-none cursor-pointer"
                  onChange={() => {
                    if (banner.length === checklist.length) {
                      setChecklist([]);
                    } else {
                      setChecklist(banner.map(({ id }) => id));
                    }
                  }}
                  checked={banner.length > 0 && checklist.length === banner.length}
                />
              </label>
            </div>
            <p className="pl-4 border-l flex-1">Name</p>
            <p className="pl-4 border-l flex-1">Description</p>
            <p className="px-4 border-l">Publish</p>
            <p className="px-4 border-l w-44">Action</p>
          </div>

          {searchBanners.length > 0 ? (
            searchBanners.map((searchedBanner) => {
              const isChecked = !!checklist.find((checkedID) => checkedID === searchedBanner.id);

              return (
                <div
                  className="flex w-full text-sm p-4 md:border-b md:border-gray-100 rounded-md md:rounded-none shadow-lg md:shadow-none mb-3 mt-4 md:mt-0 md:mb-0 items-center"
                  key={searchedBanner.id}
                >
                  <div className="pr-4 hidden md:block">
                    <label className="flex items-center space-x-3">
                      <input
                        key={isChecked ? "1" : "0"} // hack to force update
                        type="checkbox"
                        name="checked-banner"
                        className="form-tick appearance-none bg-white bg-check h-4 w-4 border border-gray-300 rounded checked:bg-primary checked:border-transparent focus:outline-none cursor-pointer"
                        onChange={() => handleChecklist(searchedBanner.id)}
                        checked={isChecked}
                      />
                    </label>
                  </div>
                  <div className="flex flex-row gap-4 w-full">
                    <div className="flex flex-col flex-1 gap-2 md:flex-row">
                      <div className="md:pl-4 flex-1">{searchedBanner.name}</div>
                      <div className="md:pl-4 flex-1">{searchedBanner.desc}</div>
                    </div>
                    <div className="flex flex-col md:flex-row flex-1 md:flex-none items-end md:items-start gap-2 md:gap-0 md:mr-8">
                      <div className="md:pl-4">
                        <div className="relative inline-block w-8 align-middle text-center select-none">
                          <input
                            type="checkbox"
                            id={`toggle-${searchedBanner.id}`}
                            name={`toggle-${searchedBanner.id}`}
                            className="toggle-checkbox absolute block w-4 h-4 rounded-full bg-white border-2 appearance-none transition-all duration-300 ease-in cursor-pointer"
                            onChange={() => toggleActive(searchedBanner.id)}
                            checked={searchedBanner.is_published}
                            disabled={isLoadingDeleteBanner
                              || isLoadingDeleteMultipleBanner
                              || isLoadingBanners
                              || isLoadingPublishBanner}
                          />
                          <label
                            htmlFor={`toggle-${searchedBanner.id}`}
                            className="toggle-label block overflow-hidden h-4 rounded-full bg-gray-300 transition-all duration-300 ease-in cursor-pointer"
                          />
                        </div>
                      </div>
                      <div className="md:pl-4 flex justify-end gap-4 w-44">
                        <button
                          type="button"
                          title="Edit"
                          className="flex items-center gap-2"
                          onClick={() => push({
                            pathname: "/banner",
                            state: { banner_id: searchedBanner.id },
                          })}
                        >
                          <EditIcon className="h-4 w-4" />
                          {" "}
                          <span className="hidden md:block">Edit</span>
                        </button>

                        <button
                          type="button"
                          title="Delete"
                          className="flex items-center gap-2"
                          onClick={() => handleDeleteBanner(searchedBanner.id)}
                        >
                          <DeleteIcon className="h-4 w-4" />
                          {" "}
                          <span className="hidden md:block">Delete</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="h-full w-full mt-16 flex-1 flex flex-col gap-1 justify-center items-center">
              <IllustrationNoItems className="h-64 w-64 mb-4" />

              <p className="text-lg">
                There&#39;s no
                {" "}
                {debouncedKeyword.length > 0 ? debouncedKeyword : "banner"}
              </p>
              <p className="text-secondary">
                You haven&#39;t add any
                {" "}
                {debouncedKeyword.length > 0 ? `${debouncedKeyword} in banner` : "banner"}
              </p>
            </div>
          )}
        </div>

        <ModalConfirmation
          isOpen={modalConfirmation.isOpen}
          content="Are you sure?"
          onCloseModal={() => setModalConfirmation({ isOpen: false, data: null })}
          onConfirmModal={callbackConfirmation}
          confirmationText="Delete"
          confirmationLoading={isLoadingDeleteBanner || isLoadingDeleteMultipleBanner}
        />
      </div>
    </>
  );
}

export default BannerPage;
