import {
  addDays, format, subDays, eachDayOfInterval, parse,
} from "date-fns";
import {
  Suspense,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Calendar from "react-calendar";
import LoadingBar from "react-top-loading-bar";
import Popup from "reactjs-popup";
import type { PopupActions } from "reactjs-popup/dist/types";
import clsx from "clsx";

import { ReactComponent as CalendarIcon } from "../assets/icons/ic_calendar_new.svg";
import { ReactComponent as TotalCategoriesIcon } from "../assets/icons/reports-icons/total_categories.svg";
import { ReactComponent as TotalItemIcon } from "../assets/icons/reports-icons/total_items.svg";
import { ReactComponent as TotalOrdersIcon } from "../assets/icons/reports-icons/total_orders.svg";
import { ReactComponent as TotalRevenueIcon } from "../assets/icons/reports-icons/total_revenue.svg";

import { LoadingAnimation, ReportCounter } from "../components";
import { PaymentMethodChart, RevenueAreaChart } from "../components/charts";
import { Revenue, itemServices, useGetAnalyticQuery } from "../services";
import { ORDER_TYPE_LIST_TEXT } from "../constants";

// const RevenueAreaChart = lazy(() => import("../components/charts/revenue-chart"));

// type RevenueChartOptionsValueType = "monthly" | "weekly" | "daily";
// type RevenueChartOptionsType = {
//   Icon: any;
//   name: string;
//   value: RevenueChartOptionsValueType;
// }

const formatDate = (date: Date, type = "dd MMM yyyy") => format(date, type);
// const revenueChartOptions: RevenueChartOptionsType[] = [
//   {
//     Icon: CalendarIcon,
//     name: "Day",
//     value: "daily",
//   },
//   {
//     Icon: CalendarIcon,
//     name: "Week",
//     value: "weekly",
//   },
//   {
//     Icon: CalendarIcon,
//     name: "Month",
//     value: "monthly",
//   },
// ];

// const revenueNames = [
//   {
//     id: "order_dinein_tablet",
//     name: "Dine-in-Tablet",
//   },
//   {
//     id: "order_dinein_qrmobile",
//     name: "Dine-in-Mobile",
//   },
//   {
//     id: "order_pickup",
//     name: "Pick-up",
//   },
//   {
//     id: "order_delivery",
//     name: "Delivery",
//   },
//   {
//     id: "order_drivetru",
//     name: "Drive-through",
//   },
// ];

function ReportsPage() {
  const today = new Date();
  const skipRefetchOnFirstMount = useRef(false);

  const loadingBarRef = useRef(null);
  const calendarRef = useRef<PopupActions | null>(null);

  const [openDatePicker, setOpenDatePicker] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState([subDays(today, 7), today]);
  const [okDateRange, setOkDateRange] = useState([subDays(today, 7), today]);
  const [revenues, setRevenues] = useState<{
    name: string;
    data: number[]
  }[]>([]);
  const [revenueChartXAxis, setRevenueChartXAxis] = useState<string[]>([]);
  const [paymentMethods, setPaymentMethods] = useState<{
    id: string;
    value: number;
  }[]>([]);
  // const [revenueDisplay, setRevenueDisplay] = useState<RevenueChartOptionsValueType>("monthly");

  const { data, refetch, isLoading } = useGetAnalyticQuery({
    from: format(okDateRange[0], "yMMdd"),
    to: format(okDateRange[1], "yMMdd"),
  });

  const reportData = useMemo(() => {
    const responseData = { ...(data?.data || {}) };

    return responseData;
  }, [data]);

  const maxWidth = "w-full";
  const sectionHeight = "min-h-[325px]";
  // const maxWidth = "w-full xl:max-w-[1440px] mx-auto";
  const selectedDates = okDateRange?.map((date, index) => {
    if (index === 0) {
      return formatDate(date, "dd MMM");
    }

    return formatDate(date);
  }).join(" - ");

  /**
   * Get rnadom date in range
   * @param {String} startDate
   * @param {String} endDate
   * @returns {Date} random date
   */
  const getRandomDateInRange = (startDate: string, endDate: string): Date => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const randomTime = start.getTime() + Math.random() * (end.getTime() - start.getTime());
    return new Date(randomTime);
  };

  /**
   * Generate random sorted dates
   * @param {Number} numOfRandomDates
   * @returns {String[]}
   */
  const generateSortedRandomDates = (numOfRandomDates = 7): string[] => {
    const rawDates = new Set();
    const start = okDateRange[0].toISOString();
    const end = okDateRange[1].toISOString();

    rawDates.add(new Date(start));
    rawDates.add(new Date(end));

    for (let i = 0; i < numOfRandomDates; i += 1) {
      const randomDate = getRandomDateInRange(start, end);

      rawDates.add(randomDate);
    }

    const sortedDates = [...rawDates].sort((a: any, b: any) => a - b);

    const dates = sortedDates
      .map((date) => format(date as Date, "yyyy-MM-dd"))
      .filter((value, index, self) => self.indexOf(value) === index);

    return dates;
  };

  /**
   * Generate sequential dates
   * @param {Number} limit
   * @returns {String[]} Dates
   */
  // const generateSequentialDates = (limit = 7): string[] => {
  //   const startDate = new Date(okDateRange[0].toISOString()); // Tanggal saat ini
  //   const endDate = new Date(okDateRange[1].toISOString());
  //   const dates = [];

  //   for (let i = 0; i < limit; i += 1) {
  //     const currentDate = addDays(startDate, i);
  //     dates.push(format(currentDate, "yyyy-MM-dd"));
  //   }

  //   return dates;
  // };

  /**
   * Find Max Number from an Array
   * @param {Number[]} arr Array of number
   * @returns {Number} max number
   */
  const findMax = (arr: number[]): number => {
    if (arr.length <= 0) return 10;

    let max = arr[0];

    for (let i = 0; i < arr.length; i += 1) {
      if (max < arr[i]) {
        max = arr[i];
      }
    }

    return max;
  };

  /**
   * Calculate percentage
   * @param {Number[]} numbers Array of number
   * @return {Number[]} Array of percent
   */
  const calculatePercentage = (numbers: number[]): number[] => {
    const sum = numbers.reduce((a, b) => a + b, 0);
    const percentages = numbers.map((value) => Number.parseFloat(((value / sum) * 100).toFixed(2)));

    return percentages;
  };

  /**
   * Convert string date to Date
   * @param {String} dateString
   * @returns {Date}
   */
  const convertStringToDate = (dateString: string): Date => {
    const [year, month, day] = dateString.split("-");
    const date = new Date(
      parseInt(year, 10),
      parseInt(month, 10) - 1,
      parseInt(day, 10),
    );

    return date;
  };

  // useEffect(() => {
  //   if (reportData?.revenues) {
  //     let datesLength = 0;

  //     const revenueData = reportData?.revenues?.map(({ order_type_id, dates }) => {
  //       const name = revenueNames.find(
  //         ({ id }) => id === order_type_id,
  //       )?.name || order_type_id;

  //       return {
  //         name,
  //         data: dates.length > 0 ? dates.map(({ value }) => {
  //           if (typeof value === "undefined") return 0;

  //           datesLength = dates.length;
  //           return value;
  //         }) : Array(datesLength || 7).fill(0),
  //       };
  //     });

  //     let availableDates: typeof revenueChartXAxis = [];

  //     for (let i = 0; i < reportData?.revenues[i].dates.length; i += 1) {
  //       if (reportData?.revenues[i].dates.length !== 0) {
  //         availableDates = [...(reportData?.revenues[i].dates.map(({ date }) => date) || [])];
  //       }
  //     }

  //     if (availableDates.length === 0) {
  //       // availableDates = [...generateSequentialDates(datesLength || 8)];
  //       availableDates = [...generateSortedRandomDates()];
  //     }

  //     setRevenues(revenueData);
  //     setRevenueChartXAxis(availableDates);
  //   }
  // }, [reportData?.revenues]);

  useEffect(() => {
    if (reportData?.revenues) {
      const tempRevenue: typeof revenues = [];
      const availableDates = new Set();

      for (let i = 0; i < reportData?.revenues.length; i += 1) {
        for (let j = 0; j < reportData.revenues[i].dates.length; j += 1) {
          const { date } = reportData.revenues[i].dates[j];

          availableDates.add(date);
        }
      }

      if ([...availableDates].length === 0) {
        generateSortedRandomDates(5).forEach((date) => availableDates.add(date));
      }

      for (let i = 0; i < reportData.revenues.length; i += 1) {
        const { order_type_id } = reportData.revenues[i];
        // const name = revenueNames.find(
        //   ({ id }) => id === order_type_id,
        // )?.name || order_type_id;
        const name = ORDER_TYPE_LIST_TEXT[order_type_id as keyof typeof ORDER_TYPE_LIST_TEXT] || order_type_id;

        tempRevenue.push({ name, data: Array([...availableDates].length).fill(null) });
      }

      for (let i = 0; i < reportData.revenues.length; i += 1) {
        const { dates } = reportData.revenues[i];

        for (let j = 0; j < dates.length; j += 1) {
          const { date, value } = dates[j];
          const dataIndex = [...availableDates].findIndex((item) => item === date);

          if (dataIndex >= 0) {
            tempRevenue[i].data.splice(dataIndex, 1, value);
          }
        }
      }

      setRevenues(tempRevenue);
      setRevenueChartXAxis([...availableDates] as string[]);
    }
  }, [reportData?.revenues]);

  useEffect(() => {
    if (reportData?.payment_methods) {
      setPaymentMethods(reportData.payment_methods.map(({ id, value }) => ({
        id,
        value: parseInt(value, 10),
      })).reverse());
    }
  }, [reportData?.payment_methods]);

  useEffect(() => {
    if (!skipRefetchOnFirstMount.current) {
      skipRefetchOnFirstMount.current = true;
      return;
    }

    refetch();
  }, [okDateRange]);

  useEffect(() => {
    const progress = loadingBarRef.current as any;

    if (isLoading) {
      progress?.continuousStart();
    } else {
      progress?.complete();
    }
  }, [isLoading]);

  if (!data) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <LoadingAnimation />
      </div>
    );
  }

  return (
    <>
      <LoadingBar height={4} color="#0078D3" ref={loadingBarRef} />

      <header className={clsx(
        "flex flex-col md:flex-row md:justify-between md:items-center",
        "p-6 pb-0",
        maxWidth,
      )}
      >
        <div>
          <h2 className={clsx(
            "font-bold lg:font-semibold text-2xl lg:text-[26px] lg:leading-[33px]",
            "pr-4 md:mb-1",
            "overflow-hidden overflow-ellipsis whitespace-nowrap",
          )}
          >
            Reports
          </h2>

          <span style={{ color: "#8083A3" }}>Here you can see all reports</span>
        </div>

        <div className={clsx(
          "max-w-[175px] rounded-lg h-12 bg-[#F8F8F8] overflow-hidden hidden md:flex",
          openDatePicker ? "" : "hover:bg-gray-100",
        )}
        >
          <div className="flex items-center pl-3">
            <CalendarIcon className="w-4 h-4" color="#666666" />
          </div>

          <Popup
            ref={calendarRef}
            position="bottom right"
            contentStyle={{
              width: "auto",
              maxWidth: 560,
              background: "white",
            }}
            closeOnDocumentClick
            closeOnEscape
            open={openDatePicker}
            onOpen={() => setDateRange(dateRange)}
            onClose={() => {
              setOpenDatePicker(false);
              setOkDateRange(dateRange);
              refetch();
            }}
            trigger={(
              <input
                type="text"
                className="w-full pl-2 text-xs font-bold bg-transparent"
                value={`${dateRange?.map((date, index) => {
                  if (index === 0) {
                    return formatDate(date, "dd MMM");
                  }

                  return formatDate(date);
                }).join(" - ")}`}
                readOnly
              />
            )}
          >
            <Calendar
              selectRange
              showDoubleView
              onChange={setDateRange}
              returnValue="range"
              defaultValue={dateRange}
            />
            <div className="flex justify-end w-full">
              <button
                title="Ok"
                type="button"
                className="self-end px-3 py-1 my-1 mr-1 text-sm border rounded"
                onClick={() => {
                  setOkDateRange(dateRange);
                  calendarRef.current?.close();
                }}
              >
                Ok
              </button>
            </div>
          </Popup>
        </div>
      </header>

      {/* <div className={clsx("p-6", "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3", maxWidth)}>
        <div className={clsx("border rounded-xl", "p-6", "flex flex-row items-center gap-4")}>
          <div>
            <TotalCategoriesIcon className="w-[45px] h-[45px]" />
          </div>
          <div>
            <p className="font-bold font-inter">
              {reportData?.category_total || 0}
              {" "}
              categories
            </p>
            <small className="text-sm" style={{ color: "#8083A3" }}>Total categories</small>
          </div>
        </div>

        <div className={clsx("border rounded-xl", "p-6", "flex flex-row items-center gap-4")}>
          <div>
            <TotalItemIcon className="w-[45px] h-[45px]" />
          </div>
          <div>
            <p className="font-bold font-inter">
              {reportData?.item_total || 0}
              {" "}
              items
            </p>
            <small className="text-sm" style={{ color: "#8083A3" }}>Total items</small>
          </div>
        </div>

        <div className={clsx("border rounded-xl", "p-6", "flex flex-row items-center gap-4")}>
          <div>
            <TotalOrdersIcon className="w-[45px] h-[45px]" />
          </div>
          <div>
            <p className="font-bold font-inter">
              {reportData?.order_total || 0}
              {" "}
              orders
            </p>
            <small className="text-sm" style={{ color: "#8083A3" }}>Total orders</small>
          </div>
        </div>

        <div className={clsx("border rounded-xl", "p-6", "flex flex-row items-center gap-4")}>
          <div>
            <TotalRevenueIcon className="w-[45px] h-[45px]" />
          </div>
          <div>
            <p className="font-bold font-inter">
              USD
              {" "}
              {reportData?.revenue_total || 0}
            </p>
            <small className="text-sm" style={{ color: "#8083A3" }}>Total revenue</small>
          </div>
        </div>
      </div> */}
      <ReportCounter
        categoryTotal={reportData?.category_total || 0}
        itemTotal={reportData?.item_total || 0}
        orderTotal={reportData?.order_total || 0}
        revenueTotal={reportData?.revenue_total || 0}
      />

      <div className={clsx("flex flex-col lg:flex-row lg:items-stretch gap-4", "p-6 pt-0", "w-full", maxWidth)}>
        {/*  */}
        <div id="total-orders" className={clsx("w-full", "border rounded-2xl")}>
          <div className={clsx("flex flex-row items-stretch justify-between", "py-0 pl-5 pr-5", "w-full", "border-b")}>
            <span className="my-4 font-bold">Revenue</span>

            <div className={clsx("flex flex-row items-center gap-2", "text-black font-semibold")}>
              <CalendarIcon className="w-4 h-4" />

              <span className="text-xs">
                {selectedDates}
              </span>
            </div>
            {/* <div className={clsx("flex flex-row items-stretch justify-end gap-2 md:gap-4", "text-black font-semibold")}>
              {revenueChartOptions.map(({ Icon, name, value }) => {
                const isSelected = value === revenueDisplay;

                return (
                  <button
                    type="button"
                    key={name}
                    onClick={() => setRevenueDisplay(value)}
                    className={clsx(
                      "flex flex-row items-center justify-center gap-1",
                      "text-[11px] relative px-1",
                      isSelected ? "text-[#007AFF] border-b-[#007AFF]" : "text-[#8083A3]",
                    )}
                  >
                    <Icon className={clsx("h-4 w-4", isSelected ? "text-[#007AFF]" : "text-[#8083A3]")} />
                    <span>{name}</span>

                    {isSelected && (
                      <div className={clsx("h-0.5 w-full", "absolute -bottom-[0.5px] left-0 z-0", isSelected ? "bg-[#007AFF]" : "bg-[#8083A3]")} />
                    )}
                  </button>
                );
              })}
            </div> */}
          </div>

          <div className={clsx("w-full pt-4 overflow-y-hidden relative", sectionHeight)}>
            <div className="grid w-full grid-cols-1 px-4">
              {/* <div className="grid w-full grid-cols-1"> */}
              <Suspense fallback={<div>Loading chart...</div>}>
                <RevenueAreaChart
                  series={revenues}
                  chartOptions={{
                    xaxis: {
                      categories: [...(revenueChartXAxis || [])].map((date) => {
                        // const formattedDate = format(convertStringToDate(date), "dd MMM yyyy");
                        const formattedDate = format(convertStringToDate(date), "MMM dd");

                        return formattedDate;
                      }),
                    },
                  }}
                />
              </Suspense>
            </div>
          </div>
        </div>

        <div
          className={clsx(
            "border rounded-2xl",
            // "w-full lg:w-1/3",
            "w-full h-full lg:max-w-sm",
          )}
        >
          <div className={clsx("flex flex-row items-center justify-between", "py-4 px-5", "w-full", "border-b")}>
            <span className="font-bold">Payment methods</span>

            <div className={clsx("flex flex-row items-center gap-2", "text-black font-semibold")}>
              <CalendarIcon className="w-4 h-4" />

              <span className="text-xs">
                {selectedDates}
              </span>
            </div>
          </div>

          {/* <div className={clsx("flex flex-col items-center justify-center p-6 relative h-4/5", sectionHeight)}> */}
          <div className={clsx("flex flex-col items-center justify-center p-6 relative", sectionHeight)}>
            <div className="absolute z-10 text-center -translate-y-4">

              {(() => {
                if (paymentMethods.length === 0) return "";

                const percentages = calculatePercentage(
                  paymentMethods.map(({ value }) => value),
                ).map((value, idx) => ({
                  value: value || 0,
                  id: paymentMethods[idx].id,
                  total: paymentMethods[idx].value,
                }));

                let max = percentages[0];

                for (let i = 0; i < percentages.length; i += 1) {
                  if (percentages[i].value > max.value) {
                    max = percentages[i];
                  }
                }

                return (
                  <>
                    <span className="block text-2xl font-bold text-primary">
                      $
                      {max?.total || 0}
                    </span>
                    <span className="max-w-[95px] block text-[10px] leading-tight text-[#8083A3]">
                      {`${max?.value || 0}% ${max?.id ? (
                        max.id === "pay_cash" ? "Cash" : "Credit Card"
                      ) : ""}`}
                    </span>
                  </>
                );
              })()}

            </div>

            {/* Donut chart */}
            <PaymentMethodChart
              data={paymentMethods.map(({ value }) => value)}
              chartOptions={{
                colors: paymentMethods?.map(({ id }) => {
                  if (id === "pay_credit") return "#007AFF";
                  // return "#c9dbfd";
                  return "rgba(62, 126, 255, 0.44)";
                }),
                labels: paymentMethods?.map(({ id }) => (id === "pay_credit" ? "Credit Card" : "Cash")),
                legend: {
                  position: "bottom",
                  itemMargin: {
                    horizontal: 10,
                  },
                  fontFamily: "Inter",
                  show: true,
                },
              }}
            />
          </div>
        </div>
      </div>

      <div className={clsx("flex flex-col lg:flex-row lg:items-stretch gap-4", "p-6 pt-0", maxWidth)}>
        {/* Total orders */}
        <div id="total-orders" className={clsx("w-full", "border rounded-2xl")}>
          <div className={clsx("flex flex-row items-center justify-between", "py-4 px-5", "w-full", "border-b")}>
            <span className="font-bold">Total orders</span>

            <div className={clsx("flex flex-row items-center gap-2", "text-black font-semibold")}>
              <CalendarIcon className="w-4 h-4" />

              <span className="text-xs">
                {selectedDates}
              </span>
            </div>
          </div>

          <div className={clsx("flex flex-col items-center justify-center p-6 gap-6", sectionHeight)}>
            {reportData?.order_types && reportData?.order_types?.map(({ id, value }, index) => {
              const key = index.toString();
              const max = findMax((reportData.order_types?.map(({ value: orderValue }) => orderValue) || []));
              // const name = revenueNames.find(
              //   ({ id: revenueNameId }) => revenueNameId === id,
              // )?.name || "";
              const name = ORDER_TYPE_LIST_TEXT[id as keyof typeof ORDER_TYPE_LIST_TEXT] || "";

              return (
                <div className="flex flex-col w-full gap-3" key={key}>
                  <div className="flex flex-row items-center justify-between text-sm">
                    <span>{name}</span>
                    <span className="font-bold">
                      {value}
                      {" "}
                      orders
                    </span>
                  </div>

                  <progress className="h-1 progress-bar" value={value} max={max} />
                </div>
              );
            })}
          </div>
        </div>

        {/* Top view items */}
        <div
          className={clsx(
            "border rounded-2xl",
            // "w-full lg:w-1/3",
            "w-full lg:max-w-sm",
          )}
        >
          <div className={clsx("flex flex-row items-center justify-between", "py-4 px-5", "w-full", "border-b")}>
            <span className="font-bold">Top view items</span>

            <div className={clsx("flex flex-row items-center gap-2", "text-black font-semibold")}>
              <CalendarIcon className="w-4 h-4" />

              <span className="text-xs">
                {selectedDates}
              </span>
            </div>
          </div>

          <div className={clsx("py-4 px-5", "flex flex-col gap-4", "overflow-y-auto", "h-[350px]")}>
            {/* <div className={clsx("py-4 px-5", "flex flex-col gap-4", "overflow-y-auto", sectionHeight)}> */}

            {reportData?.top_items?.map(({ image, name, value }, index) => {
              const key = index.toString();
              const itemImage = image || "https://www.pulsecarshalton.co.uk/wp-content/uploads/2016/08/jk-placeholder-image.jpg";

              if (typeof name === "object") {
                return (
                  <div className="flex flex-row items-center justify-between w-full gap-4" key={key}>
                    <div className="min-w-[44px]">
                      <img src={itemImage} alt={name.name} className="w-11 h-11 rounded-[4.5px] object-cover" />
                    </div>
                    <p className="w-full">{name.name}</p>
                    <small className="text-sm font-semibold whitespace-nowrap">
                      {value}
                      {" "}
                      views
                    </small>
                  </div>
                );
              }

              return (
                <div className="flex flex-row items-center justify-between w-full gap-4" key={key}>
                  <div className="min-w-[44px]">
                    <img src={itemImage} alt={name} className="w-11 h-11 rounded-[4.5px] object-cover" />
                  </div>
                  <p className="w-full">{name}</p>
                  <small className="text-sm font-semibold whitespace-nowrap">
                    {value}
                    {" "}
                    views
                  </small>
                </div>
              );
            })}

          </div>
        </div>
      </div>
    </>
  );
}

export default ReportsPage;
