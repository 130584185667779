import { createApi } from "@reduxjs/toolkit/query/react";

import baseQuery from "./base-service";

export interface BannerModel {
  id: string;
  name: string;
  desc: string;
  is_published: boolean;
}

export interface AddBannerRequest {
  name: string;
  desc: string;
  image: string;
  is_published: boolean;
}

export const bannerServices = createApi({
  reducerPath: "banner",
  baseQuery,
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  endpoints: (builder) => ({
    getAllBanner: builder.query<HttpResponse<BannerModel[]>, void>({
      query: () => ({
        url: "/banner",
        method: "GET",
      }),
    }),
    getBanner: builder.query<HttpResponse<BannerModel & {image: string;}>, string>({
      query: (id) => ({
        url: `/banner/single/${id}`,
        method: "GET",
      }),
    }),
    postBanner: builder.mutation<HttpResponse<{}>, AddBannerRequest>({
      query: (body) => ({
        url: "/banner",
        method: "POST",
        body,
      }),
    }),
    updateBanner: builder.mutation<HttpResponse<{}>, BannerModel>({
      query: (body) => ({
        url: "/banner",
        method: "PUT",
        body,
      }),
    }),
    updateBannerImage: builder.mutation<HttpResponse<{}>, {id: string; image: string | null;}>({
      query: (body) => ({
        url: "/banner/image",
        method: "PUT",
        body,
      }),
    }),
    publishBanner: builder.mutation<HttpResponse<{}>, string>({
      query: (id) => ({
        url: "/banner/publish",
        method: "PUT",
        body: { id },
      }),
    }),
    deleteBanner: builder.mutation<HttpResponse<{}>, string>({
      query: (id) => ({
        url: "/banner",
        method: "DELETE",
        body: { id },
      }),
    }),
    deleteMultipleBanners: builder.mutation<HttpResponse<{}>, string[]>({
      query: (id_array) => ({
        url: "/banner/multiple",
        method: "DELETE",
        body: { id_array },
      }),
    }),
  }),
});

export const {
  useGetAllBannerQuery,
  useGetBannerQuery,
  usePostBannerMutation,
  useUpdateBannerMutation,
  useUpdateBannerImageMutation,
  usePublishBannerMutation,
  useDeleteBannerMutation,
  useDeleteMultipleBannersMutation,
} = bannerServices;
