import { initializeApp } from "firebase/app";
import {
  deleteToken,
  getMessaging,
  getToken,
} from "firebase/messaging";

import { LocalStorage } from "../../utils";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};
const isDevelopment = process.env.NODE_ENV !== "production";
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export async function requestNotificationPermission() {
  try {
    if (!("Notification" in window)) return false;

    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      if (isDevelopment) console.log("Notification permission granted.");
      return true;
    }

    if (isDevelopment) console.error("Notification permission denied.");
    return false;
  } catch (error) {
    console.error("Error requesting permission:", error);
    return false;
  }
}

export const registerFCMServiceWorker = async () => {
  if ("serviceWorker" in navigator) {
    const reqNotification = await requestNotificationPermission();

    if (reqNotification) {
      // Register service worker and get token
      await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_APP_KEY,
      });
    } else {
      console.log("No notification permission granted!");
    }
  } else {
    console.log("Service Worker is not supported in this browser.");
  }
};

export async function getFCMToken() {
  let fcmToken = LocalStorage.getValue("fcmToken");

  try {
    fcmToken = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_APP_KEY,
    });
  } catch (error) {
    console.error("An error occurred while retrieving token:", error);
  }

  if (isDevelopment) console.log({ FCMToken: fcmToken });

  return fcmToken;
}

export const setupMessaging = async () => {
  await Promise.all([
    await requestNotificationPermission(),
    await registerFCMServiceWorker(),
  ]);
};

export const deleteFCMToken = async () => {
  try {
    if (!("serviceWorker" in navigator)) return;
    await deleteToken(messaging)
      .then(async () => {
        if (isDevelopment) console.log("FCM Token Deleted");
      })
      .finally(async () => {
        await setupMessaging();
      });
  } catch (error) {
    console.error("An error occurred while deleting token:", error);
  }
};

export default messaging;

// export const showNotification = (theNotification: MessagePayload) => {
//   /**
//    * {
//    *   ...theNotification,
//    *   data: {
//    *     order_id: "0001",
//    *     table: "Table 1",
//    *     payment_method: "Cash", // or "Credit Card"
//    *     order_type: "order_dinein_tablet", // see: https://documenter.getpostman.com/view/13233230/UUy7cQka#dcdf5a8e-5d19-41b8-bff2-16b48c61d986
//    *   },
//    * }
//    */
//   const { data } = theNotification;
//   const isDineIn = data?.order_type?.includes("order_dinein");
//   const orderType = ORDER_TYPE_LIST_TEXT[data?.order_type as keyof typeof ORDER_TYPE_LIST_TEXT] || "";
//   // Notification title & notification body
//   const title = isDineIn ? "Checkout Request!" : "New Order Received!";
//   const body = isDineIn ? "A checkout request received." : `You have a new order #${data?.order_id || 0} ${orderType ? `for ${orderType}.` : ""}.`;

//   registerFCMServiceWorker()
//     .then((registration) => {
//       setTimeout(() => {
//         if (registration) {
//           registration
//             .showNotification(title, {
//               body,
//               icon: theNotification.notification?.image || "https://cloudmenu-dashboard.fusionsgeeks.com/logo.png",
//               data,
//             })
//             .catch((error) => {
//               console.log("Service Worker Failed to Send", error);
//             });

//           registration.update();
//         }
//       }, 100);
//     });
// };
