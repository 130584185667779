/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  useState, useEffect, useRef, Fragment,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingBar from "react-top-loading-bar";

import { ReactComponent as ChevronDownIcon } from "../assets/icons/ic_chevron_down.svg";
import { ReactComponent as GlobeIcon } from "../assets/icons/ic_globe.svg";
import { ReactComponent as SpinnerIcon } from "../assets/icons/ic_spinner.svg";

import {
  useGetAllModifierLocalQuery,
  useUpdateModifierLocalMutation,
  useToggleActiveModifierLocalMutation,
  ModifierLocalizeModifier,
  ModifierLocalizeDatas,
} from "../services";

function ModifierLocalizePage() {
  const [form, setForm] = useState<ModifierLocalizeModifier[]>();
  const { state } = useLocation<StateModifierID>();
  const ref = useRef(null);

  const { data: dataLocalize, refetch, isLoading } = useGetAllModifierLocalQuery();
  const [updateModifier, { isLoading: isLoadingUpdateModifier }] = useUpdateModifierLocalMutation();
  const [toggleActiveLocalize, { isLoading: isLoadingActivatingLocalize }] = useToggleActiveModifierLocalMutation();
  const [isFormShow, setFormShow] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<{
    id: string;
    data: ModifierLocalizeDatas | null;
  }>({
    id: "",
    data: null,
  });

  const { goBack } = useHistory();

  /**
   * @description Function to handle toggle active modifier
   * @param id<string>
   * @param language_id<string>
   */
  const toggleActive = (id: string, language_id: string) => {
    toggleActiveLocalize({ id, language_id })
      .unwrap()
      .then((res) => {
        if (res.status === "Success") {
          toast.success("Success to toggle language");
          refetch();
        }
      })
      .catch(() => {
        toast.error("Failed to toggle language");
      });
  };

  /**
   * @description Function to handle change form
   * @param e<any>
   * @param lang<string>
   * @param name<string>
   * @param id?<string>
   */
  const handleChange = (e: any, lang: string, name: keyof ModifierLocalizeModifier, id?: string) => {
    const newForm = form?.map((dataForm) => {
      if (dataForm.lang_id === lang) {
        if (name === "is_show") {
          toggleActive(selectedItem.id, lang);

          return {
            ...dataForm,
            is_show: e.target.checked,
          };
        }

        if (id !== undefined && name === "modifier_contents" && dataForm.modifier_contents) {
          const selectedContent = dataForm.modifier_contents.find((content) => content.id === id);
          if (selectedContent) {
            const newContent = {
              ...selectedContent,
              text: e.target.value,
            };

            return {
              ...dataForm,
              modifier_contents: dataForm.modifier_contents.map((content) => {
                if (content.id === id) return newContent;
                return content;
              }),
            };
          }
        }
      }

      return dataForm;
    });

    setForm(newForm);
  };

  /**
   * @description Function to handle save updated modifier
   */
  const handleSave = () => {
    if (!(form && state.modifier_id)) return;

    updateModifier({ id: selectedItem.data?.id || state.modifier_id, data: form })
      .unwrap()
      .then((res) => {
        if (res.status === "Success") {
          refetch();
          toast.success("Success to update menu");
        }
      })
      .catch(() => {
        toast.error("Failed to update menu");
      });
  };

  useEffect(() => {
    if (selectedItem.data) {
      setForm(selectedItem.data.modifier);
    }
  }, [selectedItem.data]);

  useEffect(() => {
    if (selectedItem.id) return;

    if (state.modifier_id && dataLocalize?.data?.datas) {
      const findData = dataLocalize.data.datas.find(({ id }) => id === state.modifier_id);
      if (findData) {
        setSelectedItem({ id: state.modifier_id, data: findData });
        setForm(findData.modifier);
        return;
      }
    }

    if (dataLocalize?.data?.datas) {
      setForm(dataLocalize.data.datas[0].modifier);
    }
  }, [state.modifier_id, dataLocalize?.data]);

  useEffect(() => {
    const progress = ref.current as any;

    if (isLoading || isLoadingActivatingLocalize || isLoadingUpdateModifier) {
      progress?.continuousStart();
    } else {
      progress?.complete();
    }
  }, [isLoading, isLoadingActivatingLocalize, isLoadingUpdateModifier]);

  if (!dataLocalize?.data) {
    return (
      <div className="flex flex-col flex-1">
        <div className="flex justify-between p-6 mb-4 border-b border-line-gray">
          <h2 className="font-bold text-base md:text-2xl">Localization Modifier</h2>
        </div>
      </div>
    );
  }

  if (dataLocalize.data.language_list.length === 1) {
    return (
      <div className="flex flex-1 flex-col items-center justify-center p-6 text-center">
        <GlobeIcon className="w-16 h-16 mb-6" />
        <p className="font-bold text-lg mb-2">You only select English for your default language</p>
        <p className="text-sm mb-8">Activate other languages that will be displayed on your menu in general settings</p>
        <button type="button" title="Go to General Settings" className="button-primary">Go to General Settings</button>
      </div>
    );
  }

  return (
    <div className="flex flex-col flex-1">
      <LoadingBar height={4} color="#0078D3" ref={ref} />

      <div className="flex justify-between p-6 mb-4 border-b border-line-gray">
        <div className="flex items-center">
          <button type="button" title="Back" className="bg-transparent" onClick={goBack}>
            <ChevronDownIcon className="h-6 w-6 transform rotate-90 mr-2" />
          </button>
          <h2 className="font-bold text-base md:text-2xl">Localization Modifier</h2>
        </div>

        <div className="hidden md:flex">
          <button className="button-gray py-2 px-4 mr-4" onClick={goBack} type="button" title="Cancel">
            Cancel
          </button>
          <button
            type="button"
            title={isLoadingUpdateModifier ? "Saving..." : "Save"}
            className="button-primary py-2 px-4"
            onClick={handleSave}
            disabled={isLoadingUpdateModifier || isLoadingActivatingLocalize}
          >
            {isLoadingUpdateModifier ? (
              <div className="flex">
                <SpinnerIcon className="animate-spin h-5 w-5" viewBox="0 0 24 24" />
                <span className="text-sm ml-2 font-normal">Saving</span>
              </div>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>

      <div className="flex flex-col flex-1 md:flex-row justify-between relative h-ful">
        {/* LEFT PANE || CHOICES */}
        <div className="flex flex-col w-full min-h-full overflow-auto px-4 md:w-3/12">
          {dataLocalize.data.datas.map((data) => (
            <p
              className={
                `cursor-pointer py-2 font-semibold text-base${data.id === selectedItem.id ? " text-active" : ""}`
              }
              key={data.id}
              onClick={() => {
                setFormShow(true);
                setSelectedItem({
                  id: data.id,
                  data,
                });
              }}
            >
              {data.name}
            </p>
          ))}
        </div>

        {/* RIGHT PANE || CONTENT */}
        <div
          className={
            isFormShow
              ? "absolute md:relative bg-white flex flex-col md:gap-4 w-full md:w-9/12 h-full justify-between min-w-0"
              : "hidden md:flex md:w-9/12 min-w-0"
          }
        >
          <div className="px-6 pb-6 w-full overflow-x-auto whitespace-nowrap flex-1">
            {form?.map((formItem) => (
              <div
                className="inline-block md:h-full shadow-lg rounded w-80 md:w-96 mr-4"
                style={{ verticalAlign: "bottom" }}
                key={formItem.lang_id}
              >
                <div className="p-4 border-b border-line-gray">
                  <div className="flex">
                    <p className="font-bold flex-1">
                      {dataLocalize.data?.language_list?.find(({ code }) => formItem.lang_id === code)?.name}
                    </p>
                    {formItem.lang_id !== dataLocalize.data?.main_language && (
                      <div className="flex items-center gap-2">
                        <p>Show</p>
                        <div className="relative inline-block w-8 mr-2 align-middle select-none">
                          <input
                            type="checkbox"
                            name={`is_show${formItem.lang_id}`}
                            id={`is_show${formItem.lang_id}`}
                            className="toggle-checkbox absolute block w-4 h-4 rounded-full bg-white border-2 appearance-none transition-all duration-300 ease-in cursor-pointer"
                            onChange={(e) => handleChange(e, formItem.lang_id, "is_show")}
                            checked={formItem.is_show}
                            disabled={isLoadingActivatingLocalize || isLoadingUpdateModifier}
                          />
                          <label
                            htmlFor={`is_show${formItem.lang_id}`}
                            className="toggle-label block overflow-hidden h-4 rounded-full bg-gray-300 transition-all duration-300 ease-in cursor-pointer"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <p
                    className={
                      `text-secondary text-xs mt-2 ${
                        formItem.lang_id === dataLocalize.data?.main_language ? "opacity-100" : "opacity-0"}`
                    }
                  >
                    Default Language
                  </p>
                </div>

                <form className={formItem.lang_id === "ar" ? "p-4 form-rtl" : "p-4"}>
                  {formItem.modifier_contents.map((content) => (
                    <Fragment key={content.id}>
                      <label htmlFor={`${formItem.lang_id}_${content.id}`} className="block mb-2">
                        {content.label_name}
                      </label>
                      <input
                        id={`${formItem.lang_id}_${content.id}`}
                        disabled={isLoadingActivatingLocalize || isLoadingUpdateModifier}
                        type="text"
                        className="input-base mb-4"
                        value={content.text}
                        onChange={(e) => handleChange(e, formItem.lang_id, "modifier_contents", content.id)}
                      />
                    </Fragment>
                  ))}
                </form>
              </div>
            ))}
          </div>
        </div>

        <button
          type="button"
          title={isLoadingUpdateModifier ? "Saving..." : "Save"}
          className="md:hidden button-primary m-4"
          onClick={handleSave}
          disabled={isLoadingUpdateModifier}
        >
          {isLoadingUpdateModifier ? (
            <div className="flex">
              <SpinnerIcon className="animate-spin h-5 w-5" viewBox="0 0 24 24" />
              <span className="text-sm ml-2 font-normal">Saving</span>
            </div>
          ) : (
            "Save"
          )}
        </button>
      </div>
    </div>
  );
}

export default ModifierLocalizePage;
