import clsx from "clsx";
import {
  forwardRef,
  memo,
  type Dispatch, type SetStateAction,
} from "react";
import { Link } from "react-router-dom";

import { type OrderFilterListResponse } from "../../services";

export interface Tab extends OrderFilterListResponse {
  text: string;
}

interface OrderFilterTabProps {
  data: Tab[];
  onTabClick: Dispatch<SetStateAction<string>>;
  selectedTab: string;
}

const OrderFilterTab = forwardRef<HTMLDivElement, OrderFilterTabProps>(({ data, onTabClick, selectedTab }, ref) => (
  <div className={clsx(
    "flex flex-row items-center justify-start gap-2",
    "px-4 md:px-6",
    "relative overflow-x-auto",
  )}
  >
    {data.map(({ id, total, text }) => {
      const isActive = selectedTab === id;

      return (
        <Link
          to={(loc) => ({
            ...loc,
            hash: id,
          })}
          key={id}
          id={`tab-${id}`}
          className={clsx(
            "p-2",
            "font-medium text-sm",
            "transition-all duration-300 ease-out",
            "flex items-center justify-center gap-2",
            isActive && "text-active",
          )}
          onClick={() => {
            onTabClick(id);
          }}
        >
          {text}
          <span
            id="counter"
            className={clsx(
              "text-[10px] text-white font-bold",
              "w-5 h-5 rounded-full",
              "flex items-center justify-center",
              "transition-all duration-300 ease-out",
              isActive ? "bg-primary" : "bg-gray-300",
            )}
          >
            {total > 99 ? "99+" : total}
          </span>
        </Link>
      );
    })}

    <div
      id="tab-border-bottom"
      ref={ref}
      className={clsx(
        "h-0.5 w-4",
        "bg-primary",
        "absolute bottom-0",
        "transition-all duration-300 ease-out",
      )}
    />
  </div>
));

export default memo(OrderFilterTab);
