import {
  useState, useRef, useEffect, MouseEvent,
} from "react";
import { useHistory } from "react-router-dom";
import { useForm, useFieldArray, SubmitHandler } from "react-hook-form";
import LoadingBar from "react-top-loading-bar";
import Popup from "reactjs-popup";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";

import { ContentHeader, LoadingAnimation, ModalConfirmation } from "../components";
import { useDebounce } from "../hooks";
import { getBreakpointValue } from "../utils";

import { ReactComponent as GlobeIcon } from "../assets/icons/ic_globe.svg";
import { ReactComponent as PlusIcon } from "../assets/icons/ic_plus.svg";
import { ReactComponent as EditIcon } from "../assets/icons/ic_edit.svg";
import { ReactComponent as DeleteIcon } from "../assets/icons/ic_trash_bold.svg";
import { ReactComponent as ChevronDownIcon } from "../assets/icons/ic_chevron_down.svg";
import { ReactComponent as SpinnerIcon } from "../assets/icons/ic_spinner.svg";
import { ReactComponent as IllustrationNoItems } from "../assets/illustration_no_items.svg";

import {
  DetailModiferModel,
  useGetAllModifierQuery,
  useGetModifierQuery,
  usePostModifierMutation,
  useUpdateModifierMutation,
  useDeleteModifierMutation,
  useDeleteMultipleModifiersMutation,
  useActiveModifierMutation,
  useUserInfoQuery,
} from "../services";

interface Confirmation {
  isOpen: boolean;
  data: string | null;
}

interface Detail {
  isOpen: boolean;
  id: string | null;
}

const defaultModifierItem = {
  id: null,
  name: "",
  price: 0,
  is_active: true,
};

function ModifiersPage() {
  const ref = useRef(null);
  const { push } = useHistory();
  const [modalConfirmation, setModalConfirmation] = useState<Confirmation>({
    isOpen: false,
    data: null,
  });
  const [checklist, setChecklist] = useState<string[]>([]);
  const [openDetail, setOpenDetail] = useState<Detail>({
    isOpen: false,
    id: null,
  });
  const [keyword, setKeyword] = useState<string>("");
  const debouncedKeyword = useDebounce<string>(keyword, 500);
  const {
    register, control, handleSubmit, reset, setValue,
  } = useForm<DetailModiferModel>({
    defaultValues: {
      group_name: "",
      modifier_items: [defaultModifierItem],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "modifier_items",
    keyName: "key",
  });
  const [activeModifier, { isLoading: isLoadingActivatingModifier }] = useActiveModifierMutation();
  const [createModifier, { isLoading: isLoadingCreateModifier }] = usePostModifierMutation();
  const [updateModifier, { isLoading: isLoadingUpdateModifier }] = useUpdateModifierMutation();
  const [deleteModifier, { isLoading: isLoadingDeleteModifier }] = useDeleteModifierMutation();
  const [deleteMultipleModifiers,
    { isLoading: isLoadingDeleteMultipleModifier },
  ] = useDeleteMultipleModifiersMutation();
  const { data: dataModifiers, isLoading: isLoadingModifiers, refetch } = useGetAllModifierQuery();
  const { data: dataModifier, isLoading: isLoadingModifier } = useGetModifierQuery(openDetail.id ?? "", {
    skip: openDetail.id === null,
  });
  const { data: dataUserInfo, isLoading: isLoadingUserInfo } = useUserInfoQuery();

  const isMobile = getBreakpointValue("md") > window.innerWidth;

  /**
   * @description Function to handle check the modifier
   * @param id
   */
  const handleChecklist = (id: string) => {
    if (checklist.find((checkedID) => checkedID === id)) {
      const removedChecklist = checklist.filter((checkedID) => checkedID !== id);
      setChecklist(removedChecklist);
    } else {
      setChecklist((prevState) => [...prevState, id]);
    }
  };

  /**
   * @description Function to handle search modifier
   */
  const searchModifiers = dataModifiers?.data
    ? dataModifiers.data.filter(({ group_name }) => group_name.toLowerCase().includes(debouncedKeyword.toLowerCase()))
    : [];

  /**
   * @description Function to handle toggle active modifier
   * @param id<string>
   */
  const toggleActive = (id: string) => {
    const isActive = dataModifiers?.data?.find((modifier) => modifier.id === id)?.is_active ?? false;

    activeModifier(id)
      .unwrap()
      .then(() => {
        refetch();
        toast.success(`${isActive ? "Deactivate" : "Activate"} success`);
      })
      .catch((error) => {
        Sentry.captureException(error);
        toast.error(`${isActive ? "Deactivate" : "Active"} failed`);
      });
  };

  /**
   * @description Function to handle click go to localize
   * @param id<string>
   */
  const goToLocalize = (id: string) => {
    push({
      pathname: "/modifier-localize",
      state: {
        modifier_id: id,
      },
    });
  };

  /**
   * @description Function to handle open modal confirmation and set id to modalConfirmation.data
   * @param id<string>
   */
  const handleDeleteModifier = (id: string) => {
    setModalConfirmation({ isOpen: true, data: id });
  };

  /**
   * @description Function to handle callback of modal confirmation to delete modifier
   */
  const callbackConfirmation = () => {
    if (!modalConfirmation.data) return;

    deleteModifier(modalConfirmation.data)
      .unwrap()
      .then(() => {
        refetch();
        toast.success("Successfully delete modifier");
      })
      .catch((error) => {
        Sentry.captureException(error);
        toast.error(error.data?.message ?? "Failed to create modifier");
      })
      .finally(() => {
        setModalConfirmation({ isOpen: false, data: null });
      });
  };

  /**
   * @description Function to handle create/update modifier
   * @param data<DetailModiferModel>
   */
  const onSubmit: SubmitHandler<DetailModiferModel> = (data) => {
    if (data.group_name === "") {
      toast.error("Group name is required");
      return;
    }
    if (data.modifier_items.filter((item) => item.name === "").length > 0) {
      toast.error("Name is required");
      return;
    }

    if (openDetail.id) {
      updateModifier(data)
        .unwrap()
        .then(() => {
          refetch();
          reset();
          setOpenDetail({ id: null, isOpen: false });
        })
        .catch((error) => {
          Sentry.captureException(error);
          toast.error(error.data?.message ?? "Failed to update modifier");
        });
    } else {
      createModifier(data)
        .unwrap()
        .then(() => {
          refetch();
          reset();
          setOpenDetail({ id: null, isOpen: false });
        })
        .catch((error) => {
          Sentry.captureException(error);
          toast.error(error.data?.message ?? "Failed to create modifier");
        });
    }
  };

  /**
   * @description Function to handle add modifier item
   * @param e<React.FormEvent<HTMLButtonElement>>
   */
  const handleAddModifierItem = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    append(defaultModifierItem);
  };

  /**
   * @description Function to handle remove modifier item by index
   * @param index<number>
   */
  const handleDeleteModifer = (index: number) => {
    remove(index);
  };

  /**
   * @description Function to handle delete selected modifiers
   */
  const handleDeleteSelectedModifier = () => {
    deleteMultipleModifiers(checklist)
      .unwrap()
      .then(() => {
        refetch();
        setChecklist([]);
        toast.success("Successfully delete selected modifiers");
      })
      .catch((error) => {
        Sentry.captureException(error);
        toast.error("Failed to delete selected modifiers");
      });
  };

  useEffect(() => {
    if (dataModifier?.data) {
      reset(dataModifier.data);
    }
  }, [dataModifier, reset]);

  useEffect(() => {
    const progress = ref.current as any;
    if (isLoadingModifiers || isLoadingModifier || isLoadingUserInfo) {
      progress?.continuousStart();
    } else {
      progress?.complete();
    }
  }, [isLoadingModifier, isLoadingModifiers, isLoadingUserInfo]);

  const modifiers = dataModifiers?.data ?? [];

  if (!dataModifiers) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <LoadingAnimation />
      </div>
    );
  }

  return (
    <>
      <LoadingBar height={4} color="#0078D3" ref={ref} />
      <div className="flex-1">
        <ContentHeader
          title="Modifier"
          subtitle="On this page you can manage your group modifier group"
          buttonTitle="New Modifier"
          onButtonClick={() => {
            setValue("group_name", "");
            setValue("modifier_items", [defaultModifierItem]);
            setOpenDetail({ id: null, isOpen: true });
          }}
          onSearch={setKeyword}
          value={keyword}
        />

        <div className="mx-4 mt-4">
          {/* delete button */}
          {modifiers.length > 0 && checklist.length > 0 && (
            <div className="flex mt-4">
              <button
                type="button"
                title="Delete Selected"
                className="button-error text-sm py-2 px-4 font-normal rounded"
                onClick={handleDeleteSelectedModifier}
                disabled={isLoadingDeleteMultipleModifier
                  || isLoadingDeleteModifier
                  || isLoadingModifiers
                  || isLoadingCreateModifier
                  || isLoadingUpdateModifier
                  || isLoadingActivatingModifier
                  || isLoadingModifier}
              >
                Delete Selected
              </button>
            </div>
          )}

          <div className="hidden md:flex w-full p-4 bg-gray-100 font-medium items-center mt-4">
            <div className="pr-4">
              <label className="flex items-center space-x-3">
                <input
                  type="checkbox"
                  name="checked-demo"
                  className="form-tick appearance-none bg-white bg-check h-4 w-4 border border-gray-300 rounded checked:bg-primary checked:border-transparent focus:outline-none cursor-pointer"
                  disabled={isLoadingDeleteMultipleModifier
                  || isLoadingDeleteModifier
                  || isLoadingModifiers
                  || isLoadingCreateModifier
                  || isLoadingUpdateModifier
                  || isLoadingActivatingModifier
                  || isLoadingModifier}
                  onChange={() => {
                    if (modifiers.length === checklist.length) {
                      setChecklist([]);
                    } else {
                      setChecklist(modifiers.map(({ id }) => id));
                    }
                  }}
                  checked={modifiers.length > 0 && checklist.length === modifiers.length}
                />
              </label>
            </div>
            <div className="flex flex-1 flex-col md:flex-row">
              <p className="md:pl-4 md:w-1/2 border-l">Group Name</p>
              <p className="md:pl-4 flex-1 border-l">Modifiers</p>
            </div>
            <div className="flex flex-1 flex-col-reverse md:flex-row">
              <p className="border-l w-24 text-center">Active</p>
              <p className="md:pl-4 border-l flex-1">Action</p>
            </div>
          </div>

          {searchModifiers.length > 0 ? (
            searchModifiers.map((mod) => {
              const isChecked = !!checklist.find((id) => id === mod.id);

              return (
                <div
                  className="flex w-full text-sm p-4 md:border-b md:border-gray-100 rounded-md md:rounded-none shadow-lg md:shadow-none mb-3 md:mb-0 items-center"
                  key={mod.id}
                  // onClick={() => {
                  //   if (isMobile) {
                  //     setOpenDetail({ id: mod.id, isOpen: true });
                  //   }
                  // }}
                >
                  <div className="pr-4 hidden md:block">
                    <label className="flex items-center space-x-3">
                      <input
                        key={isChecked ? "1" : "0"} // hack to force update
                        type="checkbox"
                        name="checked-modifiers"
                        className="form-tick appearance-none bg-white bg-check h-4 w-4 border border-gray-300 rounded checked:bg-primary checked:border-transparent focus:outline-none cursor-pointer"
                        onChange={() => handleChecklist(mod.id)}
                        checked={isChecked}
                        disabled={isLoadingDeleteMultipleModifier
                          || isLoadingDeleteModifier
                          || isLoadingModifiers
                          || isLoadingCreateModifier
                          || isLoadingActivatingModifier
                          || isLoadingUpdateModifier
                          || isLoadingModifier}
                      />
                    </label>
                  </div>
                  <div className="flex flex-1 flex-col md:flex-row gap-4 md:gap-0">
                    <p className="md:pl-4 md:w-1/2 font-bold md:font-normal">{mod.group_name}</p>
                    <p className="md:pl-4 flex-1">
                      {mod.modifiers_total}
                      {" "}
                      <span className="md:hidden">Modifiers</span>
                    </p>
                  </div>
                  <div className="flex flex-1 flex-col md:flex-row gap-4 md:gap-0">
                    <div className="md:w-24 text-right md:text-center">
                      <div className="relative inline-block w-8 align-middle text-center select-none">
                        <input
                          type="checkbox"
                          id={`toggle-${mod.id}`}
                          name={`toggle-${mod.id}`}
                          className="toggle-checkbox absolute block w-4 h-4 rounded-full bg-white border-2 appearance-none transition-all duration-300 ease-in cursor-pointer"
                          onChange={() => toggleActive(mod.id)}
                          checked={mod.is_active}
                          disabled={isLoadingDeleteMultipleModifier
                            || isLoadingDeleteModifier
                            || isLoadingModifiers
                            || isLoadingCreateModifier
                            || isLoadingUpdateModifier
                            || isLoadingActivatingModifier
                            || isLoadingModifier}
                        />
                        <label
                          htmlFor={`toggle-${mod.id}`}
                          className="toggle-label block overflow-hidden h-4 rounded-full bg-gray-300 transition-all duration-300 ease-in cursor-pointer"
                        />
                      </div>
                    </div>

                    <div className="md:pl-4 flex flex-1 gap-4 justify-end md:justify-start">
                      <button
                        type="button"
                        title="Localize"
                        className="flex items-center gap-2"
                        onClick={() => goToLocalize(mod.id)}
                      >
                        <GlobeIcon className="h-4 w-4" />
                        {" "}
                        <span>Localize</span>
                      </button>

                      <button
                        type="button"
                        title="Edit"
                        className="flex items-center gap-2"
                        onClick={() => setOpenDetail({ id: mod.id, isOpen: true })}
                      >
                        <EditIcon className="h-4 w-4" />
                        {" "}
                        <span className="hidden md:block">Edit</span>
                      </button>

                      <button
                        type="button"
                        title="Delete"
                        className="flex items-center gap-2"
                        onClick={() => handleDeleteModifier(mod.id)}
                      >
                        <DeleteIcon className="h-4 w-4" />
                        {" "}
                        <span className="hidden md:block">Delete</span>
                      </button>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="h-full w-full mt-16 flex-1 flex flex-col gap-1 justify-center items-center">
              <IllustrationNoItems className="h-64 w-64 mb-4" />

              <p className="text-lg">
                There&#39;s no
                {debouncedKeyword.length > 0 ? debouncedKeyword : "modifier"}
              </p>
              <p className="text-secondary">
                You haven&#39;t add any
                {" "}
                {debouncedKeyword.length > 0 ? `${debouncedKeyword} in modifier` : "modifier"}
              </p>
            </div>
          )}
        </div>

        <ModalConfirmation
          isOpen={modalConfirmation.isOpen}
          content="Are you sure?"
          onCloseModal={() => setModalConfirmation({ isOpen: false, data: null })}
          onConfirmModal={callbackConfirmation}
          confirmationText="Delete"
          confirmationLoading={isLoadingDeleteModifier}
        />

        {/* modal modifier detail add / edit */}
        <Popup
          modal
          open={openDetail.isOpen}
          onClose={() => setOpenDetail({ id: null, isOpen: false })}
          closeOnDocumentClick={!isMobile}
          closeOnEscape
          lockScroll
          contentStyle={{
            maxHeight: isMobile ? "100%" : "80%",
            maxWidth: isMobile ? "90%" : 600,
            width: "100%",
            overflowY: "auto",
            backgroundColor: "white",
            borderRadius: 8,
          }}
        >
          <div className="w-full md:w-auto inset-0 md:relative py-4 bg-white">
            <div className="flex pb-6 pl-2 md:hidden">
              <button
                type="button"
                className="bg-transparent focus:outline-none"
                onClick={() => setOpenDetail({ id: null, isOpen: false })}
              >
                <ChevronDownIcon className="h-6 w-6 transform rotate-90 mr-2" />
              </button>
              <h2 className="font-bold text-2xl pr-4">Add New Modifier</h2>
            </div>

            <form>
              <div className="flex flex-col md:flex-row gap-1 md:gap-4 md:items-center w-full px-4 md:px-6">
                <label htmlFor="detail_group_name" className="md:w-5/12">
                  Modifier Group Name
                </label>
                <input
                  type="text"
                  placeholder="Modifier Name (e.g. “Toppings”, “Sauce”)"
                  className="input-base"
                  disabled={isLoadingModifier || isLoadingCreateModifier || isLoadingUpdateModifier}
                  {...register("group_name")}
                />
              </div>

              <div className="flex flex-col w-full my-6 py-6 border-t border-b border-line-gray px-4 md:px-6">
                {fields.map((field, index) => (
                  <div className="flex flex-col shadow md:shadow-none rounded-md p-4 md:p-0 mb-4" key={field.key}>
                    <div className="flex items-center w-full gap-4">
                      {!isMobile && (
                        <>
                          <div
                            className="relative w-7 align-middle text-center select-none inline-block"
                            style={{ minWidth: "1.75rem" }}
                          >
                            <input
                              type="checkbox"
                              id={`toggle-${field.key}`}
                              disabled={isLoadingModifier || isLoadingCreateModifier || isLoadingUpdateModifier}
                              className="toggle-checkbox absolute block w-4 h-4 rounded-full bg-white border-2 appearance-none transition-all duration-300 ease-in cursor-pointer"
                              {...register(`modifier_items.${index}.is_active` as const)}
                            />
                            <label
                              htmlFor={`toggle-${field.key}`}
                              className="toggle-label block overflow-hidden h-4 rounded-full bg-gray-300 transition-all duration-300 ease-in cursor-pointer"
                            />
                          </div>
                          <p className="-ml-2 hidden md:block">Active</p>
                        </>
                      )}

                      <input
                        type="text"
                        disabled={isLoadingModifier || isLoadingCreateModifier || isLoadingUpdateModifier}
                        placeholder="Modifier Name (e.g. “Cheese”, “Level 1”)"
                        className="input-base focus:outline-none h-10 text-xs md:text-base"
                        {...register(`modifier_items.${index}.name` as const)}
                      />

                      <div className="flex rounded">
                        <input
                          type="number"
                          min={0}
                          disabled={isLoadingModifier || isLoadingCreateModifier || isLoadingUpdateModifier}
                          className="input-base rounded-r-none h-10 text-xs md:text-base"
                          {...register(`modifier_items.${index}.price` as const, {
                            valueAsNumber: true,
                          })}
                        />
                        <p className="bg-line-gray text-xs md:text-sm rounded-r h-10 flex items-center px-2">
                          {dataUserInfo?.data?.main_currency ?? "USD"}
                        </p>
                      </div>

                      <button
                        type="button"
                        title="Delete"
                        className="bg-red-100 border border-red-500 rounded w-10 h-10 p-3 text-center self-end hidden md:block"
                        onClick={() => handleDeleteModifer(index)}
                        disabled={isLoadingModifier || isLoadingCreateModifier || isLoadingUpdateModifier}
                      >
                        <DeleteIcon className="h-4 w-4 text-red-500" />
                      </button>
                    </div>

                    {isMobile && (
                      <div className="flex justify-between mt-4">
                        <div className="flex items-center gap-2">
                          <div className="relative w-7 align-middle text-center select-none">
                            <input
                              type="checkbox"
                              id={`toggle-${field.key}`}
                              disabled={isLoadingModifier || isLoadingCreateModifier || isLoadingUpdateModifier}
                              className="toggle-checkbox absolute block w-4 h-4 rounded-full bg-white border-2 appearance-none transition-all duration-300 ease-in cursor-pointer"
                              {...register(`modifier_items.${index}.is_active` as const)}
                            />
                            <label
                              htmlFor={`toggle-${field.key}`}
                              className="toggle-label block overflow-hidden h-4 rounded-full bg-gray-300 transition-all duration-300 ease-in cursor-pointer"
                            />
                          </div>
                          <p className="text-sm">Active</p>
                        </div>

                        <button
                          type="button"
                          title="Delete"
                          onClick={() => handleDeleteModifer(index)}
                        >
                          <DeleteIcon className="h-5 w-5" color="#666666" />
                        </button>
                      </div>
                    )}
                  </div>
                ))}

                <button
                  type="button"
                  title="Add More"
                  className="button-link flex gap-2 items-center font-medium self-center md:self-start"
                  onClick={handleAddModifierItem}
                >
                  <PlusIcon className="hidden md:block h-4 w-4 bg-primary rounded-full" color="white" />
                  <span>Add More</span>
                </button>
              </div>

              <div className="flex gap-4 px-4 md:px-6 w-full md:w-auto">
                <button
                  type="button"
                  title="Cancel"
                  className="button-gray py-2 px-5 flex-1 md:flex-none"
                  onClick={(e) => {
                    e.preventDefault();
                    reset();
                    setOpenDetail({ id: null, isOpen: false });
                  }}
                >
                  Cancel
                </button>

                <button
                  type="button"
                  title={(isLoadingCreateModifier || isLoadingUpdateModifier || isLoadingModifier) ? "Saving..." : "Save"}
                  className="button-primary py-2 px-5 flex-1 md:flex-none"
                  onClick={handleSubmit(onSubmit)}
                  disabled={isLoadingCreateModifier || isLoadingUpdateModifier || isLoadingModifier}
                >
                  {isLoadingCreateModifier || isLoadingUpdateModifier || isLoadingModifier ? (
                    <SpinnerIcon className="animate-spin h-5 w-5 inline-block" viewBox="0 0 24 24" />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </form>
          </div>
        </Popup>
      </div>
    </>
  );
}

export default ModifiersPage;
