import {
  HTMLAttributes,
  Reducer,
  useCallback,
  useReducer,
  type ButtonHTMLAttributes,
  type ReactNode,
} from "react";

interface UseDropdownProps {
  id: string;
}

interface DropdownTogglerProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
}

interface DropdownContentProps extends Exclude<HTMLAttributes<HTMLDivElement>, "id"> {
  children: ReactNode;
}

interface UseDropdownReturnType {
  isOpen: boolean;
  DropdownToggler: (props: DropdownTogglerProps) => JSX.Element;
  DropdownContent: (props: DropdownContentProps) => JSX.Element;
}

const reducer: Reducer<{ id: string, open: boolean }, {
  type: "toggle-dropdown"
}> = (state, action) => {
  switch (action.type) {
  case "toggle-dropdown":
    return {
      ...state,
      open: !state.open,
    };

  default:
    return {
      ...state,
      open: !state.open,
    };
  }
};

const useDropdown = ({ id }: Partial<UseDropdownProps>): UseDropdownReturnType => {
  const [state, setState] = useReducer(reducer, {
    id: id || "default-dropdown-id",
    open: false,
  });

  const handleToggleDropdown = useCallback(() => {
    setState({
      type: "toggle-dropdown",
    });
  }, []);

  const dropdownToggler = ({ children, ...props }: DropdownTogglerProps) => (
    // eslint-disable-next-line react/button-has-type
    <button {...props} onClick={handleToggleDropdown} data-dropdown-target={state.id}>
      {children}
    </button>
  );

  const dropdownContent = ({ children, ...props }: DropdownContentProps) => {
    if (state.open) {
      return (
        <div id={state.id} {...props}>
          {children}
        </div>
      );
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  };

  return {
    DropdownToggler: dropdownToggler,
    DropdownContent: dropdownContent,
    isOpen: state.open,
  };
};

export default useDropdown;
